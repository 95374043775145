import React, { useCallback, useEffect, useState } from 'react';
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import { updateNotifications, updateQuizzSettings } from "../../../../firebase/form";
import CustomModal from "../../../../shared/CustomModal";
import MenuItem from "../MenuItem";
import { Box, Button, Center, HStack, Input } from "@chakra-ui/react";
import { Alert, Form, Select, Space, Tabs, Tag, Tooltip } from "antd";
import { StyledSelect } from "../../../../shared/form/CustomSearchableSelect";
import { StyledInput } from "../../../../shared/form/InlineTextField";
import CustomHtmlWysiwyg from "../../../../shared/form/CustomHTMLWysiwyg";
import * as Yup from "yup";
import { DEFAULT_INPUT_HEIGHT, useDefaultFormik } from "../../../../shared/form/useFormBuilder";
import colors from "../../../../utils/commonStyles";
import CustomWysiwyg, { isDraftContentEmpty } from "../../../../shared/CustomWysiwyg";
import { renderNameBlock } from "../../fieldTypes/LegalField/LegalField";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import { isEmail, rawToStyledHtml } from "../../../../utils/common";
import { indexChars } from "../QuizzVariablesSettings/QuizzVariablesSettingsModal";
import _ from 'lodash';
import { useSelector } from 'react-redux';

const renderSelectEmailFieldOptions = (items = []) => {

  return items.map((o, index) => {
    if (o.label) {
      return <Select.Option value={`${o.fieldId}:${o.id}`}>
        <HStack>
          <Box>{o.label || '---'}</Box>
        </HStack>
      </Select.Option>
    }

    return <Select.Option value={o.id}>
      <HStack>
        {!isDraftContentEmpty(o.nameDraftContent) ? rawToStyledHtml(o.nameDraftContent) : <Box>---</Box>}
      </HStack>
    </Select.Option>
  })
}

const LabelTooltip = ({ variables = [] }) => {
  return <Tooltip
    zIndex={999999}
    title={<Box p={'10px'}>
      Variables that you can use:
      {variables.map(v => <Tag color={'blue'}>{v}</Tag>)}
    </Box>}
  >
    <span>
      <QuestionCircleOutlined style={{ marginLeft: '6px' }} />
    </span>
  </Tooltip>
}

const validationSchema = Yup.object().shape({
  notifyMeSendTo: Yup.array().when('notifyMeIsActive', (value) => {
    if (value) {
      return Yup.array().of(
        Yup.string()
          .email("Enter a valid email")
      )
    }
  })
});

const EmailNotificationsModal = ({ isOpen, onClose }) => {

  const [saving, setSaving] = useState()
  const [activeTab, setActiveTab] = useState('1')

  const [wysiwygId, setWysiwygId] = useState(1)

  const [
    formData = {},
    fields,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.setFormData,
  ])

  const { isAuthenticated, user } = useSelector(state => state.persistentState)


  const formik = useDefaultFormik({
    initialValues: {
      notifyMeIsActive: false,
      notifyMeSendTo: [],
      notifyMeSubject: '',
      notifyMeContent: renderNameBlock(''),
      notifyRespondentContent: renderNameBlock(''),
    },
    validationSchema,
    onSubmit(values) {
      console.log(values)
      saveHandler(values)
    }
  });
  console.log(formData?.notifications)
  useEffect(() => {
    if (!_.isEmpty(formData)) {
      formik.setValues(formData?.notifications)
    }
  }, [formData])

  const saveHandler = async (values) => {
    setSaving(true)
    try {

      const requestData = {
        formId: formData.id,
        notifications: values
      }

      await updateNotifications(requestData)

      setFormData({ notifications: values })
      onClose()

    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }

  const isEmailFieldPresent = fields.some(f => f.type === 'email')

  const isEmailInContact = fields.filter(f => f.type === 'contactForm' && f.options.some(o => o.type === 'email')).map(f => f.options.map(o => ({ ...o, fieldId: f.id }))).flat().filter(e => e.type === 'email') || []
  console.log(isEmailInContact)
  const notifyToOptions = [
    {
      value: user?.email,
      label: user?.email,
    }
  ]

  return <CustomModal
    title={'Notifications'}
    zIndex={12}
    onOk={() => formik.handleSubmit()}
    okButtonProps={{
      isLoading: saving
    }}
    isOpen={isOpen}
    onClose={onClose}
    size={'3xl'}
  >
    <Box>
      <Tabs activeTab={activeTab} onChange={e => setActiveTab(e)}>
        <Tabs.TabPane tab="Notify me" key="1">
          <Box mt={'20px'}>
            <Form layout={'vertical'}>

              <Form.Item
                label={'Activate email notifications'}
              >
                <Box ml={'5px'}>
                  <CustomSwitch
                    value={formik.values.notifyMeIsActive}
                    onChange={e => formik.setFieldValue('notifyMeIsActive', e)}
                  />
                </Box>

              </Form.Item>

              {formik.values.notifyMeIsActive && <>

                <Form.Item
                  label={'Send a notification to'}
                  help={formik.errors.notifyMeSendTo}
                  validateStatus={formik.errors.notifyMeSendTo && 'error'}
                >
                  <StyledSelect
                    mode={'tags'}
                    bg={'white'}
                    options={notifyToOptions}
                    value={formik.values.notifyMeSendTo}
                    onChange={e => {
                      const val = e || []
                      if (val.some(v => !isEmail(v))) {
                        return
                      }
                      formik.setFieldValue('notifyMeSendTo', e)
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={<Space size={2}>
                    Subject
                    <LabelTooltip variables={[
                      '{FORM_NAME}'
                    ]} />
                  </Space>}
                >
                  <StyledInput
                    bg={'white'}
                    value={formik.values.notifyMeSubject}
                    onChange={e => formik.setFieldValue('notifyMeSubject', e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label={<Space size={2}>
                    Message
                    <LabelTooltip variables={[
                      '{FORM_NAME}',
                      '{ALL_RESPONSES}',
                    ]} />
                  </Space>}
                >
                  <Box
                    border={'1px solid'}
                    borderColor={colors.border}
                    bg={'white'}
                    borderRadius={'6px'}
                    p={'5px'}
                  >
                    <CustomWysiwyg
                      showMentions={false}
                      wysiwygId={wysiwygId}
                      value={formik.values.notifyMeContent}
                      onChange={val => formik.setFieldValue('notifyMeContent', val)}
                      bg={'white'}
                    />
                  </Box>

                </Form.Item>

              </>}

            </Form>
          </Box>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Notify respondents" key="2">

          {!isEmailFieldPresent && <Box my={'10px'}>
            <Alert
              message="To send someone an email the moment they complete your typeform, you need to add an Email question"
              type="info" showIcon />
          </Box>}

          {isEmailFieldPresent && <Box mt={'20px'}>

            <Form layout={'vertical'}>

              <Form.Item
                label={'Send a custom email to respondent'}
              >
                <Box ml={'5px'}>
                  <CustomSwitch
                    value={formik.values.notifyRespondentIsActive}
                    onChange={e => formik.setFieldValue('notifyRespondentIsActive', e)}
                  />
                </Box>

              </Form.Item>

              {formik.values.notifyRespondentIsActive && <>

                <Form.Item
                  label={'Send a notification to'}
                  help={formik.errors.notifyRespondentSendTo}
                  validateStatus={formik.errors.notifyRespondentSendTo && 'error'}
                >
                  <StyledSelect
                    mode={'multiple'}
                    bg={'white'}
                    value={formik.values.notifyRespondentSendTo}
                    onChange={e => {
                      formik.setFieldValue('notifyRespondentSendTo', e)
                    }}
                  >
                    {renderSelectEmailFieldOptions([...fields.filter(f => f.type === 'email'), ...isEmailInContact])}
                  </StyledSelect>
                </Form.Item>

                <Form.Item
                  label={<Space size={2}>
                    Subject
                  </Space>}
                >
                  <StyledInput
                    bg={'white'}
                    value={formik.values.notifyRespondentSubject}
                    onChange={e => formik.setFieldValue('notifyRespondentSubject', e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label={<Space size={2}>
                    Message
                  </Space>}
                >
                  <Box
                    border={'1px solid'}
                    borderColor={colors.border}
                    bg={'white'}
                    borderRadius={'6px'}
                    p={'5px'}
                  >
                    <CustomWysiwyg
                      showMentions={false}
                      wysiwygId={wysiwygId}
                      value={formik.values.notifyRespondentContent}
                      onChange={val => formik.setFieldValue('notifyRespondentContent', val)}
                      bg={'white'}
                    />
                  </Box>

                </Form.Item>

              </>}

            </Form>
          </Box>}

        </Tabs.TabPane>
      </Tabs>


    </Box>
  </CustomModal>
}

const EmailNotifications = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <MenuItem
        onClick={() => setOpen(true)}
      >
        Notifications >
      </MenuItem>
      {isOpen && <EmailNotificationsModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />}
    </div>
  );
};

export default EmailNotifications;