import React, {useEffect, useState} from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import {Divider, Box, HStack, Center} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import {Space} from "antd";
import {HeartOutlined, LikeOutlined, StarOutlined} from "@ant-design/icons";


const Configurator = ({data = {}, onChange}) => {

  const {required, btnText} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>

    <FieldInlineWrapper
      label={'Button text'}
    >
    </FieldInlineWrapper>

    <Box mb={'10px'}>
      <InlineTextField
        value={btnText}
        onChange={val => onChangeHandler('btnText', val)}
      />
    </Box>

  </Box>
}

const WelcomeScreenSettings = ({activeField = {}, onChange}) => {


  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      {/*<SectionHeader*/}
      {/*  my={'10px'}*/}
      {/*>*/}
      {/*  Settings*/}
      {/*</SectionHeader>*/}
      {/*<Configurator*/}
      {/*  data={generalSettings}*/}
      {/*  onChange={onGeneralSettingsChange}*/}
      {/*/>*/}
      {/*<Divider*/}
      {/*  my={'5px'}*/}
      {/*  borderColor={'#cccccc'}*/}
      {/*/>*/}

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};


export default WelcomeScreenSettings;