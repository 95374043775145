import React from 'react';
import {Center, Box} from '@chakra-ui/react';
import SideBar from "../pages/Home/components/SideBar";


const AuthLayout = ({children}) => {
  return (
    <Box
      h={'100vh'}
    >
      <Box
        display={'flex'}
      >
        <SideBar/>

        <Box
          flex={1}
          p={'20px'}
          bg={'#F2F3F7'}
          overflow={'auto'}
          h={'100vh'}

          // bg={'#FAFAFA'}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;