import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {message, Spin, Upload} from 'antd';
import React, {useEffect, useState} from 'react';
import {uploadIllustration} from "../../../utils/illustrations2";
import {Center, Box, VStack} from '@chakra-ui/react';
import colors from "../../../utils/commonStyles";
import {uploadImage} from "../../../firebase/form";
import {useParams} from "react-router-dom";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  let valid = true
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'

  const isGif = file.type === 'image/gif';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG/GIF file!');
    valid = false
  }

  const isLt14M = file.size / 1024 / 1024 < 14;

  const isLt4M = file.size / 1024 / 1024 < 4;

  const isTooLargeGif = isGif && !isLt4M

  if (isTooLargeGif) {
    message.error('GIF must smaller than 4MB!');
    valid = false
  }

  if (!isLt14M) {
    message.error('Image must smaller than 14MB!');
    valid = false

  }
  return valid;
};

const UploadSection = () => {

  const [
    formData,
    onImageModalChange
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.onImageModalChange,
  ])

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info) => {

    if (info.file.status !== 'uploading') return

    getBase64(info.file.originFileObj, async url => {
      setImageUrl({url, media_type: info.file?.type, file_name: info.file?.name})
      saveImage({url, media_type: info.file?.type, file_name: info.file?.name})
    });
  };

  const saveImage = async ({url, media_type, file_name}) => {
    setLoading(true);
    try {
      const data = await uploadImage({
        base64: url,
        media_type,
        file_name,
        organizationId: formData?.organization
      })
      onImageModalChange({ imageUrl: data.url })

      setImageUrl(null)
    } catch (err) {

    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   if (imageUrl) {
  //     saveImage(imageUrl)
  //   }
  // }, [imageUrl])

  const loader = loading && <Center>
    <Spin/>
  </Center>

  const uploadContent = (
    <Center>
      <VStack>
        <Box>
          {uploadIllustration}
        </Box>
        <Box fontWeight={700}>
          Upload or drop an image right here
        </Box>
        <Box color={colors.shadowColor} fontSize={'12px'}>
          GIF (up to 4 MB), PNG or JPG (up to 14 MB).
        </Box>
      </VStack>

    </Center>
  );

  return (
    <Upload.Dragger
      name="avatar"
      accept={['.jpeg', '.png', '.gif', '.jpg']}
      // listType="picture-card"
      showUploadList={false}
      customRequest={({file, onSuccess}) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      }}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {loading ? loader : uploadContent}
    </Upload.Dragger>
  );
};


export default UploadSection;