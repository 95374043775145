import React, {useCallback, useEffect, useState} from 'react';
import CustomModal from "../../../shared/CustomModal";
import {modalStore} from "../../../utils/common";
import {Box, Center} from "@chakra-ui/react";
import {StyledInput} from "../../../shared/form/InlineTextField";
import {createForm, createWorkspace, deleteWorkspace, updateWorkspace} from "../../../firebase/form";
import {Form} from "antd";
import {useHistory, useParams} from "react-router-dom";
import {useQueryClient} from "react-query";

export const useAddWorkspaceModalStore = modalStore()

const validateFnc = (name) => {
  if (!name) {
    return {
      error: true,
      message: 'This field is required.'
    }
  }
  if (name.length > 30) {
    return {
      error: true,
      message: 'Max 30 characters.'
    }
  }

  return {
    error: false
  }
}

const AddWorkspaceModal = ({workspaces}) => {
  const {organizationId, workspaceId} = useParams()

  const queryClient = useQueryClient()

  const history = useHistory()

  const [name, setName] = useState()

  const [error, setError] = useState()

  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useAddWorkspaceModalStore(store => [store.isOpen, store.onClose, store.data])

  const {update, delete: deleteMode} = data

  const onCloseHandler = () => {
    setName('')
    onClose()
  }

  useEffect(() => {
    if (data.name) {
      setName(data.name)
    }
  }, [data])

  const createWorkspaceHandler = useCallback(
    async (values) => {

      const validateResults = validateFnc(values.name)

      if (!deleteMode && validateResults.error) {
        return setError(validateResults.message)
      }

      setError('')

      setLoading(true)

      try {

        const requestData = {
          ...values,
          id: workspaceId
        }

        if (update) {
          await updateWorkspace(requestData)
        } else if (deleteMode) {
          await deleteWorkspace(requestData)
          const findDefaultWorkspaceId = workspaces.find(w => w.isDefault)?.id
          history.push(`/organization/${organizationId}/workspace/${findDefaultWorkspaceId}`)
        } else {
          const data = await createWorkspace(requestData)
          history.push(`/organization/${organizationId}/workspace/${data?.id}`)
        }

        queryClient.invalidateQueries('organizations')

        onCloseHandler()

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [data]
  )


  return <CustomModal
    title={update ? 'Rename workspace' : deleteMode ? 'Delete workspace' : 'Create workspace'}
    zIndex={12}
    onOk={() => createWorkspaceHandler({name, organizationId})}
    okButtonProps={{
      title: update ? 'Rename' : deleteMode ? 'Delete' : 'Create',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    // size={'xl'}
  >
    <Box
      p={'30px 0 10px'}
    >
      {deleteMode && <Box
        fontSize={'16px'}
      >
        <p>
          You’ll lose all forms and responses collected
        </p>

        <p>
          We can’t recover them—they’ll be gone forever.
        </p>
      </Box>}

      {!deleteMode &&

      <Form
        layout={'vertical'}
      >
        <Form.Item
          validateStatus={error && 'error'}
          help={error}
        >
          <StyledInput
            isInvalid={error}
            placeholder={'Name your workspace'}
            bg={'white'}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Form.Item>
      </Form>
      }



    </Box>


  </CustomModal>
};

export default AddWorkspaceModal;