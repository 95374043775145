import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import { useDefaultFormik } from "../../../../shared/form/useFormBuilder";
import React, { useEffect, useState } from "react";
import { mapToIdsArray, rawToStyledHtml } from "../../../../utils/common";
import InlineTextField from "../../../../shared/form/InlineTextField";
import CustomSearchableSelect, { StyledSelect } from "../../../../shared/form/CustomSearchableSelect";
import { Box, HStack } from "@chakra-ui/react";
import { Col, DatePicker, Row, Select, Space } from "antd";
import DeleteButton from "../../../../shared/DeleteButton";
import {
  CONDITION_FIELDS,

  getLogicalOperatorsByFieldType,
  logicalOperatorOptions,
} from "./JumpingLogicSettingsModal";
import * as Yup from "yup";
import _ from "lodash";
import CustomNumberInput from "../../../../shared/form/CustomNumberInput";
import { fieldTypes } from "../../fieldTypes/config";
import { isDraftContentEmpty } from "../../../../shared/CustomWysiwyg";
import { indexChars } from "../QuizzVariablesSettings/QuizzVariablesSettingsModal";
import { useTranslation } from "../../../../hooks/useTranslation";
import CustomDatePicker from "../../../../shared/form/CustomDatePicker";


const basicLogicalOperatorOptions = [
  {
    label: 'AND',
    value: 'AND'
  }, {
    label: 'OR',
    value: 'OR'
  }
]

const renderEqualTo = (keyName, fieldData) => {
  if (keyName === 'ifQuestionId') {
    const equalToOptionsType = getLogicalOperatorsByFieldType(fieldData?.type)
    return logicalOperatorOptions[equalToOptionsType]?.[0]?.value
  } else if (keyName === 'ifNumberVariableId') {
    return logicalOperatorOptions.number[0].value
  } else if (keyName === 'ifStringVariableId') {
    return logicalOperatorOptions.string[0].value
  } else if (keyName === 'ifHiddenVariableId') {
    return logicalOperatorOptions.string[0].value
  }
}

const renderSelectFieldOptions = (items = [], type) => {
  console.log(type, items)
  return items.map((o, index) => <Select.Option value={o.id}>
    <HStack>
      <Box>
        {indexChars[index]}.
      </Box>
      <Box>
        {/* {type === 'select' ? o.name : (isDraftContentEmpty(o.nameBlocks) ? rawToStyledHtml(o.nameBlocks) : <Box>---</Box>)} */}
        {type === 'select' ? o.name : rawToStyledHtml(o.nameBlocks)}

      </Box>
    </HStack>
  </Select.Option>)
}

const YesNoOptionsSelect = ({ value, onChange }) => {

  const [translations] = useTranslation(store => ([store.translations]))

  const options = [
    {
      value: 'yes',
      label: translations["yesNo.yes"],
      char: 'A'
    }, {
      value: 'no',
      label: translations["yesNo.no"],
      char: 'B'
    }
  ]

  return <StyledSelect
    value={value}
    onChange={onChange}
  >
    {options.map(o => <Select.Option value={o.value}>
      {o.char}.{' '}{o.label}
    </Select.Option>)}
  </StyledSelect>
}

const LegalSelect = ({ value, onChange }) => {

  const [translations] = useTranslation(store => ([store.translations]))

  const options = [
    {
      value: 'accept',
      label: translations["legalField.accept"],
      char: 'A'
    }, {
      value: 'noAccept',
      label: translations["legalField.noAccept"],
      char: 'B'
    }
  ]

  return <StyledSelect
    value={value}
    onChange={onChange}
  >
    {options.map(o => <Select.Option value={o.value}>
      {o.char}.{' '}{o.label}
    </Select.Option>)}
  </StyledSelect>
}

const QuestionAndVariablesSelect = ({
  fieldData,
  questions,
  numberVariables = [],
  stringVariables = [],
  hiddenVariables = [],
  conditionData,
  onChange,
  value
}) => {


  const updateValues = (keyName, value) => {
    const updatedValues = {
      ifQuestionId: null,
      ifNumberVariableId: null,
      ifStringVariableId: null,
      ifHiddenVariableId: null,
    }

    let equalTo = renderEqualTo(keyName, fieldData)

    updatedValues[keyName] = value
    onChange({ ...conditionData, ...updatedValues, equalTo, value: null })
  }

  const handleChange = (val) => {
    const type = val.split(':')?.[0]
    const id = val.split(':')?.[1]
    if (type === 'Q') {
      updateValues('ifQuestionId', id)
    }
    if (type === 'NUM') {
      updateValues('ifNumberVariableId', id)
    }
    if (type === 'STR') {
      updateValues('ifStringVariableId', id)
    }
    if (type === 'H') {
      updateValues('ifHiddenVariableId', id)
    }
  }

  return <StyledSelect
    placeholder={'Select Question or Variable'}
    value={value}
    onChange={handleChange}
  >

    {!_.isEmpty(questions) && <Select.OptGroup label={'QUESTIONS'}>
      {questions?.map((f, index) => {

        const Icon = fieldTypes[f.type].icon

        return <Select.Option value={`Q:${f.id}`}>
          <Space>
            <Icon size={20} />
            <Box
              display={'flex'}
            >
              <span style={{ marginRight: '4px' }}>{f.order}.</span>{rawToStyledHtml(f.nameDraftContent)}
            </Box>
          </Space>
        </Select.Option>

      })}
    </Select.OptGroup>}

    {!_.isEmpty(numberVariables) && <Select.OptGroup label={'NUMBER VARIABLES'}>
      {numberVariables?.map((f, index) => <Select.Option value={`NUM:${f.id}`}>{f.name}</Select.Option>)}
    </Select.OptGroup>}

    {!_.isEmpty(stringVariables) && <Select.OptGroup label={'STRING VARIABLES'}>
      {stringVariables?.map((f, index) => <Select.Option value={`STR:${f.id}`}>{f.name}</Select.Option>)}
    </Select.OptGroup>}

    {!_.isEmpty(hiddenVariables) && <Select.OptGroup label={'HIDDEN VARIABLES'}>
      {hiddenVariables?.map((f, index) => <Select.Option value={`H:${f.id}`}>{f.name}</Select.Option>)}
    </Select.OptGroup>}

  </StyledSelect>
}

const ConditionBlock = ({ conditionData, index, onRemove, onChange, fieldData, fields = [] }) => {

  const [
    formData = {},
  ] = useFormConfiguratorStore(state => [
    state.formData,
  ])

  const { numberVariables: numberVar = [], stringVariables = [], hiddenVariables = [] } = formData

  const numberVariables = [...numberVar, { id: 'score', name: 'score' }]

  const formik = {
    values: conditionData,
    setFieldValue: (keyName, value) => {
      onChange({ ...conditionData, [keyName]: value })
    },
    setValues: (values) => {
      onChange({ ...values })
    }
  }

  const onChangeHandler = (name, value) => formik.setFieldValue(name, value)

  const [showDeleteButton, setShowDeleteButton] = useState(false)

  const [highlight, setHighlight] = useState(false)

  const {
    ifQuestionId,
    ifNumberVariableId,
    ifStringVariableId,
    ifHiddenVariableId,
    equalTo,
    value,
    logicalOperator
  } = conditionData


  // ============================================================FIELDS SCOPE =========================================
  const filteredFields = fields.filter(f => CONDITION_FIELDS.includes(f?.type) && f?.order <= fieldData?.order)

  let currentValue = null
  let EqualToValueInput = null

  const field = filteredFields?.find(f => f.id === ifQuestionId)

  if (ifQuestionId && mapToIdsArray(filteredFields).includes(ifQuestionId)) {
    currentValue = `Q:${ifQuestionId}`

    // xayooo
    if (['textfield', 'textarea', 'email', 'website'].includes(field?.type)) {
      EqualToValueInput = <InlineTextField
        value={value}
        onChange={val => onChangeHandler('value', val)}
      />
    } else if (['multiChoice', 'singleChoice', 'pictureChoice', 'select'].includes(field?.type)) {

      const options = renderSelectFieldOptions(field?.options, field.type)
      console.log(field?.options)
      EqualToValueInput = <CustomSearchableSelect
        value={value}
        onChange={val => onChangeHandler('value', val)}
        children={options}
      />
    } else if (['yesNo'].includes(field?.type)) {
      EqualToValueInput = <YesNoOptionsSelect
        value={value}
        onChange={val => onChangeHandler('value', val)}
      />
    } else if (['legal'].includes(field?.type)) {
      EqualToValueInput = <LegalSelect
        value={value}
        onChange={val => onChangeHandler('value', val)}
      />
    } else if (['numberfield', 'opinionScale', 'rating'].includes(field?.type)) {
      EqualToValueInput = <CustomNumberInput
        value={value}
        onChange={val => onChangeHandler('value', val)}
      />
    } else if (['datefield'].includes(field?.type)) {
      EqualToValueInput = <CustomDatePicker
        value={value}
        onChange={val => {
          console.log(val, 'val')
          onChangeHandler('value', val)
        }}
      />
    }

  } else if (ifNumberVariableId && mapToIdsArray(numberVariables).includes(ifNumberVariableId)) {
    currentValue = `NUM:${ifNumberVariableId}`
    EqualToValueInput = <CustomNumberInput
      value={value}
      onChange={val => onChangeHandler('value', val)}
    />
  } else if (ifStringVariableId && mapToIdsArray(stringVariables).includes(ifStringVariableId)) {
    currentValue = `STR:${ifStringVariableId}`
    EqualToValueInput = <InlineTextField
      value={value}
      onChange={val => onChangeHandler('value', val)}
    />
  } else if (ifHiddenVariableId && mapToIdsArray(hiddenVariables).includes(ifHiddenVariableId)) {
    currentValue = `H:${ifHiddenVariableId}`
    EqualToValueInput = <InlineTextField
      value={value}
      onChange={val => onChangeHandler('value', val)}
    />
  }

  let equalToOptions = []

  if (currentValue) {
    const type = currentValue?.split(':')?.[0]
    const id = currentValue?.split(':')?.[1]

    const findFieldType = fields?.find(f => f.id === id)?.type

    const equalToType = getLogicalOperatorsByFieldType(
      findFieldType,
      type === 'NUM',
      type === 'STR',
      type === 'H'
    )
    equalToOptions = logicalOperatorOptions[equalToType]
  }

  return <Box>

    {index !== 0 && <Row
      style={{ marginBottom: '15px' }}
    >
      <Col xs={4}>
        <Box
          m={'6px'}
        >
          <CustomSearchableSelect
            showSearch={false}
            value={logicalOperator}
            onChange={val => onChangeHandler('logicalOperator', val)}
            options={basicLogicalOperatorOptions}
          />
        </Box>
      </Col>
    </Row>}

    <Box
      onMouseEnter={() => setShowDeleteButton(true)}
      onMouseLeave={() => setShowDeleteButton(false)}
      position={'relative'}
      bg={highlight ? '#f2f2f2' : 'inherit'}
      p={'6px'}
      borderRadius={'6px'}
    >

      <Box
        onMouseEnter={() => setHighlight(true)}
        onMouseLeave={() => setHighlight(false)}
        opacity={showDeleteButton ? 1 : 0}
        position={'absolute'}
        top={'6px'}
        right={'-30px'}
        onClick={onRemove}
      >
        <DeleteButton />
      </Box>


      <Row
        style={{ marginBottom: '15px' }}
      >
        <QuestionAndVariablesSelect
          questions={filteredFields}
          numberVariables={numberVariables}
          stringVariables={stringVariables}
          hiddenVariables={hiddenVariables}
          value={currentValue}
          onChange={onChange}
          conditionData={conditionData}
          fieldData={fieldData}
        />
      </Row>

      {(!['signature', 'fileUpload'].includes(field?.type)) && currentValue && <Row
        style={{ paddingBottom: '15px', height: '47px' }}
        gutter={10}
      >
        <Col xs={5}>
          <CustomSearchableSelect
            showSearch={false}
            options={equalToOptions}
            permittedValues={equalToOptions.map(e => e.value)}
            value={formik.values?.equalTo}
            onChange={(e) => formik.setFieldValue('equalTo', e)}
          />
        </Col>

        <Col xs={19}
        >
          {EqualToValueInput}
        </Col>
      </Row>}

      {field?.type === 'fileUpload' && <Row style={{ paddingBottom: '15px' }}>
        <Col xs={24}>
          <StyledSelect
            value={'is_upload'}
            options={[{ value: 'is_upload', label: 'is upload' }]}
          />
        </Col>
      </Row>}

      {field?.type === 'signature' && <Row style={{ paddingBottom: '15px' }}>
        <Col xs={24}>
          <StyledSelect
            value={'is_upload'}
            options={[{ value: 'is_upload', label: 'is upload' }]}
          />
        </Col>
      </Row>}


    </Box>
  </Box>
}

export default ConditionBlock
