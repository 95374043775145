import React from 'react';
import {Box, Divider, HStack} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import SectionHeader from "../../../../shared/SectionHeader";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import {Space} from "antd";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import CustomNumberInput from "../../../../shared/form/CustomNumberInput";

export const multipleSelectionLimitTypeOptions = [
  {
    value: 'unlimited',
    label: 'Unlimited'
  }, {
    value: 'exactNumber',
    label: 'Exact number'
  }, {
    value: 'range',
    label: 'Range'
  }
]
const Configurator = ({data = {}, onChange}) => {

  const {
    required,
    randomize,
    superSize,
    multipleSelection,
    multipleSelectionLimitType,
    multipleSelectionLimitExactNumber,
    multipleSelectionLimitMinNumber,
    multipleSelectionLimitMaxNumber,
    objectFit
  } = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>

    <FieldInlineWrapper
      label={'Super size'}
    >
      <CustomSwitch
        value={superSize}
        onChange={val => onChangeHandler('superSize', val)}
      />
    </FieldInlineWrapper>


    <FieldInlineWrapper
      label={'Multiple Selection'}
    >
      <CustomSwitch
        value={multipleSelection}
        onChange={val => onChangeHandler('multipleSelection', val)}
      />
    </FieldInlineWrapper>


    {multipleSelection && <Box
      py={'10px'}
    >
      <Space>
        <Box
          mb={'6px'}
          w={'100%'}
          flex={1}
        >
          <StyledSelect
            dropdownMatchSelectWidth={false}
            value={multipleSelectionLimitType}
            defaultValue={'unlimited'}
            onChange={val => onChangeHandler('multipleSelectionLimitType', val)}
            options={multipleSelectionLimitTypeOptions}
          />
        </Box>

        {multipleSelectionLimitType === 'exactNumber' && <CustomNumberInput
          min={1}
          value={multipleSelectionLimitExactNumber}
          integerOnly={true}
          onChange={val => onChangeHandler('multipleSelectionLimitExactNumber', val)}
        />}


        {multipleSelectionLimitType === 'range' && <>
          <CustomNumberInput
            min={1}
            value={multipleSelectionLimitMinNumber}
            onChange={val => onChangeHandler('multipleSelectionLimitMinNumber', val)}
          />

          <CustomNumberInput
            min={1}
            value={multipleSelectionLimitMaxNumber}
            onChange={val => onChangeHandler('multipleSelectionLimitMaxNumber', val)}
          />
        </>}

      </Space>
    </Box>}


    <FieldInlineWrapper
      label={'Randomize'}
    >
      <CustomSwitch
        value={randomize}
        onChange={val => onChangeHandler('randomize', val)}
      />
    </FieldInlineWrapper>
    <FieldInlineWrapper
      label={'Fit Images'}
    >
      <CustomSwitch
        value={objectFit}
        onChange={val => onChangeHandler('objectFit', val)}
      />
    </FieldInlineWrapper>
  </Box>
}

const PictureChoiceSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl
  const {generalSettings} = activeField

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};

export default PictureChoiceSettings;