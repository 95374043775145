import React, {useCallback, useState} from 'react';
import {duplicateForm} from "../../../firebase/form";

const useDuplicateQuery = (onSuccess) => {

  const [loading, setLoading] = useState()

  const duplicateFormHandler = useCallback(
    async (values) => {

      setLoading(true)

      try {

        const requestData = {
          ...values
        }

        const data = await duplicateForm(requestData)

        onSuccess(data)

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {loading, duplicateFormHandler}
};

export default useDuplicateQuery;