import {modalStore} from "../../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import React, {useState} from "react";
import {createForm, deleteResponses} from "../../../../firebase/form";
import CustomModal from "../../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";

export const useDeleteResponseModalStore = modalStore()

const DeleteResponseModal = ({refs = {}, actionCallbacks = {}}) => {

  const {formId} = useParams()

  const [isOpen, onClose, data = {}] = useDeleteResponseModalStore(store => [store.isOpen, store.onClose, store.data])

  const responseIds = data?.responseIds || refs.responseIds || []

  const [deleting, setDeleting] = useState();

  const deleteResponsesHandler = async () => {
    setDeleting(true)
    try {

      await deleteResponses({
        formId,
        responseIds
      })

      actionCallbacks.onDelete()
      onClose()
    } catch (err) {
      console.log(err, 'err')
    } finally {
      setDeleting(false)
    }
  }

  return <CustomModal
    title={'Delete responses'}
    zIndex={12}
    okButtonProps={{
      loading: deleting,
      title: 'Delete'
    }}
    isOpen={isOpen}
    onClose={onClose}
    onOk={deleteResponsesHandler}
    size={'xl'}
  >
    <Box py={'30px'}>
      We can't recover any responses once you delete them.
    </Box>
  </CustomModal>
};

export default DeleteResponseModal;