import axios from 'axios';
import AuthService from "../services/AuthService";
import appConfig from "../utils/appConfig";
import { useMutation, useQuery, useQueryClient } from "react-query";
import openNotification from '../shared/openNotification';

export const defaultQueryConfig = {
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  cacheTime: 0,
}

export const useOrganizationsQuery = (enabled, onSuccess) => {
  const get = AuthService.fetchMyOrganizations

  const { data, isLoading, isFetching, refetch } = useQuery('organizations', get, {
    ...defaultQueryConfig,
    refetchOnWindowFocus: true,
    refetchOnmount: true,
    retry: true,
    enabled,
    onSuccess: ({ data }) => {
      onSuccess && onSuccess(data)
    }
  })

  const organizations = data?.data || []

  return {
    organizations,
    isLoading,
    isFetching,
    fetchOrganizations: refetch
  }
};

export const useBillingQuery = (enabled, onSuccess) => {
  const get = AuthService.getBilling

  const { data, isLoading, isFetching, refetch } = useQuery('billing', get, {
    ...defaultQueryConfig,
    enabled,
    onSuccess: ({ data }) => {
      onSuccess && onSuccess(data)
    }
  })

  return {
    data: data?.data || {},
    isLoading,
    isFetching,
    fetchOrganizations: refetch
  }
};

export const useGoogleSheetOAuthQuery = (enabled = true, onSuccess) => {
  const get = AuthService.getGoogleSheetOAuth

  const { data, isLoading, isFetching, refetch } = useQuery('googleSheet-oauth', get, {
    ...defaultQueryConfig,
    enabled,
    onSuccess: ({ data }) => {
      onSuccess && onSuccess(data)
    }
  })

  return {
    data: data?.data || {},
    isLoading,
    isFetching,
  }
};


export const useSaveIntegrationGoogleSheet = (onSuccess) => {

  const create = AuthService.createIntegrationGoogleSheet

  const { mutate, isLoading } = useMutation('createIntegrationGoogleSheet', create, {
    onSuccess: ({ data }) => {
      onSuccess && onSuccess(data)
    }
  })

  return {
    mutate,
    isLoading,
  }
};

export const removeMember = async (values) => {
  try {
    console.log(values, '==================')
    const { data } = await AuthService.removeMember(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updatePermission = async (values) => {
  try {
    const { data } = await AuthService.updatePermission(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const addMember = async (values) => {
  console.log(values)
  try {
    const { data } = await AuthService.addMember(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}
export const acceptInvite = async (values) => {
  try {
    const { data } = await AuthService.acceptInvite(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getTokenData = async (token) => {
  try {
    const { data } = await AuthService.getTokenData(token)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getForm = async (id) => {
  try {
    const { data } = await AuthService.fetchForm(id)

    const results = {
      ...data,
      themeId: data.theme?.id
    }

    return results
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getForms = async (values) => {
  try {
    const { data } = await AuthService.fetchForms(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getBilling = async (values) => {
  try {
    const { data } = await AuthService.getBilling(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getCheckoutSession = async (values) => {
  try {
    const { data } = await AuthService.getCheckoutSession(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateBilling = async (values) => {
  try {
    const { data } = await AuthService.updateBilling(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}


export const getResponses = async (id, params) => {
  try {
    const { data } = await AuthService.fetchFormResponses(id, params)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getMyImages = async (params) => {
  try {
    const { data } = await AuthService.fetchMyImages(params)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getAnalytics = async (id) => {
  try {
    const { data } = await AuthService.fetchFormAnalytics(id)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getResponsesCSV = async (id) => {
  try {
    const { data } = await AuthService.fetchFormResponsesCSV(id)
    var blob = new Blob([data], {
      type: 'text/plain'
    });
    const fileURL = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = fileURL
    a.download = "file.csv"
    a.click()

    console.log(data, typeof data)

  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getPixabayImages = async (params) => {
  try {

    const data = await fetch(`https://pixabay.com/api/?${new URLSearchParams(params)}`).then(res => res.json())

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}
export const getTemplates = async () => {
  try {

    const { data } = await AuthService.getTemplates()

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const createWorkspace = async (values) => {
  try {
    const { data } = await AuthService.createWorkspace(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const deleteResponses = async (values) => {
  try {
    const { data } = await AuthService.bulkDeleteResponse(values)
    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}


export const uploadImage = async (values) => {
  try {
    const { data } = await AuthService.uploadImage(values)
    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const addImage = async (values) => {
  try {
    console.log('fsddfd')
    const { data } = await AuthService.addImage(values)
    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}


export const updateWorkspace = async (values) => {
  try {
    const { data } = await AuthService.updateWorkspace(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const deleteWorkspace = async (values) => {
  try {
    const { data } = await AuthService.deleteWorkspace(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const createForm = async (values) => {

  try {

    const { data } = await AuthService.createForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}
export const duplicateForm = async (values) => {
  try {
    const { data } = await AuthService.duplicateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}
export const moveForm = async (values) => {
  try {
    const { data } = await AuthService.moveToForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}
export const saveFormAsTemplate = async (values) => {
  try {
    const { data } = await AuthService.saveAsTemplate(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateForm = async (values) => {
  try {
    const { data } = await AuthService.updateForm(values)
    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const deleteForm = async (values) => {
  try {
    const { data } = await AuthService.deleteForm(values)
    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const createField = async (values) => {
  try {

    const { data } = await AuthService.createField(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateField = async (values) => {
  if (!values.id) return
  try {
    const { data } = await AuthService.updateField(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const deleteField = async (values) => {
  try {
    const { data } = await AuthService.deleteField(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const duplicateField = async (values) => {
  try {
    const { data } = await AuthService.duplicateField(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}



export const updateFieldsOrder = async (formId, type, fields) => {
  try {
    return null
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}


export const createTheme = async (values) => {
  try {
    const { data } = await AuthService.createTheme(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateTheme = async (values) => {
  try {
    const { data } = await AuthService.updateTheme(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const deleteTheme = async (values) => {
  try {
    const { data } = await AuthService.deleteTheme(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateAccountSettings = async (values) => {

  try {
    const { data } = await AuthService.updateAccountSettings(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateVariables = async (values) => {
  try {
    values.id = values.formId
    const { data } = await AuthService.updateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateJumpingLogicSettings = async (values) => {
  try {
    values.id = values.formId
    const { data } = await AuthService.updateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateQuizzSettings = async (values) => {
  try {
    values.id = values.formId
    const { data } = await AuthService.updateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateSettings = async (values) => {
  try {
    values.id = values.formId
    const { data } = await AuthService.updateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateMessages = async (values) => {
  try {
    values.id = values.formId
    const { data } = await AuthService.updateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateNotifications = async (values) => {
  try {
    values.id = values.formId
    const { data } = await AuthService.updateForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateAccessAndScheduling = async (values) => {

  values.id = values.formId

  try {

    const { data } = await AuthService.updateForm(values)

    return {}
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const updateMetadata = async (values) => {

  values.id = values.formId

  try {

    const { data } = await AuthService.updateForm(values)

    return {}
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const publishForm = async (values) => {
  try {
    const { data } = await AuthService.publishForm(values)

    return data
  } catch (e) {
    openNotification({
      type: 'error',
      description: e?.data?.message,
    })
    throw e
  }
}

export const getUsage = async (values = {}) => {

  try {
    const { data } = await AuthService.getUsage()
    return data

  } catch (err) {
  }
}

export const getTemplateCategory = async (values = {}) => {

  try {
    const { data } = await AuthService.getTemplateCategory()
    return data

  } catch (err) {
  }
}

export const addTemplateCategory = async (values = {}) => {

  try {
    const { data } = await AuthService.addTemplateCategory(values)

  } catch (err) {
  }
}
