import React, {useEffect, useState} from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import {Input, Box, Flex, HStack, Center} from '@chakra-ui/react'
import styled from "styled-components";
import _ from "lodash";
import {ReactSortable} from "react-sortablejs";
import UnderlineTextButton from "../../../../shared/UnderlineTextButton";
import {randomId, removeById, shade} from "../../../../utils/common";
import CustomWysiwyg from "../../../../shared/CustomWysiwyg";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {initialDraftContent, QuestionFontSizes} from "../config";
import CenterImage from "../../../../shared/CenterImage";
import {Player} from "../../components/Content";
import {CloseCircleOutlined, DeleteOutlined, HeartOutlined, LikeOutlined, StarOutlined} from "@ant-design/icons";
import {IndexCharContainer, indexChars} from "../MultiChoice/MultiChoice";
import {setColorOpacity} from "../MultiChoice/MultiChoice";


export const OptionContainer = styled(Center)`
  margin-right: 5px;
  border-radius: 6px;
  flex-direction: column;
  
  &:last-child{
    margin-right: 0px;    
  } 
`;

const getIconByType = (type) => {
  switch (type) {
    case 'star': {
      return StarOutlined
    }
    case 'heart': {
      return HeartOutlined
    }
    case 'like': {
      return LikeOutlined
    }
  }
  return StarOutlined
}

const renderScaleOptions = (fromScale = 1, toScale = 10) => {
  const results = []

  for (let step = fromScale; step <= toScale; step++) {
    results.push(step)
  }
  return results
}

const Rating = ({fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber}) => {

  const {questionColor, fontSize, align, answerColor, backgroundColor} = theme

  const {generalSettings = {}} = fieldData
  const {fromScale, toScale, iconType, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null


  if (!fieldData) return null

  const IconComponent = getIconByType(iconType)

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        display={'flex'}
        color={answerColor}
        justifyContent={align}
      >
        {renderScaleOptions(fromScale, toScale).map(e => <OptionContainer

        >
          <IconComponent
            style={{fontSize: '49px'}}
          />
          {e}

        </OptionContainer>)}
      </Box>


    </FieldWrapper>
  );
};


export default Rating;