import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {convertFromRaw, convertToRaw, EditorState, ContentState} from "draft-js";
import createLinkPlugin from "@draft-js-plugins/anchor";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import Editor from "@draft-js-plugins/editor";
import {BoldButton, ItalicButton, UnderlineButton} from "@draft-js-plugins/buttons";
import _ from "lodash";
import styled, {css} from "styled-components";
import {Box} from "@chakra-ui/react";
import colors from "../../utils/commonStyles";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import useDebouncedEffect from "../../hooks/useDebounceEffect";

import {Component} from 'react';
import {createEditorStateWithText} from '@draft-js-plugins/editor';
// import editorStyles from './editorStyles.module.css';

const linkPlugin = createLinkPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const {InlineToolbar} = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin, linkPlugin];
const text =
  'Try selecting a part of this text and click on the link button in the toolbar that appears …';

const CustomHtmlWysiwyg = ({onChange, value, wysiwygId}) => {

  const ref = useRef(null)


  const [content, setContent] = useState(EditorState.createEmpty())

  useEffect(() => {
    const wysiwygValue = !_.isEmpty(value) ? value : ''
    const blocksFromHtml = htmlToDraft(wysiwygValue);
    const {contentBlocks, entityMap} = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const editorState = EditorState.createWithContent(contentState);
    setContent(editorState)
  }, [wysiwygId])

  // const handleChange = () => {
  //   const rawContentState = convertToRaw(content.getCurrentContent());
  //   const markup = draftToHtml(
  //     rawContentState
  //   );
  //
  //   onChange(markup)
  // }
  //
  // useDebouncedEffect(handleChange, [content], 300, 1000)

  return <div>
    <Editor
      ref={ref}
      editorState={content}
      onChange={val => setContent(val)}
      plugins={plugins}
    />
    <InlineToolbar>
      {
        // may be use React.Fragment instead of div to improve perfomance after React 16
        (externalProps) => (
          <div>
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <linkPlugin.LinkButton {...externalProps} />
          </div>
        )
      }
    </InlineToolbar>
  </div>
}

const memoWrapper = React.memo(CustomHtmlWysiwyg, (prev, next) => {
  return _.isEqual(prev.value, next.value)
})


const EditorContainer = styled(Box)`
  position: relative;
  display: flex;
  justify-content: ${({textAlign}) => textAlign === 'center' && 'center'} !important;
  box-sizing: border-box;
  cursor: text;
  background: white;
  border: 1px solid ${colors.border};
  border-radius: 6px;
  text-align: ${({textAlign}) => textAlign || 'flex-start'} !important;
  padding: 15px 7px;
  
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  
  .DraftEditor-root {
    min-width: 1px !important;
  }

  .DraftEditor-editorContainer{
      position: relative;
      // padding-right: 15px;
      min-width: 1px !important;
      // width: 100%;
      ${({afterContent, beforeContent}) => (afterContent || beforeContent) && css`
        &:before{
          content: '${beforeContent}${afterContent}';
          display: flex;
          flex-direction: row;
          position: absolute;
          top: 0;
          transform: translate(-120%); 
          // left: -17px;
        }  
      `}
  }
  
  .bpsgbes{
    background: white !important;
  }

  .m6zwb4v{
    color: ${colors.primary};
    background: ${colors.mentionBg};
    border: 1px solid ${colors.primary};
    border-radius: 6px;
    font-size: ${({mentionFontSize}) => mentionFontSize};
    display: inline-block;
    max-width: 400px;

    // span{
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   display: -webkit-box;
    //   word-wrap: break-word;
    //   -webkit-line-clamp: 1;
    //   -webkit-box-orient: vertical;
    // }
  }

  .tukdd6b {
    background: white !important;

    button {
      background: white !important;    
      padding: 0 !important;
    }
  }

  .mnw6qvm{
    max-height: 400px;
    overflow-y: auto !important;
    border-color: #bbbbbb;
    border-radius: 6px;
  }
`

export default CustomHtmlWysiwyg;