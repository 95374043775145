import React from 'react';
import { Box, HStack } from "@chakra-ui/react";
import colors from "../../../../utils/commonStyles";

export const Span = ({ children, ...rest }) => {
  return (
    <Box
      fontSize={'12px'}
      color={'rgb(137, 137, 137)'}
      {...rest}
    >
      {children}
    </Box>
  );
};

const Card = ({ children, ...rest }) => {
  return (
    <Box
      borderRadius={'6px'}
      border={'1px solid #f2f2f2'}
      p={'10px'}
      boxShadow={colors.shadowColor}
      bg={'white'}
      mb={'22px'}
      {...rest}
    >
      {children}
    </Box>
  );
};

const FieldAnalyticsItem = ({ children, title, description, icon }) => {
  return (
    <Card
      mb={'30px'}
      p={'25px'}
      pr={'50px'}
    >
      <HStack align={'flex-start'}>

        <Box mr={'10px'}>
          {icon}
        </Box>

        <Box flex={1}>
          <Box>
            {title}
          </Box>
          <Span mt={'5px'} >
            {description}
          </Span>
          <Box mt={'30px'}>
            {children}
          </Box>
        </Box>

      </HStack>
    </Card>
  );
};

export default FieldAnalyticsItem;