import React, {useEffect} from 'react';
import {Box, HStack} from "@chakra-ui/react";
import SideBar from "../Home/components/SideBar";
import {Space} from "antd";
import {PageHeader} from "../Home";
import Tabs from "../../shared/Tabs";
import Team from "./components/Team";
import _ from "lodash";
import {useUserState} from "../../App";
import {useParams} from "react-router-dom";
import useOrganizationLocation from "../Home/hooks/useOrganizationLocation";
import {useSelector} from "react-redux";
import {useOrganizationsQuery} from "../../firebase/form";
import General from "./General";
import Usage from './Usage';
import Billing from './Billing';
import Plans from './Plans';


const Content = ({fetchOrganizations}) => {
  return <Box
    borderRadius={'14px'}
    bg={'white'}
    p={'20px'}
    maxW={'1200px'}
  >
    <Tabs>
      {[
        {
          key: 'General',
          name: 'General',
          content: <Box>
            <General/>
          </Box>
        },
        {
          key: 'team',
          name: 'Team',
          content: <Team
            fetchOrganizations={fetchOrganizations}
          />
        },
        {
          key: 'billing',
          name: 'Billing',
          content: <Billing
            fetchOrganizations={fetchOrganizations}
          />
        },
        {
          key: 'plans',
          name: 'Plans',
          content: <Plans
            fetchOrganizations={fetchOrganizations}
          />
        },
        {
          key: 'usage',
          name: 'Usage',
          content: <Usage
            fetchOrganizations={fetchOrganizations}
          />

        },
      ]}
    </Tabs>
  </Box>
}

const Settings = ({}) => {

  const [
    setOrganizations,
  ] = useUserState(store => ([
    store.setOrganizations,
  ]))

  const {isAuthenticated, user} = useSelector(state => state.persistentState)

  const {
    organizations,
    isLoading: organizationsLoading,
    fetchOrganizations
  } = useOrganizationsQuery(isAuthenticated, (data) => {
    setOrganizations(data)
  })

  useOrganizationLocation(false)


  return (
    <Box
      h={'100vh'}
    >
      {/*<Header/>*/}
      <Box
        display={'flex'}
      >
        <SideBar
          activeItem={'Account'}
        />

        <Box
          flex={1}
          // p={'20px'}
          bg={'#F2F3F7'}
          // bg={'#FAFAFA'}
        >
          <Box
            // p={'20px 30px'}
            h={'100vh'}
            d={'flex'}

            flexDirection={'column'}
            p={'40px 50px'}
            overflow={'auto'}

          >
            <HStack
              mb={'20px'}
              justifyContent={'space-between'}
            >
              <PageHeader
                title={'Account'}
              />

            </HStack>


            <Content

              fetchOrganizations={fetchOrganizations}/>


          </Box>


        </Box>
      </Box>
    </Box>
  );
};

export default Settings;