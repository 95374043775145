import React, { useEffect, useState } from 'react';
import { modalStore } from "../../utils/common";
import { useHistory, useParams } from "react-router-dom";
import { Box, Center } from "@chakra-ui/react";
import { Select, Space, Spin } from "antd";
import { PicRightOutlined, PlusOutlined } from "@ant-design/icons";
import { duplicateForm, useOrganizationsQuery } from '../../firebase/form';
import { useUserState } from '../../App';
import CustomModal from '../../shared/CustomModal';
import { useSelector } from 'react-redux';
import { StyledSelect } from '../../shared/form/CustomSearchableSelect';
import openNotification from '../../shared/openNotification';
import { useQueryClient } from 'react-query';
import useFormConfiguratorStore from '../FormConfigurator/stores/useFormConfiguratorStore';


export const useTemplateModalStore = modalStore()

const TemplateModal = ({ canBeClosed = true }) => {
    const queryClient = useQueryClient()

    const { id: idFormUrl } = useParams()

    const [workspaceId, setWorkspaceId] = useState()

    const { isAuthenticated, user } = useSelector(state => state.persistentState)

    const [
        resetStore
    ] = useFormConfiguratorStore(state => [
        state.resetStore,
    ])

    const [
        setOrganizations,
    ] = useUserState(store => ([
        store.setOrganizations,
    ]))

    const {
        organizations,
        isLoading: organizationsLoading,
        fetchOrganizations
    } = useOrganizationsQuery(isAuthenticated, (data) => {
        setOrganizations(data)
    })

    const history = useHistory()

    const [isOpen, onClose, data = {}] = useTemplateModalStore(store => [store.isOpen, store.onClose, store.data])

    const { id } = data

    const [creatingForm, setCreatingForm] = useState(false)

    const createFormHandler = async (workspaceId, id) => {
        resetStore && resetStore()
        setCreatingForm(true)
        try {
            console.log(idFormUrl, id, idFormUrl || id)

            const requestData = {
                id: idFormUrl || id,
                workspaceId
            }

            const { id: formId } = await duplicateForm(requestData)

            if (formId) {
                history.push(`/form/${formId}`, {
                    showNameModal: true
                })
            }
            queryClient.invalidateQueries('organizations')


            onClose()
        } catch (e) {
            console.log(e)
            openNotification({ type: 'error', description: e?.data?.message })
        } finally {
            setCreatingForm(false)
        }
    }



    useEffect(() => {

        if (workspaceOptions.length === 1) {
            if (!canBeClosed) {
                createFormHandler(workspaceOptions[0]?.value, id)
            }
            setWorkspaceId(workspaceOptions[0]?.value)
        } else if (workspaceOptions.length > 1) {
            setWorkspaceId(workspaceOptions[0]?.value)
        }
    }, [organizations])

    if (!user) return null

    const workspaceOptions = organizations.filter(o => (o.owner.id === user.id) || o.permissions.includes('form_create')).map(o => o.workspaces).flat().map(o => ({
        value: o.id,
        label: o.name,
    }))

    return <CustomModal
        title={'Use template'}
        zIndex={12}
        isOpen={isOpen}
        onClose={canBeClosed && onClose}
        okButtonProps={{
            title: 'Use template',
            disabled: creatingForm
        }}
        onOk={() => createFormHandler(workspaceId, idFormUrl || id)}
        size={'xl'}
    >
        <Box
            d={'flex'}
            justifyContent={'center'}
            p={'30px 0 10px'}
        >

            {creatingForm && <Spin />}

            {!creatingForm && <Center>

                <Box>

                    <Box>
                        Please select where you want to create your form.
                    </Box>

                    <StyledSelect
                        disabled={creatingForm}
                        dropdownMatchSelectWidth={false}
                        value={workspaceId}
                        onChange={e => setWorkspaceId(e)}
                        options={workspaceOptions}
                        bg={'white'}
                    />
                </Box>

            </Center>
            }


        </Box>
    </CustomModal>
};

const ChoiceCard = ({ children, onClick }) => {
    return <Box
        onClick={onClick}
        p={'40px'}
        bg={'white'}
        mx={'20px'}
        borderRadius={'8px'}
        border={'1px solid #f2f2f2'}
        color={'#8c8c8c'}
        d={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        cursor={'pointer'}
    >
        {children}
    </Box>
}

export default TemplateModal;