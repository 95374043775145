import React, {useEffect} from 'react';
import {Box, HStack, Button, Center} from '@chakra-ui/react'

const DraggableModal = ({children, header, width}) => {


  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  useEffect(() => {
    dragElement(document.getElementById("draggableModal"));
  }, [])


  return (
    <Box
      borderRadius={'8px'}
      w={width || '600px'}
      zIndex={999}
      top={'50px'}
      left={'260px'}
      w={'430px'}
      h={'520px'}
      position={'absolute'}
      backgroundColor={'white'}
      border={'1px solid rgba(0, 0, 0, 0.07)'}
      boxShadow={'rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px'}
      id="draggableModal"
      overflow={'hidden'}
      zIndex={99999999}
    >
      <Box
        cursor={'move'}
        id="draggableModalheader"
      >
        {header}
      </Box>

      <Box >
        {children}
      </Box>

    </Box>
  );
};

export default DraggableModal;