import {Center, Box} from '@chakra-ui/react';
import React from 'react';

const CenterImage = ({
                       src,
                       width,
                       height,
                       alignItems = 'center',
                       justifyContent = 'center',
                       opacity = 1,
                       maxHeight='100%',
                       onClick
}) => {
  return (
    <Box
    onClick={onClick}
      display={'flex'}
      justifyContent={justifyContent}
      alignItems={'center'}
      opacity={opacity}
      style={{
        width,
        height
      }}
    >
      <img
        style={{
          borderRadius: '8px',
          maxWidth: '100%',
          maxHeight,
        }}
        src={src}
      />
    </Box>
  );
};

export default CenterImage;