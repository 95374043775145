import React from 'react';
import {Box, Button} from "@chakra-ui/react";
import colors from "../utils/commonStyles";

const PrimaryButton = ({children, loading,isLoading, ...rest}) => {
  return (
    <Button
      // background={'blue.700'}
      background={colors.primaryLinear}
      _hover={{
        // background: 'blue.700',
        // opacity: 0.7
      }}
      color={'white'}
      w={['100%']}
      fontWeigth={200}
      {...rest}
      isLoading={loading || isLoading}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;