import React, {useEffect, useState} from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import {Input, Box, Flex, HStack, Center} from '@chakra-ui/react'
import styled from "styled-components";
import _ from "lodash";
import {ReactSortable} from "react-sortablejs";
import UnderlineTextButton from "../../../../shared/UnderlineTextButton";
import {randomId, removeById, shade} from "../../../../utils/common";
import CustomWysiwyg from "../../../../shared/CustomWysiwyg";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {initialDraftContent, QuestionFontSizes} from "../config";
import CenterImage from "../../../../shared/CenterImage";
import {Player} from "../../components/Content";
import {CloseCircleOutlined, DeleteOutlined} from "@ant-design/icons";
import {useTranslation} from "../../../../hooks/useTranslation";
import {message} from "antd";
import {renderNameBlock} from "../LegalField/LegalField";

export const indexChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X' ,'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX' ,'AY', 'AZ']

export const ChoiceItemContainer = styled(Box)`
  border-radius: 10px;
  padding: 8px 15px;
  position: relative;
  min-height: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  min-height: 40px;
  cursor: pointer;
  font-size: 20px;
  overflow: hidden;
  // backdrop-filter: blur(4px);
`;

export const IndexCharContainer = styled(Box)`
  font-size: 13px;
  font-weight: 600;
  font-family: sans-serif;
  // background: white;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  // margin-right: 10px;
`;

export const setColorOpacity = (color, opacity) => {
  const RGB = color;
  const A = opacity;
  const RGBA = '(' + parseInt(RGB.substring(1, 3), 16) + ',' + parseInt(RGB.substring(3, 5), 16) + ',' + parseInt(RGB.substring(5, 7), 16) + ',' + A + ')';
  return `rgba${RGBA}`
}

export const OptionContainer = styled(Box)`
  // border-radius: 8px;
  padding: 4px 12px 4px 6px;
  padding-right: 20px;
  position: relative;
  min-height: 30px;
  margin-bottom: 10px;
  display: flex;
    // backdrop-filter: blur(4px);

`;

export const DeleteButton = styled(Center)`
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  z-index: 9
  cursor: pointer;
  display: flex;
  justify-content: flex-end; 
`;

export const otherNameBlock = {
  "blocks": [
    {
      "key": "drc9v",
      "text": "Other",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
}

export const otherOption = {
  id: 'other',
  nameBlocks: otherNameBlock
}

export const OptionItem = ({
                             id,
                             name,
                             char,
                             onRemove,
                             allowRemove = true,
                             color,
                             backgroundColor,
                             nameBlocks,
                             onChange,
                             readOnly,
                             fontSize='16px',
                             borderRadius = '8px'
                           }) => {

  const [highlight, setHighlight] = useState()

  return <OptionContainer
    // borderColor={color}
    fontSize={'20px'}
    backgroundColor={setColorOpacity(color, 0.1)}
    color={color}
    border={`1px solid`}
    borderColor={setColorOpacity(color, 1)}
    onMouseEnter={() => setHighlight(true)}
    onMouseLeave={() => setHighlight(false)}
    borderRadius={borderRadius}
  >
    <Center
      mr={'10px'}
    >
      <IndexCharContainer
        boxShadow={`${setColorOpacity(color, 1)} 0px 0px 0px 0.5px inset`}
        // backgroundColor={shade(color, 0.01, backgroundColor)}
        backgroundColor={setColorOpacity(color, 0.15)}
        color={color}
      >
        {char}
      </IndexCharContainer>
    </Center>


    {/*{name ? <Box fontSize={'16px'}>{name}</Box> : <CustomWysiwyg*/}
    {/*  readOnly={readOnly}*/}
    {/*  value={nameBlocks}*/}
    {/*  onChange={onChange}*/}
    {/*  fontSize={'16px'}*/}
    {/*  placeholder={'Set'}*/}
    {/*  id={id}*/}
    {/*/>}*/}

    <CustomWysiwyg
      readOnly={readOnly}
      value={nameBlocks}
      onChange={onChange}
      fontSize={fontSize}
      placeholder={'choice'}
      id={id}
    />

    {/* <textarea
     onKeyPress={e => {
      if(e.key === 'Enter')
         e.preventDefault()
      }}
      
      onChange={e => onChange(e.target.value)}
      value={nameBlocks}

    /> */}

    {
      allowRemove && highlight && <DeleteButton
        w={'30px'}
        h={'100%'}
        onClick={onRemove}
      >
        <DeleteOutlined
          style={{cursor: 'pointer', fontSize: '16px'}}

        />
      </DeleteButton>
    }

  </OptionContainer>
}


const Configurator = ({
                        messages,
                        options = [],
                        otherOption,
                        onChange,
                        color,
                        backgroundColor,
                        generalSettings = {},
                        align,
                        fontSize,
                        theme = {}
                      }) => {

  const translations = messages

  const {buttonRadius} = theme

  const borderRadius = buttonRadius === 'sharp' ? '8px' : '44px'

  const {
    multipleSelectionLimitType,
    multipleSelectionLimitExactNumber = 1,
    multipleSelectionLimitMinNumber = 1,
    multipleSelectionLimitMaxNumber = options.length
  } = generalSettings

  const onAddHandler = () => {

    const newOption = {
      id: randomId(),
      name: 'Choice 223',
      nameBlocks: initialDraftContent
    }
    const updatedOptions = [...options, newOption]
    onChange(updatedOptions)
  }

  const onRemoveHandler = (optionId) => {
    onChange(removeById(options, optionId))
  }

  const infoText = multipleSelectionLimitType === 'exactNumber' ? `${translations['choice']} ${multipleSelectionLimitExactNumber}` :
    multipleSelectionLimitType === 'range' ?
      `${translations['choiceRange']} ${multipleSelectionLimitMinNumber}-${multipleSelectionLimitMaxNumber}`
      : null

  return (
    <Box>

      {infoText && <Box
        color={color}
        mb={'20px'}
        textAlign={align === 'center' && 'center'}
      >
        {infoText}
      </Box>}

      <ReactSortable
        list={options}
        setList={(data) => {
          onChange(data)
        }}
        id={'sortable'}
      >
        {options.map((o, index) => (<OptionItem
            key={o.id}
            char={indexChars[index]}
            id={o.id}
            // name={o.name}
            onRemove={() => onRemoveHandler(o.id)}
            color={color}
            backgroundColor={backgroundColor}
            nameBlocks={o.nameBlocks}
            fontSize={fontSize === 'sm' ? '16px' : '20px'}
            borderRadius={borderRadius}
            onChange={(data) => {
              const copyOptions = options.map((option, i) => {
                if (option.id === o.id) {
                  return {
                    ...option,
                    nameBlocks: data
                  }
                } else {
                  return {...option}
                }
              })
              onChange(copyOptions)
            }}
          />
        ))}
      </ReactSortable>

      {otherOption && <OptionItem
        id={'other'}
        fontSize={fontSize === 'sm' ? '16px' : '20px'}
        char={indexChars[options.length]}
        allowRemove={false}
        color={color}
        backgroundColor={backgroundColor}
        readOnly={true}
        borderRadius={borderRadius}

        nameBlocks={renderNameBlock(messages.choiceOtherPlaceholder || '')}
      />}
      <Flex>
        <UnderlineTextButton
          color={color}
          onClick={onAddHandler}
        >
          Add choice...
        </UnderlineTextButton>
      </Flex>

    </Box>
  )
}


const MultiChoice = ({fieldData, onChange, theme = {}, mentionOptions, messages = {}, showQuestionNumber}) => {

  const {questionColor, fontSize, align, answerColor, backgroundColor} = theme

  const {options = [], generalSettings = {}} = fieldData
  const {other, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  const onChangeHandler = (options = []) => {
    if (other) {
      onChange({options: [...options, otherOption]})
    } else {
      onChange({options})
    }
  }

  useEffect(() => {
    let optionsWithoutOther = options.filter(o => o.id !== 'other')
    if (other) {
      return onChange({options: [...optionsWithoutOther, otherOption]})
    }
    return onChange({options: optionsWithoutOther})
  }, [other])


  if (!fieldData) return null

  const isOtherOption = options.some(o => o.id === 'other')
  let optionsWithoutOther = isOtherOption ? options.filter(o => o.id !== 'other') : options

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}
        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        display={'flex'}
        justifyContent={align}
      >
        <Configurator
          align={align}
          fontSize={fontSize}
          messages={messages}
          options={optionsWithoutOther}
          onChange={onChangeHandler}
          color={answerColor}
          theme={theme}
          backgroundColor={backgroundColor}
          otherOption={isOtherOption}
          generalSettings={generalSettings}
        />
      </Box>

    </FieldWrapper>
  );
};

export default MultiChoice;