import React, {useState} from 'react';
import {ReactSortable} from "react-sortablejs";
import {Select, Space} from "antd";
import _ from 'lodash';
import {Divider, Box, HStack, Center} from "@chakra-ui/react";
import {CloseOutlined, DragOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {StyledSelect} from "./CustomSearchableSelect";
import {removeByIndex} from "../../utils/common";
import InlineTextField, {StyledInput} from "./InlineTextField";
import PrimaryButton from "../PrimaryButton";


const Container = styled(Box)`
  display:flex;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  border: 1px dashed #666666;
  padding: 5px 10px;
  margin-bottom: 5px;
  &:hover{
    border: 1px dashed #7d93ff;
    transition: all 0.5s easy;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  &:hover{
    transition: all 0.5s easy;
    color: #7d93ff;
  }
`;

const Item = ({label, onRemove, allowRemove = true}) => {
  return (
    <Container>
      <div>
        <HStack>
          <IconWrapper>
            <DragOutlined/>
          </IconWrapper>
          <Box flex={1}>

            {label}
          </Box>
        </HStack>
      </div>
      {allowRemove && (
        <div style={{marginLeft: '20px'}}>
          <IconWrapper onClick={onRemove}>
            <CloseOutlined/>
          </IconWrapper>
        </div>
      )}
    </Container>
  );
};

const SortableList = ({onChange, items = []}) => {

  let sortableValues = items?.map(v => ({value: v})) || []

  return (
    <ReactSortable
      list={sortableValues}
      setList={(data) => {
        onChange(data.map(d => d.value))
      }}
      id={'sortable'}
    >
      {items?.map((v, index) => {
        return (
          <Item
            key={v}
            label={v}
            onRemove={() => onChange(removeByIndex(items, index))}
            allowRemove={true}
          />
        )
      })}
    </ReactSortable>
  )
}

const FormDraggableSelect = ({
                               error, name, value = [], onChange, disabled,
                               placeholder, onBlur, autoFocus, options = [],
                               loading, sortableList = false, id
                             }) => {


  const [newOptionValue, setNewOptionValue] = useState()

  return <div id={'d'}>
    <Space>
      <StyledInput
        w={'100%'}
        value={newOptionValue}
        onChange={e => setNewOptionValue(e.target.value)}
      />


      <PrimaryButton
        size={'sm'}
        isDisabled={!newOptionValue}
        onClick={() => {
          console.log('eeeeee')
          setNewOptionValue('')
          onChange([...value, newOptionValue])
        }}
      >
        Add
      </PrimaryButton>
    </Space>

    <div style={{marginTop: '20px'}}>
      {sortableList && <SortableList
        onChange={(val) => onChange(val)}
        items={value}
      />}
    </div>
  </div>
};

const memoWrapper = React.memo(FormDraggableSelect, (prev, next) => {
  return _.isEqual(prev, next)
})

export default memoWrapper;