import { Icon, createIcon, HStack } from '@chakra-ui/react'
import { Tab, TabList, TabPanel, TabPanels, Center, Box } from "@chakra-ui/react";
import React from "react";
import { setColorOpacity } from "./MultiChoice/MultiChoice";

export const IconLabel = (bg, icon) => ({ label, size = 25 }) => {
  return <Box
    borderRadius={'4px'}
    // backgroundColor={bg}
    boxShadow={`${'#3366FF'} 0px 0px 0px 1px inset`}
    bg={setColorOpacity('#3366FF', 0.1)}
  // p={'3px'}


  >
    <HStack
      flex={1}
    // p={'0px 5px'}
    >
      <Center
        w={`${size}px`}
        h={`${size}px`}
      >
        {icon}
      </Center>

      {label && <Center
        w={'20px'}
        h={'25px'}
        fontWeight={600}
      >
        {label}
      </Center>}
    </HStack>
  </Box>
}

export const ColorPreviewIcon = ({ color }) => <Icon viewBox='0 0 12 16' color={'blue.400'}>
  <g>
    <path fill={color} d="M12 10c0-3.74-4.18-7.439-6-10-1.82 2.561-6 6.26-6 10a6 6 0 1 0 12 0z"></path>
    <path
      fill={'gray.500'}
      d="M5.59 2.133c-.022.026-1.089 1.288-1.403 1.669C2.026 6.422 1 8.266 1 10a5 5 0 0 0 10 0c0-1.734-1.026-3.578-3.187-6.198-.314-.381-1.38-1.643-1.402-1.669A50.34 50.34 0 0 1 6 1.637a50.34 50.34 0 0 1-.41.496zM12 10a6 6 0 1 1-12 0C0 6.26 4.18 2.561 6 0c1.82 2.561 6 6.26 6 10z"
    ></path>
  </g>
</Icon>

export const AlignLeftIcon = ({ color }) => <Icon viewBox='0 0 16 12'>
  xmlns="http://www.w3.org/2000/svg">
  <path d="M0 0H16V1.5H0V0Z"></path>
  <path d="M0 5H8V6.5H0V5Z"></path>
  <path d="M12 10H0V11.5H12V10Z"></path>
</Icon>
export const AlignCenterIcon = ({ color }) => <Icon viewBox='0 0 16 12'>
  <path d="M0 0H16V1.5H0V0Z"></path>
  <path d="M4 5H12V6.5H4V5Z"></path>
  <path d="M14 10H2V11.5H14V10Z"></path>
</Icon>

export const multiChoiceIcon = <Box
  pt={'3px'}
>
  <Icon viewBox='0 0 14 14' color='black'>
    <path fill='black'
      d="M13.7072.707124L5.00008 9.41423.292969 4.70712c.781051-.78104 2.047381-.78104 2.828431 0L5.00008 6.5858 10.8788.707124c.781-.7810482 2.0473-.7810482 2.8284 0z"></path>
  </Icon>
</Box>

export const dropdownIcon = <Box
  pt={'7px'}
>
  <Icon viewBox='0 0 14 14' color='black'>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00008 3.58581L3.12139 0.707124C2.34035 -0.0739251 1.07402 -0.0739234 0.292969 0.707125L6.00008 6.41423L11.7072 0.707125C10.9261 -0.0739234 9.6598 -0.0739234 8.87876 0.707125L6.00008 3.58581Z"></path>
  </Icon>
</Box>

export const textFieldIcon = <Box
// pt={'3px'}
>
  <Icon viewBox="0 0 14 6" color='black'>
    <path d="M0 0h7v2H0V0zM9 0h5v2H9V0zM0 4h3v2H0V4zM5 4h9c0 1.10457-.8954 2-2 2H5V4z"></path>
  </Icon>
</Box>

export const textAreaIcon = <Box
// pt={'3px'}
>
  <Icon viewBox="0 0 14 10" color='black'>
    <path
      d="M8 8h6c0 1.10457-.8954 2-2 2H8V8zM0 8h6v2H0V8zM5 4h9v2H5V4zM0 4h3v2H0V4zM10 0h4v2h-4V0zM0 0h8v2H0V0z"></path>
  </Icon>
</Box>

export const welcomeIcon = <Box
// pt={'3px'}
>
  <Icon viewBox="0 0 14 14" color='black'>
    <path
      d="M0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H7.5V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2Z"></path>
    <path d="M11 1H9V13H11V1Z"></path>
    <path d="M14 3H12.5V11H14V3Z"></path>
  </Icon>
</Box>

export const endingIcon = <Box
  transform={'rotate(180deg)'}
>
  <Icon viewBox="0 0 14 14" color='black'>
    <path
      d="M0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H7.5V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2Z"></path>
    <path d="M11 1H9V13H11V1Z"></path>
    <path d="M14 3H12.5V11H14V3Z"></path>
  </Icon>
</Box>

export const yesNoIcon = <Box
  transform={'rotate(180deg)'}
>
  <Icon viewBox="0 0 14 14" color='black'>
    <svg width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" className=""
      viewBox="0 0 1024 1024">
      <path
        d="M16 512c0 273.932 222.066 496 496 496s496-222.068 496-496S785.932 16 512 16 16 238.066 16 512z m496 368V144c203.41 0 368 164.622 368 368 0 203.41-164.622 368-368 368z"></path>
    </svg>
  </Icon>
</Box>

export const singleChoiceFieldIcon = <Box
// pt={'3px'}
>
  <Icon viewBox="0 0 18 18" color='black'>
    {/*<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
    <circle cx="9" cy="9" r="8" fill="none" stroke="black" stroke-width="2" />
    <circle cx="9" cy="9" r="5" fill="black" />
    {/*</svg>*/}
  </Icon>
</Box>

export const pictureChoiceFieldIcon = <Box
  pb={'4px'}
>
  <Icon viewBox="0 0 24 24" fill={'black'}>
    {/*<rect className="cls-1" x="1" y="1" width="22" height="22" rx="3" ry="3" />*/}
    <polyline className="cls-1" points="2 22 11 13 21 23" />
    <polyline className="cls-1" points="15 17 19 13 23 17" />
    <circle className="cls-1" cx="14" cy="7" r="2" />
  </Icon>
</Box>

export const opinionScaleIcon = <Box
// pb={'4px'}
>
  <Icon viewBox="0 0 14 13" fill={'black'}>
    <path
      d="M12 0c1.1045 0 2 .8955 2 2v11h-4V0h2zM4 10.5V13H0V8.5h2c1.1045 0 2 .8955 2 2zM9 6v7H5V4h2c1.1045 0 2 .8955 2 2z"></path>
  </Icon>
</Box>

export const ratingIcon = <Box
// pb={'4px'}
>
  <Icon viewBox="0 0 14 14" fill={'black'}>
    <path
      d="M7.64345.467259L8.98398 4.5934h4.33832c.6554 0 .9275.83827.3977 1.22371l-3.5097 2.55024 1.3405 4.12615c.2026.623-.5105 1.1414-1.0408.7564l-3.50969-2.5506-3.50969 2.5502c-.53026.385-1.24339-.133-1.04083-.7565l1.34053-4.12609-3.510132-2.5498C-.250072 5.4321.022496 4.5934.677883 4.5934H5.01622L6.35674.467259c.20257-.623012 1.08458-.623012 1.28671 0z"></path>
  </Icon>
</Box>

export const ImageIcon = ({ viewBox = '0 0 16 14', fill = '#808080', onClick }) => <Icon
  onClick={onClick}
  viewBox={viewBox}
  fill={fill}>
  <path
    fill={fill}
    d="M0 .5v11c0 1.1045.8955 2 2 2h12c1.1045 0 2-.8955 2-2V.5H0zm14.5 11c0 .175-.096.322-.2325.411L12.4605 9.5c-.4065-.7025-1.4205-.7025-1.827 0l-1.196 1.355L6.554 6.5c-.4065-.7025-1.4205-.7025-1.827 0L1.5 11.449V2h13v9.5z"></path>
  <path
    fill={fill}

    d="M11.6095 6.50001c.8889 0 1.6095-.7206 1.6095-1.6095 0-.88891-.7206-1.6095-1.6095-1.6095-.8889 0-1.6095.72059-1.6095 1.6095 0 .8889.7206 1.6095 1.6095 1.6095z"></path>

</Icon>

export const ImageConfigIcon = () => <Icon viewBox='0 0 16 12' fill={'#808080'}>
  <path d="M0 1.5H16V3.5H0V1.5Z"></path>
  <path d="M0 8.5H16C16 9.60457 15.1046 10.5 14 10.5H0V8.5Z"></path>
  <circle cx="11" cy="2.5" r="2.5"></circle>
  <circle cx="5" cy="9.5" r="2.5"></circle>
</Icon>

export const RemoveIcon = () => <Icon viewBox='0 0 11 14' fill={'#808080'}>
  <path
    d="M0 4.49845l.902993 7.48815C1.03774 13.1346 2.01073 14 3.16704 14h3.92434c1.15586 0 2.12886-.8654 2.26404-2.0134l.90298-7.48815H0zM8.8453 1.53529l-2.01204-.35481c-.02232-.526746-.406-.986554-.94675-1.0819286l-.4598-.0809368C4.88596-.0777596 4.3684.2228 4.16715.710171L2.11005.347924C1.18037.183863.29356.804671.129499 1.73479L10.2326 3.51627c.1636-.93011-.45718-1.81692-1.3873-1.98098z"></path>
</Icon>