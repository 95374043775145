import React, { useEffect, useRef, useState } from 'react';
import { Box, HStack, Button, Center, Image } from '@chakra-ui/react'
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import { SliderBox } from "./Content";
import { getPixabayImages, uploadImage } from "../../../firebase/form";
import { Spin } from "antd";
import { StyledInput } from '../../../shared/form/InlineTextField';
import useDebouncedEffect from '../../../hooks/useDebounceEffect';


const ImagesGallery = () => {

  const [
    formData,
    onImageModalChange,
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.onImageModalChange,
  ])

  const allowFetchRef = useRef(true)

  const [images, setImages] = useState([])
  const [paginationData, setPaginationData] = useState({})
  const [loading, setLoading] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(70)

  const [q, setQ] = useState('')

  const maxPage = Math.ceil(paginationData.total / perPage) || 1

  const fetchImages = async (_page) => {
    setLoading(true)

    try {
      const data = await getPixabayImages({
        key: '31063407-617ca9525f76839f0205494ce',
        per_page: perPage,
        page: _page || page,
        q
      })

      if(_page){
        setImages(data.hits)

      }else{
        setImages([...images, ...data.hits])

      }
      setPaginationData(data)

    } catch (err) {

    } finally {
      setLoading(false)
    }

  }

  useDebouncedEffect(() => fetchImages(1), [q], 300, 500)

  const handleScroll = (e) => {
    // const bottom = e.target.scrollHeight - e.target.scrollTop - 400 < e.target.clientHeight;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPage(prev => prev + 1)
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (page <= maxPage) {
      fetchImages()
    }
  }, [page])

  const saveImage = async ({ url, organizationId }) => {
    setLoading(true);
    try {
      const data = await uploadImage({
        url,
        fromGallery: true,
        organizationId
      })
      console.log(data)
      onImageModalChange({ imageUrl: data.url })

    } catch (err) {

    } finally {
      setLoading(false)
    }
  }

  const onClickHandler = async (i) => {
    saveImage({ url: i?.largeImageURL, organizationId: formData?.organization })
  }

  return (
    <Box position={'relative'}>

      {loading && <Center
        position={'absolute'}
        top={0}
        left={0}
        w='100%'
        h='100%'
        bg={'rgba(255,255,255,0.5)'}
      >
        <Box>
          <Spin />
        </Box>
      </Center>}

      <Box mb={'10px'}>
        <StyledInput
          onChange={e => setQ(e.target.value)}
          value={q}
        />
      </Box>

      <Box
        display={'flex'}
        flexWrap={'wrap'}
        w={'100%'}
        h={'375px'}
        overflowY={'scroll'}
        overflowX={'hidden'}
        onScroll={handleScroll}
      >


        <Box
          flex={'0 0 50%'}
        >
          {images.filter((_, index) => index % 2 === 0).map((i, index) => (
            <Box
              cursor={'pointer'}
              p={'3px'}
              onClick={() => onClickHandler(i)}
            >
              <img
                rel={i?.webformatURL}
                src={i?.previewURL}
                style={{
                  borderRadius: '6px',

                  width: '100%',
                  objectFit: "cover"
                }}
              />
            </Box>
          ))}
        </Box>

        <Box
          flex={'0 0 50%'}
          p={'3px'}
        >
          {images.filter((_, index) => index % 2 === 1).map((i, index) => (
            <Box
              cursor={'pointer'}

              p={'3px'}
              onClick={() => onClickHandler(i)}
            >
              <img
                src={i?.previewURL}
                style={{
                  borderRadius: '6px',
                  width: '100%',
                  objectFit: "cover"
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>



    </Box>
  );
};

export default ImagesGallery;