import React, {useEffect, useState} from 'react';
import ConfiguratorLayout from "./components/ConfiguratorLayout";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";

import useFormConfiguratorStore from "./stores/useFormConfiguratorStore";
import _ from 'lodash'
import {getForm} from "../../firebase/form";
import Content from "./components/Content";
import FieldsList from "./components/FieldsList";
import RightToolsBar from "./components/RightToolsBar";
import useFieldAutoSave from "./hooks/useFieldAutoSave";
import FormNameModal, {useFormNameModalStore} from "./components/FormNameModal";
import {parseSearchQuery} from "../../utils/common";
import {useInitFontFamily} from "./components/Design";
import {Redirect, Route, Switch} from "react-router-dom";
import UnauthorizedLayout from "../../shared/UnauthorizedLayout";
import AppLoading from "../../shared/AppLoading";
import ConfiguratorHeader from "./components/ConfiguratorHeader";
import {Box} from "@chakra-ui/react";
import Results from "./components/analytics/Results";
import Share from "./components/share";
import Integrations from './components/integrations/Integrations';

export const HEADER_HEIGHT = '47px'

const Config = () => {
  useInitFontFamily()
  return null
}

const FormConfigurator = () => {

  const {formId, fieldId} = useParams()

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const location = useLocation();
  const queryObject = parseSearchQuery(location.search);

  const [onOpenFormNameModal] = useFormNameModalStore(store => [store.onOpen])

  const [
    fields,
    endingFields,
    formData,
    activeField,
    setActiveField,
    setFields,
    setEndingFields,
    setFormData,
    setGalleryThemes,
    setMyThemes
  ] = useFormConfiguratorStore(state => [
    state.fields,
    state.endingFields,
    state.formData,
    state.activeField,
    state.setActiveField,
    state.setFields,
    state.setEndingFields,
    state.setFormData,
    state.setGalleryThemes,
    state.setMyThemes,
  ])

  const [formInitializing, setFormInitializing] = useState()

  const fetchForm = async () => {

    setFormInitializing(true)

    try {

      const data = await getForm(formId)

      setFormData(data)
      setGalleryThemes(data.galleryThemes)
      setMyThemes(data.myThemes)

      if (location.state?.showNameModal) {
        onOpenFormNameModal({
          name: data.name,
          id: data?.id
        })
      }

      const fields = data?.fields?.map((f, index) => ({...f, order: index + 1}))
      const endingFields = data?.endingFields?.map((f, index) => ({...f, order: index + 1}))

      setFields(fields)
      setEndingFields(endingFields)

    } catch (err) {
      console.log(err)
      history.push('/404')
    } finally {
      setFormInitializing(false)
    }
  }

  useEffect(() => {
    if (!_.isEmpty(formData) && !_.isEmpty(fields) && !formInitializing) {

      const block = queryObject?.block
      let firstField

      const mergedFields = [...fields, ...endingFields]
      const fieldIds = mergedFields?.map(f => f.id)
      const allFieldIds = [...fieldIds, 'welcome']

      if (!block || !allFieldIds.includes(block)) {

        firstField = !_.isEmpty(formData.welcomeScreen) ? formData.welcomeScreen : fields[0]
        setActiveField({...firstField})
      } else if (block === 'welcome') {
        if (!_.isEmpty(formData.welcomeScreen)) {
          setActiveField({...formData.welcomeScreen})
        } else {
          setActiveField(fields[0])
        }
      } else {
        const findField = mergedFields.find(f => f.id === block)
        setActiveField(findField)
      }

    }
  }, [location, formInitializing])

  useEffect(() => {
    fetchForm()
  }, [formId])

  useFieldAutoSave(fields, endingFields, activeField, setFields, setEndingFields, setFormData)

  if (formInitializing) return <AppLoading/>

  return (
    <>
      <Config/>
      <FormNameModal/>

      <ConfiguratorHeader/>

      <Switch>

        <Route
          key={path}
          exact
          path={path}
        >
          <ConfiguratorLayout
            leftSideBar={<FieldsList/>}
            rightSideBar={<RightToolsBar/>}
          >
            <Content/>
          </ConfiguratorLayout>
        </Route>


        <Route
          path={`${path}/integrate`}
        >
          <Integrations formData={formData} fetchForm={fetchForm}/>
        </Route>


        <Route
          path={`${path}/share`}
        >
          <Share formData={formData}/>
        </Route>


     


        <Route
          path={`${path}/results`}
        >
          <Results/>
        </Route>


      </Switch>
    </>
  );
}


export default FormConfigurator;