import React from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import { Input, Box, Flex, HStack, Center } from '@chakra-ui/react'
import styled from "styled-components";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { Player } from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import { FakeButton, FakeInput } from "../TextField/TextField";
import { Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { OptionItem } from "../MultiChoice/MultiChoice";

const PhoneField = ({ fieldData, onChange, theme = {}, mentionOptions, messages, showQuestionNumber }) => {

  if (!fieldData) return null

  const { questionColor, fontSize, align, answerColor, buttonBackground, buttonColor } = theme
  const { options = [], generalSettings = {} } = fieldData
  const { country = {}, required } = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <HStack alignItems={'flex-end'}>
        <Box w={'80px'} >
          <FakeInput
            color={answerColor}
            fontSize={fontSize === 'sm' && '20px'}
          >
            <Space
              style={{ padding: '0 8px' }}
            >
              <div
                style={{
                  border: '1px solid #f2f2f2',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                }}
              >
                <img src={`https://flagcdn.com/h24/${country.code.toLowerCase()}.png`} alt={`${country.name} flag`} />
              </div>

              <Center>
                <DownOutlined style={{ fontSize: '20px' }} />
              </Center>

            </Space>

          </FakeInput>
        </Box>
        <Box flex={1}>
          <FakeInput
            color={answerColor}
            fontSize={fontSize === 'sm' && '20px'}

          >
            {country.dial_code}
          </FakeInput>
        </Box>
      </HStack>
      <Flex
        justifyContent={align === 'center' ? 'center' : 'flex-start'}

        mt={'15px'}
      >
        <FakeButton
          backgroundColor={buttonBackground}
          color={buttonColor}
          theme={theme}
        >
          {messages['ok']}
        </FakeButton>
      </Flex>

    </FieldWrapper>
  );
};

export default PhoneField;