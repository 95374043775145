import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Box, Center, Button, VStack } from "@chakra-ui/react";
import { Form, Space, Spin, Steps } from "antd";
import { PicRightOutlined, PlusOutlined } from "@ant-design/icons";
import { modalStore } from '../../../../utils/common';
import CustomModal from '../../../../shared/CustomModal';
import GoogleLogo from "../../../../assets/googleLogo.svg";
import { useGoogleLogin } from '@react-oauth/google';
import PrimaryButton from '../../../../shared/PrimaryButton';
import { useGoogleSheetOAuthQuery, useSaveIntegrationGoogleSheet } from '../../../../firebase/form';
import InlineTextField, { StyledInput } from '../../../../shared/form/InlineTextField';

const { Step } = Steps;

const StepsMy = ({ current }) => {
    return <Steps size="small" current={current}>
        <Step
            icon={<Center
                w={'26px'}
                h={'26px'}
                border={'2px solid'}
                borderRadius={'50%'}
                fontSize={'12px'}
            >1</Center>}
        />
        <Step icon={<Center
            w={'26px'}
            h={'26px'}
            border={'2px solid'}
            borderRadius={'50%'}
            fontSize={'12px'}
        >2</Center>} />
        <Step icon={<Center
            w={'26px'}
            h={'26px'}
            border={'2px solid'}
            borderRadius={'50%'}
            fontSize={'12px'}
        >3</Center>} />
    </Steps>
}

const GoogleButton = (props) => {
    return <Button
        background={'white'}
        border={'1px solid'}
        borderColor={'blue.700'}
        _hover={{
            background: 'white',
            opacity: 0.9
        }}
        color={'blue.700'}
        w={['100%']}
        fontWeigth={200}
        {...props}
    >
        <Space>
            <Box>
                <img
                    src={GoogleLogo}
                />
            </Box>
            <Box>
                Log in via Google
            </Box>
        </Space>

    </Button>
}

export const useGoogleSheetsModalStore = modalStore()


const GoogleSheetsModal = ({fetchForm}) => {

    const { formId } = useParams()

    const history = useHistory()

    const [isOpen, onClose, data = {}] = useGoogleSheetsModalStore(store => [store.isOpen, store.onClose, store.data])


    const [values, setValues] = useState({
        sheetTitle: ''
    })

    const [loading, setLoading] = useState()

    const [currentStep, setCurrentStep] = useState(0)


    const { mutate, isLoading } = useSaveIntegrationGoogleSheet(() => {
        fetchForm()
        onClose()
        setValues({})
    })
    const { data: oauthData, isLoading: loadingOAuthData } = useGoogleSheetOAuthQuery()


    const googleSuccess = async (res) => {

        const token = res

        try {
            setValues(res)
            setCurrentStep(1)
            console.log(res)
            //   signIn(data)
            //   history.push('/')
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    const googleError = (err) => {
        console.log('Google Sign In was unsuccessful. Try again later', err);
    }

    const login = useGoogleLogin({
        onSuccess: googleSuccess,
        onError: googleError,
        scope: 'https://www.googleapis.com/auth/spreadsheets',
        flow: 'auth-code',
        locale: 'en_US',
        redirect_uri: 'https://app.formee.io',
    });



    const Step0 = currentStep === 0 && <>
        <Box py={'30px'}>
            Click the auth button below to link your google sheet account.
        </Box>

        <Box mt={'40px'}>
            <GoogleButton onClick={() => {
                login()
            }} />
        </Box>
    </>



    const Step1 = currentStep === 1 && <>

        <Box p={'25px 0 10px 0'}>
            <Form layout='vertical'>
                <Form.Item
                    label={'Name your new spreadsheet'}
                >
                    <InlineTextField
                        value={values.sheetTitle}
                        // onChange={e => console.log(e.target.value)}
                        onChange={e => {
                            setValues(prev => ({ ...prev, sheetTitle: e || '' }))
                        }}
                        bg={'white'} 
                        />
                </Form.Item>
            </Form>
        </Box>

        <Box mt={'40px'}>
            <PrimaryButton
                size='sm'
                loading={isLoading}
                onClick={() => {
                    setCurrentStep(2)
                }} >
                OK
            </PrimaryButton>
        </Box>
    </>



    const Step2 = currentStep === 2 && <>

        <Box py={'30px'}>
            Click Activate integration to save your settings and finish the setup
        </Box>

        <Box mt={'40px'}>
            <PrimaryButton
                size='sm'
                loading={isLoading}
                onClick={async () => {
                    mutate({
                        ...values,
                        formId
                    })
                   

                }} >
                Activate
            </PrimaryButton>
        </Box>
    </>


    return <CustomModal
        title={'Google Sheet'}
        zIndex={12}
        isOpen={isOpen}
        onClose={() => {
            onClose()
            setValues({})
            setCurrentStep(0)
        }}
        size={'3xl'}
        showFooter={false}
    >
        <Box
            d={'flex'}
            justifyContent={'center'}
            p={'30px 0 10px'}
        >
            <Center>
                <VStack>
                    <Center w={'300px'}>
                        <StepsMy current={currentStep} />
                    </Center>

                    {Step0}
                    {Step1}
                    {Step2}

                </VStack>
            </Center>
        </Box>
    </CustomModal>
};

const ChoiceCard = ({ children, onClick }) => {
    return <Box
        onClick={onClick}
        p={'40px'}
        bg={'white'}
        mx={'5px'}
        borderRadius={'14px'}
        border={'1px solid #dddddd'}
        color={'#8c8c8c'}
        d={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        cursor={'pointer'}
    >
        {children}
    </Box>
}

export default GoogleSheetsModal;