import React from 'react';
import {Box, HStack, Button, Input} from '@chakra-ui/react'
import {DEFAULT_INPUT_HEIGHT, defaultOnChange} from "./useFormBuilder";
import colors from "../../utils/commonStyles";


const InlineTextField = ({formik, name, value, onChange, ...rest}) => {

  const _value = value || formik?.values?.[name]

  return (
    <Box
    >
      {/*{showMaxLength && <Box*/}
      {/*  position={'absolute'}*/}
      {/*  right={'15px'}*/}
      {/*  top={'50%'}*/}
      {/*  transform={'translateY(-50%)'}*/}
      {/*>*/}
      {/*  {_value?.length}/{maxLength}*/}
      {/*</Box>}*/}
      <StyledInput
        value={_value}
        onChange={e => {
          onChange ? onChange(e.target.value) : defaultOnChange(formik)(name, e.target.value)
        }}
        {...rest}
      />
    </Box>
  );
};

export const StyledInput = (props) => <Input
  h={DEFAULT_INPUT_HEIGHT}
  borderColor={colors.border}
  bg={colors.lightGrey}
  px={'8px'}
  _placeholder={{
    color: colors.border
  }}
  {...props}
/>

export default InlineTextField;