import React from 'react';
import ToolsSection from "../../../shared/ToolsSection";
import {Button, Box, HStack} from "@chakra-ui/react";
import {IconButton} from "./ThemeEditor";
import {CloseIcon} from "@chakra-ui/icons";
import SectionHeader from "../../../shared/SectionHeader";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import {ImageConfigIcon, ImageIcon, RemoveIcon} from "../fieldTypes/icons";
import {defaultButtonProps} from "../../../utils/commonStyles";
import {FieldInlineWrapper} from "./ThemeEditor";
import SecondaryButton from "../../../shared/SecondaryButton";

const RightToolsBarImageSettings = () => {

  const [
    updateActiveField,
    setMyThemes,
    activeField,
    updateLayoutConfig,
    setActiveField,
    galleryThemes,
    myThemes,
    activeTheme,
    setActiveTheme,
    setImageModalOpen,
    setImageSettingsModalOpen,
    setImageSettingsModalClose,
  ] = useFormConfiguratorStore(state => [
    state.updateActiveField,
    state.setMyThemes,
    state.activeField,
    state.updateLayoutConfig,
    state.setActiveField,
    state.galleryThemes,
    state.myThemes,
    state.activeTheme,
    state.setActiveTheme,
    state.setImageModalOpen,
    state.setImageSettingsModalOpen,
    state.setImageSettingsModalClose,
  ])


  const onChangeImage = (data) => {
    updateLayoutConfig({
      // videoUrl: null,
      ...data,
    })
  }

  const onRemoveImage = () => {
    updateLayoutConfig({
      imageUrl: null,
      videoUrl: null,
    })
  }

  // const onChangeImageConfig = (data) => {
  //   updateActiveField({
  //     layoutConfig: {
  //       ...activeField.layoutConfig,
  //       imageConfig: {...data}
  //     }
  //   })
  // }


  const imageUrl = activeField?.layoutConfig?.imageUrl
  const videoUrl = activeField?.layoutConfig?.videoUrl

  return (
    <ToolsSection>
      <FieldInlineWrapper
        label={<SectionHeader>
          Image
        </SectionHeader>}
      >

        {(imageUrl || videoUrl) ? (
          <HStack>
            <IconButton
              onClick={() => setImageModalOpen({
                onImageModalChange: onChangeImage
              })}
            >
              <ImageIcon/>
            </IconButton>
            {/*<IconButton*/}
            {/*  onClick={() => setImageSettingsModalOpen({*/}
            {/*    onImageSettingModalChange: onChangeImageConfig*/}
            {/*  })}*/}
            {/*>*/}
            {/*  <ImageConfigIcon/>*/}
            {/*</IconButton>*/}
            <IconButton
              onClick={onRemoveImage}
            >
              <RemoveIcon/>
            </IconButton>

          </HStack>
        ) : (
          <SecondaryButton
            {...defaultButtonProps}
            fontSize={'14px'}
            // p={'3px'}
            size={'sm'}
            onClick={() => setImageModalOpen({
              onImageModalChange: onChangeImage
            })}
          >
            + Add
          </SecondaryButton>
        )}
      </FieldInlineWrapper>


    </ToolsSection>
  );
};

export default RightToolsBarImageSettings;