import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useUserState} from "../../../App";
import _ from "lodash";

const useUserPermissions = (permissions = []) => {
  const history = useHistory()

  const [
    currentOrganization,
    isOwner,
    userPermissions
  ] = useUserState(store => ([
    store.currentOrganization,
    store.isOwner,
    store.permissions,
  ]))

  const isPermitted = currentOrganization?.permissions?.some(p => permissions.includes(p))
  return isOwner || isPermitted
};

export default useUserPermissions;