import React from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import {Divider} from "@chakra-ui/react";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";

const ContactFormSettings = ({activeField = {}, onChange}) => {


  return (
    <div>
      {/*<SectionHeader*/}
      {/*  my={'10px'}*/}
      {/*  mb={'20px'}*/}
      {/*>*/}
      {/*  Settings*/}
      {/*</SectionHeader>*/}

      {/*<Divider*/}
      {/*  my={'5px'}*/}
      {/*  borderColor={'#cccccc'}*/}
      {/*/>*/}

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      {<>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};

export default ContactFormSettings;