import { Box, HStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createMentionPlugin from '@draft-js-plugins/mention';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

import { EyeInvisibleOutlined } from "@ant-design/icons";
import createLinkPlugin from '@draft-js-plugins/anchor';
import { BoldButton, ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import _ from "lodash";
import styled, { css } from "styled-components";
import { TextEllipsis } from "../pages/FormConfigurator/components/FieldsList";
import { fieldTypes } from "../pages/FormConfigurator/fieldTypes/config";
import { IconLabel } from "../pages/FormConfigurator/fieldTypes/icons";
import { rawToHtml } from '../utils/common';
import colors from "../utils/commonStyles";
import { customSuggestionsFilter } from "./msicForWysiwyg";

// import editorStyles from './editorStyles.module.css';

const MENTION_FIELDS = [
  'textfield',
  'multiChoice',
  'singleChoice',
  'pictureChoice',
  'yesNo',
  'opinionScale',
  'rating',
  'textarea',
  'email',
  'website',
  'numberfield',
  'datefield',
  'legal',
  'phone',
  'select'
]

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export const isDraftContentEmpty = ({blocks = []}) => {
  return !blocks?.[0]?.text
}


function Entry(props) {

  const {
    mention,
    theme,
    searchValue,
    isFocused,
    ...parentProps
  } = props;

  const IconComponent = mention.resource === 'Q' ? fieldTypes[mention.type]?.icon :
    mention.resource === 'HIDDEN' ? IconLabel('',
      <EyeInvisibleOutlined/>) : IconLabel('', '@')

  return (
    <div
      {...parentProps}
    >
      <HStack
        m={'5px 0 8px 0'}
        fontSize={'14px'}
      >
        <IconComponent label={mention?.order}/>
        <TextEllipsis
          linesLength={1}
        >
          {mention?.label || rawToHtml(mention.nameDraftContent)}
        </TextEllipsis>
      </HStack>
    </div>

  );
}

export const renderMentionOptions = (fields = [], activeField, formData) => {

  const {numberVariables = [], stringVariables = [], hiddenVariables = []} = formData

  let results = []

  fields.filter(f => (activeField.type === 'endingField' ? true : (f.order < activeField.order)) && MENTION_FIELDS.includes(f.type)).forEach((f, index) => {
    const name = convertFromRaw(f?.nameDraftContent).getPlainText('\u0001')
    results.push({
      resource: 'Q',
      type: f.type,
      order: f.order,
      nameDraftContent: f.nameDraftContent,
      uid: f.id,
      name
    })
  })

  results.push({
    resource: 'NUM',
    uid: 'score',
    name: 'score',
    label: 'score'
  })

  numberVariables.forEach((f, index) => {
    const name = f.name
    results.push({
      resource: 'NUM',
      uid: f.id,
      name,
      label: name
    })
  })

  stringVariables.forEach((f, index) => {
    const name = f.name
    results.push({
      resource: 'STR',
      uid: f.id,
      name,
      label: name
    })
  })

  hiddenVariables.forEach((f, index) => {
    const name = f.name
    results.push({
      resource: 'HIDDEN',
      uid: f.id,
      name,
      label: name
    })
  })

  return results
}


const inlineToolbarPlugin = createInlineToolbarPlugin();
const {InlineToolbar} = inlineToolbarPlugin;


const CustomWysiwyg = ({
                         id,
                         value,
                         onChange,
                         fontSize,
                         placeholderFontSize,
                         top,
                         mentionFontSize,
                         textAlign,
                         placeholder,
                         readOnly,
                         beforeContent,
                         afterContent,
                         mentionOptions,
                         showMentions = true
                       }) => {

  const prevId = usePrevious(id);

  const ref = useRef(null);

  const convertFromRawValue = convertFromRaw(value)

  const [editorState, setEditorState] = useState(() =>
    convertFromRawValue ? EditorState.createWithContent(convertFromRawValue) : EditorState.createEmpty()
  );

  const [open, setOpen] = useState(false);

  const [suggestions, setSuggestions] = useState(mentionOptions);

  const {InlineToolbar, LinkButton, MentionSuggestions, plugins} = useMemo(() => {
    const linkPlugin = createLinkPlugin();
    const inlineToolbarPlugin = createInlineToolbarPlugin();
    const {InlineToolbar} = inlineToolbarPlugin;

    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true,
    });

    const {MentionSuggestions} = mentionPlugin;

    const plugins = [inlineToolbarPlugin, linkPlugin, mentionPlugin];

    return {plugins, MentionSuggestions, InlineToolbar, LinkButton: linkPlugin.LinkButton};
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);


  const onSearchChange = useCallback(({value}) => {

    setSuggestions(customSuggestionsFilter(value, mentionOptions));
  }, []);

  useEffect(() => {
    if (prevId !== id) {
      setTimeout(() => {
        setEditorState(convertFromRawValue ? EditorState.createWithContent(convertFromRawValue) : EditorState.createEmpty())
      }, 0)
    }
  }, [id, value, beforeContent, afterContent])


  return (
    <>
      <EditorContainer
        beforeContent={editorState.getCurrentContent().hasText() && (beforeContent || '')}
        afterContent={editorState.getCurrentContent().hasText() && (afterContent || '')}
        fontSize={fontSize}
        placeholderFontSize={placeholderFontSize}
        top={top}
        mentionFontSize={mentionFontSize}
        textAlign={textAlign}
        onClick={() => {
          ref.current.focus();
        }}
      >
        {
          !editorState.getCurrentContent().hasText() && <Placeholder
            justifyContent={textAlign}
          >
            {`${beforeContent || ''}${afterContent || ''} ${placeholder}`}
          </Placeholder>
        }

        <Editor
          stripPastedStyles={true}

          readOnly={readOnly}
          editorKey={'editor'}
          editorState={editorState}
          onBlur={state => {
            const toRaw = convertToRaw(editorState.getCurrentContent())
            console.log(toRaw, 'toRaw')

            onChange(toRaw)
          }}
          onChange={setEditorState}
          plugins={plugins}
          ref={ref}

        />

        <InlineToolbar
        >
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <LinkButton {...externalProps} />
              </>
            )
          }
        </InlineToolbar>

        {showMentions && <MentionSuggestions
        
          open={open}
          onOpenChange={onOpenChange}
          entryComponent={Entry}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />}

      </EditorContainer>
    </>
  );
}


const memoWrapper = React.memo(CustomWysiwyg, (prev, next) => {
  return _.isEqual(prev.value, next.value)
})


const EditorContainer = styled(Box)`
  position: relative;
  display: flex;
  min-width: 60px;
  justify-content: ${({textAlign}) => textAlign === 'center' && 'center'} !important;
  box-sizing: border-box;
  cursor: text;
  border-radius: 2px;
  text-align: ${({textAlign}) => textAlign || 'flex-start'} !important;
  
  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
  
  .DraftEditor-root {
    min-width: 1px !important;
  }

  .DraftEditor-editorContainer{
      position: relative;
      min-width: 1px !important;
      ${({afterContent, beforeContent}) => (afterContent || beforeContent) && css`
        &:before{
          content: '${beforeContent}${afterContent}';
          display: flex;
          font-size: ${({placeholderFontSize}) => placeholderFontSize || '24px'};
          top: ${({top}) => top || '0px'};
          flex-direction: row;
          position: absolute;
          transform: translate(-130%); 
          // left: -17px;
        }  
      `}
  }
  
  .bpsgbes{
    background: white !important;
  }
    
  .m6zwb4v{
    color: ${colors.primary};
    background: ${colors.mentionBg};
    border: 1px solid ${colors.primary};
    border-radius: 6px;
    // font-size: ${({mentionFontSize}) => mentionFontSize};
    font-size: 13px;
    display: inline-block;
    max-width: 400px;
    
    // span{
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   display: -webkit-box;
    //   word-wrap: break-word;
    //   -webkit-line-clamp: 1;
    //   -webkit-box-orient: vertical;
    // }
  }
  
  .tukdd6b {
    background: white !important;
    
    button {
      background: white !important;    
      padding: 0 !important;
    }
  }
  
  .mnw6qvm{
    max-height: 400px;
    overflow-y: auto !important;
    border-color: #bbbbbb;
    border-radius: 6px;
    color: black;
    font-size: 16px;
    font-family: Poppins;
  }
`
const Placeholder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: transparent;
  line-height: 30px;
  justify-content: ${
  ({justifyContent}) => justifyContent
};
  // color: #dddddd;
  z-index: 0;
  opacity: 0.4;
  font-style: italic;
  font-weight: 400;
`

export default CustomWysiwyg;
