import React from 'react';
import {Box, HStack} from "@chakra-ui/react";
import {DownOutlined} from "@ant-design/icons";
import {Space, Dropdown} from "antd";
import {INPUT_BORDER_RADIUS} from "../index";
import colors from "../../../utils/commonStyles";
import {TextEllipsis} from "../../FormConfigurator/components/FieldsList";


export const DropDownMenu = ({options, onChange, value}) => {
  return <Box
    overflow={'hidden'}
    borderRadius={INPUT_BORDER_RADIUS}
    bg={'white'}
    boxShadow={colors.shadowColor}
    p={'6px 0'}
  >
    {options.filter(o => !o.hasOwnProperty('visible') || o.visible).map(o => <Box
      alignText={'center'}
      p={'10px 20px'}
      color={o.value === 'delete' && 'red'}
      cursor={'pointer'}
      _hover={{bg: '#f2f2f2'}}
      bg={value === o.value && '#f2f2f2'}
      onClick={() => o.onClick ? o.onClick() : (onChange && onChange(o.value))}
    >
      {o.label}
    </Box>)}
  </Box>

}

const CustomDropdown = ({value, options = [], onChange, containerProps}) => {


  const valueLabel = options.find(o => o.value === value)?.label

  // const menu = <Box
  //   overflow={'hidden'}
  //   borderRadius={INPUT_BORDER_RADIUS}
  //   bg={'white'}
  //   boxShadow={colors.shadowColor}
  //   p={'6px 0'}
  // >
  //   {options.map(o => <Box
  //     alignText={'center'}
  //     p={'10px 20px'}
  //     cursor={'pointer'}
  //     _hover={{bg: '#f2f2f2'}}
  //     onClick={() => onChange(o.value)}
  //   >
  //     {o.label}
  //   </Box>)}
  // </Box>


  const menu = <DropDownMenu
    value={value}
    options={options}
    onChange={onChange}
  />

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={'click'}
      >
        <Box
          cursor={'pointer'}
          bg={'white'}
          borderRadius={INPUT_BORDER_RADIUS}
          w={'230px'}
          p={'10px 18px'}
          {...containerProps}
        >
          <HStack
            justifyContent={'space-between'}
          >
            <Box
            >
              <TextEllipsis

                linesLength={1}
              >

                {valueLabel}
              </TextEllipsis>
            </Box>
            <DownOutlined/>
          </HStack>
        </Box>
      </Dropdown>


    </>
  );
};

export default CustomDropdown;