import React, { useState } from 'react';
import { Box, Center } from "@chakra-ui/react";
import QuestionSettings from "./QuestionSettings";
import LogicSettings from "./LogicSettings";
import Design from "./Design";
import styled from "styled-components";
import { Tabs } from 'antd';
import { SettingOutlined } from "@ant-design/icons";
import Settings from "./Settings";

const { TabPane } = Tabs;

const RightToolsBar = () => {

  const [activeTab, setActiveTab] = useState('question')

  return (
    <Box
      // h={'100%'}
      // bg={'red'}
    >
      <StyledTabs
        onChange={t => setActiveTab(t)}
        // tabBarExtraContent={<Center mt={'3px'}>
        //   <SettingOutlined
        //     onClick={() => setActiveTab('settings')}
        //     style={{
        //       fontSize: '18px',
        //       color: activeTab === 'settings' && '#3366FF'
        //     }}
        //   />
        // </Center>}
        defaultActiveKey="question"
        activeKey={activeTab}
        // style={{ padding: '0px 10px 10px 10px' }}
      >


        <TabPane
          tab={<Box
            style={{
              padding: '0px 12px',
              // color: '#3366FF'
            }}
          >
            Question
          </Box>}
          key="question"
        >
          <Box px={'10px'}>
          <QuestionSettings />

          </Box>
        </TabPane>

        {/* <TabPane
          tab="Logic"
          key="logic"
          style={{margin: 0}}
        >
          <LogicSettings/>
        </TabPane> */}

        <TabPane style={{
          height: '100%',
      
        }}
          tab={<Box
            style={{
              padding: '0px 14px',
              // color: '#3366FF'
            }}
          >
            
            Design
          </Box>}
          key="design"

        >
           <Box px={'10px'}>
           <Design />

          </Box>
        </TabPane>



        <TabPane style={{ height: '100%' }}
          tab={<Center
            style={{
              paddingLeft: '9px',
              fontSize: '18px',
              width: '24px',
              overflow: 'hidden'
              // padding: '0px 10px'
              // color: '#3366FF'
            }}
          >
            <SettingOutlined

            />
          </Center>}
          key="sett"
        >
           <Box px={'10px'}>
           <Settings />

          </Box>
        </TabPane>

        {/* 
        {activeTab === 'settings' && <Box w={'100%'}>
            <Settings/>
        </Box>} */}


      </StyledTabs>


    </Box>
  );
};

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab:not(:first-of-type){
    margin-left: 20px !important;

  }
`;

export default RightToolsBar;