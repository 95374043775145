import React from 'react';
import SecondaryButton from "../../../shared/SecondaryButton";
import TemplateCategoriesModal, {useTemplateCategoriesModalStore} from "./TemplateCategoriesModal";
import {Space} from "antd";

const SuperAdminTools = ({formData}) => {

  const [onOpenCetegories, isOpen] = useTemplateCategoriesModalStore(store => [store.onOpen, store.isOpen])

  return (
    <>
      {isOpen && <TemplateCategoriesModal/>}

      <Space>

        <SecondaryButton
          size={'sm'}
          px={'4px'}
          onClick={() => {
            onOpenCetegories({...formData, isSaveAsTemplate: true})
          }}
        >
          Save as template
        </SecondaryButton>
        <SecondaryButton
          size={'sm'}
          px={'4px'}
          onClick={() => {
            onOpenCetegories()

          }}
        >
          Template categories
        </SecondaryButton>
      </Space>

    </>
  );
};

export default SuperAdminTools;