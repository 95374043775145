import { Box } from "@chakra-ui/react";
import React from 'react';
import CenterImage from "../../../../shared/CenterImage";
import FieldWrapper from "../../../../shared/FieldWrapper";
import { Player } from "../../components/Content";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { renderNameBlock } from "../LegalField/LegalField";
import { OptionItem } from "../MultiChoice/MultiChoice";

export const yesNameBlock = {
  "blocks": [
    {
      "key": "drc9v",
      "text": "Yes",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
}

export const noNameBlock = {
  "blocks": [
    {
      "key": "drc9v",
      "text": "No",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
}

const YesNoField = ({ fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber, messages }) => {
  const translations = messages

  const { questionColor, fontSize, align, answerColor, backgroundColor } = theme

  const { generalSettings = {} } = fieldData
  const { required } = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  if (!fieldData) return null

  const { buttonRadius } = theme

  const borderRadius = buttonRadius === 'sharp' ? '8px' : '44px'

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage

          justifyContent={align === 'center' ? 'center' : 'flex-start'}

          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        d={'flex'}
        justifyContent={align === 'center' ? 'center' : 'flex-start'}

      >

        <Box
          maxW={'200px'}
        >
          <OptionItem
            id={'yes'}
            char={translations["yesNo.Y"]}
            borderRadius={borderRadius}
            name={'Yes'}
            allowRemove={false}
            color={answerColor}
            fontSize={fontSize === 'sm' ? '16px' : '20px'}

            backgroundColor={backgroundColor}
            readOnly={true}
            nameBlocks={renderNameBlock(translations["yesNo.yes"])}
          />
          <OptionItem
            id={'no'}
            char={translations["yesNo.N"]}
            borderRadius={borderRadius}

            name={'No'}
            allowRemove={false}
            color={answerColor}
            fontSize={fontSize === 'sm' ? '16px' : '20px'}

            backgroundColor={backgroundColor}
            readOnly={true}
            nameBlocks={renderNameBlock(translations["yesNo.no"])}
          />
        </Box>
      </Box>


    </FieldWrapper>
  );
};

export default YesNoField;