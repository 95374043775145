import React, {useEffect, useState} from 'react';
import CustomWysiwyg from "../../../shared/CustomWysiwyg";
import {Box, HStack, Button, Center} from '@chakra-ui/react'
import {
  DescriptionFontSizes,
  DescriptionFontSizesMention,
  QuestionFontSizes,
  QuestionFontSizesMention, QuestionPlaceholderFontSizes, QuestionPlaceholderTop
} from "../fieldTypes/config";
import _ from "lodash";

const TitleAndDescriptionInputs = ({
                                     id,
                                     data,
                                     onChange,
                                     color,
                                     fontSize,
                                     textAlign,
                                     beforeContent,
                                     afterContent,
                                     mentionOptions
                                   }) => {

  // const [nameDraftContent, setNameDraftContent] = useState(data.nameDraftContent)
  // const [descriptionDraftContent, setDescriptionDraftContent] = useState(data.descriptionDraftContent)

  //
  // useEffect(() => {
  //   onChange({nameDraftContent, descriptionDraftContent})
  // }, [nameDraftContent, descriptionDraftContent])

  // useEffect(() => {
  //   setNameDraftContent(data.nameDraftContent)
  //   setDescriptionDraftContent(data.descriptionDraftContent)
  // }, [data])

  return (
    <Box
      color={color}
    >
      <Box
        mb={fontSize === 'sm' ? '15px' : '20px'}
      >
        <CustomWysiwyg
          mentionOptions={mentionOptions}
          afterContent={afterContent}
          beforeContent={beforeContent}
          id={id}
          value={data.nameDraftContent}
          onChange={(val) => {
            onChange({nameDraftContent: val, descriptionDraftContent: data?.descriptionDraftContent})
          }}
          fontSize={QuestionFontSizes[fontSize]}
          placeholderFontSize={QuestionPlaceholderFontSizes[fontSize]}
          top={QuestionPlaceholderTop[fontSize]}
          mentionFontSize={QuestionFontSizesMention[fontSize]}
          textAlign={textAlign}
          placeholder={'Your question here. Recall information with @'}
        />
      </Box>

      <Box
        mb={'25px'}

      >
        <CustomWysiwyg
          mentionOptions={mentionOptions}
          value={data.descriptionDraftContent}
          id={`sub${id}`}
          onChange={(val) => {
            onChange({nameDraftContent: data?.nameDraftContent, descriptionDraftContent: val})

          }}
          fontSize={DescriptionFontSizes[fontSize]}
          mentionFontSize={DescriptionFontSizesMention[fontSize]}
          textAlign={textAlign}
          placeholder={'Description (optional)'}
        />
      </Box>


    </Box>
  );
};

const memoWrapper = React.memo(TitleAndDescriptionInputs, (prev, next) => {
  return _.isEqual(prev, next)
})


export default TitleAndDescriptionInputs;