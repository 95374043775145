import { DeleteOutlined } from "@ant-design/icons";
import { Box, Center, Textarea, VStack, HStack } from "@chakra-ui/react";
import { Form, message, Spin, Upload } from "antd";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { updateMetadata, uploadImage } from "../../../../firebase/form";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import colors from "../../../../utils/commonStyles";
import { uploadIllustration } from "../../../../utils/illustrations2";
import { TableButton } from "../analytics/ResponsesTable";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    let valid = true
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'

    const isGif = file.type === 'image/gif';

    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/GIF file!');
        valid = false
    }

    const isLt14M = file.size / 1024 / 1024 < 14;

    const isLt4M = file.size / 1024 / 1024 < 4;

    const isTooLargeGif = isGif && !isLt4M

    if (isTooLargeGif) {
        message.error('GIF must smaller than 4MB!');
        valid = false
    }

    if (!isLt14M) {
        message.error('Image must smaller than 14MB!');
        valid = false

    }
    return valid;
};

const MetaData = ({ formData = {} }) => {


    const { id, metaData } = formData

    const [values, setValues] = useState(metaData || {});
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const { title, description } = values

    const handleChange = (info) => {

        if (info.file.status !== 'uploading') return

        getBase64(info.file.originFileObj, async url => {
            console.log(info)
            setImageUrl({ url, media_type: info.file?.type, file_name: info.file?.name })
        });
    };

    const saveImage = async ({ url, media_type, file_name }) => {
        setLoading(true);
        try {
            const data = await uploadImage({
                base64: url,
                media_type,
                file_name,
                organizationId: formData?.organization,
                type: 'metaDataImage'
            })
            onChangeHandler('imageUrl', data.url)
            setImageUrl(null)
        } catch (err) {

        } finally {
            setLoading(false)
        }
    }

    const onChangeHandler = (key, val) => {
        setValues(prev => ({ ...prev, [key]: val }))
    }

    useEffect(() => {
        if (imageUrl) {
            saveImage(imageUrl)
        }
    }, [imageUrl])

    useEffect(() => {
        if (metaData) {
            setValues(metaData)
        }
    }, [metaData])



    const autoSave = () => {
        if (!_.isEmpty(values)) {
            updateMetadata({ metaData: values, formId: id })
        }
    }

    useDebouncedEffect(autoSave, [values], 400)

    const loader = loading && <Center>
        <Spin />
    </Center>

    const uploadContent = (
        <Center px={'20px'}>
            <VStack>
                <Box>
                    {uploadIllustration}
                </Box>
                <Box fontWeight={700}>
                    Upload or drop an image right here
                </Box>
                <Box color={colors.shadowColor} fontSize={'12px'}>
                    PNG or JPG (up to 14 MB).
                </Box>
            </VStack>

        </Center>
    );

    return <>
        <VStack>
            <Box >

                <div class="py-6" >
                    <div class="text-font_primary font-semibold text-lg text-center">Custom link preview</div>
                    <div class="text-font_secondary font-normal text-sm text-center  py-2 px-6 lg:px-24 mx-auto"> Customize the meta description that goes with your formee link.</div>

                </div>

                <Form layout={"vertical"}>

                    <Box display={'flex'} justifyContent={'center'}>
                        <Box>
                            <Form.Item label={'Image'}>


                                {values.imageUrl && <Box display={'flex'}>
                                    <Box w={'350px'}>
                                        <img src={values.imageUrl} />

                                    </Box>
                                    <Box pl={'10px'}>
                                        <TableButton
                                            w={'auto'}

                                            onClick={() => {
                                                onChangeHandler('imageUrl', null)
                                            }}
                                        >
                                            <HStack>
                                                <Box>
                                                    <DeleteOutlined />
                                                </Box>
                                            </HStack>
                                        </TableButton>
                                    </Box>
                                </Box>}

                                {!values.imageUrl && <Upload.Dragger
                                    name="avatar"
                                    accept={['.jpeg', '.png', '.gif', '.jpg']}
                                    // listType="picture-card"
                                    showUploadList={false}
                                    customRequest={({ file, onSuccess }) => {
                                        setTimeout(() => {
                                            onSuccess("ok");
                                        }, 0);
                                    }}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {loading ? loader : uploadContent}
                                </Upload.Dragger>}


                            </Form.Item>

                            <Form.Item label={'Title'}>
                                <InlineTextField
                                    value={title}
                                onChange={e => onChangeHandler('title', e)}
                                />
                            </Form.Item>
                            <Form.Item label={'Description'}>
                                <Textarea
                                    borderColor={colors.border}
                                    bg={colors.lightGrey}
                                    px={'8px'}
                                    _placeholder={{
                                        color: colors.border
                                    }}
                                    value={description}
                                    onChange={e => onChangeHandler('description', e.target.value)}
                                    />
                            </Form.Item>
                        </Box>

                    </Box>


                </Form>
            </Box>

        </VStack>


    </>
}

export default MetaData