import React from 'react';
import styled from 'styled-components'

const Anhor = ({children, ...rest}) => {
  return (
    <StyledAnhor {...rest} target={'_blank'}>
      {children}
    </StyledAnhor>
  );
};

const StyledAnhor = styled.a`
    text-decoration: inherit;
    color: inherit !important;
    cursor: pointer;
`

export default Anhor;