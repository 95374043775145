import React, { useEffect, useState } from 'react';
import { Center, Box, Menu, MenuButton, MenuList, HStack } from '@chakra-ui/react';
import CustomDropdownMenu from "./CustomDropdownMenu";
import { AddIcon, ChevronDownIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { HexColorPicker } from "react-colorful";
import { defaultOnChange } from "./form/useFormBuilder";
import { ColorPreviewIcon } from "../pages/FormConfigurator/fieldTypes/icons";
import _ from 'lodash'
import colors from "../utils/commonStyles";
import { SketchPicker } from 'react-color';

const ColorPicker = ({ name, formik, onChange, value }) => {

  value = value || formik.values?.[name]

  const [color, setColor] = useState(value)

  useEffect(() => {
    setColor(value)
  }, [formik])


  useEffect(() => {
    let timer = setTimeout(() => {

      if (onChange) {
        onChange(color)
      } else {
        defaultOnChange(formik)(name, color)

      }

    }, 400)
    return () => clearTimeout(timer)
  }, [color])

  return (
    <>
      <Menu
        border={'1px solid rgba(0, 0, 0, 0.07)'}
        boxShadow={'rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px'}
      >
        <MenuButton
          as={HStack}
          // p={'0px'}
          _hover={{
            background: 'rgb(228, 228, 228)'
          }}
          bg={colors.lightGrey}
          cursor={'pointer'}
          p={'2px 9px'}
          borderRadius={'6px'}
          // colorScheme={'gray.500'}
          borderWidth={'1px'}
          borderColor={'gray.500'}
        // w={'50px'}
        // h={'36px'}

        >
          <HStack
            p={'3px 1px'}
          >

            <ColorPreviewIcon
              color={value}
            />
            <ChevronDownIcon
              boxSize={'1.3em'}
              color={'gray.500'}
            />
          </HStack>
        </MenuButton>
        <MenuList p={0}>
          <SketchPicker
            presetColors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]}
            onChangeComplete={val => setColor(val.hex)}
            color={color}
          // onChange={}
          />

        </MenuList>
      </Menu>
    </>
  );
};


const memoWrapper = React.memo(ColorPicker, (prev, next) => {
  return prev?.formik && _.isEqual(prev?.formik?.values, next?.formik?.values)
})

export default memoWrapper;