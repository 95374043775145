import React, {useState} from 'react';
import {useDefaultFormik} from "../../../../shared/form/useFormBuilder";
import {renderNewConditionInitialData} from "./FieldBlock";
import {mapToIdsArray, rawToStyledHtml, removeByIndex, updateByIndex} from "../../../../utils/common";
import {Col, Divider, Input, InputNumber, Row, Select, Space} from "antd";
import {Box, Center, HStack} from "@chakra-ui/react";
import {Card, GhostButton, Label} from "./JumpingLogicSettingsModal";
import CustomSearchableSelect, {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import _ from "lodash";
import * as Yup from "yup";
import ConditionBlock from "./ConditionBlock";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import colors from "../../../../utils/commonStyles";
import {setColorOpacity} from "../../fieldTypes/MultiChoice/MultiChoice";
import {fieldTypes} from "../../fieldTypes/config";

const validationSchema = Yup.object().shape({});

const renderLogicOperatorString = (type) => {
  switch (type) {
    case 'add':
      return 'to'
    case 'multiply':
      return 'by'
    case 'subtract':
      return 'from'
    case 'divide':
      return 'by'
  }
}
const ThenOptionsSelect = ({value, onChange}) => {

  return <StyledSelect
    value={value}
    onChange={onChange}
  >
    <Select.OptGroup
      label={'LOGICAL'}
    >
      <Select.Option value={'jumpTo'}>Jump to</Select.Option>
    </Select.OptGroup>
    <Select.OptGroup label={'TEXT'}>
      <Select.Option value={'replace'}>Replace</Select.Option>
    </Select.OptGroup>
    <Select.OptGroup label={'NUMBER'}>
      <Select.Option value={'add'}>Add</Select.Option>
      <Select.Option value={'subtract'}>Subtract</Select.Option>
      <Select.Option value={'divide'}>Divide</Select.Option>
      <Select.Option value={'multiply'}>Multiply</Select.Option>
    </Select.OptGroup>
  </StyledSelect>
}

const JumpToOptionsSelect = ({_value, onChange, fieldData, fields = [], endingFields = []}) => {

  let value = null

  /// ============================================= SCOPE ========================================================================
  const permittedFields = fields.filter(f => f.id !== fieldData.id)

  const permittedFieldsIds = mapToIdsArray(permittedFields.map(f => ({...f, id: `Q:${f.id}`})))
  const permittedEndingFieldsIds = mapToIdsArray(endingFields.map(f => ({...f, id: `E:${f.id}`})))

  if (_value && [...permittedFieldsIds, ...permittedEndingFieldsIds].includes(_value)) {
    value = _value
  }

  return <StyledSelect
    value={value}
    onChange={onChange}
  >
    {!_.isEmpty(permittedFields) && <Select.OptGroup label={'QUESTIONS'}>
      {permittedFields?.map((f, index) => {

        const Icon = fieldTypes[f.type].icon

        return <Select.Option key={f.id} value={`Q:${f.id}`}>
          <Space>
            <Icon size={20}/>
            <Box
              display={'flex'}
            >
              <span style={{marginRight: '4px'}}>{f.order}.</span>{rawToStyledHtml(f.nameDraftContent)}
            </Box>
          </Space>
        </Select.Option>
      })}
    </Select.OptGroup>}


    {!_.isEmpty(endingFields) && <Select.OptGroup label={'ENDINGS'}>
      {endingFields?.map((f, index) => {

        const Icon = fieldTypes[f.type].icon

        return <Select.Option key={f.id} value={`E:${f.id}`}>
          <Space>
            <Icon size={20}/>
            <Box
              display={'flex'}
            >
              <span style={{marginRight: '4px'}}>{f.order}.</span>{rawToStyledHtml(f.nameDraftContent)}
            </Box>
          </Space>
        </Select.Option>
      })}
    </Select.OptGroup>}

  </StyledSelect>
}

const ReplaceOptionsSelect = ({_value, onChange, stringVariables = []}) => {

  const permittedValues = mapToIdsArray(stringVariables)

  let value = null

  if (_value && permittedValues.includes(_value)) {
    value = _value
  }

  return <StyledSelect
    width={'100%'}
    value={value}
    onChange={onChange}
  >
    {stringVariables.map(v => <Select.Option value={v.id}>
        {v.name}
      </Select.Option>
    )}
  </StyledSelect>
}


const NumberVariablesOptionsSelect = ({_value, onChange, numberVariables = []}) => {

  const permittedValues = mapToIdsArray(numberVariables)

  let value = null

  if (_value && permittedValues.includes(_value)) {
    value = _value
  }

  return <StyledSelect
    width={'100%'}
    value={value}
    onChange={onChange}
  >
    {numberVariables.map(v => <Select.Option value={v.id}>
        {v.name}
      </Select.Option>
    )}
  </StyledSelect>
}

const NumericalSelectOptions = ({_value, onChange, fields = [], fieldData = {}, numberVariables}) => {

  const _valueType = _value?.split(':')?.[0]

  const numberFields = fields.filter(f => ['number'].includes(f.id) && f?.order <= fieldData?.order)

  let value = null
  let numberInputValue = null
  /// ============================================= SCOPE ========================================================================

  const permittedNumberVariables = mapToIdsArray(numberVariables.map(f => ({...f, id: `NUM:${f.id}`})))

  const permittedFields = mapToIdsArray(numberFields.map(f => ({...f, id: `Q:${f.id}`})))

  if (_value && [...permittedNumberVariables, ...permittedFields].includes(_value)) {
    value = _value
  }
  if (_valueType === 'CALC') {
    value = _value.split(':')[1]
    numberInputValue = _value.split(':')[1]
  }

  return <StyledSelect
    onChange={onChange}
    value={value}

    dropdownRender={menu => (
      <div>
        {menu}
        <Divider style={{margin: '4px 0'}}/>
        <HStack style={{display: 'flex', justifyContent: 'center', padding: 8}}>
          <Label
            color={'black'}
            p={'0 5px'}
          >
            OR ENTER A NUMBER
          </Label>
          <InputNumber
            value={numberInputValue}
            onChange={e => onChange(`CALC:${e}`)}
          />
        </HStack>
      </div>
    )}
  >
    {!_.isEmpty(numberFields) && <Select.OptGroup label={'QUESTIONS'}>
      {fields?.map((f, index) => <Select.Option value={`Q:${f.id}`}>
        <b>{f.order} - {f.type}</b>
      </Select.Option>)}
    </Select.OptGroup>}

    {!_.isEmpty(numberVariables) && <Select.OptGroup label={'ENDINGS'}>
      {numberVariables?.map((f, index) => <Select.Option value={`NUM:${f.id}`}>
        <b>@{f.name}</b>
      </Select.Option>)}
    </Select.OptGroup>}

  </StyledSelect>
}


const RuleBlock = ({ruleData, onChange, onRemove, fieldData, fields, endingFields}) => {

  const [
    formData = {},
  ] = useFormConfiguratorStore(state => [
    state.formData,
  ])

  const {stringVariables = [], numberVariables: numberVariablesWithoutScore = []} = formData

  const numberVariables = [...numberVariablesWithoutScore, {id: 'score', name: 'score'}]

  const onChangeHandler = ((keyName, value) => {
    onChange({...ruleData, [keyName]: value})
  })

  const addNewConditionBlock = () => {
    const newItem = renderNewConditionInitialData(fieldData, fields)
    onChangeHandler('conditions', [...conditions, newItem])
  }

  const removeConditionBlock = (index) => {
    onChangeHandler('conditions', removeByIndex(conditions, index))
  }

  const updateConditionBlock = (data, index) => {
    onChangeHandler('conditions', updateByIndex(conditions, data, index))
  }

  const {conditions, thenWhat, thenJumpToId, replaceWhatVariableId, replaceTo, calculateBy, variableId} = ruleData

  return <Box
    mb={'10px'}
    p={'20px'}
    pr={'35px'}
    border={`1px dashed ${colors.primary}`}
    borderRadius={'8px'}
    bg={setColorOpacity(colors.primary, 0.01)}
  >
    <Row>
      <Col xs={2}>
        <Center>
          <Label>IF</Label>
        </Center>

      </Col>
      <Col xs={22}>

        <Box
          // p={'0 px 0 px'}
        >
          {conditions.map((c, index) => <ConditionBlock
            conditionData={c}
            fieldData={fieldData}
            fields={fields}
            index={index}
            onRemove={() => removeConditionBlock(index)}
            onChange={(data) => {
              updateConditionBlock(data, index)
            }}
          />)}
        </Box>

      </Col>
    </Row>

    <HStack
      p={'0 20px 20px 15px'}
    >
      <Box flex={1}/>
      <Box>
        <GhostButton
          onClick={addNewConditionBlock}
        >
          + Add condition
        </GhostButton>
      </Box>
    </HStack>

    <Row
      gutter={10}
    >
      <Col xs={2}>
        <Center>
          <Label>THEN</Label>
        </Center>
      </Col>

      <Col xs={4}>
        <Box
        >
          <ThenOptionsSelect
            value={thenWhat}
            onChange={(val) => onChangeHandler('thenWhat', val)}
          />
        </Box>
      </Col>

      <Col xs={18}>

        {thenWhat === 'jumpTo' && <Box
        >
          <JumpToOptionsSelect
            _value={thenJumpToId}
            onChange={(val) => onChangeHandler('thenJumpToId', val)}
            fields={fields}
            fieldData={fieldData}
            endingFields={endingFields}
          />
        </Box>}

        {thenWhat === 'replace' && <Row align={'center'}>
          <Col flex={1}>
            <ReplaceOptionsSelect
              _value={replaceWhatVariableId}
              onChange={(val) => onChangeHandler('replaceWhatVariableId', val)}
              stringVariables={stringVariables}
            />
          </Col>
          =
          <Col>

          </Col>
          <Col flex={1}>

            <Input
              value={replaceTo}
              onChange={e => onChangeHandler('replaceTo', e.target.value)}
            />
          </Col>
        </Row>}

        {['add', 'multiply', 'subtract', 'divide'].includes(thenWhat) && <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={['add', 'subtract'].includes(thenWhat) ? 'row' : 'row-reverse'}
        >
          <NumericalSelectOptions
            fieldData={fieldData}
            _value={calculateBy}
            onChange={(val) => onChangeHandler('calculateBy', val)}
            numberVariables={numberVariables}
          />
          <Box mx={'5px'}>
            {renderLogicOperatorString(thenWhat)}
          </Box>
          <NumberVariablesOptionsSelect
            numberVariables={numberVariables}
            _value={variableId}
            onChange={(val) => onChangeHandler('variableId', val)}
          />
        </Box>}


      </Col>
    </Row>


    <HStack
      p={'20px 20px 0 15px'}
    >
      <Box flex={1}/>
      <Box>
        <GhostButton
          color={'rgb(140, 3, 3)'}
          onClick={onRemove}
        >
          Remove rule
        </GhostButton>
      </Box>
    </HStack>
  </Box>
}

export default RuleBlock;