import React, { useState } from 'react';
import { Form } from "antd";
import InlineTextField, { StyledInput } from "../../shared/form/InlineTextField";
import { Box, HStack } from "@chakra-ui/react";
import PrimaryButton from "../../shared/PrimaryButton";
import { useUserState } from "../../App";
import { useSelector } from "react-redux";
import { updateAccountSettings } from "../../firebase/form";
import { updateById } from "../../utils/common";
import Divider from "../FormConfigurator/components/Divider";
import * as Yup from 'yup'
import { useDefaultFormik } from '../../shared/form/useFormBuilder';
import openNotification from '../../shared/openNotification';

const validationSchema = Yup.object().shape({

  password: Yup.string().required('Password is required').min(8, 'Minimum 8 characters').max(30, 'Maximum 30 characters'),
  confirmPassword: Yup.string().required('Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match')
})

export const ErrorMessage = ({ children }) => {
  return <Box
    color={'red'}
  >
    {children}
  </Box>
}


const General = () => {

  const [
    organizations,
    setOrganizations,
  ] = useUserState(store => ([
    store.organizations,
    store.setOrganizations,
  ]))

  const { user } = useSelector(state => state.persistentState)

  const [saving, setSaving] = useState()
  const [pasSaving, setPasSaving] = useState()


  const [myOrganization, setMyOrganization] = useState(organizations?.find(o => o.owner?.id === user?.id))

  const email = myOrganization?.owner?.email
  const username = myOrganization?.owner?.username

  const saveHandler = async () => {
    setSaving(true)

    try {
      await updateAccountSettings({ username, organizationName: myOrganization.name })
      setOrganizations(updateById(organizations, myOrganization))
    } catch (err) {
    } finally {
      setSaving(false)
    }
  }

  const passwordSaveHandler = async (password) => {
    setPasSaving(true)

    try {
      await updateAccountSettings({ password })
      // formik.setValues({ password: '', confirmPassword: '' })
      formik.resetForm()
      openNotification({ description: 'Password has been changed.' })
    } catch (err) {
    } finally {
      setPasSaving(false)
    }
  }


  const formik = useDefaultFormik({
    initialValues: {},
    validationSchema,
    onSubmit(values) {
      passwordSaveHandler(values.password)
    },
  })

  const { password, confirmPassword } = formik.values

  const handleChange = (fieldName, e) => {
    formik.setFieldValue(fieldName, e)
  }


  return (
    <>
      <Box
      // p={'0 20px 20px 20px'}
      >
        <HStack mb={'40px'}>
          <Box flex={1} fontSize={'16px'}
            fontWeight={800}
            letterSpacing={'3px'}
            textTransform={'uppercase'}
          >
            Profile settings
          </Box>
          <Box>
            <PrimaryButton size={'sm'} onClick={saveHandler} loading={saving}>
              Save
            </PrimaryButton>
          </Box>
        </HStack>
        {/*<Divider m={'20px 0 40px 0'}/>*/}
        <Box maxW={'450px'}>

          <Form layout={"vertical"}>
            <Form.Item label={'Email'}>
              <InlineTextField
                value={email}
                disabled={true}
              />
            </Form.Item>
            <Form.Item label={'Username'}>
              <InlineTextField
                value={username}
                onChange={e => setMyOrganization(prev => ({ ...prev, owner: { ...prev.owner, username: e } }))}
              />
            </Form.Item>
            <Form.Item label={'Organization name'}>
              <InlineTextField
                value={myOrganization.name}
                onChange={e => setMyOrganization(prev => ({ ...prev, name: e }))}
              />
            </Form.Item>
          </Form>
        </Box>

      </Box>

      <Divider mb={'25px'} />
      <Box>
        <HStack mb={'40px'}>
          <Box flex={1} fontSize={'16px'}
            fontWeight={800}
            letterSpacing={'3px'}
            textTransform={'uppercase'}
          >
            Change Password
          </Box>
          <Box>
            <PrimaryButton size={'sm'} onClick={() => formik.handleSubmit()} loading={pasSaving}>
              Save
            </PrimaryButton>
          </Box>
        </HStack>

        <Box maxW={'450px'}>
          <Form layout={"vertical"}>

            <Box
              mb={['20px']}

            >
              <Form.Item label={'New password'}>
                <InlineTextField
                  bg={'white'}
                  borderColor={'#abbdd4'}
                  w={['270px']}
                  value={password}
                  isInvalid={formik.errors.password}
                  type={'password'}
                  onChange={e => handleChange('password', e)}
                  placeholder=''
                // size='sm'
                />
              </Form.Item>

              <ErrorMessage>
                {formik.errors.password}
              </ErrorMessage>
            </Box>
            <Box
              mb={['20px']}

            >
              <Form.Item label={'Confirm password'}>
                <InlineTextField
                  bg={'white'}
                  isInvalid={formik.errors.confirmPassword}
                  type={'password'}

                  borderColor={'#abbdd4'}
                  w={['270px']}
                  value={confirmPassword}
                  onChange={e => handleChange('confirmPassword', e)}
                  placeholder=''
                // size='sm'
                />
              </Form.Item>
              <ErrorMessage>
                {formik.errors.confirmPassword}
              </ErrorMessage>
            </Box>
          </Form>

        </Box>
      </Box>
    </>
  );
};

export default General;