import React, {useCallback, useEffect, useState} from 'react';
import {modalStore} from "../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import {addMember, createWorkspace, deleteWorkspace, updateWorkspace} from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Form} from "antd";
import {StyledInput} from "../../../shared/form/InlineTextField";
import {Checkbox, Col, Row} from 'antd';
import openNotification from "../../../shared/openNotification";

export const permissionsOptions = [
  {
    value: 'workspace_create',
    label: 'Create workspace',
  }, {
    value: 'workspace_delete',
    label: 'Delete workspace',
  }, {
    value: 'workspace_update',
    label: 'Update workspace',
  }, {
    value: 'form_create',
    label: 'Create form',
  }, {
    value: 'form_delete',
    label: 'Delete form',
  }, {
    value: 'form_update',
    label: 'Update form',
  },
]

export const useAddMemberModalStore = modalStore()

const validateFnc = (name) => {
  if (!name) {
    return {
      error: true,
      message: 'This field is required.'
    }
  }

  return {
    error: false
  }
}

const AddMemberModla = ({workspaces}) => {
  const {organizationId, workspaceId} = useParams()

  const history = useHistory()

  const [name, setName] = useState()
  const [permissions, setPermissions] = useState([])

  const [error, setError] = useState()

  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useAddMemberModalStore(store => [store.isOpen, store.onClose, store.data])

  const {update, delete: deleteMode} = data

  const onCloseHandler = () => {
    setName('')
    setError(null)
    setPermissions([])
    onClose()
  }

  useEffect(() => {
    if (data.name) {
      setName(data.name)
    }
  }, [data])

  const createWorkspaceHandler = useCallback(
    async (values) => {

      const validateResults = validateFnc(values.email)

      if (validateResults.error) {
        return setError(validateResults.message)
      }

      setError('')

      setLoading(true)

      try {

        const requestData = {
          ...values
        }

        const data = await addMember(requestData)

        openNotification({description: 'Invitation has been sent'})
        onCloseHandler()

      } catch (e) {
       

      } finally {
        setLoading(false)
      }
    },
    [data]
  )


  return <CustomModal
    title={'Add member'}
    zIndex={12}
    onOk={() => createWorkspaceHandler({email: name, permissions})}
    okButtonProps={{
      title: 'Send invitation',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    size={'2xl'}
  >
    <Box
      p={'30px 0 10px'}
    >


      <Form
        layout={'vertical'}
      >
        <Form.Item
          validateStatus={error && 'error'}
          help={error}
          label={'E-mail'}
        >
          <StyledInput
            isInvalid={error}
            placeholder={'eg: joe.stark@flerr.com'}
            bg={'white'}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={'Permissions'}
        >
          <Checkbox.Group style={{width: '100%'}}
                          value={permissions}
                          onChange={val => setPermissions(val)}>
            <Row gutter={[5, 5]}>
              {permissionsOptions.map(o => <Col key={o.value} span={8}>
                <Checkbox value={o.value}>{o.label}</Checkbox>
              </Col>)}


            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>


    </Box>


  </CustomModal>
};

export default AddMemberModla;