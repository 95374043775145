import React, {useCallback, useEffect, useState} from 'react';
import jwt from 'jsonwebtoken'
import {useHistory, useLocation, useParams} from "react-router-dom";
import {SECRET_KEY} from "../../constants/config";
import colors from "../../utils/commonStyles";
import {Box, Button, Center, Input, VStack} from "@chakra-ui/react";
import {Divider, Result, Space, Spin} from "antd";
import Logo, {LogoWord} from "../../shared/Logo";
import PrimaryButton from "../../shared/PrimaryButton";
import GoogleLogo from "../../assets/googleLogo.svg";
import {BigFlerr} from "../Login";
import {acceptInvite, addMember, getTokenData} from "../../firebase/form";
import openNotification from "../../shared/openNotification";
import {useSelector} from "react-redux";

const InvitationAccept = ({fetchOrganizations}) => {
//look@themessage.app 15!Q1q1220

  const {user} = useSelector(state => state.persistentState)

  const {token} = useParams()
  const history = useHistory()
  const location = useLocation()

  const [tokenData, setTokenData] = useState({})

  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [fetchingData, setFetchingData] = useState(true)


  const accept = useCallback(
    async (values) => {

      if (!user) {
        history.push(`/login?redirectTo=${location.pathname}`)
        return
      }
      setError('')

      setLoading(true)

      try {
        const data = await acceptInvite(token)
        const {organization} = data

        fetchOrganizations()
        history.push(`/organization/${organization.id}/workspace`)

        openNotification({description: 'Welcome in new team'})

        // location.reload()
      } catch (e) {
        const {data = {}} = e
        openNotification({type: 'error', description: data.message})
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getTokenDataHandler = async () => {
    setFetchingData(true)
    try {
      const data = await getTokenData(token)
      setTokenData(data)

    } catch (e) {
      const {data = {}} = e
      setError(data?.message)
    } finally {
      setFetchingData(false)
    }
  }

  useEffect(() => {
    getTokenDataHandler()
  }, [])


  const {organization, email} = tokenData

  const successContent = !fetchingData && <>
    <VStack
      mb={['40px']}
    >
      <Space
        align={"center"}
        size={2}
      >
        <Logo width={'60px'}/>
        <LogoWord fontSize={'34px'}/>
      </Space>

      {error && <Result
        status="500"
        title="Sorry, something went wrong."
        subTitle={error}
      />}

      {!error && <>
        <Box
          fontSize={['25px']}
          fontWeight={700}
          w={'100%'}
          textAlign={'center'}
          pt={'30px'}
        >
          Team invite
        </Box>
        <Box
          fontSize={['17px']}
          fontWeight={300}
          w={'100%'}
          textAlign={'center'}

        >
          You have be invited to join <Box
          as={'span'}
          fontWeight={800}
          color={colors.primary}
        >{organization.name}</Box> team on formee. To get started click the button below.
        </Box>
      </>}
    </VStack>
    <Box
      w={['270px']}
    >
      {!error && <PrimaryButton
        onClick={accept}
        loading={loading}
        w={['100%']}
        fontWeigth={200}
      >
        Join team
      </PrimaryButton>}

    </Box>
  </>

  return <Center
    h={'100vh'}
    bg={colors.bg}
  >

    <Box
      position={'absolute'}
      bottom={['-20%']}
      left={'20%'}
      zIndex={1}
    >
      <BigFlerr/>

    </Box>


    <Box
      zIndex={9999}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      p={'70px 50px'}
      bg={'white'}
      borderRadius={'12px'}
      boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
      maxW={['650px']}
      w={'100%'}
    >

      {fetchingData && <Center h={'100%'}>
        <Spin/>
      </Center>}


      {successContent}

    </Box>

  </Center>
};

export default InvitationAccept;