import React from 'react';
import {Divider as ChakraDivider, Box, HStack, Center} from "@chakra-ui/react";

const Divider = (props) => {
  return (
    <ChakraDivider
      my={'5px'}
      borderColor={'#cccccc'}
      {...props}
    />
  );
};

export default Divider;