import React, {useCallback, useEffect, useState} from 'react';
import {modalStore} from "../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Form} from "antd";
import {StyledInput} from "../../../shared/form/InlineTextField";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";

export const useFormNameModalStore = modalStore()

const validateFnc = (name) => {
  if (!name) {
    return {
      error: true,
      message: 'This field is required.'
    }
  }
  if (name.length > 30) {
    return {
      error: true,
      message: 'Max 30 characters.'
    }
  }

  return {
    error: false
  }
}

const FormNameModal = () => {
  const {organizationId, workspaceId} = useParams()

  const history = useHistory()

  const [
    setFormData,
  ] = useFormConfiguratorStore(state => [
    state.setFormData,
  ])

  const [name, setName] = useState()

  const [error, setError] = useState()

  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useFormNameModalStore(store => [store.isOpen, store.onClose, store.data])

  const {update, delete: deleteMode} = data

  const onCloseHandler = () => {
    setName('')
    history.replace({state: undefined})
    onClose()
  }

  useEffect(() => {
    if (data.name) {
      setName(data.name)
    }
  }, [data])

  const updateFormNameHandler = useCallback(
    async (values) => {

      const validateResults = validateFnc(values.name)

      if (!deleteMode && validateResults.error) {
        return setError(validateResults.message)
      }

      setError('')

      setLoading(true)

      try {

        const requestData = {
          ...values
        }

        // await updateFormName(requestData)

        setFormData({name: values.name})

        onCloseHandler()

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [data]
  )


  return <CustomModal
    title={'Bring your new form to life'}
    zIndex={12}
    onOk={() => updateFormNameHandler({name, formId: data?.id})}
    okButtonProps={{
      title: 'Save',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    // size={'xl'}
  >
    <Box
      p={'30px 0 10px'}
    >

      <Form
        layout={'vertical'}
      >
        <Form.Item
          validateStatus={error && 'error'}
          help={error}
        >
          <StyledInput
            isInvalid={error}
            placeholder={'Name your form'}
            bg={'white'}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Form.Item>
      </Form>


    </Box>


  </CustomModal>
};

export default FormNameModal;