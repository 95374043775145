import React from 'react';
import DraggableModal from "../../../shared/DraggableModal";
import {Box, HStack, Button, Center} from '@chakra-ui/react'
import {IconButton} from '@chakra-ui/react'
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import {TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react'
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import ImagesGallery from "./ImagesGallery";
import VideoGallery from "./VideoGallery";
import {Tabs} from "antd";
import GifsGallery from "./GifsGallery";
import UploadSection from "./UploadSection";
import MyImages from "./MyImages";

const ImageToolsModal = () => {

  const [
    imageModalOpen,
    setImageModalOpen,
    setImageModalClose,
  ] = useFormConfiguratorStore(state => [
    state.imageModalOpen,
    state.setImageModalOpen,
    state.setImageModalClose,
  ])

  if (!imageModalOpen) return null

  const Header = <Box>
    <HStack
      p={['15px']}
      justifyContent={'space-between'}
    >
      <Box>
        <b>Media gallery</b>
      </Box>

      <Box
        cursor={'pointer'}
        onClick={setImageModalClose}
      >
        <CloseIcon/>
      </Box>

    </HStack>
  </Box>

  return (
    <DraggableModal
      header={Header}
    >
      <Tabs
      destroyInactiveTabPane={true}
      style={{padding: '0 15px'}}>

        <Tabs.TabPane tab="Upload" key="1">
          <UploadSection/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Gallery" key="2">
          <ImagesGallery/>
        </Tabs.TabPane>

        {/*<Tabs.TabPane tab="Gifs" key="3">*/}
        {/*  <GifsGallery/>*/}
        {/*</Tabs.TabPane>*/}

        <Tabs.TabPane tab="Video" key="4">
          <VideoGallery/>
        </Tabs.TabPane>

        <Tabs.TabPane tab="My gallery" key="5">
          <MyImages/>
        </Tabs.TabPane>
      </Tabs>

    </DraggableModal>
  );
};

export default ImageToolsModal;