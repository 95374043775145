import React, {useEffect, useState} from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import {Divider, Box, HStack, Center} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";

const fromScaleOptions = [
  {
    value: 0,
    label: 0
  }, {
    value: 1,
    label: 1
  }
]

const toScaleOptions = [
  {
    value: 5,
    label: 5
  }, {
    value: 6,
    label: 6
  }, {
    value: 7,
    label: 7
  }, {
    value: 8,
    label: 8
  }, {
    value: 9,
    label: 9
  }, {
    value: 10,
    label: 10
  }
]

const Configurator = ({data = {}, onChange}) => {

  const {required, fromScale, toScale, leftText, centerText, rightText} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>

    <HStack
      p={['10px 0px']}
    >
      <StyledSelect
        value={fromScale}
        onChange={e => onChangeHandler('fromScale', e)}
        style={{width: '80px'}}
        options={fromScaleOptions}
      />
      <Center>
        to
      </Center>
      <StyledSelect
        value={toScale}
        onChange={e => onChangeHandler('toScale', e)}
        style={{width: '80px'}}
        options={toScaleOptions}
      />
    </HStack>

    <Box
      p={['10px 0px']}
    >
      <InlineTextField
        placeholder={'Left label'}
        value={leftText}
        onChange={(e) => onChangeHandler('leftText', e)}
      />
    </Box>
    <Box
      p={['10px 0px']}
    >
      <InlineTextField
        placeholder={'Center label'}
        value={centerText}
        onChange={(e) => onChangeHandler('centerText', e)}
      />
    </Box>
    <Box
      p={['10px 0px']}
    >
      <InlineTextField
        placeholder={'Right label'}
        value={rightText}
        onChange={(e) => onChangeHandler('rightText', e)}
      />
    </Box>

  </Box>
}

const OpinionScaleSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({...data})
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onGeneralSettingsChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};


export default OpinionScaleSettings;