import React from 'react';
import styled from "styled-components";
import {Divider, Input, Space, Table as AntTable, Form, Button} from 'antd'
import {Box, HStack} from "@chakra-ui/react";


const Table = ({
                 dataSource = [],
                 columns = [],
                 loading,
                 showHeader = true,
                 rowKey = 'id',
                 scroll = {x: 1000},
                 rowSelection,
                 pagination = {},
  ...rest
               }) => {


  const paginationConfig = {
    position: ['bottomLeft'],
    defaultPageSize: 15,
    showSizeChanger: true,
    pageSizeOptions: ['10', '15', '20', '30', '50', '100'],
    ...pagination
  }

  return (
    < >
      <StyledTable
        pagination={paginationConfig}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        scroll={scroll}
        rowKey={rowKey}
        showHeader={showHeader}
        rowSelection={rowSelection}
        {...rest}
      />
    </>
  )
}

export const StyledTable = styled(AntTable)`
.ant-table {
  // font-size: 9px;
}

.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px 10px;
  
}
.my-tag {
  // font-size: 12px;
}
.ant-table-thead > tr > th {
  height: 5px;
  padding: 14px 10px;
}
    .row-highlight{
      background: #f6f7f9;
    }
   
`;

export const TextEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${({lines}) => lines || '5'};
  -webkit-box-orient: vertical;
`;


const StyledText = styled.span`
  color: #686868;
  font-weight: 400;
  font-size: 14px;
`

export default Table;