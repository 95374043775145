import React, { useEffect, useRef, useState } from 'react';
import { Box, HStack, Button, Center, Image } from '@chakra-ui/react'
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import { SliderBox } from "./Content";
import { getMyImages, getPixabayImages } from "../../../firebase/form";
import { Spin } from "antd";


const MyImages = () => {

  const [
    formData,
    onImageModalChange,
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.onImageModalChange,
  ])

  const [images, setImages] = useState([])
  const [paginationData, setPaginationData] = useState({})
  const [loading, setLoading] = useState([])
  const [page, setPage] = useState(1)

  const totalPages = paginationData?.totalPages || 1

  const fetchImages = async () => {
    setLoading(true)

    try {
      const data = await getMyImages({
        organizationId: formData?.organization,
        // per_page: 70,
        page
      })

      setImages([...images, ...data.docs])
      setPaginationData(data)

    } catch (err) {

    } finally {
      setLoading(false)
    }

  }

  const handleScroll = (e) => {
    // const bottom = e.target.scrollHeight - e.target.scrollTop - 400 < e.target.clientHeight;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPage(prev => prev + 1)
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (page <= totalPages) {
      fetchImages()
    }
  }, [page])

  return (
    <Box position={'relative'}>

      {loading && <Center
        position={'absolute'}
        top={0}
        left={0}
        w='100%'
        h='100%'
        bg={'rgba(255,255,255,0.5)'}
      >
        <Box>
          <Spin />
        </Box>
      </Center>}

      <Box
        display={'flex'}
        flexWrap={'wrap'}
        w={'100%'}
        h={'375px'}
        overflowY={'scroll'}
        overflowX={'hidden'}
        onScroll={handleScroll}
      >
        <Box
          flex={'0 0 50%'}
        >
          {images.filter((_, index) => index % 2 === 0).map((i, index) => (
            <Box
              p={'3px'}
              cursor={'pointer'}

              onClick={() => onImageModalChange({ imageUrl: i?.imageUrl })}
            >
              <img
                rel={i?.imageUrl}
                src={i?.imageUrl}
                style={{
                  borderRadius: '6px',
                  width: '100%',
                  objectFit: "cover"
                }}
              />
            </Box>
          ))}
        </Box>

        <Box
          flex={'0 0 50%'}
          p={'3px'}
        >
          {images.filter((_, index) => index % 2 === 1).map((i, index) => (
            <Box
              p={'3px'}
              cursor={'pointer'}
              onClick={() => onImageModalChange({ imageUrl: i?.imageUrl })}
            >
              <img
                src={i?.imageUrl}
                style={{
                  borderRadius: '6px',
                  width: '100%',
                  objectFit: "cover"
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>


    </Box>
  );
};
export default MyImages;