import { QuestionCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Box, Center } from "@chakra-ui/react";
import { Tag, Tooltip } from "antd";
import React, { useState } from 'react';
import { updateMessages } from "../../../../firebase/form";
import CustomModal from "../../../../shared/CustomModal";
import { StyledInput } from "../../../../shared/form/InlineTextField";
import SecondaryButton from "../../../../shared/SecondaryButton";
import colors from "../../../../utils/commonStyles";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import MenuItem from "../MenuItem";
import { FieldInlineWrapper } from "../ThemeEditor";
import { defaultTranslations } from "./defaultMessages";

const items = [
  {
    title: 'Button to confirm answer',
    id: 'ok',
    maxLength: 125
  }, {
    title: 'Keyboard instruction to go to next question',
    id: 'pressEnter',
    maxLength: 125
  },{
    title: 'Line break',
    id: 'lineBreak',
    maxLength: 125
  }, {
    title: 'Hint for multiple selection',
    id: 'choiceUnlimited',
    maxLength: 125
  }, {
    title: 'Label for “Other” answer option',
    id: 'choiceOtherPlaceholder',
    maxLength: 125
  }, {
    title: 'Hint for adding text',
    id: 'textFieldPlaceholder',
    maxLength: 125
  }, {
    title: 'Instruction for Dropdown question',
    id: 'selectPlaceholder',
    maxLength: 125
  }, {
    title: 'Button to respond “Yes”',
    id: 'yesNo.yes',
    maxLength: 125
  }, {
    title: 'Button to respond “No”',
    id: 'yesNo.no',
    maxLength: 125
  }, {
    title: 'Keyboard shortcut for "Yes" option',
    id: 'yesNo.Y',
    maxLength: 1
  }, {
    title: 'Keyboard shortcut for "No" option',
    id: 'yesNo.N',
    maxLength: 1
  }, {
    title: 'Button to accept statement in a Legal question',
    id: 'legalField.accept',
    maxLength: 125
  }, {
    title: 'Button to reject statement in a Legal question',
    id: 'legalField.noAccept',
    maxLength: 125
  }, {
    title: 'Keyboard shortcut for "Accept" option',
    id: 'legalField.Y',
    maxLength: 1
  }, {
    title: 'Keyboard shortcut for "Reject" option',
    id: 'legalField.N',
    maxLength: 1
  }, {
    title: 'Button to send typeform',
    id: 'submit',
    maxLength: 125
  }, {
    title: 'If an answer is required',
    id: 'requiredErrorMessage',
    maxLength: 200
  }, {
    title: 'If the number entered is too low',
    id: 'numberMinErrorMessage',
    maxLength: 200
  }, {
    title: 'If the number entered is too high',
    id: 'numberMaxErrorMessage',
    maxLength: 200
  }, {
    title: 'If an email address is incorrect',
    id: 'emailErrorMessage',
    maxLength: 200
  }, {
    title: 'If a URL is incorrect',
    id: 'websiteErrorMessage',
    maxLength: 200
  }, {
    title: 'If a phone number is not valid',
    id: 'phoneErrorMessage',
    maxLength: 200
  }, {
    title: 'If number exceed set min and max limits',
    id: 'websiteErrorMessage',
    maxLength: 200
  }, {
    title: 'If an answer requires a selection',
    id: 'requiredMoreErrorMessage',
    maxLength: 200
  }, {
    title: 'Range of choices',
    id: 'choiceRange',
    maxLength: 200
  }, {
    title: 'Number of choices',
    id: 'choice',
    maxLength: 125
  }, {
    title: 'Continue',
    id: 'continue',
    maxLength: 40
  }, {
    title: 'Start',
    id: 'start',
    maxLength: 125
  }, {
    title: '"Other" option',
    id: 'other',
    maxLength: 40
  }, {
    title: 'Create a typeform',
    id: 'createTypeform',
    maxLength: 125
  }, {
    title: 'Confirmation that typeform was sent',
    id: 'allDone',
    maxLength: 125
  }, {
    title: 'Error if there’s a problem showing a typeform',
    id: 'unavailable',
    maxLength: 125
  }, {
    title: 'Hint telling respondent where to drop file',
    id: 'dragAndDrop',
    maxLength: 125
  }, {
    title: 'Error when respondent uploads a file that exceeds the size limit',
    id: 'fileTooBig',
    maxLength: 125
  }, {
    title: 'Size limit',
    id: 'sizeLimit',
    maxLength: 125
  },
]

const MessagesModal = ({ isOpen, onClose, messages = {}, setMessages }) => {

  const [saving, setSaving] = useState()

  const [
    formData = {},
    fields,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.setFormData,
  ])

  const lang = formData.settings?.lang


  const saveHandler = async () => {
    setSaving(true)
    try {

      const requestData = {
        formId: formData.id,
        messages
      }

      await updateMessages(requestData)

      setFormData({ messages })
      onClose()

    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }

  const handleChange = (name, val) => {
    setMessages({ ...messages, [name]: val })
  }

  const onClearAll = () => {
    setMessages({ ...defaultTranslations[lang] })
  }

  const footerExtraContent = <SecondaryButton
    size={'sm'}
    onClick={onClearAll}
  >
    Reset all
  </SecondaryButton>

  return <CustomModal
    isCentered={false}

    title={'Messages'}
    isCentered={false}
    zIndex={12}
    onOk={() => saveHandler()}
    okButtonProps={{
      isLoading: saving
    }}
    footerExtraContent={footerExtraContent}
    isOpen={isOpen}
    onClose={onClose}
    size={'3xl'}
  >
    <Box>

      {items.map(i => <FieldInlineWrapper
        label={<Box>
          {i.title}
          {i.variables && <>
            <Tooltip
              zIndex={999999}
              title={<Box p={'10px'}>
                <Box mb={'6px'}>
                  Variables:
                </Box>
                {i.variables?.map(v => <Tag color={'blue'}>{v}</Tag>)}
              </Box>}
            >
              <span>
                <QuestionCircleOutlined style={{ marginLeft: '6px' }} />
              </span>
            </Tooltip>
          </>}
        </Box>}
      >
        <Box
          position={'relative'}
          d={'flex'}
          border={'1px solid'}
          borderColor={colors.border}
          borderRadius={'6px'}
          w={'380px'}
          bg={'white'}
        >

          <StyledInput
            maxLength={i.maxLength || 100}
            border={0}
            bg={'white'}
            fontSize={'13px'}
            // value={messages[i.id] === undefined ? defaultTranslations[lang]?.[i.id] : messages[i.id]}
            value={messages[i.id]}
            onChange={e => handleChange(i.id, e.target.value)}
          />

          <Center fontSize={'11px'} p={'0 4px'} >
            {messages[i.id]?.length || 0}/{i.maxLength || 50}
          </Center>

          {(messages[i.id] !== undefined && (messages[i.id] !== defaultTranslations[lang]?.[i.id])) && <Center
            m={'3px 4px'}
            p={'2px 5px'}
            bg={'#dddddd'}
            borderRadius={'6px'}
            cursor={'pointer'}
            onClick={() => {
              handleChange(i.id, defaultTranslations[lang]?.[i.id])
            }}
          >
            <ReloadOutlined />
          </Center>}

        </Box>
      </FieldInlineWrapper>)}

    </Box>
  </CustomModal>
}

const MessagesModalButton = ({ messages, setMessages }) => {

  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <MenuItem
        onClick={() => setOpen(true)}
      >
        Messages >
      </MenuItem>
      {isOpen && <MessagesModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        messages={messages}
        setMessages={setMessages}
      />}
    </div>
  );
};


export default MessagesModalButton;