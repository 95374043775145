import React, { useCallback, useEffect, useState } from 'react';
import { useDefaultFormik } from "../../../shared/form/useFormBuilder";
import useDebouncedEffect from "../../../hooks/useDebounceEffect";
import { createTheme, updateQuizzSettings, updateSettings, updateTheme } from "../../../firebase/form";
import { updateById } from "../../../utils/common";
import * as Yup from "yup";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import _ from 'lodash';
import { Box, HStack } from "@chakra-ui/react";
import CustomSelect from "../../../shared/form/CustomSelect";
import MessagesModal from "./Messages/MessagesModal";
import AccessAndScheduling from "./AccessAndScheduling/AccessAndScheduling";
import EmailNotifications from "./EmailNotifications/EmailNotifications";
import { FieldInlineWrapper, IconButton } from "./ThemeEditor";
import CustomSwitch from "../../../shared/form/CustomSwitch";
import { defaultTranslations } from "./Messages/defaultMessages";
import openNotification from '../../../shared/openNotification';
import JumpingLogicSettingsModal from "./JumpingLogicSettings/JumpingLogicSettingsModal";
import VariablesSettingsModal from "./VariablesSettings/VariablesSettingsModal";
import QuizzVariablesSettingsModal from "./QuizzVariablesSettings/QuizzVariablesSettingsModal";
import ToolsSection from '../../../shared/ToolsSection';
import { ImageIcon, RemoveIcon } from '../fieldTypes/icons';
import SecondaryButton from '../../../shared/SecondaryButton';
import { defaultButtonProps } from '../../../utils/commonStyles';


const BrandLogo = ({ value, onChange }) => {

  const [
    
    setImageModalOpen,

  ] = useFormConfiguratorStore(state => [
   
    state.setImageModalOpen,
  ])


  const onChangeImage = (data) => {
   
    onChange(data?.imageUrl)
  
  }

  const onRemoveImage = () => {
    onChange(null)

  }

  // const onChangeImageConfig = (data) => {
  //   updateActiveField({
  //     layoutConfig: {
  //       ...activeField.layoutConfig,
  //       imageConfig: {...data}
  //     }
  //   })
  // }


  const imageUrl = value

  return (
    <div>
      {(imageUrl) ? (
        <HStack>
          <IconButton
            onClick={() => setImageModalOpen({
              onImageModalChange: onChangeImage
            })}
          >
            <ImageIcon />
          </IconButton>
          {/*<IconButton*/}
          {/*  onClick={() => setImageSettingsModalOpen({*/}
          {/*    onImageSettingModalChange: onChangeImageConfig*/}
          {/*  })}*/}
          {/*>*/}
          {/*  <ImageConfigIcon/>*/}
          {/*</IconButton>*/}
          <IconButton
            onClick={onRemoveImage}
          >
            <RemoveIcon />
          </IconButton>

        </HStack>
      ) : (
        <SecondaryButton
          {...defaultButtonProps}
          fontSize={'14px'}
          // p={'3px'}
          size={'sm'}
          onClick={() => setImageModalOpen({
            onImageModalChange: onChangeImage
          })}
        >
          + Add
        </SecondaryButton>
      )}
    </div>
  );
};

const changeLang = (messages = {}, prevTrans, nextTrans) => {
  const keys = Object.keys(prevTrans)
  const results = {}

  keys.forEach(k => {
    if (messages[k] === prevTrans[k]) {
      results[k] = nextTrans[k]
    } else {
      results[k] = messages[k]
    }
  })
  return results
}

const langOptions = [
  {
    value: 'en',
    label: 'English'
  }, {
    value: 'pl',
    label: 'Polish'
  }
]

const validationSchema = Yup.object().shape({});


const Settings = () => {

  const [
    formData,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.setFormData,
  ])

  const [messages, setMessages] = useState({})

  const [settings, setSettings] = useState({})


  const { lang, showBranding, showProgress, showQuestionNumber, logoUrl } = settings

  const handleChange = (name, value) => {
    const updatedSettings = { ...settings, [name]: value }
    setSettings(updatedSettings)
    saveHandler(updatedSettings, messages)
  }

  const saveHandler = async (settings, messages) => {
    try {

      const requestData = {
        formId: formData?.id,
        settings
      }

      updateSettings(requestData)

      setFormData({ settings })

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!_.isEmpty(formData)) {
      setSettings(formData.settings)
      setMessages(formData?.messages || {})
    }
  }, [formData])

  return (
    <Box
      h={'calc(100vh - 120px)'}
      overflowX={'auto'}
    >
      <Box
        mb={'10px'}
        w={'100%'}

      >



        <CustomSelect
          value={lang}
          onChange={e => {
            if (e !== lang) {
              const updatedMessages = changeLang(messages, defaultTranslations[lang], defaultTranslations[e])
              setMessages(updatedMessages)
              setFormData({ messages: updatedMessages })
            }
            handleChange('lang', e)
          }}
          options={langOptions}
          style={{
            width: '100%'
          }}
        />
      </Box>


      <Box fontSize={'14px'} mb={'8px'} textAlign={'center'}>
        Logic and calculations
      </Box>

      <JumpingLogicSettingsModal />
      <VariablesSettingsModal />
      {/*<Divider/>*/}

      <QuizzVariablesSettingsModal />


      <Box fontSize={'14px'} mb={'8px'} textAlign={'center'}>
        Settings
      </Box>

      <Box>
        <AccessAndScheduling />
      </Box>

      <Box>
        <MessagesModal messages={messages} setMessages={setMessages} />
      </Box>

      <Box>
        <EmailNotifications />
      </Box>

      <Box>
        <FieldInlineWrapper
          label={'Formee branding'}
        >
          <CustomSwitch
            value={showBranding}
            onChange={val => {
              if (val === false && !formData.canRemoveBranding) {
                openNotification({ type: 'error', description: "Can't remove branding! Upgrade your plan." })
                return
              }

              handleChange('showBranding', val)
            }}
          />
        </FieldInlineWrapper>

        {/* <FieldInlineWrapper
          label={'Progress bar'}
        >
          <CustomSwitch
            value={showProgress}
            onChange={val => handleChange('showProgress', val)}
          />
        </FieldInlineWrapper> */}

        <FieldInlineWrapper
          label={'Question number'}
        >
          <CustomSwitch
            value={showQuestionNumber}
            onChange={val => handleChange('showQuestionNumber', val)}
          />
        </FieldInlineWrapper>


        <FieldInlineWrapper
          label={'Brand logo'}
        >
          <BrandLogo
           value={logoUrl}
           onChange={val => handleChange('logoUrl', val)}
          />
        </FieldInlineWrapper>

      </Box>

    </Box>
  );
};

export default Settings;