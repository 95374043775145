import React from 'react';
import {Box, Button, Input} from "@chakra-ui/react";

const SecondaryButton = ({children, ...rest}) => {
  return (
    <Button
      // background={'transparent'}
      bg={'white'}
      _hover={{
        // background: 'blue.700',
        opacity: 0.7
      }}
      border={'1px solid'}

      borderColor={'blue.700'}
      color={'blue.700'}
      w={['100%']}
      fontWeigth={200}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;