import { Box } from "@chakra-ui/react";
import React from 'react';
import { fieldTypes } from "../fieldTypes/config";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";

const QuestionSettings = () => {

  const [
    activeField,
    setActiveField,
  ] = useFormConfiguratorStore(state => [
    state.activeField,
    state.setActiveField,
  ])

  // const Component = useMemo(() => fieldTypes?.[activeField?.type]?.settingsComponent, [activeField]) 
  const Component = fieldTypes?.[activeField?.type]?.settingsComponent

  if (!activeField) return null

  if (!Component) return null

  return (
    <Box>
      <Component
      key={activeField.id}
        activeField={activeField}
        onChange={data => setActiveField({...activeField, generalSettings: data})}
      />

    </Box>
  );
};

export default QuestionSettings;