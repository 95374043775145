import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import create from 'zustand'
import {routes} from "./constants/config";
import AppLoading from "./shared/AppLoading";
import {useSelector} from 'react-redux'
import UnauthorizedLayout from "./shared/UnauthorizedLayout";
import AuthService from "./services/AuthService";
import _ from 'lodash';
import FormConfigurator from "./pages/FormConfigurator";
import {useOrganizationsQuery} from "./firebase/form";
import Settings from "./pages/Settings";
import ChooseTemplate from './pages/ChooseTamplate';
import Templates from './pages/Templates';

export const useUserState = create(set => ({
  initializing: true,
  signedIn: false,
  isOwner: false,
  permissions: [],
  user: null,
  currentOrganization: null,
  organizations: null,
  workspaces: null,
  updateAuthState(payload) {
    set(store => ({
      initializing: false,
      lastUpdate: new Date().toISOString(),
      signedIn: Boolean(payload),
      user: {...store.user, ...payload},
    }));
  },
  setInitializing(payload) {
    set(store => ({
      ...store,
      initializing: payload,
    }));
  },
  setOrganizations(payload) {
    set(store => ({
      ...store,
      organizations: payload,
    }));
  },
  setCurrentOrganization({currentOrganization, isOwner, permissions}) {
    set(store => ({
      ...store,
      isOwner,
      permissions,
      currentOrganization,
      workspaces: currentOrganization.workspaces
    }));
  },
  setWorkspaces(payload) {
    set(store => ({
      ...store,
      workspaces: payload,
    }));
  },

}))

const App = () => {

  const [
    setOrganizations,
  ] = useUserState(store => ([
    store.setOrganizations,
  ]))

  const {isAuthenticated, user} = useSelector(state => state.persistentState)


  const {organizations, isLoading: organizationsLoading, fetchOrganizations} = useOrganizationsQuery(isAuthenticated,(data) => {
    setOrganizations(data)
  })

  if (organizationsLoading) return <AppLoading/>

  return (
    <>

      <Switch>

        <Route
          path={'/form/:formId'}
        >
          <FormConfigurator/>
        </Route>

        <Route
          path={'/account'}
        >
          <Settings/>
        </Route>

        <Route
          path={'/templates'}
        >
          <Templates/>
        </Route>

        
        <Route
          path={'/useTemplate/:id?'}
        >
          <ChooseTemplate/>
        </Route>

        {routes.map((r) => (
          <Route
            key={r.path}
            path={r.path}
            exact={r.hasOwnProperty('exact') ? r.exact : true}
            render={(props) => {
              if (!isAuthenticated && r.authenticated) return <Redirect to={`/login?redirectTo=${props.match.url}`}/>

              const Layout = r.layout || UnauthorizedLayout

              return <Layout>
                <r.component
                  fetchOrganizations={fetchOrganizations}
                />
              </Layout>
            }}
          />
        ))}

        <Redirect to={isAuthenticated ? "/organization/-/workspace" : "/login"}/>
        {/*{signedIn ?  : <Redirect to="/login"/>}*/}

      </Switch>
    </>
  );
};

export default App;