import React, {useState} from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import {Input, Box, HStack, Center} from '@chakra-ui/react'
import styled from "styled-components";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {Player} from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import colors from "../../../../utils/commonStyles";
import {setColorOpacity} from "../MultiChoice/MultiChoice";
import {UploadOutlined} from "@ant-design/icons";

const FileUpload = ({fieldData, onChange, theme = {}, mentionOptions, messages = {}, showQuestionNumber}) => {


  const {questionColor, fontSize, align, answerColor, buttonBackground, buttonColor} = theme
  const {options = [], generalSettings = {}} = fieldData
  const {required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null


  if (!fieldData) return null


  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'
        
        />}

      </Box>}

      <Box
        d={'flex'}
        justifyContent={align === 'center' ? 'center' : 'flex-start'}

      >

        <Box
          p={'20px'}
          bg={setColorOpacity(answerColor, 0.06)}
          w={'500px'}
          border={`2px dashed ${answerColor}`}
          borderRadius={'8px'}
          h={'236px'}
        >
          <Center
            mb={'10px'}
            h={'180px'}
            flexDirection={'column'}
            // borderBottom={'1px solid black'}
          >
            <Box
              mb={'10px'}
            >
              <UploadOutlined
                style={{
                  fontSize: '32px',
                  color: answerColor
                }}
              />

            </Box>
            <Box
            color={answerColor}
              mb={'10px'}
            >
              <b>
                {messages.dragAndDrop}
              </b>
            </Box>

            <Box
            color={answerColor}
            
            >
              {messages.sizeLimit} 10 MB
            </Box>
          </Center>
        </Box>
      </Box>

    </FieldWrapper>
  );
};

export default FileUpload;