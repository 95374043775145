import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Box, Center, Flex } from "@chakra-ui/react";
import { IndexCharContainer, indexChars } from "../MultiChoice/MultiChoice";
import { modalStore, randomId, removeById, shade } from "../../../../utils/common";
import CustomWysiwyg from "../../../../shared/CustomWysiwyg";
import { CloseCircleOutlined, DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { initialDraftContent } from "../config";
import { ReactSortable } from "react-sortablejs";
import UnderlineTextButton from "../../../../shared/UnderlineTextButton";
import FieldWrapper from "../../../../shared/FieldWrapper";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { Player } from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import { setColorOpacity } from "../MultiChoice/MultiChoice";
import { ImageIcon } from "../icons";
import colors from "../../../../utils/commonStyles";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import { Input, Space } from "antd";
import CustomModal from "../../../../shared/CustomModal";
import { useTranslation } from "../../../../hooks/useTranslation";


export const OptionContainer = styled(Box)`
  border-radius: 10px;
  padding: 10px 10px;
  position: relative;
  min-height: 30px;
  margin-bottom: 10px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
    // backdrop-filter: blur(4px);

`;

export const OptionsToolsBarWrapper = styled(Center)`
    position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  z-index: 9;
  padding-bottom: 3px;
`;

export const OptionsToolsBar = styled(Center)`
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;  
  padding: 3px 8px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end; 
`;

export const otherNameBlock = {
  "blocks": [
    {
      "key": "drc9v",
      "text": "Other",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
}

export const otherOption = {
  id: 'other',
  nameBlocks: otherNameBlock
}

const useOptionSettingsModalStore = modalStore()

const OptionSettingsModal = () => {

  const [isOpen, onClose, data = {}] = useOptionSettingsModalStore(store => ([
    store.isOpen,
    store.onClose,
    store.data,
  ]))

  const { onSave } = data

  const [optionData, setOptionData] = useState(data?.optionData)

  return <CustomModal
    isCentered={false}

    title={'Option settings'}
    isOpen={isOpen}
    onClose={onClose}
    onOk={() => {
      onSave(optionData)
      onClose()
    }}
  >
    <Box>
      Alt text
    </Box>
    <Input.TextArea
      maxLength={125}
      max={125}
      showCount
      value={optionData.altText}
      onChange={e => setOptionData({ ...optionData, altText: e.target.value })}
      style={{
        background: 'white',
        borderRadius: '6px'
      }}
    />
  </CustomModal>
}

export const OptionItem = ({
  id,
  name,
  superSize,
  char,
  onRemove,
  allowRemove = true,
  width,
  color,
  imageUrl,
  backgroundColor,
  objectFit,
  nameBlocks,
  onImageClick,
  onSettingsModalOpen,
  onChange,
  readOnly
}) => {

  const [highlight, setHighlight] = useState()

  return <OptionContainer
    w={width}
    fontSize={'20px'}
    backgroundColor={setColorOpacity(color, 0.1)}
    color={color}
    boxShadow={`${setColorOpacity(color, 1)} 0px 0px 0px 1px inset`}
    onMouseEnter={() => setHighlight(true)}
    onMouseLeave={() => setHighlight(false)}
  >
    <Center
      h={superSize ? '200px' : '160px'}
      mb={'6px'}
      cursor={'pointer'}
      onClick={onImageClick}
      
    >
      {!imageUrl && <ImageIcon
        fill={color}
      />}


      {imageUrl && !objectFit && <CenterImage

        src={imageUrl}
        height={'100%'}
        width={'100%'}
      />}

      {imageUrl && objectFit && <img

        src={imageUrl}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />}

    </Center>

    <Box
      display={'flex'}
      alignItems={'center'}
      flex={1}

    >
      <Center
        h={'100%'}
        mr={'10px'}
      >
        <IndexCharContainer
          boxShadow={`${setColorOpacity(color, 1)} 0px 0px 0px 0.5px inset`}
          // backgroundColor={shade(color, 0.01, backgroundColor)}
          backgroundColor={setColorOpacity(color, 0.15)}

          color={color}
        >
          {char}
        </IndexCharContainer>
      </Center>

      <CustomWysiwyg
        readOnly={readOnly}
        value={nameBlocks}
        onChange={onChange}
        fontSize={'14px'}
        placeholder={'choice'}
        id={id}
      />

      {
        allowRemove && highlight && <OptionsToolsBarWrapper>
          <OptionsToolsBar
            backgroundColor={'white'}
            color={colors.primary}
            border={`1px solid ${setColorOpacity(colors.primary, 1)}`}
          >
            <Space size={14}>
              <Center>

                <DeleteOutlined
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={onRemove}
                />
              </Center>

              <Center>

                <SettingOutlined
                  style={{
                    fontSize: '16px',
                  }}
                  onClick={onSettingsModalOpen}
                />

              </Center>
              <Box
                mb={'3px'}
              >
                <ImageIcon
                  fill={colors.primary}
                  onClick={onImageClick}
                />
              </Box>

            </Space>
          </OptionsToolsBar>
        </OptionsToolsBarWrapper>
      }
    </Box>


  </OptionContainer>
}


const Configurator = ({ options = [], otherOption, onChange, color, backgroundColor, generalSettings, messages, align }) => {

  const translations = messages

  const [isOpen, onOpen] = useOptionSettingsModalStore(store => ([
    store.isOpen,
    store.onOpen,
  ]))

  const {
    objectFit, superSize, multipleSelection,
    multipleSelectionLimitType,
    multipleSelectionLimitExactNumber = 1,
    multipleSelectionLimitMinNumber = 1,
    multipleSelectionLimitMaxNumber = options.length
  } = generalSettings

  const [
    setImageModalOpen,
  ] = useFormConfiguratorStore(state => [
    state.setImageModalOpen,
  ])

  const onAddHandler = () => {
    const newOption = {
      id: randomId(),
      nameBlocks: initialDraftContent
    }
    const updatedOptions = [...options, newOption]
    onChange(updatedOptions)
  }

  const onRemoveHandler = (optionId) => {
    onChange(removeById(options, optionId))
  }

  const onChangeImage = (optionId, image) => {
    const copyOptions = options.map((option, i) => {
      if (optionId === option.id) {
        return {
          ...option,
          imageUrl: image.imageUrl
        }
      } else {
        return { ...option }
      }
    })
    onChange(copyOptions)
  }

  const onSaveOptionSettings = (data) => {
    const copyOptions = options.map((option, i) => {
      if (data.id === option.id) {
        return {
          ...data
        }
      } else {
        return { ...option }
      }
    })
    onChange(copyOptions)
  }

  const onSettingsModalOpen = (optionData) => {
    onOpen({ optionData, onSave: onSaveOptionSettings })
  }

  const infoText = multipleSelection && multipleSelectionLimitType === 'unlimited' ? translations['choiceUnlimited'] :
    multipleSelectionLimitType === 'exactNumber' ? `${translations['choice']} ${multipleSelectionLimitExactNumber}` :
      multipleSelectionLimitType === 'range' ? `${translations['choiceRange']} ${multipleSelectionLimitMinNumber}-${multipleSelectionLimitMaxNumber}`
        : null

  return (
    <>
      {isOpen && <OptionSettingsModal />}



      <Box
        w={'100%'}
      >

        {infoText && <Box
          color={color}
          mb={'20px'}
          textAlign={align === 'center' && 'center'}
        >
          {infoText}
        </Box>}


        <ReactSortable
          list={options}
          setList={(data) => {
            onChange(data)
          }}
          style={{ display: "flex", flexWrap: "wrap" }}

          id={'sortable'}
        >
          {options.map((o, index) => (
            <OptionItem
              key={o.id}
              char={indexChars[index]}
              id={o.id}
              name={o.name}
              onRemove={() => onRemoveHandler(o.id)}
              width={superSize ? 'calc(50% - 8px)' : 'calc(25% - 8px)'}
              superSize={superSize}
              color={color}
              backgroundColor={backgroundColor}
              objectFit={objectFit}
              imageUrl={o.imageUrl}
              nameBlocks={o.nameBlocks}
              onImageClick={() => setImageModalOpen({
                onImageModalChange: (data) => onChangeImage(o.id, data)
              })}
              onSettingsModalOpen={() => onSettingsModalOpen(o)}
              onChange={(data) => {
                const copyOptions = options.map((option, i) => {
                  if (option.id === o.id) {
                    return {
                      ...option,
                      nameBlocks: data
                    }
                  } else {
                    return { ...option }
                  }
                })
                onChange(copyOptions)
              }}
            />
          ))}
        </ReactSortable>

        {/*{otherOption && <OptionItem*/}
        {/*  id={'other'}*/}
        {/*  char={indexChars[options.length]}*/}
        {/*  name={'Other'}*/}
        {/*  allowRemove={false}*/}
        {/*  color={color}*/}
        {/*  backgroundColor={backgroundColor}*/}
        {/*  readOnly={true}*/}
        {/*  nameBlocks={otherNameBlock}*/}
        {/*/>}*/}
        <Flex
          mt={'15px'}
        >
          <UnderlineTextButton
            color={color}
            onClick={onAddHandler}
          >
            Add choice...
          </UnderlineTextButton>
        </Flex>
      </Box>
    </>
  )
}


const PictureChoice = ({ fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber, messages }) => {


  const { questionColor, fontSize, align, answerColor, backgroundColor } = theme

  const { options = [], generalSettings = {} } = fieldData

  const { other, required } = generalSettings

  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  const onChangeHandler = (options = []) => {
    if (other) {
      onChange({ options: [...options, otherOption] })
    } else {
      onChange({ options })
    }
  }

  useEffect(() => {
    let optionsWithoutOther = options.filter(o => o.id !== 'other')
    if (other) {
      return onChange({ options: [...optionsWithoutOther, otherOption] })
    }
    return onChange({ options: optionsWithoutOther })
  }, [other])


  if (!fieldData) return null

  const isOtherOption = options.some(o => o.id === 'other')
  let optionsWithoutOther = isOtherOption ? options.filter(o => o.id !== 'other') : options

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        display={'flex'}
        justifyContent={align}
      >
        <Configurator
          align={align}
          options={optionsWithoutOther}
          onChange={onChangeHandler}
          generalSettings={generalSettings}
          color={answerColor}
          backgroundColor={backgroundColor}
          otherOption={isOtherOption}
          messages={messages}

        />
      </Box>

    </FieldWrapper>
  );
};


export default PictureChoice;