import React, {useCallback, useEffect, useState} from 'react';
import {modalStore} from "../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import {removeMember, updatePermission} from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Checkbox, Col, Form, Row} from "antd";
import {permissionsOptions} from "./AddMemberModla";
import openNotification from "../../../shared/openNotification";



export const useRemoveUserModlaStore = modalStore()


const RemoveUserModla = ({fetchOrganizations}) => {
  const {organizationId, workspaceId} = useParams()

  const history = useHistory()


  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useRemoveUserModlaStore(store => [store.isOpen, store.onClose, store.data])


  const onCloseHandler = () => {
    onClose()
  }

  const removeUserHandler = useCallback(
    async (values) => {


      setLoading(true)

      try {

        const requestData = {
          ...values
        }

        const data = await removeMember(requestData)

        fetchOrganizations()

        onCloseHandler()

        openNotification({description: 'Member has been removed'})

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [data]
  )
console.log(data)

  return <CustomModal
    title={'Remove member'}
    zIndex={12}
    onOk={() => removeUserHandler({userId: data?.userId})}
    okButtonProps={{
      title: 'Remove',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    size={'2xl'}
  >
    <Box
      p={'30px 0 30px'}
    >
      Are your sure you want to remove member from your team?
    </Box>


  </CustomModal>
};

export default RemoveUserModla;