import React, { useEffect, useMemo, useState } from 'react';
import { Box, HStack, Center, VStack } from "@chakra-ui/react";
import { StyledSelect } from '../../../../shared/form/CustomSearchableSelect';
import CustomNumberInput from '../../../../shared/form/CustomNumberInput';
import PrimaryButton from '../../../../shared/PrimaryButton';
import { useParams } from 'react-router-dom';
import { useGetCodeModalStore } from './GetCodeModal';
import { StyledInput } from '../../../../shared/form/InlineTextField';
import { Input, Tabs } from 'antd';
import colors from '../../../../utils/commonStyles';
import ColorPicker from '../../../../shared/ColorPicker'
import Selecton from './Selection';
import useFormConfiguratorStore from '../../stores/useFormConfiguratorStore';
import { StyledTabs } from '../ConfiguratorHeader';
import SecondaryButton from '../../../../shared/SecondaryButton';
import { IconButton } from '../ThemeEditor';
import { ImageIcon, RemoveIcon } from '../../fieldTypes/icons';
import ImageToolsModal from '../ImageToolsModal';
import useDebouncedEffect from '../../../../hooks/useDebounceEffect';
import CustomSwitch from '../../../../shared/form/CustomSwitch';
import CustomSelect from '../../../../shared/form/CustomSelect';
const { TabPane } = Tabs;

const SCRIPT_URL = 'https://form.formee.io/static/embed.js'
// const SCRIPT_URL = '/embed.js'

const renderHtml0 = (data = {}) => {
    let { formId, height, heightUnit, width, widthUnit } = data
    height = !height ? '100%' : `${height}${heightUnit}`
    width = !width ? '100%' : `${width}${widthUnit}`
    return `<div id="formee-widget" data-formee-id="${formId}" data-formee-type="standard" style="height: ${height}; width: ${width};" ></div>`
}

const renderHtml1 = (data = {}) => {
    let { formId, size = 'medium', bg = 'blue', color = 'white', title = 'Try me!', position = 'right' } = data
    return `<button 
        id="formee-widget" 
        data-formee-id="${formId}" 
        data-formee-type="popup" 
        data-size="${size}"  
        data-position="${position}" 
        style="all: unset; background: ${bg};
             color: ${color};
             font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:20px;border-radius:25px;padding:0 33px;font-weight:bold;height:50px;cursor:pointer;line-height:50px;text-align:center;margin:0;text-decoration:none">${title}</button>`
}

const renderHtml2 = (data = {}) => {
    let { formId, size = 'medium', bg = 'blue', color = 'white', title = 'Try me!', position = 'right' } = data
    return `<button id="formee-widget" data-formee-id="${formId}" data-formee-type="slider" data-size="${size}" data-position="${position}" style="all: unset; background: ${bg}; color: ${color};font-family:Helvetica,Arial,sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:20px;border-radius:25px;padding:0 33px;font-weight:bold;height:50px;cursor:pointer;line-height:50px;text-align:center;margin:0;text-decoration:none">${title}</button> `
}

const renderHtml3 = (data = {}) => {
    let { formId, size = 'medium', bg = 'blue', color = 'white', title = 'Try me!', position = 'right', imageUrl, openRule, isCustomRules, scrollOption, timeOption, closeOnSubmit, closeDelay } = data


    return `<div 
    id="formee-widget" 
    data-formee-id="${formId}" 
    data-formee-type="sidetab"
    data-formee-image-url="${imageUrl}" 
    data-formee-title="${title}" 
    data-formee-bg="${bg}" 
    data-formee-color="${color}" 
    ${isCustomRules ? `data-formee-open-rule="${openRule}"` : ''}
    ${isCustomRules && openRule === 'scroll' ? `data-formee-scroll-option="${scrollOption}"` : ''}
    ${isCustomRules && openRule === 'time' ? `data-formee-time-option="${timeOption}"` : ''}
    ${closeOnSubmit ? `data-formee-close-on-submit="${closeDelay}"` : ''}
    
         style="all:unset;"
         ></div>
        
         `
}

const renderHtml4 = (data = {}) => {
    let { formId, size = 'medium', bg = 'blue', color = 'white', title = 'Try me!', position = 'right', imageUrl, openRule, isCustomRules, scrollOption, timeOption, closeOnSubmit, closeDelay } = data

    return `<div 
    id="formee-widget" 
    data-formee-id="${formId}" 
    data-formee-type="popover"
    data-formee-image-url="${imageUrl}" 
    data-formee-title="${title}" 
    data-formee-bg="${bg}" 
    data-formee-color="${color}" 
    ${isCustomRules ? `data-formee-open-rule="${openRule}"` : ''}
    ${isCustomRules && openRule === 'scroll' ? `data-formee-scroll-option="${scrollOption}"` : ''}
    ${isCustomRules && openRule === 'time' ? `data-formee-time-option="${timeOption}"` : ''}
    ${closeOnSubmit ? `data-formee-close-on-submit="${closeDelay}"` : ''}

         style="all:unset;"
         ></div>
        
     `
}

const FormFor0 = ({ data, onChange }) => {
    return <Box >
        <div
            class="font-normal mt-6 text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Width</span>
            <div class='w-full pt-[5px] mr-2'>
                <CustomNumberInput
                    value={data.width}
                    onChange={e => onChange('width', e)}
                />
            </div>
            <div class="w-full">
                <StyledSelect
                    value={data.widthUnit}
                    onChange={e => onChange('widthUnit', e)}
                >
                    <StyledSelect.Option value="%">%</StyledSelect.Option>
                    <StyledSelect.Option value="px">px</StyledSelect.Option>
                </StyledSelect>
            </div>

        </div>
        <div
            class="font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Height</span>
            <div class='w-full pt-[5px] mr-2'>
                <CustomNumberInput
                    value={data.height}
                    onChange={e => onChange('height', e)}
                />
            </div>
            <div class="w-full">
                <StyledSelect
                    value={data.heightUnit}
                    onChange={e => onChange('heightUnit', e)}
                >
                    <StyledSelect.Option value="%">%</StyledSelect.Option>
                    <StyledSelect.Option value="px">px</StyledSelect.Option>
                </StyledSelect>
            </div>

        </div>
    </Box>
}

const FormFor1 = ({ data, onChange }) => {
    return <Box>
        <div
            class="font-normal mt-6 text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Size</span>

            <div class="w-full">
                <StyledSelect
                    value={data.size}
                    onChange={e => onChange('size', e)}
                >
                    <StyledSelect.Option value="small">Small</StyledSelect.Option>
                    <StyledSelect.Option value="medium">Medium</StyledSelect.Option>
                    <StyledSelect.Option value="large">Large</StyledSelect.Option>
                </StyledSelect>
            </div>

        </div>
        <div
            class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Button text</span>
            <div class='w-full'>
                <Input
                    style={{ borderRadius: '6px' }}
                    value={data.title}
                    onChange={e => onChange('title', e.target.value)}
                />
            </div>
        </div>
        <div
            class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Button background</span>
            <div class=''>
                <ColorPicker
                    style={{ borderRadius: '6px' }}
                    value={data.bg}
                    onChange={e => onChange('bg', e)}
                />
            </div>
        </div>
        <div
            class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Button color</span>
            <div class=''>
                <ColorPicker
                    style={{ borderRadius: '6px' }}
                    value={data.color}
                    onChange={e => onChange('color', e)}
                />
            </div>
        </div>
    </Box>
}

const FormFor2 = ({ data, onChange }) => {
    return <Box>
        <div
            class="font-normal mt-6 text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Slide direction</span>

            <div class="w-full">
                <StyledSelect
                    value={data.position}
                    onChange={e => onChange('position', e)}
                >
                    <StyledSelect.Option value="right">Right</StyledSelect.Option>
                    <StyledSelect.Option value="left">Left</StyledSelect.Option>
                </StyledSelect>
            </div>

        </div>
        <div
            class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Button text</span>
            <div class='w-full'>
                <Input
                    style={{ borderRadius: '6px' }}
                    value={data.title}
                    onChange={e => onChange('title', e.target.value)}
                />
            </div>
        </div>
        <div
            class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Button background</span>
            <div class=''>
                <ColorPicker
                    style={{ borderRadius: '6px' }}
                    value={data.bg}
                    onChange={e => onChange('bg', e)}
                />
            </div>
        </div>
        <div
            class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
            <span class="w-full">Button color</span>
            <div class=''>
                <ColorPicker
                    style={{ borderRadius: '6px' }}
                    value={data.color}
                    onChange={e => onChange('color', e)}
                />
            </div>
        </div>
    </Box>
}

const FormFor3 = ({ data, onChange }) => {
    const [
        updateLayoutConfig,
        setImageModalOpen,
    ] = useFormConfiguratorStore(state => [
        state.updateLayoutConfig,
        state.setImageModalOpen,
    ])


    const onChangeImage = (data) => {
        onChange('imageUrl', data?.imageUrl)
    }

    const onRemoveImage = () => {
        onChange('imageUrl', '')
    }

    return <Box>

        <StyledTabs
        // defaultActiveKey={'1'}
        // activeKey={currentTab}
        // onChange={(e) => setCurrentTab(e)}
        // style={{ padding: '10px' }}
        // pb={'0px'}
        >
            <TabPane tab={<Box

                fontSize={'14px'}>Design</Box>} key={'1'}
            >
                <div
                    class="pt-[10px] mt-4 font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full flex-1">Custom icon</span>
                    <div class='w-full'>
                        {(data['imageUrl']) ? (
                            <HStack>
                                <IconButton
                                    onClick={() => setImageModalOpen({
                                        onImageModalChange: onChangeImage
                                    })}
                                >
                                    <ImageIcon />
                                </IconButton>

                                <IconButton
                                    onClick={onRemoveImage}
                                >
                                    <RemoveIcon />
                                </IconButton>

                            </HStack>
                        ) : (
                            <SecondaryButton
                                // {...defaultButtonProps}
                                fontSize={'14px'}
                                // p={'3px'}
                                size={'sm'}
                                onClick={() => setImageModalOpen({
                                    onImageModalChange: onChangeImage
                                })}
                            >
                                + Add
                            </SecondaryButton>
                        )}
                    </div>
                </div>
                <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full">Button text</span>
                    <div class='w-full'>
                        <Input
                            style={{ borderRadius: '6px' }}
                            value={data.title}
                            onChange={e => onChange('title', e.target.value)}
                        />
                    </div>
                </div>

                <div
                    class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full">Button background</span>
                    <div class=''>
                        <ColorPicker
                            style={{ borderRadius: '6px' }}
                            value={data.bg}
                            onChange={e => onChange('bg', e)}
                        />
                    </div>
                </div>

                <div
                    class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full">Button color</span>
                    <div class=''>
                        <ColorPicker
                            style={{ borderRadius: '6px' }}
                            value={data.color}
                            onChange={e => onChange('color', e)}
                        />
                    </div>
                </div>
            </TabPane>
            <TabPane tab={<Box fontSize={'14px'}>Advanced</Box>} key={'2'}>

                <div
                    class="pt-[10px] mt-4  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="flex-1">Custom launch options</span>
                    <div class=''>
                        <CustomSwitch
                            style={{ borderRadius: '6px' }}
                            value={data.isCustomRules}
                            onChange={e => onChange('isCustomRules', e)}
                        />
                    </div>
                </div>
                {data.isCustomRules && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <div class='w-full'>
                        <CustomSelect
                            style={{ borderRadius: '6px' }}
                            value={data.openRule}
                            onChange={e => onChange('openRule', e)}
                            options={[
                                {
                                    value: 'load',
                                    label: 'On page load'
                                },
                                // {
                                //     value: 'exit',
                                //     label: 'On exit intent'
                                // },
                                {
                                    value: 'scroll',
                                    label: 'After scrolling'
                                },
                                {
                                    value: 'time',
                                    label: 'After a set time'
                                },
                            ]}
                        />
                    </div>
                </div>}
                {data.isCustomRules && data.openRule === 'scroll' && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <div class='w-full'>
                        <CustomSelect
                            style={{ borderRadius: '6px' }}
                            value={data.scrollOption}
                            onChange={e => onChange('scrollOption', e)}
                            options={[
                                {
                                    value: '30',
                                    label: '30%'
                                },
                                {
                                    value: '60',
                                    label: '60%'
                                },
                                {
                                    value: '90',
                                    label: '90%'
                                }
                            ]}
                        />
                    </div>
                </div>}

                {data.isCustomRules && data.openRule === 'time' && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <div class='w-full'>
                        <CustomNumberInput
                            style={{ borderRadius: '6px' }}
                            value={data.timeOption}
                            onChange={e => onChange('timeOption', e)}

                        />
                    </div>
                    <span class="w-full">secounds</span>

                </div>}


                {/* <div
                    class="pt-[10px] mt-4  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="flex-1">Close on submit</span>
                    <div class=''>
                        <CustomSwitch
                            style={{ borderRadius: '6px' }}
                            value={data.closeOnSubmit}
                            onChange={e => onChange('closeOnSubmit', e)}
                        />
                    </div>
                </div> */}

                {data.closeOnSubmit && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="flex-1">Delay for</span>

                    <div class=''>
                        <CustomNumberInput
                            style={{ borderRadius: '6px' }}
                            value={data.closeDelay}
                            onChange={e => onChange('closeDelay', e)}

                        />
                    </div>
                    <span class="flex-1">secounds</span>

                </div>}

            </TabPane>

        </StyledTabs>

    </Box>
}

const FormFor4 = ({ data, onChange }) => {
    const [
        updateLayoutConfig,
        setImageModalOpen,
    ] = useFormConfiguratorStore(state => [
        state.updateLayoutConfig,
        state.setImageModalOpen,
    ])


    const onChangeImage = (data) => {
        onChange('imageUrl', data?.imageUrl)
    }

    const onRemoveImage = () => {
        onChange('imageUrl', '')
    }

    return <Box>

        <StyledTabs
        // defaultActiveKey={'1'}
        // activeKey={currentTab}
        // onChange={(e) => setCurrentTab(e)}
        // style={{ padding: '10px' }}
        // pb={'0px'}
        >
            <TabPane tab={<Box

                fontSize={'14px'}>Design</Box>} key={'1'}
            >
                <div
                    class="pt-[10px] mt-4 font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full flex-1">Custom icon</span>
                    <div class='w-full'>
                        {(data['imageUrl']) ? (
                            <HStack>
                                <IconButton
                                    onClick={() => setImageModalOpen({
                                        onImageModalChange: onChangeImage
                                    })}
                                >
                                    <ImageIcon />
                                </IconButton>

                                <IconButton
                                    onClick={onRemoveImage}
                                >
                                    <RemoveIcon />
                                </IconButton>

                            </HStack>
                        ) : (
                            <SecondaryButton
                                // {...defaultButtonProps}
                                fontSize={'14px'}
                                // p={'3px'}
                                size={'sm'}
                                onClick={() => setImageModalOpen({
                                    onImageModalChange: onChangeImage
                                })}
                            >
                                + Add
                            </SecondaryButton>
                        )}
                    </div>
                </div>
                {/* <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full">Button text</span>
                    <div class='w-full'>
                        <Input
                            style={{ borderRadius: '6px' }}
                            value={data.title}
                            onChange={e => onChange('title', e.target.value)}
                        />
                    </div>
                </div> */}

                <div
                    class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full">Button background</span>
                    <div class=''>
                        <ColorPicker
                            style={{ borderRadius: '6px' }}
                            value={data.bg}
                            onChange={e => onChange('bg', e)}
                        />
                    </div>
                </div>

                <div
                    class="pt-[10px] font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="w-full">Button color</span>
                    <div class=''>
                        <ColorPicker
                            style={{ borderRadius: '6px' }}
                            value={data.color}
                            onChange={e => onChange('color', e)}
                        />
                    </div>
                </div>
            </TabPane>
            <TabPane tab={<Box fontSize={'14px'}>Advanced</Box>} key={'2'}>

                <div
                    class="pt-[10px] mt-4  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="flex-1">Custom launch options</span>
                    <div class=''>
                        <CustomSwitch
                            style={{ borderRadius: '6px' }}
                            value={data.isCustomRules}
                            onChange={e => onChange('isCustomRules', e)}
                        />
                    </div>
                </div>
                {data.isCustomRules && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <div class='w-full'>
                        <CustomSelect
                            style={{ borderRadius: '6px' }}
                            value={data.openRule}
                            onChange={e => onChange('openRule', e)}
                            options={[
                                {
                                    value: 'load',
                                    label: 'On page load'
                                },
                                // {
                                //     value: 'exit',
                                //     label: 'On exit intent'
                                // },
                                {
                                    value: 'scroll',
                                    label: 'After scrolling'
                                },
                                {
                                    value: 'time',
                                    label: 'After a set time'
                                },
                            ]}
                        />
                    </div>
                </div>}
                {data.isCustomRules && data.openRule === 'scroll' && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <div class='w-full'>
                        <CustomSelect
                            style={{ borderRadius: '6px' }}
                            value={data.scrollOption}
                            onChange={e => onChange('scrollOption', e)}
                            options={[
                                {
                                    value: '30',
                                    label: '30%'
                                },
                                {
                                    value: '60',
                                    label: '60%'
                                },
                                {
                                    value: '90',
                                    label: '90%'
                                }
                            ]}
                        />
                    </div>
                </div>}

                {data.isCustomRules && data.openRule === 'time' && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <div class='w-full'>
                        <CustomNumberInput
                            style={{ borderRadius: '6px' }}
                            value={data.timeOption}
                            onChange={e => onChange('timeOption', e)}

                        />
                    </div>
                    <span class="w-full">secounds</span>

                </div>}


                {/* <div
                    class="pt-[10px] mt-4  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="flex-1">Close on submit</span>
                    <div class=''>
                        <CustomSwitch
                            style={{ borderRadius: '6px' }}
                            value={data.closeOnSubmit}
                            onChange={e => onChange('closeOnSubmit', e)}
                        />
                    </div>
                </div> */}

                {data.closeOnSubmit && <div
                    class="pt-[10px]  font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">
                    <span class="flex-1">Delay for</span>

                    <div class=''>
                        <CustomNumberInput
                            style={{ borderRadius: '6px' }}
                            value={data.closeDelay}
                            onChange={e => onChange('closeDelay', e)}

                        />
                    </div>
                    <span class="flex-1">secounds</span>

                </div>}

            </TabPane>

        </StyledTabs>

    </Box>
}

const defValues = [
    {
        width: '100',
        height: '100',
        widthUnit: '%',
        heightUnit: '%'
    },
    {
        size: 'medium',
        bg: '#475FFC',
        color: 'white',
        title: 'Try me!',
        heightUnit: '%'
    },
    {
        bg: '#475FFC',
        color: 'white',
        title: 'Try me!',
        position: 'right',
        heightUnit: '%'
    },
    {
        imageUrl: '',
        title: 'Try me!',
        bg: '#475FFC',
        color: 'white',
        openRule: 'load',
        scrollOption: '60',
        timeOption: '5',
        closeOnSubmit: false,
        closeDelay: '2'
    },
    {
        imageUrl: '',
        bg: '#475FFC',
        color: 'white',
        openRule: 'load',
        scrollOption: '60',
        timeOption: '5',
        closeOnSubmit: false,
        closeDelay: '2'
    }
]

const modes = [
    {
        icon: (active = true) => <div
            class={`group cursor-pointer hover:text-primary font-medium text-xs text-center  flex flex-col space-y-1`}>
            <svg class="group-hover:text-primary text-font_secondary" width="106" height="74"
                viewBox="0 0 106 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="105" height="73" rx="5.5" fill="white" stroke="currentColor">
                </rect>
                <rect x="8" y="8" width="90" height="58" rx="4" fill="currentColor"></rect>
            </svg>
            <span> Standard </span>
        </div>,

    }, {
        icon: (active = true) => <div
            class="group cursor-pointer hover:text-primary font-medium text-xs text-center  flex flex-col space-y-1">
            <svg class="group-hover:text-primary text-primary" width="106" height="74" viewBox="0 0 106 74"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="105" height="73" rx="5.5" fill="white" stroke="currentColor">
                </rect>
                <rect x="14" y="18" width="78" height="38" rx="4" fill="currentColor"></rect>
            </svg><span>Popup</span>
        </div>
    }, {
        icon: (active = true) => <div
            class="group cursor-pointer hover:text-primary font-medium text-xs text-center  flex flex-col space-y-1">
            <svg class="group-hover:text-primary text-font_secondary" width="106" height="74"
                viewBox="0 0 106 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="105" height="73" rx="5.5" fill="white" stroke="currentColor">
                </rect>
                <rect x="53" y="8" width="45" height="58" rx="4" fill="currentColor"></rect>
            </svg><span> Slider </span>
        </div>
    },
    {
        icon: (active = true) => <div class="
        group cursor-pointer hover:text-primary font-medium text-xs text-center  flex flex-col space-y-1 
        text-font_secondary
    ">
            <svg class="group-hover:text-primary text-font_secondary" width="106" height="74"
                viewBox="0 0 106 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="105" height="73" rx="5.5" fill="white" stroke="currentColor">
                </rect>
                <rect x="62" y="12" width="36" height="50" rx="4" fill="currentColor"></rect>
                <rect x="56" y="29" width="4" height="16" rx="2" fill="currentColor"></rect>
            </svg>
            <span> Side tab </span>
        </div>
    },
    {
        icon: (active = true) => <div class="
        group cursor-pointer hover:text-primary font-medium text-xs text-center  flex flex-col space-y-1 
        text-font_secondary
    ">
            <svg width="106" height="74" viewBox="0 0 106 74" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 0.5H6C2.96243 0.5 0.5 2.96243 0.5 6V68C0.5 71.0376 2.96243 73.5 6 73.5H100C103.038 73.5 105.5 71.0376 105.5 68V6C105.5 2.96243 103.038 0.5 100 0.5Z" fill="white" stroke="currentColor" />
                <path d="M91 12H63C60.7909 12 59 13.7909 59 16V58C59 60.2091 60.7909 62 63 62H91C93.2091 62 95 60.2091 95 58V16C95 13.7909 93.2091 12 91 12Z" fill="currentColor" />
                <circle cx="96.5" cy="66.5" r="3.5" fill="currentColor" />
            </svg>


            <span> Popover </span>
        </div>
    }
]


const EmbedSection = ({ }) => {

    const [
        formData = {},
        fields,
        setFormData
    ] = useFormConfiguratorStore(state => [
        state.formData,
        state.fields,
        state.setFormData,
    ])

    const { formId } = useParams()
    const [onOpen] = useGetCodeModalStore(s => ([s.onOpen]))

    const [data, setData] = useState()
    const [mode, setMode] = useState()
    const [flag, setFlag] = useState()
    const [htmlText, setHtmlText] = useState()

    useEffect(() => {
        setMode(0)
        setData({ ...defValues[0] })
    }, [])

    const onChangeHandler = (name, val) => {
        setData({ ...data, [name]: val })
    }

    const onOpenModalHandler = () => {
        onOpen({
            htmlText: htmlText + `<script src="${SCRIPT_URL}"></script>`
        })
    }

    let values = { ...data, formId }

    let Form = mode === 0 ? <FormFor0 data={data} onChange={onChangeHandler} /> :
        mode === 1 ? <FormFor1 data={data} onChange={onChangeHandler} /> :
            mode === 2 ? <FormFor2 data={data} onChange={onChangeHandler} /> :
                mode === 3 ? <FormFor3 data={data} onChange={onChangeHandler} /> :
                    mode === 4 ? <FormFor4 data={data} onChange={onChangeHandler} /> :
                        null

    useDebouncedEffect(() => {
        let htmlText = mode === 0 ? renderHtml0(values) : mode === 1 ? renderHtml1(values) : mode === 2 ? renderHtml2(values) : mode === 3 ? renderHtml3(values) : renderHtml4(values)
        setHtmlText(htmlText)


        setFlag(false)
        setFlag(true)

        setTimeout(() => {

            function sendMessage() {
                document.getElementById('formee-preview-iframe').contentWindow.postMessage(htmlText, "*")
            }

            sendMessage()
        }, 500)

    }, [mode, data], 400)


    return (
        <>
            <ImageToolsModal />
            <div class="py-6" >
                <div class="text-font_primary font-semibold text-lg text-center">Embed in a web page</div>
                <div class="text-font_secondary font-normal text-sm text-center  py-2 px-6 lg:px-24 mx-auto">Embed forms on your
                    website or product.</div>
            </div>

            <div class="flex flex-row  justify-center  h-full mx-auto pb-10">

                <Box maxW={'270px'} px={'20px'} id="step-share-embed" >
                    <div class="flex flex-col">

                        <div class="flex flex-auto flex-wrap  gap-3 justify-between mb-3">

                            {modes.map((m, index) => <Box
                                color={mode === index && colors.primary}
                                className={`text-${mode === index ? 'blue-500' : 'font_secondary'}`}
                                onClick={() => {
                                    if (mode === index) return
                                    setData({ ...defValues[index] })
                                    setMode(index)
                                }}>
                                {m.icon()}
                            </Box>)}

                        </div>
                    </div>

                    <div class="mt-6">
                        <PrimaryButton onClick={onOpenModalHandler}>
                            {`< > Get code`}
                        </PrimaryButton>
                    </div>
                </Box>


                <Box flex={1} mx={'20px'}>
                    <Box bg={'white'} borderRadius={'8px'} className={`h-[630px] rounded-lg`}>

                        {mode === 0 && flag &&
                            <Selecton

                            />
                        }

                        {mode === 1 && flag &&
                            <Selecton
                            />

                        }

                        {mode === 2 && flag &&
                            <Selecton
                            />
                        }

                        {mode === 3 && flag &&
                            <Selecton
                            />
                        }

                        {mode === 4 && flag &&
                            <Selecton
                            />
                        }

                    </Box>
                </Box>


                <Box
                    bg={'white'} borderRadius={'8px'}
                    w={'300px'} px={'20px'} >
                    <Box >
                        {Form}
                    </Box>


                </Box>

                {/* <script src={SCRIPT_URL}></script> */}
            </div>
        </>

    );
};

export default EmbedSection;