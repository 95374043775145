import { Box, Center } from "@chakra-ui/react";
import { Spin } from "antd";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getAnalytics } from "../../../../firebase/form";
import colors from "../../../../utils/commonStyles";
import { fieldTypes } from "../../fieldTypes/config";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import { FieldAnalyticsContent } from "./FieldAnalyticsContent";
import FieldAnalyticsItem from "./FieldAnalyticsItem";
// import Pdf from 'react-to-pdf'
import moment from 'moment';

const Card = ({children, ...rest}) => {
  return (
    <Box
      borderRadius={'6px'}
      border={'1px solid #f2f2f2'}
      p={'10px'}
      {...rest}
    >
      {children}
    </Box>
  );
};


const AnalyticsItem = ({title, description}) => {
  return <Box
    mr={'47px'}
    color={colors.black}
    // p={'25px'}
    // w={'200px'}
  >
    <Box fontSize={'13px'}
         fontWeight={300}
      // mb={'20px'}
    >
      {description}

    </Box>
    <Box
      fontSize={'40px'}
      // fontWeight={600}
    >
      {title}

    </Box>
  </Box>
}


const AnalyticsItems = ({statistics}) => {

  const {submitted, desktop, mobile, completeTime, see, started} = statistics
  const completeTimeAve = completeTime / (submitted || 1)

  return <Box d={'flex'}
    my={'60px'}
  >
    <AnalyticsItem
      title={see}
      description={'Views'}
    />
    <AnalyticsItem
      title={started}
      description={'Starts'}
    />
    <AnalyticsItem
      title={submitted}
      description={'Submissions'}
    />
    <AnalyticsItem
      title={`${started > 0 ? Math.round((submitted / started) * 100) : 0}%`}
      description={'Completion rate'}
    /> <AnalyticsItem
    title={moment.utc(completeTimeAve*1000).format((completeTimeAve >= (60*60)) ? 'HH:mm:ss' : 'mm:ss')}
    description={'Time to complete'}
  /><AnalyticsItem
    title={desktop}
    description={'Desktop'}
  /><AnalyticsItem
    title={mobile}
    description={'Mobile'}
  />
  </Box>
}

const Insights = () => {

  const [
    fields,
  ] = useFormConfiguratorStore(state => [
    state.fields,
  ])

  const {formId} = useParams()

  const ref = React.createRef();

  const [analyticsData, setAnalyticsData] = useState([]);
  const [statistics, setStatistics] = useState({});

  const [fetching, setFetching] = useState();

  const {submitted, desktop, mobile, completeTime, see, started} = statistics

  const fetchAnalytics = async () => {
    setFetching(true)
    try {
      const data = await getAnalytics(formId)
      setAnalyticsData(data.analyticsData)
      setStatistics(data.statistics)
    } catch (err) {
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    fetchAnalytics()
  }, [])

  if (fetching) return <Center mt={'50px'}>
    <Spin/>
  </Center>

  return (
    <Box maxW={'900px'}>
      <AnalyticsItems statistics={statistics}/>

      {/*<Pdf targetRef={ref} filename="answers.pdf">*/}
      {/*  {({ toPdf }) => <Tooltip title={'Print this response'}>*/}
      {/*    <TableButton onClick={toPdf}>*/}
      {/*      <DownloadOutlined/>*/}
      {/*    </TableButton>*/}
      {/*  </Tooltip>}*/}
      {/*</Pdf>*/}

      <div ref={ref}>

        {analyticsData?.map((f, index) => {
          const IconComponent = fieldTypes[f.type]?.icon
          return (
            <FieldAnalyticsItem
              title={f.label || '---'}
              description={`${f.allAnswered} out of ${submitted} people answered this question`}
              // description={`${100} out of ${112} people answered this question`}
              icon={<IconComponent label={index + 1}/>}
            >
              <FieldAnalyticsContent data={f}/>
            </FieldAnalyticsItem>
          )
        })}
      </div>

    </Box>
  );
};

export default Insights;