import React, { useState } from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import { Input, Box, HStack, Center } from '@chakra-ui/react'
import styled from "styled-components";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { Player } from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import colors from "../../../../utils/commonStyles";
import PrimaryButton from "../../../../shared/PrimaryButton";
import { setColorOpacity } from "../MultiChoice/MultiChoice";
import SecondaryButton from "../../../../shared/SecondaryButton";
import { Select, Space } from "antd";
import { DEFAULT_INPUT_HEIGHT } from "../../../../shared/form/useFormBuilder";
import { UploadOutlined } from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";


const Tabs = ({ onChange, mode, tabs = [] }) => {

  return <Box
    display={'flex'}
  >
    {tabs.filter(v => v.visible).map((t, index) => <Box
      p={'10px 10px 10px 10px'}
      borderBottom={mode === t.id && `4px solid ${colors.primary}`}
      cursor={'pointer'}

      onClick={() => onChange(t.id)}
    >
      {t.name}
    </Box>)}
  </Box>
}

const Signature = ({ fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber }) => {


  const { questionColor, fontSize, align, answerColor, buttonBackground, buttonColor } = theme
  const { options = [], generalSettings = {} } = fieldData
  const { required, showDrawMode, showTypeMode, showUploadMode } = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null


  if (!fieldData) return null

  const containerProps = {
    p: '20px',
    bg: setColorOpacity(answerColor, 0.06),
    // w: '450px',
    border: `2px dashed ${answerColor}`,
    borderRadius: '8px',
    h: '236px'
  }

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          maxHeight='380px'

          width={'100%'}
        />}
      </Box>}

      <Box
      // d={'flex'}
      // justifyContent={align === 'center' ? 'center' : 'flex-start'}

      >


        <Box
          {...containerProps}
        >
          <Box>
            <Box
              mb={'10px'}
              h={'150px'}
              borderRadius={'8px'}
              bg={setColorOpacity(answerColor, 0.06)}
              border={`1px solid ${answerColor}`}
              p={['3px']}
              // w={'670px'}
              h={'142px'}
            >

            </Box>

            <HStack
              justifyContent={'end'}
            >
              <Space>

                <SecondaryButton
                  cursor={'default'}
                  size={'sm'}
                  w={'70px'}
                  borderColor={answerColor}
                  color={answerColor}
                  opacity={0.8}
                >
                  Clear
                </SecondaryButton>
                <PrimaryButton
                  cursor={'default'}

                  size={'sm'}
                  w={'70px'}
                  background={answerColor}
                >
                  Save
                </PrimaryButton>
              </Space>
            </HStack>
          </Box>
        </Box>
      </Box>
    </FieldWrapper>
  );
};

const InputWrapper = styled(Box)`
  font-family: 'Dancing Script', cursive;
 
`;

export default Signature;