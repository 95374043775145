import {convertFromRaw} from "draft-js";
import _ from "lodash";

export const MAX_MENTION_TEXT_LENGTH = 120

export const updateFieldMentions = (activeField = {}, fields = [], formData = {}) => {

  const {descriptionDraftContent, nameDraftContent} = activeField

  const cloneNameDraftContent = _.cloneDeep(nameDraftContent)
  const cloneDescriptionDraftContent = _.cloneDeep(descriptionDraftContent)

  return {
    ...activeField,
    nameDraftContent: update(cloneNameDraftContent, fields, formData),
    descriptionDraftContent: update(cloneDescriptionDraftContent, fields, formData),
  }
}


const update = (blockContent = {}, fields = [], formData = {}) => {

  const {numberVariables = [], stringVariables = [], hiddenVariables = []} = formData

  const {entityMap = {}, blocks = []} = blockContent

  const updatedEntityMap = {}

  const updatedBlocks = blocks.map(block => {

    let updatedText = block.text

    let removeToLeftBy = 0

    const updatedEntityRanges = block.entityRanges.map(range => {

      const offset = range.offset - removeToLeftBy

      if (!entityMap[range.key]) {
        updatedText = cutText(updatedText, offset, range.length)
        removeToLeftBy += range.length
        return null
      }

      const resource = entityMap[range.key]?.data?.mention?.resource
      const uid = entityMap[range.key]?.data?.mention?.uid

      // FOR QUESTIONS
      if (resource === 'Q') {

        const findQuestion = fields.find(f => f.id === uid)

        if (findQuestion) {
          updatedEntityMap[range.key] = _.clone(entityMap[range.key])
          const updatedValue = '@' + wrapText(convertFromRaw(findQuestion?.nameDraftContent).getPlainText('\u0001'))
          const lengthDiff = range.length - updatedValue.length

          const results = {
            ...range,
            offset: range.offset - removeToLeftBy,
            length: updatedValue.length
          }

          removeToLeftBy += lengthDiff

          updatedText = swapText(updatedText, offset, range.length, updatedValue)

          return results
        } else {
          updatedText = cutText(updatedText, offset, range.length)
          removeToLeftBy += range.length
          return null
        }
      }

      // FOR NUMBERS
      if (resource === 'NUM') {
        const findVariable = [...numberVariables, {
          id: 'score',
          name: 'score',
          label: 'score'
        }].find(f => f.id === uid)
        if (findVariable) {
          updatedEntityMap[range.key] = _.clone(entityMap[range.key])
          const updatedValue = '@' + wrapText(findVariable.name)
          const lengthDiff = range.length - updatedValue.length
          const results = {
            ...range,
            offset: range.offset - removeToLeftBy,
            length: updatedValue.length
          }
          removeToLeftBy += lengthDiff
          updatedText = swapText(updatedText, offset, range.length, updatedValue)
          return results
        } else {
          updatedText = cutText(updatedText, offset, range.length)
          removeToLeftBy += range.length
          return null
        }
      }

      // FOR STRINGS
      if (resource === 'STR') {
        const findVariable = stringVariables.find(f => f.id === uid)
        if (findVariable) {
          updatedEntityMap[range.key] = _.clone(entityMap[range.key])
          const updatedValue = '@' + wrapText(findVariable.name)
          const lengthDiff = range.length - updatedValue.length
          const results = {
            ...range,
            offset: range.offset - removeToLeftBy,
            length: updatedValue.length
          }
          removeToLeftBy += lengthDiff
          updatedText = swapText(updatedText, offset, range.length, updatedValue)
          return results
        } else {
          updatedText = cutText(updatedText, offset, range.length)
          removeToLeftBy += range.length
          return null
        }
      }

      // FOR HIDDEN
      if (resource === 'HIDDEN') {
        const findVariable = hiddenVariables.find(f => f.id === uid)
        if (findVariable) {
          updatedEntityMap[range.key] = _.clone(entityMap[range.key])
          const updatedValue = '@' + wrapText(findVariable.name)
          const lengthDiff = range.length - updatedValue.length
          const results = {
            ...range,
            offset: range.offset - removeToLeftBy,
            length: updatedValue.length
          }
          removeToLeftBy += lengthDiff
          updatedText = swapText(updatedText, offset, range.length, updatedValue)
          return results
        } else {
          updatedText = cutText(updatedText, offset, range.length)
          removeToLeftBy += range.length
          return null
        }
      }

      return range
    }).filter(r => !!r)


    return {
      ...block,
      entityRanges: updatedEntityRanges,
      text: updatedText
    }
  })


  return {
    blocks: updatedBlocks,
    entityMap: updatedEntityMap
  }
}

const cutText = (text, offset, length) => {
  return text.slice(0, offset) + text.slice(offset + length, text.length)
}

const swapText = (text, offset, length, newText) => {
  return text.slice(0, offset) + newText + text.slice(offset + length, text.length)
}

const wrapText = (text) => {
  if (text.length > MAX_MENTION_TEXT_LENGTH) {
    return text.slice(0, 117) + '...'
  }
  return text
}
