import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Center,
  HStack
} from '@chakra-ui/react'
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import {Space} from "antd";

const CustomModal = (props) => {

  const {onClose, okButtonProps, onOk, footerExtraContent, children, size, title, showFooter = true, isCentered = true} = props

  return (
    <Modal
      isCentered={isCentered}

      {...props}
    >
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody
          bg={'rgb(250, 250, 250)'}
        >
          {children}
        </ModalBody>
        <ModalFooter>
          {showFooter && <>
            <Box
              w={'100%'}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box>
                {footerExtraContent}
              </Box>
              <Space>
                <SecondaryButton
                  size={'sm'}
                  colorScheme='blue'
                  mr={3}
                  onClick={onClose}
                >
                  Close
                </SecondaryButton>

                <PrimaryButton
                  size={'sm'}
                  variant='ghost'
                  onClick={onOk}
                  {...okButtonProps}
                >
                  {okButtonProps?.title || 'Save'}
                </PrimaryButton>

              </Space>
            </Box>
          </>}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;