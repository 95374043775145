import React, {useEffect, useRef} from 'react';
import styled from "styled-components"
import gsap from "gsap"
import {Box, Center, HStack} from '@chakra-ui/react'
import {useScrollPosition} from "../utils/useScrollPosition";
import {FacebookIcon} from "./icons";

const Container = styled(Box)`
  // position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  // bottom: 0;
  // left: 20px;
  z-index: 10;
`

const IconWrapper = styled.div`
  margin: 3px 0;
  cursor: pointer;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.2);
  }
`
const SectionDisplayContainer = styled.div`
  width: 30px;
  height: 80px;
  position: relative;
  overflow: hidden;
`
const SectionDisplayInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 400%;
  height: 100%;
  display: flex;
  transition: transform 0.5s;
  transform: ${({ position }) => {
  switch (position) {
    case "home":
      return "translateX(0)"
    case "about":
      return "translateX(25%)"
    case "projects":
      return "translateX(50%)"
    case "contact":
      return "translateX(75%)"
  }
}};
`
const ItemWrapper = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SectionTitle = styled.span`
  color: #cccccc;
  font-size: 12px;
  transform: rotate(90deg);
`
const IconsWrapper = styled.div``

const TopLineWrapper = styled.div`
  overflow-y: hidden;
  padding: 0 2px;
`
const TopLine = styled.span`
  display: block;
  position: relative;
  width: 1px;
  background: #cccccc;
  height: 210px;
  border-radius: 50px;
  transition: all 1s;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #cccccc;
    transform: ${({ position }) => {
  switch (position) {
    case "home":
      return "translateY(-75%)"
    case "about":
      return "translateY(-50%)"
    case "projects":
      return "translateY(-25%)"
    case "contact":
      return "translateY(0)"
  }
}};
    transition: transform 0.8s;
    border-radius: 50px;
  }
`
const BotLine = styled.span`
  display: block;
  position: relative;
  width: 1px;
  background: #cccccc;
  height: 50px;
  border-radius: 50px;
`

const SideNavigation = () => {
  // const scrollPosition = useScrollPosition()
  const topLineRef = useRef(null)
  const lineRef = useRef(null)
  const iconsRef = useRef(null)
  const sectionRef = useRef(null)

  useEffect(() => {
    const line = lineRef.current
    const topLine = topLineRef.current
    const icons = iconsRef.current
    const section = sectionRef.current

    const tl = gsap.timeline()

    tl.set([line, ...icons.children, topLine], { autoAlpha: 0 })
    tl.set(section, { x: "-=30", autoAlpha: 0 })

    tl.fromTo(
      line,
      { y: "+=300" },
      { y: "10", autoAlpha: 1, duration: 0.6, delay: "1" }
    )
      .to(section, 0.4, { x: "0", autoAlpha: 1 })
      .staggerTo([...icons.children, topLine], 1, { autoAlpha: 1 }, 0.2)
  }, [])


  return (
    <Container>
      <Box
      mb={'25px'}
      >
        <HamburgerIcon/>
      </Box>
      <TopLineWrapper ref={topLineRef}>
        <TopLine  />
      </TopLineWrapper>
      <SectionDisplayContainer ref={sectionRef}>
        <SectionDisplayInner >
          <ItemWrapper>
            <SectionTitle>CONTACT</SectionTitle>
          </ItemWrapper>
          <ItemWrapper>
            <SectionTitle>PROJECTS</SectionTitle>
          </ItemWrapper>
          <ItemWrapper>
            <SectionTitle>ABOUT</SectionTitle>
          </ItemWrapper>
          <ItemWrapper>
            <SectionTitle>HOME</SectionTitle>
          </ItemWrapper>
        </SectionDisplayInner>
      </SectionDisplayContainer>
      <IconsWrapper ref={iconsRef}>
        <IconWrapper>
          <a href='/' target="_blank">
            {FacebookIcon}
          </a>
        </IconWrapper>
        <IconWrapper>
          <a href={'/'} target="_blank">
            {FacebookIcon}

          </a>
        </IconWrapper>
        <IconWrapper>
          <a href={'/'} target="_blank">
            {FacebookIcon}

          </a>
        </IconWrapper>
      </IconsWrapper>

      <BotLine ref={lineRef} />
    </Container>
  )
}

const HamburgerIcon = () => {
  return <Box>
    <Box
      h={'5px'}
      w={'40px'}
      bg={'#27272E'}
    >

    </Box> <Box
      h={'5px'}
      w={'40px'}
      // bg={'#cccccc'}
    >

    </Box> <Box
      h={'5px'}
      w={'40px'}
      bg={'#27272E'}
    >

    </Box>
  </Box>
}
export default SideNavigation;