import { BankFilled, CalendarOutlined, EditFilled, FolderOpenOutlined, InfoCircleOutlined, LinkOutlined, MailFilled, NumberOutlined, PhoneFilled, ProfileOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons/lib/icons";

import ContactFormSettings from "../fieldTypesSettings/ContactForm/ContactFormSettings";
import DateFieldSettings from "../fieldTypesSettings/DateField/DateFieldSettings";
import EmailSettings from "../fieldTypesSettings/Email/EmailSettings";
import EndingFieldSettings from "../fieldTypesSettings/EndingField/EndingFieldSettings";
import FileUploadSettings from "../fieldTypesSettings/FileUpload/FileUploadSettings";
import InfoFieldSettings from "../fieldTypesSettings/InfoField/InfoFieldSettings";
import LegalFieldSettings from "../fieldTypesSettings/LegalField/LegalFieldSettings";
import MultiChoiceSettings from "../fieldTypesSettings/MultiChoice/MultiChoiceSettings";
import NumberFieldSettings from "../fieldTypesSettings/NumberField/NumberFieldSettings";
import OpinionScaleSettings from "../fieldTypesSettings/OpinionScale/OpinionScaleSettings";
import PhoneFieldSettings from "../fieldTypesSettings/PhoneField/PhoneFieldSettings";
import PictureChoiceSettings from "../fieldTypesSettings/PictureChoice/PictureChoiceSettings";
import RatingSettings from "../fieldTypesSettings/Rating/RatingSettings";
import SelectFieldSettings from "../fieldTypesSettings/SelectField/SelectFieldSettings";
import SignatureSettings from "../fieldTypesSettings/Signature/SignatureSettings";
import SingleChoiceSettings from "../fieldTypesSettings/SingleChoice/SingleChoiceSettings";
import TextAreaSettings from "../fieldTypesSettings/TextArea/TextAreaSettings";
import WebsiteSettings from "../fieldTypesSettings/Website/WebsiteSettings";
import WelcomeScreenSetings from "../fieldTypesSettings/WelcomeScreen/WelcomeScreenSetings";
import YesNoFieldSettings from "../fieldTypesSettings/YesNoField/YesNoFieldSettings";
import ContactForm from "./ContactForm/ContactForm";
import DateField from "./DateField/DateField";
import Email from "./Email/Email";
import EndingField from "./EndingField/EndingField";
import FileUpload from "./FileUpload/FileUpload";
import {
  dropdownIcon,
  endingIcon,
  IconLabel,
  multiChoiceIcon, opinionScaleIcon,
  pictureChoiceFieldIcon, ratingIcon,
  singleChoiceFieldIcon,
  textAreaIcon,
  textFieldIcon, welcomeIcon, yesNoIcon
} from "./icons";
import InfoField from "./InfoField/InfoField";
import LegalField from "./LegalField/LegalField";
import MultiChoice from "./MultiChoice/MultiChoice";
import NumberField from "./NumberField/NumberField";
import OpinionScale from "./OpinionScale/OpinionScale";
import PhoneField from "./PhoneField/PhoneField";
import PictureChoice from "./PictureChoice/PictureChoice";
import Rating from "./Rating/Rating";
import SelectField from "./SelectField/SelectField";
import Signature from "./Signature/Signature";
import SingleChoice from "./SingleChoice/SingleChoice";
import TextArea from "./TextArea/TextArea";
import TextField from "./TextField/TextField";
import WebsiteField from "./WebsiteField/WebsiteField";
import WelcomeScreen from "./WelcomeScreen";
import YesNoField from "./YesNoField/YesNoField";

export const InputFontSizes = {
  lg: '30px',
  md: '30px',
  sm: '22px',
}

export const ChoiceFontSizes = {
  lg: '20px',
  md: '20px',
  sm: '16px',
}
export const QuestionFontSizes = {
  lg: '36px',
  md: '24px',
  sm: '16px',
}

export const QuestionPlaceholderFontSizes = {
  lg: '24px',
  md: '24px',
  sm: '16px',
}
export const QuestionPlaceholderTop = {
  lg: '10px',
  md: '0px',
  sm: '0px',
}
export const QuestionFontSizesMention = {
  lg: '30px',
  md: '18px',
  sm: '12px',
}
export const DescriptionFontSizes = {
  lg: '20px',
  md: '20px',
  sm: '14px',
}
export const DescriptionFontSizesMention = {
  lg: '18px',
  md: '18px',
  sm: '13px',
}

export const fieldTypes = {
  multiChoice: {
    type: 'multiChoice',
    label: 'Multi Choice',
    icon: IconLabel('#D65C99', multiChoiceIcon),
    backgroundColor: '#D65C99',
    component: MultiChoice,
    settingsComponent: MultiChoiceSettings,
  },
  select: {
    type: 'select',
    label: 'Dropdown',
    icon: IconLabel('#D65C99', dropdownIcon),
    backgroundColor: '#D65C99',
    component: SelectField,
    settingsComponent: SelectFieldSettings,
  },
  singleChoice: {
    type: 'singleChoice',
    label: 'Single Choice',
    icon: IconLabel('#D65C99', singleChoiceFieldIcon),
    backgroundColor: '#D65C99',
    component: SingleChoice,
    settingsComponent: SingleChoiceSettings,
  },
  pictureChoice: {
    type: 'pictureChoice',
    label: 'Picture Choice',
    icon: IconLabel('#D65C99', pictureChoiceFieldIcon),
    backgroundColor: '#D65C99',
    component: PictureChoice,
    settingsComponent: PictureChoiceSettings,
  },
  yesNo: {
    type: 'yesNo',
    label: 'Yes/No',
    icon: IconLabel('#D65C99', yesNoIcon),
    backgroundColor: '#D65C99',
    component: YesNoField,
    settingsComponent: YesNoFieldSettings,
  },
  legal: {
    type: 'legal',
    label: 'Legal',
    icon: IconLabel('#D65C99', <BankFilled/>),
    backgroundColor: '#D65C99',
    component: LegalField,
    settingsComponent: LegalFieldSettings,
  },
  opinionScale: {
    type: 'opinionScale',
    label: 'Opinion Scale',
    icon: IconLabel('#D65C99', opinionScaleIcon),
    backgroundColor: '#D65C99',
    component: OpinionScale,
    settingsComponent: OpinionScaleSettings,
  },
  rating: {
    type: 'rating',
    label: 'Rating',
    icon: IconLabel('#D65C99', ratingIcon),
    backgroundColor: '#D65C99',
    component: Rating,
    settingsComponent: RatingSettings,
  },
  textfield: {
    type: 'textfield',
    label: 'Short text',
    icon: IconLabel('#D25e39', textFieldIcon),
    backgroundColor: '#D25e39',
    component: TextField,
    settingsComponent: TextAreaSettings,
  },
  textarea: {
    type: 'textarea',
    label: 'Long text',
    icon: IconLabel('#D25e39', textAreaIcon),
    backgroundColor: '#D25e39',
    component: TextArea,
    settingsComponent: TextAreaSettings,
  },
  contactForm: {
    type: 'contactForm',
    label: 'Contact form',
    icon: IconLabel('#225e39', <ProfileOutlined/>),
    backgroundColor: '#225e39',
    component: ContactForm,
    settingsComponent: ContactFormSettings,
  },
  signature: {
    type: 'signature',
    label: 'Signature',
    icon: IconLabel('#225e39', <EditFilled/>),
    backgroundColor: '#225e39',
    component: Signature,
    settingsComponent: SignatureSettings,
  },
  datefield: {
    type: 'datefield',
    label: 'Date',
    icon: IconLabel('#225e39', <CalendarOutlined/>),
    backgroundColor: '#225e39',
    component: DateField,
    settingsComponent: DateFieldSettings,
  },
  fileUpload: {
    type: 'fileUpload',
    label: 'File Upload',
    icon: IconLabel('#225e39', <FolderOpenOutlined/>),
    backgroundColor: '#225e39',
    component: FileUpload,
    settingsComponent: FileUploadSettings,
  },
  numberfield: {
    type: 'numberfield',
    label: 'Number',
    icon: IconLabel('#225e39', <NumberOutlined/>),
    backgroundColor: '#225e39',
    component: NumberField,
    settingsComponent: NumberFieldSettings,
  },
  infofield: {
    type: 'infofield',
    label: 'Info',
    icon: IconLabel('#225e39', <InfoCircleOutlined/>),
    backgroundColor: '#225e39',
    component: InfoField,
    settingsComponent: InfoFieldSettings,
  },
  email: {
    type: 'email',
    label: 'Email',
    icon: IconLabel('#225e39', <MailFilled/>),
    backgroundColor: '#225e39',
    component: Email,
    settingsComponent: EmailSettings,
  },
  website: {
    type: 'website',
    label: 'Website',
    icon: IconLabel('#225e39', <LinkOutlined/>),
    backgroundColor: '#225e39',
    component: WebsiteField,
    settingsComponent: WebsiteSettings,
  },
  phone: {
    type: 'phone',
    label: 'Phone',
    icon: IconLabel('#225e39', <PhoneFilled/>),
    backgroundColor: '#225e39',
    component: PhoneField,
    settingsComponent: PhoneFieldSettings,
  },
  welcomeScreen: {
    type: 'welcomeScreen',
    label: 'Welcome screen',
    icon: IconLabel('#22527d', welcomeIcon),
    backgroundColor: '#22527d',
    component: WelcomeScreen,
    settingsComponent: WelcomeScreenSetings,
  },
  endingField: {
    type: 'endingField',
    label: 'Ending screen',
    icon: IconLabel('#a266f1', endingIcon),
    backgroundColor: '#a266f1',
    component: EndingField,
    settingsComponent: EndingFieldSettings,
  }
}

export const initialDraftContent = {
  "blocks": [
    {
      "key": "6rc9v",
      "text": "",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
}