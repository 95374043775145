import React from 'react';
import {Box, HStack, VStack} from "@chakra-ui/react";
import {EyeOutlined} from "@ant-design/icons";
import colors from "../../../../utils/commonStyles";
import {Span} from './FieldAnalyticsItem'

const Card = ({children, ...rest}) => {
  return (
    <Box
      borderRadius={'6px'}
      border={'1px solid #f2f2f2'}
      p={'10px'}
      {...rest}
    >
      {children}
    </Box>
  );
};


export const XAxis = ({percent, label, responsesCount}) => {
  return (
    <Box mb={'15px'}>
      <HStack mb={'5px'}>
        <Box flex={1}>
          {label}
        </Box>
        <HStack>
          <Span mr={'15px'}>
            {responsesCount} resp.
          </Span>
          <Box>
            {percent}%
          </Box>
        </HStack>
      </HStack>

      <Box
        h={'20px'}
        borderRadius={'4px'}
        bg={'#f2f2f2'}
        overflow={'hidden'}
      >
        <Box
          h={'100%'}
          w={`${percent}%`}
          bg={'#00b3b3'}
        />

      </Box>
    </Box>
  );
};


export const YAxis = ({percent, label, responsesCount}) => {
  return (
    <Box>
      <Box d={'flex'} flexDirection={'column'} align={'center'}>

        <Box>
          {percent}%
        </Box>

        <Box d={'flex'} flexDirection={'column'} align={'center'}>
          <Span>
            {responsesCount}
          </Span>
          <Span>
            resp.
          </Span>
        </Box>

      </Box>

      <Box
        h={'150px'}
        borderRadius={'4px'}
        bg={'#f2f2f2'}
        w={'100%'}
        overflow={'hidden'}
      >
        <Box
          w={'100%'}
          h={`${100 - percent}%`}
        />
        <Box
          w={'100%'}
          h={`${percent}%`}
          bg={'#00b3b3'}
        />

      </Box>

      <VStack mt={'10px'}>
        <Box>
          {label}
        </Box>
      </VStack>

    </Box>
  );
};

