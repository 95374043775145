import React from 'react';
import ToolsSection from "../../../shared/ToolsSection";
import {Box, Button, HStack, IconButton, Grid} from "@chakra-ui/react";
import SectionHeader from "../../../shared/SectionHeader";
import {CloseIcon} from "@chakra-ui/icons";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import LayoutItem from "./LayoutItem";
import colors from "../../../utils/commonStyles";
import {setColorOpacity} from "../fieldTypes/MultiChoice/MultiChoice";

const availableLayouts = [
  {
    id: 1
  }, {
    id: 7
  },{
    id: 2
  }, {
    id: 3
  }, {
    id: 4
  }, {
    id: 5
  }, {
    id: 6
  }
]

const RightToolsBarLayoutSettings = () => {

  const [
    activeField,
    updateActiveField,
  ] = useFormConfiguratorStore(state => [
    state.activeField,
    state.updateActiveField,
  ])


  const onChangeLayout = (id) => {
    updateActiveField({
      layoutConfig: {
        ...activeField.layoutConfig,
        layoutId: id
      }
    })
  }

  const imageUrl = activeField?.layoutConfig?.imageUrl
  const videoUrl = activeField?.layoutConfig?.videoUrl

  if (!imageUrl && !videoUrl) return null

  const enabledLayoutIds = videoUrl ? [1, 2, 3, 7] : [1, 2, 3, 4, 5, 6, 7]

  return (
    <ToolsSection>
      <Box
        mb={'5px'}
      >
        <SectionHeader>
          Layout
        </SectionHeader>
      </Box>
      <Box>
        <Grid
          templateColumns={[
            'repeat(3, 1fr)',
          ]}
          justifyContent={"center"}
          gap={2}
        >
          {availableLayouts.map((l) => (
            <LayoutItem
              layoutId={l.id}
              disabled={!enabledLayoutIds.includes(l.id)}
              _color={l.id === activeField?.layoutConfig?.layoutId ? setColorOpacity(colors.primary, 0.97) : colors.border}
              onClick={() => onChangeLayout(l.id)}
            />
          ))}
        </Grid>

      </Box>
    </ToolsSection>
  );
};

export default RightToolsBarLayoutSettings;