import {MenuButton} from "@chakra-ui/react";
import React from "react";

const colors = {
  primaryLinear: 'linear-gradient(18deg, rgba(71,95,252,1) 0%, rgba(71,95,252,1) 45%, rgba(71,193,252,1) 92%)',
  primaryLinearReverse: 'linear-gradient(18deg, rgba(71,193,252,1) 0%, rgba(71,95,252,1) 75%, rgba(71,95,252,1) 92%)',
  bg: '#F2F3F7',
  bgDark: '#d0d3e2',
  primary: '#3366FF',
  lightPrimary: '#99b3ff',
  mentionBg: '#e6ecff',
  border: '#bbbbbb',
  lightGrey: 'rgb(250, 250, 250)',
  darkGrey: 'rgb(115, 115, 115)',
  black: '#25292D',

  inputBg: 'white',
  inputBorder: `1px solid #bbbbbb`,
  shadowColor: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
}

export const defaultButtonProps = {
  cursor: 'pointer',
  p: '5px 9px',
  borderRadius: '6px'
}


export default colors