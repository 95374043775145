import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import './index.less'
import './output.css'
import './imports.no-module.less'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {extendTheme, ChakraProvider} from "@chakra-ui/react"
import colors from './utils/commonStyles'
import configureStore from './store'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/es/integration/react'
import setupInterceptors from "./utils/setupInterceptors";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {QueryClient, QueryClientProvider} from 'react-query';

export const {persistor, store} = configureStore()

const chakraColors = {
  blue: {
    50: '#3366FF',
    100: '#3366FF',
    200: '#3366FF',
    300: '#3366FF',
    400: '#3366FF',
    500: '#3366FF',
    600: '#3366FF',
    700: '#3366FF',
    800: '#3366FF',
    900: '#3366FF',
  },
  brand: {
    50: '#f2ffde',
    100: '#defcb2',
    200: '#caf884',
    300: '#b5f554',
    400: '#a1f226',
    500: '#88d90d',
    600: '#69a905',
    700: '#4a7801',
    800: '#2b4800',
    900: '#0b1900',
  },
  gray: {
    500: '#8c8c8c'
  },
  teal: {
    500: colors.primaryLinearReverse
  }
}

const theme = extendTheme({colors: chakraColors})

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      // clientId={process.env.GOOGLE_AUTH_CLIENT_ID}
      clientId={'641976323143-8uk0v03itv54gvudga3n9011glb7tqr7.apps.googleusercontent.com'}
    >
      <Provider store={store}>
        <PersistGate loading={'Loading...'} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
          <BrowserRouter>

            <ChakraProvider theme={theme}>
              <App/>
            </ChakraProvider>

          </BrowserRouter>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

setupInterceptors(store);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
