import React from 'react';
import { Box, HStack, Wrap, Center } from "@chakra-ui/react";
import { fieldTypes } from "../../fieldTypes/config";
import { TextEllipsis } from "../FieldsList";
import colors from "../../../../utils/commonStyles";
import moment from 'moment'
import SecondaryButton from "../../../../shared/SecondaryButton";
import { EyeOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { ToolsCell } from "./ResponsesTable";

const Tag = ({ children, wrapText, imageUrl }) => <HStack
  p={'3px 6px'}
  mr={'4px'}
  bg={colors.bg}
  borderRadius={'8px'}
  // border={'1px solid #dddddd'}
  fontSize={'13px'}
>
  {imageUrl && <img
    src={imageUrl}
    style={{
      width: '22px',
      height: '22px',
      objectFit: 'cover'
    }}
  />}
  <Box whiteSpace={!wrapText && 'nowrap'}>
    {children}
  </Box>

</HStack>
//createdAt

export const SingleAnswerPreview = ({ type, value, expanded }) => {
  let content = ''

  if (['multiChoice', 'pictureChoice'].includes(type)) {
    if (Array.isArray(value)) {
      const Container = expanded ? Wrap : HStack
      content = <Container>
        {value?.map(e => <Tag wrapText={expanded} imageUrl={e.imageUrl}>{e.label || '---'}</Tag>)}
      </Container>
    }
  } else if (['singleChoice', 'yesNo', 'legal', 'select'].includes(type)) {
    const Container = expanded ? Wrap : HStack
    content = <Container>
      <Tag wrapText={expanded}>{value || '---'}</Tag>
    </Container>
  } else if (['fileUpload', 'signature'].includes(type)) {
    const Container = expanded ? Wrap : HStack
    content = <Container>
      <Tag wrapText={expanded}>{<a target='_blank' href={value}>{value}</a> || '---'}</Tag>
    </Container>
  } else {
    content = <Box>{value}</Box>
  }

  return <Box
    // width={'max-content'}
    // maxW={'300px'}
    w={'100%'}
  >
    <TextEllipsis linesLength={expanded ? 999 : 2}>
      {content}
    </TextEllipsis>
  </Box>
}

export const renderColumns = (columns, config = {}) => {

  const toolsColumn = {
    title: 'Tools',
    key: 'tools',
    width: '50px',
    fixed: 'left',
    render: (_, record) => <ToolsCell record={record} />
  }

  const createdAtColumn = {
    title: 'Date',
    key: 'createdAt',
    width: '140px',
    render: (_, record) => <Box>
      {moment(record.createdAt).format('DD MMM YYYY')}
      <Box fontSize={'13px'} color={'rgb(115, 115, 115)'}>
        {moment(record.createdAt).format('HH:mm')}
      </Box>
    </Box>
  }

  let results = [toolsColumn, createdAtColumn]

  let renderedColumns = columns.map(c => {

    const { expanded } = config

    const IconComponent = fieldTypes?.[c.type]?.icon

    return {
      key: c.id,
      width: '208px',
      title: <HStack>
        {IconComponent && <Box mr={'5px'}>
          <IconComponent />
        </Box>}

        {!IconComponent && <Center p={'2px'} w={'25px'} h={'25px'} borderRadius={'4px'} bg={'rgba(51,102,255,0.1)'} border={`1px solid ${colors.primary}`} >
          @
        </Center>}

        <Box
          // display={'inline-block'}
          // w={'100%'}
          minW={'50px'}
          width={'max-content'}
          maxW={'300px'}
          fontWeight={400}
        >
          <TextEllipsis linesLength={2}>
            {c.title || '...'}
          </TextEllipsis>
        </Box>
      </HStack>,
      render: (_, record) => {
        return <SingleAnswerPreview
          value={record?.[c.id]}
          type={c.type}
          expanded={expanded}
        />
      }
    }
  })

  return results.concat(renderedColumns)
}
