import React, {useEffect, useState} from 'react';

const useDebouncedEffect = (effect, deps = [], delay, activeAfter) => {

  const [allowEffect, setAllowEffect] = useState(!!!activeAfter)

  if (activeAfter) {
    setTimeout(() => setAllowEffect(true), activeAfter)
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if (allowEffect) {
        effect && effect()
      }
    }, delay);

    return () => clearTimeout(handler);
  }, [...deps]);
}

export default useDebouncedEffect;