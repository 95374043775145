import CustomModal from "../../../../shared/CustomModal";
import { Box, HStack, Center, VStack } from "@chakra-ui/react";
import openNotification from "../../../../shared/openNotification";
import { modalStore } from "../../../../utils/common";

export const useGetCodeModalStore = modalStore()

const GetCodeModal = ({ }) => {

    const [isOpen, onClose, data = {}] = useGetCodeModalStore(s => ([s.isOpen, s.onClose, s.data]))

    const { htmlText } = data


    return <CustomModal
        title={'Get your Embed code'}
        isOpen={isOpen}
        onClose={onClose}
        onOk={() => {
            navigator.clipboard.writeText(htmlText);
            openNotification({ description: 'Copied !' })
        }}
        okButtonProps={{
            title: 'Copy'
        }}
        size={'xl'}
    >
        <Box p={'20px 0'}>

            <Box mb={'10px'}>
            Paste this code in your html, for this form to appere in your website.
            </Box>

            <Box >
                <Box
                p={'10px'}
                borderRadius={'6px'}
                    bg={'black'}
                    className="rounded-md p-4 text-white text-sm font-normal">
                    {htmlText}
                </Box>
            </Box>

        </Box>


    </CustomModal>
}

export default GetCodeModal