import { MoreOutlined, EditOutlined } from "@ant-design/icons";
import { Box, Button } from "@chakra-ui/react";
import { Dropdown, Space } from "antd";
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useUserState } from "../../../App";
import useFormConfiguratorStore from "../../FormConfigurator/stores/useFormConfiguratorStore";
import useDuplicateQuery from "../hooks/useDuplicateQuery";
import useUserPermissions from "../hooks/useUserPermissions";
import { useDeleteFormModalStore } from "./ConfirmFormDeleteModal";
import { DropDownMenu } from "./CustomDropdown";
import { useMoveFormModalStore } from "./FormMoveToModal";
import { useRenameFormModalStore } from "./RenameFormModal";


const FormAvatar = ({theme = {}}) => {
  const {backgroundColor, imageUrl} = theme || {}
  return <Box
    borderRadius={'6px'}
    bg={backgroundColor}
    w={'40px'}
    h={'40px'}
    overflow={'hidden'}
    // boxShadow={`inset 1px 1px 3px ${colorLuminance(backgroundColor, diff * -1)}, inset -2px -2px 4px ${colorLuminance(backgroundColor, diff)}`}
    border={'1px solid #808080'}
    // borderColor={pSBC(null, backgroundColor)}
  >
    {imageUrl && <img
      src={imageUrl}
      style={{
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      }}
    />}

  </Box>
}

const CardItem = ({title, description}) => {

  return <Box
    d={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    mr={'20px'}
  >

    <Box
      fontWeight={700}
    >
      {title}
    </Box>
    <Box
      fontWeight={400}
      fontSize={'12px'}
    >
      {description}
    </Box>
  </Box>
}

const MoreOptions = ({form, fetchForms, resetStore}) => {

  const {workspaceId} = useParams()
  
  const [
    workspaces,
  ] = useUserState(store => ([
    store.workspaces,
  ]))

  const [onOpenRenameFormModal] = useRenameFormModalStore(store => [store.onOpen])

  const [onOpenMoveFormModal] = useMoveFormModalStore(store => [store.onOpen])

  const [onOpenDeleteModal] = useDeleteFormModalStore(store => [store.onOpen])

  const {id, name, createdAt, fieldsCount, responsesCount} = form

  const {duplicateFormHandler, loading: duplicating} = useDuplicateQuery()

  const history = useHistory()

  const onEdit = () => {
    console.log(`/form/${id}`)
    resetStore && resetStore()
    history.push(`/form/${id}`)
  }

  const onRename = () => {
    onOpenRenameFormModal({name, id})
  }

  const onDuplicate = async () => {
    resetStore && resetStore()
    
    await duplicateFormHandler({id: form.id, workspaceId})
    fetchForms()
  }
  const onMoveTo = async () => {
    onOpenMoveFormModal({id: form.id})
  }

  const onDelete = async () => {
    onOpenDeleteModal({id: form.id})
  }

  const allowEditForm = useUserPermissions(['form_update'])
  const allowDeleteForm = useUserPermissions(['form_delete'])

  const allowMoveTo = allowEditForm && (workspaces.length > 1)

  const moreOptions = [
    {
      label: 'Edit',
      value: 'edit',
      onClick: onEdit,
      visible: allowEditForm
    },
    {
      label: 'Rename',
      value: 'rename',
      onClick: onRename,
      visible: allowEditForm
    }, {
      label: 'Duplicate',
      value: 'duplicate',
      onClick: onDuplicate,
      visible: allowEditForm
    }, {
      label: 'Move to',
      value: 'move_to',
      onClick: onMoveTo,
      visible: allowMoveTo
    }, {
      label: 'Delete',
      value: 'delete',
      onClick: onDelete,
      visible: allowDeleteForm
    },
  ]

  const moreOptionsMenu = <DropDownMenu
    options={moreOptions}
  />

  return <Dropdown
    overlay={moreOptionsMenu}
    trigger={'click'}
    placement="bottomRight"
  >
    <Box
      borderRadius={'6px'}
      _hover={{bg: 'white'}}
    >
      <Button
        isLoading={duplicating}
        type={'link'}
        style={{background: 'transparent'}}
      >
        <MoreOutlined style={{fontSize: '24px'}}/>
      </Button>
    </Box>

  </Dropdown>
}


const FormListCard = ({form = {}, fetchForms}) => {

  const [
   resetStore
  ] = useFormConfiguratorStore(state => [
    state.resetStore,
  ])

const history = useHistory()
  let {theme = {}, name, createdAt, fieldsCount, statistics = {}, isPublished, publishedFormData} = form

  if(isPublished){
    const parsedData = JSON.parse(publishedFormData) || {}
    theme = parsedData.theme 
  }
  

  const responsesCount = statistics.submitted || 0

  return (
    <Box
      p={'16px'}
      borderBottom={'1px solid #f2f2f2'}
      borderRadius={'8px'}
      bg={'white'}
      // bg={'hsl(228, 24%, 96%)'}
      d={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      mx={'15px'}
      transition={'0.3s'}

    >
      <Space>
        <FormAvatar theme={theme}/>
        <Box ml={'10px'}>
          <Box fontSize={'16px'} fontWeight={300}>
            {name}
          </Box>
          <Box fontSize={'11px'}>
            Created: {moment(createdAt).format('Do MMM YYYY')}
          </Box>
        </Box>

      </Space>

      <Space>

        {isPublished && <>
          <CardItem title={fieldsCount} description={'Questions'}/>
          <CardItem title={responsesCount} description={'Responses'}/>

          <Box border='1px solid green' bg={'#ccffdd'} p={'3px 10px'} borderRadius={'14px'} fontSize={'10px'}>
            Published
          </Box>
          {/* <CardItem title={<CheckCircleOutlined style={{color: 'green'}}  />} description={'Published'}/> */}
        </>}

        {!isPublished && <Box border='1px solid red' bg={'#ffe6e6'} p={'3px 10px'} borderRadius={'14px'} fontSize={'10px'}>
            Not Published
          </Box>}

        <Box
          px={'0px'}
        >
          <MoreOptions
          resetStore={resetStore}
            form={form}
            fetchForms={fetchForms}
          />
        </Box>

        <Box cursor={'pointer'} onClick={() => {
          resetStore()
           history.push(`/form/${form.id}`)
        }}>
          <EditOutlined/>
        </Box>

      </Space>


    </Box>
  );
};

export default FormListCard;