import {Box, Divider, HStack} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import {Space} from "antd";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import React, {useEffect, useState} from "react";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import SectionHeader from "../../../../shared/SectionHeader";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import {countryPhones} from "../../../../utils/countryPhones";

const Configurator = ({data = {}, onChange}) => {

  const {required, country = {}} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>

    <Box py={'10px'} mb={'10px'}>
      <StyledSelect
        showSearch={true}
        value={country?.code}
        onChange={e => {
          const findCountry  = countryPhones.find(f => f.code === e)
          onChangeHandler('country', findCountry)
        }}
        style={{width: '100%'}}
      >
        {countryPhones.map(c => <StyledSelect.Option value={c.code}>
          <Space
            display={'flex'}
            flexDirection={'row'}
          >
            <Box
              w={'25px'}
            >
              <img src={`https://flagcdn.com/h24/${c.code.toLowerCase()}.png`} alt={`${c.name} flag`}/>
            </Box>
            <Box>
              {c.name}
            </Box>
          </Space>
        </StyledSelect.Option>)}
      </StyledSelect>
    </Box>
  </Box>
}

const PhoneFieldSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({...data})
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onGeneralSettingsChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};


export default PhoneFieldSettings;