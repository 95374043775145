import React, {useState} from 'react';
import {modalStore} from "../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import {createForm} from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Space, Spin} from "antd";
import {PicRightOutlined, PlusOutlined} from "@ant-design/icons";
import {startFromScratch, templateUse} from "../../../utils/illustrations";
import { useQueryClient } from 'react-query';

export const useAddFormModalStore = modalStore()

const AddFormModal = () => {
  const queryClient = useQueryClient()

  const {organizationId, workspaceId} = useParams()

  const history = useHistory()

  const [isOpen, onClose, data = {}] = useAddFormModalStore(store => [store.isOpen, store.onClose, store.data])

  const [creatingForm, setCreatingForm] = useState(false)

  const createFormHandler = async (values) => {
    setCreatingForm(true)
    try {

      const requestData = {
        workspaceId,
        organizationId
      }

      const {id} = await createForm(requestData)

      history.push(`/form/${id}`, {
        showNameModal: true
      })
    queryClient.invalidateQueries('organizations')

      onClose()
    } catch (e) {
      console.log(e)
    } finally {
      setCreatingForm(false)
    }
  }

  return <CustomModal
    title={'Create form'}
    zIndex={12}
    isOpen={isOpen}
    onClose={onClose}
    size={'3xl'}
    showFooter={false}
  >
    <Box
      d={'flex'}
      justifyContent={'center'}
      p={'30px 0 10px'}
    >

      {creatingForm && <Spin/>}

      {!creatingForm && <Space>
        <ChoiceCard
          onClick={() => {
            createFormHandler()
          }}
        >

          {/*<PlusOutlined style={{fontSize: '40px'}}/>*/}
          {startFromScratch}
          <Box
            fontSize={'18px'}
            mt={'15px'}
            fontWeight={300}
            color={'black'}
          >
            Start from scratch
          </Box>

        </ChoiceCard>

        <ChoiceCard
          onClick={() => {
            history.push('/templates')
            onClose()

          }}
        >
          {/*<PicRightOutlined*/}
          {/*  style={{fontSize: '40px'}}*/}
          {/*/>*/}

          {templateUse}
          <Box
            fontSize={'18px'}
            mt={'15px'}
            fontWeight={300}
            color={'black'}

          >
            Use a template
          </Box>

        </ChoiceCard>

      </Space>
      }

      {/*<PrimaryButton*/}
      {/**/}
      {/*>*/}
      {/*  Blank*/}
      {/*</PrimaryButton>*/}

    </Box>
  </CustomModal>
};

const ChoiceCard = ({children, onClick}) => {
  return <Box
    onClick={onClick}
    p={'40px'}
    bg={'white'}
    mx={'5px'}
    borderRadius={'14px'}
    border={'1px solid #dddddd'}
    color={'#8c8c8c'}
    d={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    cursor={'pointer'}
  >
    {children}
  </Box>
}

export default AddFormModal;