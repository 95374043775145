import React from 'react';
import { Drawer, Space, Button, Tooltip } from "antd";
import { modalStore } from "../../../../utils/common";
import moment from "moment";
import { Box, HStack, Center } from "@chakra-ui/react";
import { TableButton } from "./ResponsesTable";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { fieldTypes } from "../../fieldTypes/config";
import { SingleAnswerPreview } from "./msic";
import { TextEllipsis } from "../FieldsList";
import colors from "../../../../utils/commonStyles";
import { useDeleteResponseModalStore } from "./DeleteResponseModal";

export const useResponseDrawerStore = modalStore()

const renderAnswers = (columns = [], values = {}) => columns.map(column => {

  const IconComponent = fieldTypes[column.type]?.icon


  return <Box mb={'20px'}>
    <HStack align={'flex-start'}>
      {IconComponent && <Box mr={'5px'}>
        <IconComponent />
      </Box>}

      {!IconComponent && <Center p={'2px'} w={'25px'} h={'25px'} borderRadius={'4px'} bg={'rgba(51,102,255,0.1)'} border={`1px solid ${colors.primary}`} >
        @
      </Center>}

      <Box>
        <Box color={colors.darkGrey} mb={'10px'}>
          <TextEllipsis linesLength={1}>
            {column.title || '...'}
          </TextEllipsis>
        </Box>

        <SingleAnswerPreview
          expanded={true}
          type={column.type}
          value={values[column.id]}
        />
      </Box>

    </HStack>

  </Box>
})

const ResponseDrawer = ({ columns = [] }) => {

  const [onOpen] = useDeleteResponseModalStore(store => [store.onOpen])

  const [isOpen, onClose, data = {}] = useResponseDrawerStore(store => [store.isOpen, store.onClose, store.data])

  const { createdAt, id, ...values } = data

  const onDeleteHandler = () => {
    onOpen({
      responseIds: [id]
    })
  }

  const ref = React.createRef();

  const title = <HStack>
    <Box pr={'10px'}>
      <Space>

        {/*<Pdf targetRef={ref} filename="answers.pdf">*/}
        {/*  {({ toPdf }) => <Tooltip title={'Print this response'}>*/}
        {/*    <TableButton onClick={toPdf}>*/}
        {/*      <DownloadOutlined/>*/}
        {/*    </TableButton>*/}
        {/*  </Tooltip>}*/}
        {/*</Pdf>*/}

        <Tooltip title={'Delete'}>
          <TableButton onClick={onDeleteHandler}>
            <DeleteOutlined />
          </TableButton>
        </Tooltip>

      </Space>
    </Box>

    <Box>
      {moment(createdAt).format('DD MMM YYYY')}
    </Box>

    <Box fontSize={'13px'} color={'rgb(115, 115, 115)'}>
      {moment(createdAt).format('HH:mm')}
    </Box>

  </HStack>

  return <Drawer
    title={title}
    width={550}
    onClose={onClose}
    visible={isOpen}
    extra={
      <Space>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      </Space>
    }
  >
    <Box ref={ref}>
      {renderAnswers(columns, values)}
    </Box>
  </Drawer>
};

export default ResponseDrawer;