import React, {useEffect, useRef, useState} from 'react';
import ConfiguratorHeader from "./ConfiguratorHeader";
import {Box, HStack, Button} from '@chakra-ui/react'
import styled from 'styled-components';
import useConfigurationContentDimensions from "../../../hooks/useConfigurationContentDimensions";
import FieldsList from "./FieldsList";
import Content from "./Content";
import ImageToolsModal from "./ImageToolsModal";
import RightToolsBar from "./RightToolsBar";
import CustomWysiwyg from "../../../shared/CustomWysiwyg";
import ImageConfigToolsModal from "./ImageConfigToolsModal";
import colors from "../../../utils/commonStyles";
import AppLoading from "../../../shared/AppLoading";

const MENU_WIDTH = '256px'


const MenuWrapper = styled(Box)`
  height: 100%;
  background: white;
  transition: all 0.3s;
  position: relative;
  margin: 0;
  width: ${MENU_WIDTH};

`;

const MenuInner = styled(Box)`
  height: 100%;
`;

const ContentContainer = styled(Box)`
  border: 1px solid #dddddd;
  border-radius: 8px;
  background: white;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const LeftMenuWrapper = ({children, onClick}) => {

  const [open, setOpen] = useState(true)


  return (
    <MenuWrapper
      w={open ? '256px' : '0px'}
      h={'100%'}

      borderRight={'1px solid #dddddd'}
    >
      <Box
        overflow={"hidden"}
        h={'100%'}

      >
        <MenuInner>
          {children}
        </MenuInner>
      </Box>

      {/*<Button*/}
      {/*  onClick={() => setOpen(!open)}*/}
      {/*  position={'absolute'}*/}
      {/*  right={'-50px'}*/}
      {/*  bottom={'10px'}*/}
      {/*  zIndex={9}*/}
      {/*>*/}
      {/*  {'<'}*/}
      {/*</Button>*/}
    </MenuWrapper>
  )
}

const RightMenuWrapper = ({children}) => {
  return (
    <MenuWrapper
      // w={'256px'}
      
      borderLeft={'1px solid #dddddd'}
      overflow={'auto'}
    >
      <MenuInner>
        {children}
      </MenuInner>
    </MenuWrapper>
  )
}

const ConfiguratorLayout = ({children, isLoading, rightSideBar, leftSideBar}) => {

  const sliderRootRef = useRef()

  const {width, height, scale} = useConfigurationContentDimensions(sliderRootRef)

  if (isLoading) return <AppLoading title={'Please wait we are loading your form...'}/>


  return (
    <>
      <ImageToolsModal/>
      {/* <ImageConfigToolsModal/> */}
      <Box
        h={'100vh'}
        w={'100%'}
        overflow={'hidden'}
        backgroundColor={colors.bg}

      >
        <Box
          display={'flex'}
          h={'calc(100% - 48px)'}
          w={'100%'}
        >
          <LeftMenuWrapper>
            {leftSideBar}
          </LeftMenuWrapper>
          <Box
            ref={sliderRootRef}
            margin={0}
            flex={1}
            px={['15px']}
          >
            <ContentContainer
              h={height}
              mt={'30px'}
            >
              {children}
            </ContentContainer>
          </Box>
          <RightMenuWrapper>
            {rightSideBar}
          </RightMenuWrapper>

        </Box>
      </Box>
    </>
  );
};

export default ConfiguratorLayout;