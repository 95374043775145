import React, {useState} from 'react';
import AddMemberModla, {useAddMemberModalStore} from "./AddMemberModla";
import {Box, Center, HStack} from "@chakra-ui/react";
import PrimaryButton from "../../../shared/PrimaryButton";
import {useUserState} from "../../../App";
import {useParams} from "react-router-dom";
import {Avatar, Space} from "antd";
import {ColorList} from "../../Home/components/OrganizationSelect";
import colors from "../../../utils/commonStyles";
import DeleteButton from "../../../shared/DeleteButton";
import UpdatePermissionModal, {useUpdatePermissionModalStore} from "./UpdatePermissionModal";
import RemoveUserModla, {useRemoveUserModlaStore} from "./RemoveUserModla";
import {useSelector} from "react-redux";

const UserCard = ({user = {}, onEditPermission,onRemove}) => {

  const {email = '', isOwner} = user

  const role = isOwner ? 'owner' : 'member'

  return <Box
    border={'1px solid #f2f2f2'}
    p={'10px 20px'}
    d={'flex'}
    alignItems={'center'}
    borderRadius={'8px'}
    bg={colors.bg}
    mb={'10px'}
  >

    <Avatar style={{backgroundColor: ColorList[3], verticalAlign: 'middle'}} size="large">
      {email[0]?.toUpperCase()}
    </Avatar>

    <Box
      ml={'10px'}
      flex={1}
      fontWeight={300}
      fontSize={'16px'}
    >
      {email}
    </Box>
    <Box
      ml={'10px'}
      fontWeight={600}
      fontSize={'12px'}
    >
      {role.toUpperCase()}
    </Box>

    {!isOwner && <Box
      ml={'20px'}
    >
      <Space>
        <Center
          onClick={onEditPermission}
          px={'10px'}
          h={'30px'}
          borderRadius={'6px'}
          cursor={'pointer'}
          bg={colors.bgDark}
        >
          Manage permission
        </Center>
        <DeleteButton
          onClick={onRemove}
          bg={colors.bgDark}
        />

      </Space>
    </Box>
    }

  </Box>
}
const Team = ({fetchOrganizations}) => {


  const [onOpen] = useAddMemberModalStore(store => [store.onOpen])
  const [onOpenPermissionModal] = useUpdatePermissionModalStore(store => [store.onOpen])
  const [onOpenRemoveUserModal] = useRemoveUserModlaStore(store => [store.onOpen])

  const {user} = useSelector(state => state.persistentState)

  const [

    organizations,
    currentOrganization
  ] = useUserState(store => ([
    store.organizations,
    store.currentOrganization,
  ]))

  const myOrganization = organizations?.find(o => o.owner?.id === user?.id)

  console.log(organizations, myOrganization)
  const users = myOrganization?.users || []

  const mapWithRoles = users.map(u => u.id === user?.id ? ({...u, isOwner: true}) : u)
  const permissions = currentOrganization?.permissions

  return (
    <>
      <AddMemberModla/>
      <UpdatePermissionModal
        fetchOrganizations={fetchOrganizations}
      />
      <RemoveUserModla
        fetchOrganizations={fetchOrganizations}
      />
      <Box
        d={'flex'}
        alignItems={'center'}
      >
        <Box
          flex={1}
          fontSize={'16px'}
          fontWeight={800}
          letterSpacing={'3px'}
        >
          MEMBERS
        </Box>
        <Box>
          <PrimaryButton
            size={'sm'}
            onClick={() => onOpen()}
          >
            Add team member
          </PrimaryButton>
        </Box>
      </Box>

      <Box
        mt={'20px'}
      >
        {mapWithRoles.map(u => <UserCard
          onRemove={() => onOpenRemoveUserModal({
            userId: u.id,
            email: u.email,
          })}
          onEditPermission={() => onOpenPermissionModal({
            permissions: u.permissions,
            userId: u.id
          })}
          user={u}
        />)}
      </Box>
    </>
  );
};

export default Team;