import React, { useState } from 'react';
import { Form } from "antd";
import InlineTextField, { StyledInput } from "../../shared/form/InlineTextField";
import { Box, HStack } from "@chakra-ui/react";
import PrimaryButton from "../../shared/PrimaryButton";
import { useUserState } from "../../App";
import { useSelector } from "react-redux";
import { getCheckoutSession, updateAccountSettings, updateBilling, useBillingQuery } from "../../firebase/form";
import { updateById } from "../../utils/common";
import Divider from "../FormConfigurator/components/Divider";
import Stripe from 'stripe'
import { loadStripe } from '@stripe/stripe-js';
import SecondaryButton from '../../shared/SecondaryButton';

export const PUBLISHABLE_KEY = 'pk_live_51MHz2BDcoLvB0wchuncX0FSyts0KEE0ePLyzToNzd0uqQ9GYZ0jhW33r1xpqpnBBAjpHXmrmH7WUf06I6esLiwuc00gAVVA837'

export let stripePromise = loadStripe(
    PUBLISHABLE_KEY
);

// const stripe = await stripePromise;


const productToPriceMap = {
    basic_month: 'basic_month',
    basic_year: 'basic_year'
}

const Column = ({ data = {}, type, onSelect, planName, hasPlan, loading }) => {

    const id = data.id === 'free' ? 'free' : `${data.id}_${type}`

    const isActive = id === planName
    return <div class="max-w-sm mx-auto border rounded-xl  border-gray-400 w-[100%] mb-2">
        <div class="p-4">
            <h6 class="text-[18px] text-gray-700 capitalize  dark:text-white">{data.title}</h6>
            <h6 class="mt-4 text-2xl font-medium text-gray-700 sm:text-4xl dark:text-gray-300">${data[type]}.00 <span class="text-base font-medium">/Month</span></h6>

            <Box mt={'15px'}>
                {isActive ? <SecondaryButton
                    size={'sm'}
                // onClick={onSelect}
                >
                    Current plan
                </SecondaryButton>
                    : <PrimaryButton
                        size={'sm'}
                        onClick={onSelect}
                        disabled={loading}
                    >
                        {hasPlan ? 'Change  plan' : 'Select plan'}
                    </PrimaryButton>
                }
            </Box>

        </div>

        <div class="p-4">
            <div class="mt-0 space-y-4">
                {data.features.map(f => <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>

                    <span class="mx-1 text-gray-700 dark:text-gray-300 text-[14px]">{f}</span>
                </div>)}
            </div>
        </div>
    </div>
}

const Plans = () => {

    const [
        organizations,
        setOrganizations,
    ] = useUserState(store => ([
        store.organizations,
        store.setOrganizations,
    ]))


    const { user } = useSelector(state => state.persistentState)

    const [saving, setSaving] = useState()

    const [myOrganization, setMyOrganization] = useState(organizations?.find(o => o.owner?.id === user?.id))

    const planId = myOrganization?.billing?.plan || 'free'

    const hasPlan = planId !== 'free'

    // const { data: billing, isLoading } = useBillingQuery(planId !== 'free')

    const { email, username } = myOrganization?.owner

    const onSelect = async (id) => {
        setSaving(true)
        try {
            const { sessionId } = await getCheckoutSession({ productId: id })

            const stripe = await stripePromise;

            await stripe.redirectToCheckout({ sessionId })
        } catch (err) {
            console.log(err)
        } finally {
            setSaving(false)

        }
    }

    const onUpdate = async (id) => {
        setSaving(true)
        try {
            const { session } = await updateBilling({ productId: id })

            window.location.replace(session.url)
        } catch (err) {
            console.log(err)
        } finally {
            setSaving(false)

        }
    }

    const [month, setMonth] = useState(true)

    const type = month ? 'year' : 'month'

    return (
        <Box
        // p={'0 20px 20px 20px'}
        >
            <div class="flex justify-center mt-6">
                <div class="flex items-center space-x-3 mb-8">
                    <div class="text-sm text-gray-500 font-medium min-w-[120px] text-right">Monthly</div>
                    <div class="relative select-none w-[44px]">
                        <input type="checkbox" id="toggle" class="peer sr-only" x-model="annual" checked={month} onChange={e => setMonth(e.target.checked)} />
                        <label class="block overflow-hidden cursor-pointer h-6 rounded-full bg-gray-400 peer-checked:bg-blue-500" for="toggle">
                            <span className={`absolute block rounded-full w-5 h-5 top-0.5 ${month ? 'right-0.5' : 'left-0.5'}   bg-white shadow-sm transition-all duration-150 ease-out`} aria-hidden="true"></span>
                            <span class="sr-only">Pay annually</span>
                        </label>
                    </div>
                    <div class="text-sm text-gray-500 font-medium min-w-[120px]">Annually</div>
                </div>
            </div>

            <div className="grid  gap-[10px] row-gap-[10px] lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-4 xl:max-w-screen-lg sm:mx-auto mb-12 mt-2">
                {plansData.map(d => <Column
                    data={d}
                    type={type}
                    hasPlan={hasPlan}
                    onSelect={() => hasPlan ? onUpdate() : onSelect(`${d.id}_${type}`)}
                    planName={planId}
                    loading={saving}
                />)}
            </div>
        </Box>
    );
};

export default Plans;

const defaultFeatures = []
const plansData = [
    {
        title: 'Free',
        id: 'free',
        month: 0,
        year: 0,
        features: [
            <><b>100</b> responses/month</>,
            <><b>1</b> user</>,
            <><b>100MB</b> storage</>,
            <><b>1</b> workspace</>,
            <><b>5</b> forms</>,
            <><b>10</b> questions per form</>,
            ...defaultFeatures
        ]
    },
    {
        title: 'Basic',
        id: 'basic',

        month: 18,
        year: 12,
        features: [
            <><b>1 000</b> responses/month</>,
            <><b>3</b> users</>,
            <><b>1GB</b> storage</>,
            <><b>3</b> workspaces</>,
            <><b>Unlimited</b> forms</>,
            <><b>Unlimited</b> questions</>,
            ...defaultFeatures
        ]
    },
    {
        title: 'Pro',
        id: 'pro',

        month: 30,
        year: 24,
        features: [
            <><b>5 000</b> responses/month</>,
            <><b>5</b> users</>,
            <><b>2GB</b> storage</>,
            <><b>5</b> workspaces</>,
            <><b>Unlimited</b> forms</>,
            <><b>Unlimited</b> questions</>,
            'Remove Formee branding',
            'Own branding',
            ...defaultFeatures,
        ]
    },
    {
        title: 'Business',
        id: 'business',

        month: 60,
        year: 48,
        features: [
            <><b>Unlimited</b> responses/month</>,
            <><b>20</b> users</>,
            <><b>15GB</b> storage</>,
            <><b>20</b> workspaces</>,
            <><b>Unlimited</b> forms</>,
            <><b>Unlimited</b> questions</>,
            'Remove Formee branding',
            'Own branding',
            ...defaultFeatures,
        ],
    },
    // {
    //     title: 'Professional',
    //     id: 'professional',
    //     month: 230,
    //     year: 198,
    //     features: [
    //         <><b>100 000</b> responses/month</>,
    //         <><b>20</b> users</>,
    //         <><b>10GB</b> storage</>,
    //         <><b>20</b> workspaces</>,
    //         <><b>Unlimited</b> forms</>,
    //         <><b>Unlimited</b> questions</>,
    //         'Remove Formee branding',
    //         ...defaultFeatures,
    //     ]
    // },




]