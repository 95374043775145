import React from 'react';
import {Box, HStack, Button} from '@chakra-ui/react'
import styled from "styled-components";

const Container = styled(Box)`
  cursor: pointer;
  font-size: 20px;
`;


const UnderlineTextButton = ({children, onClick, color}) => {
  return (
    <Container
      borderBottomWidth={'2px'}
      borderBottomColor={color}
      color={color}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

export default UnderlineTextButton;