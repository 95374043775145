import React, {useEffect} from 'react';
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {useDefaultFormik} from "../../../../shared/form/useFormBuilder";
import {mapToIdsArray, rawToStyledHtml, removeByIndex, updateByIndex} from "../../../../utils/common";
import * as Yup from "yup";
import _ from "lodash";
import {
  Card, CONDITION_FIELDS,
  getFieldValueAndOptions,
  getLogicalOperatorsByFieldType, GhostButton, Label,
  logicalOperatorOptions,
} from "./JumpingLogicSettingsModal";
import {Select, Space} from "antd";
import CustomSearchableSelect, {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import {Box, HStack, Button, Center} from '@chakra-ui/react'
import RuleBlock from "./RuleBlock";
import {fieldTypes} from "../../fieldTypes/config";

const validationSchema = Yup.object().shape({});


const AlwaysJumpToSelect = ({fields = [], endingFields = [], field = {}, _value, onChange}) => {

  /// ============================== Select Scope ===============================================================================================

  let value

  const permittedFields = fields.filter(f => f.id !== field.id)

  const permittedFieldsIds = mapToIdsArray(permittedFields.map(f => ({...f, id: `Q:${f.id}`})))
  const permittedEndingFieldsIds = mapToIdsArray(endingFields.map(f => ({...f, id: `E:${f.id}`})))

  if (_value && [...permittedFieldsIds, ...permittedEndingFieldsIds].includes(_value)) {
    value = _value
  }

  return <StyledSelect
    name={'alwaysJumpTo'}
    value={value}
    onChange={onChange}
    allowClear={true}
    dropdownMatchSelectWidth={true}
    style={{width: '100%'}}
  >

    {!_.isEmpty(fields) && <Select.OptGroup label={'QUESTIONS'} >
      {fields?.map((f, index) => {

        const Icon = fieldTypes[f.type].icon

        return <Select.Option key={f.id} value={`Q:${f.id}`}>
          <Space>
            <Icon size={20}/>
            <Box
              display={'flex'}
              w='400px'
            >
              <span style={{marginRight: '4px'}}>{f.order}.</span>{rawToStyledHtml(f.nameDraftContent)}
            </Box>
          </Space>
        </Select.Option>
      })}
    </Select.OptGroup>}


    {!_.isEmpty(endingFields) && <Select.OptGroup label={'ENDINGS'}>
      {endingFields?.map((f, index) => {

        const Icon = fieldTypes[f.type].icon

        return <Select.Option key={f.id} value={`E:${f.id}`}>
          <Space>
            <Icon size={20}/>
            <Box
              display={'flex'}
              w='400px'

            >
              <span style={{marginRight: '4px'}}>{f.order}.</span>{rawToStyledHtml(f.nameDraftContent)}
            </Box>
          </Space>
        </Select.Option>
      })}

    </Select.OptGroup>}

  </StyledSelect>
}

const getFirstAvailableQuestion = (fields = [], activeField) => {
  /// =================================================================== SCOPE =======================================================================
  const permittedFields = fields.filter(f => CONDITION_FIELDS.includes(f?.type) && f?.order <= activeField?.order)

  return _.last(permittedFields)
}

const getEqualTo = () => {

}

export const renderNewConditionInitialData = (activeField = {}, fields = []) => {
  const availableField = getFirstAvailableQuestion(fields, activeField)

  const type = availableField?.type

  const equalToType = getLogicalOperatorsByFieldType(type, !!!availableField)

  const {
    value,
    valueOptions
  } = getFieldValueAndOptions(availableField)

  return {
    ifQuestionId: !!availableField ? availableField?.id : null,
    ifNumberVariableId: !!availableField ? null : 'score',
    ifStringVariableId: null,
    ifHiddenVariableId: null,
    equalTo: logicalOperatorOptions[equalToType]?.[0]?.value,
    value,
    valueOptions,
    logicalOperator: "AND"
  }
}

const FieldBlock = ({
                      fieldData,
                      fields = [],
                      endingFields = [],
                      data,
                      onChange,
                      order
                    }) => {

  const formik = {
    values: {
      rules: data?.rules || [],
      alwaysJumpTo: data?.alwaysJumpTo
    },
    setFieldValue: (keyName, value) => {
      onChange({...data, [keyName]: value})
    },
    setValues: (values) => {
      onChange({...values})
    }
  }


  const rules = formik.values?.rules || []
  const {alwaysJumpTo} = formik.values

  // ====================== SCOPE ======================================
  const alwaysJumpToFields = fields.filter(f => f.id !== fieldData?.id)


  const addNewRuleBlock = () => {

    const newItem = {
      conditions: [
        renderNewConditionInitialData(fieldData, fields)
      ],
      thenWhat: 'jumpTo',
      // jumpTo
      thenJumpToId: null,
      // replace
      replaceWhatVariableId: null,
      replaceTo: null,
      //calculate
      calculateBy: null,
      variableId: null,
    }
    formik.setFieldValue('rules', [...rules, newItem])
  }

  const removeRuleBlock = (index) => {
    formik.setFieldValue('rules', removeByIndex(rules, index))
  }

  const FieldIcon = fieldTypes[fieldData.type]?.icon

  return (
    <Box
      p={'30px 0'}
    >

      <Card>

        <Box
          fontWeight={600}
          fontSize={'16px'}
          display={'flex'}
          mb={'20px'}
        >
          <Box mr={'4px'}>{order}. </Box>

          <Box
            mx={'5px'}
          >
            <FieldIcon/>
          </Box>

          {rawToStyledHtml(fieldData.nameDraftContent)}
        </Box>


        {rules?.map((r, index) => <RuleBlock
          ruleData={r}
          onRemove={() => removeRuleBlock(index)}
          onChange={(data) => {
            formik.setFieldValue('rules', updateByIndex(rules, data, index))
          }}
          fieldData={fieldData}
          fields={fields}
          endingFields={endingFields}
        />)}


        <Center
          p={'30px 0'}
        >
          <GhostButton
            fontSize={'22px'}
            onClick={addNewRuleBlock}
          >
            + Add rule
          </GhostButton>
        </Center>


        <HStack>
          <Box
            px={'15px'}
          >
            <Label>
              Always jump to
            </Label>
          </Box>

          <Box
            flex={1}
          >
            <AlwaysJumpToSelect
              _value={alwaysJumpTo}
              onChange={(val) => formik.setFieldValue('alwaysJumpTo', val)}
              field={fieldData}
              fields={alwaysJumpToFields}
              endingFields={endingFields}
            />


          </Box>
        </HStack>
      </Card>


    </Box>
  );
};

export default FieldBlock;