import React from 'react';
import {Box, HStack, Input} from "@chakra-ui/react";
import colors from "../../../utils/commonStyles";
import {SearchOutlined} from "@ant-design/icons";
import {INPUT_BORDER_RADIUS} from "../index";

const SearchInput = ({placeholder = 'Search form',value, onChange, ...rest}) => {
  return (
    <>
      <Box
        bg={colors.bgDark}

        // bg={colors.bg}
        borderRadius={INPUT_BORDER_RADIUS}
        maxW={'300px'}
        p={'2px 18px 2px 1px'}
        {...rest}
      >
        <HStack
          justifyContent={'space-between'}
        >
          <Box
            flex={1}
          >
            <Input
            value={value}
            onChange={onChange}
              border={0}
              h={'37px'}
              placeholder={placeholder}
              bg={'transparent'}
            />
          </Box>
          <SearchOutlined/>
        </HStack>
      </Box>
    </>
  );
};

export default SearchInput;