import React from 'react';
import {Box, Divider} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import SectionHeader from "../../../../shared/SectionHeader";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";

const Configurator = ({data = {}, onChange}) => {

  const {required, showDrawMode, showTypeMode, showUploadMode} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  const isDisabled = (value) => value && !(showDrawMode || showTypeMode || showUploadMode)

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>

    {/*<FieldInlineWrapper*/}
    {/*  label={'Draw'}*/}
    {/*>*/}
    {/*  <CustomSwitch*/}
    {/*    isDisabled={isDisabled(showDrawMode)}*/}
    {/*    value={showDrawMode}*/}
    {/*    onChange={val => onChangeHandler('showDrawMode', val)}*/}
    {/*  />*/}
    {/*</FieldInlineWrapper>*/}

    {/*<FieldInlineWrapper*/}
    {/*  label={'Type'}*/}
    {/*>*/}
    {/*  <CustomSwitch*/}
    {/*    isDisabled={isDisabled(showTypeMode)}*/}
    {/*    value={showTypeMode}*/}
    {/*    onChange={val => onChangeHandler('showTypeMode', val)}*/}
    {/*  />*/}
    {/*</FieldInlineWrapper>*/}

    {/*<FieldInlineWrapper*/}
    {/*  label={'Upload'}*/}
    {/*>*/}
    {/*  <CustomSwitch*/}
    {/*    isDisabled={isDisabled(showUploadMode)}*/}
    {/*    value={showUploadMode}*/}
    {/*    onChange={val => onChangeHandler('showUploadMode', val)}*/}
    {/*  />*/}
    {/*</FieldInlineWrapper>*/}
  </Box>
}

const SignatureSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl
  const {generalSettings} = activeField

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};

export default SignatureSettings;