import React from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import {Input, Box, Flex, HStack, Center} from '@chakra-ui/react'
import styled from "styled-components";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {Player} from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import {FakeButton, FakeInput} from "../TextField/TextField";

const splitOptions = {
  line: '-',
  slash: '/',
  dot: '·'
}

const renderPlaceholder = (split, format) => {
  return format.replaceAll('-', ` ${splitOptions[split]} `).toUpperCase()
}


const getBlockInfo = (format, index) => {
  if (format === 'dd-mm-yyyy') {
    return {
      0: {
        description: 'Day',
        placeholder: 'DD'
      },
      1: {
        description: 'Month',
        placeholder: 'MM'
      },
      2: {
        description: 'Year',
        placeholder: 'YYYY'
      }
    }
  } else if (format === 'mm-dd-yyyy') {
    return {
      0: {
        description: 'Month',
        placeholder: 'MM'
      },
      1: {
        description: 'Day',
        placeholder: 'DD'
      },
      2: {
        description: 'Year',
        placeholder: 'YYYY'
      }
    }
  } else if (format === 'yyyy-mm-dd') {
    return {
      1: {
        description: 'Month',
        placeholder: 'MM'
      },
      2: {
        description: 'Day',
        placeholder: 'DD'
      },
      0: {
        description: 'Year',
        placeholder: 'YYYY'
      }
    }
  }
}

const DayBlock = ({color, description = 'Day', placeholder}) => {
  return <Box
  >
    <Box
      mb={'15px'}
    >
      {description}
    </Box>
    <Box
    >
      <FakeInput color={color}>
        {placeholder}
      </FakeInput>
    </Box>
  </Box>
}

const DateField = ({fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber, messages}) => {

  if (!fieldData) return null


  const {questionColor, fontSize, align, answerColor, buttonBackground, buttonColor} = theme
  const {options = [], generalSettings = {}} = fieldData
  const {format, split, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null


  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}


      <Box>
        <FakeInput
          fontSize={fontSize === 'sm' && '20px'}

          color={answerColor}
        >
          {renderPlaceholder(split, format)}
        </FakeInput>
      </Box>

      <Flex
        mt={'15px'}
        justifyContent={align === 'center' ? 'center' : 'flex-start'}
      >
        <FakeButton
          backgroundColor={buttonBackground}
          color={buttonColor}
          theme={theme}
        >
          {messages['ok']}

        </FakeButton>
      </Flex>

    </FieldWrapper>
  );
};


export default DateField;