
export const uploadIllustration = <svg id="ab2617e5-3528-439c-8013-3c2eda2b7811" data-name="Layer 1"
                                       xmlns="http://www.w3.org/2000/svg"
                                       width="100%" viewBox="0 0 865.76 682.89">
  <defs>
    <clipPath id="bc47161b-74cb-47b8-88c3-f63844da70f7">
      <rect id="a69ea779-271e-49f2-97ff-86558de2bc98" data-name="SVGID" x="569.24" y="310.4" width="116.91"
            height="91.75" fill="#fff"/>
    </clipPath>
    <clipPath id="f7f64421-70fe-4370-8077-c2d19085a9e5">
      <rect id="ba61bb08-8b75-44be-8918-6434830e05db" data-name="SVGID" x="92.89" y="425.97" width="192.03"
            height="126.3" fill="#fff"/>
    </clipPath>
  </defs>
  <path
    d="M28,626.78H571.7a0,0,0,0,1,0,0v3.05a6.92,6.92,0,0,1-6.92,6.92H35A6.92,6.92,0,0,1,28,629.83v-3.05a0,0,0,0,1,0,0Z"
    fill="#2f2e41"/>
  <path
    d="M61.3,298.41H534.76A20.29,20.29,0,0,1,555,318.69V604.53A9.07,9.07,0,0,1,546,613.6H50.09A9.07,9.07,0,0,1,41,604.53V318.69A20.29,20.29,0,0,1,61.3,298.41Z"
    fill="#2f2e41"/>
  <rect x="58.09" y="313.21" width="479.43" height="289.01" rx="12.86" fill="#fff"/>
  <rect x="89.02" y="421.46" width="200.41" height="135.97" fill="#2f2e41"/>
  <polygon points="506.05 558.18 500.79 558.18 500.79 556.68 504.55 556.68 504.55 552.92 506.05 552.92 506.05 558.18"
           fill="#2f2e41"/>
  <path
    d="M491.71,558.18h-9.09v-1.5h9.09Zm-18.17,0h-9.08v-1.5h9.08Zm-18.17,0h-9.08v-1.5h9.08Zm-18.16,0h-9.08v-1.5h9.08Zm-18.17,0H410v-1.5H419Zm-18.16,0h-9.09v-1.5h9.09Zm-18.17,0h-9.08v-1.5h9.08Zm-18.16,0h-9.09v-1.5h9.09Zm-18.17,0H337.3v-1.5h9.08Zm-18.17,0h-9.08v-1.5h9.08Z"
    fill="#2f2e41"/>
  <polygon points="310.05 558.18 304.79 558.18 304.79 552.92 306.29 552.92 306.29 556.68 310.05 556.68 310.05 558.18"
           fill="#2f2e41"/>
  <path
    d="M306.29,544.46h-1.5V536h1.5Zm0-16.93h-1.5v-8.46h1.5Zm0-16.93h-1.5v-8.46h1.5Zm0-16.92h-1.5v-8.46h1.5Zm0-16.93h-1.5v-8.46h1.5Zm0-16.92h-1.5v-8.47h1.5Zm0-16.93h-1.5v-8.46h1.5Z"
    fill="#2f2e41"/>
  <polygon points="306.29 425.98 304.79 425.98 304.79 420.71 310.05 420.71 310.05 422.22 306.29 422.22 306.29 425.98"
           fill="#2f2e41"/>
  <path
    d="M491.71,422.22h-9.09v-1.51h9.09Zm-18.17,0h-9.08v-1.51h9.08Zm-18.17,0h-9.08v-1.51h9.08Zm-18.16,0h-9.08v-1.51h9.08Zm-18.17,0H410v-1.51H419Zm-18.16,0h-9.09v-1.51h9.09Zm-18.17,0h-9.08v-1.51h9.08Zm-18.16,0h-9.09v-1.51h9.09Zm-18.17,0H337.3v-1.51h9.08Zm-18.17,0h-9.08v-1.51h9.08Z"
    fill="#2f2e41"/>
  <polygon points="506.05 425.98 504.55 425.98 504.55 422.22 500.79 422.22 500.79 420.71 506.05 420.71 506.05 425.98"
           fill="#2f2e41"/>
  <path
    d="M506.05,544.46h-1.5V536h1.5Zm0-16.93h-1.5v-8.46h1.5Zm0-16.93h-1.5v-8.46h1.5Zm0-16.92h-1.5v-8.46h1.5Zm0-16.93h-1.5v-8.46h1.5Zm0-16.92h-1.5v-8.47h1.5Zm0-16.93h-1.5v-8.46h1.5Z"
    fill="#2f2e41"/>
  <path
    d="M347,503.46h2.6v13.46a7,7,0,0,0,1.4,4.82,7,7,0,0,0,8.76,0,7,7,0,0,0,1.4-4.82V503.46h2.61v13.46c0,2.93-.69,5.12-2.09,6.57s-3.49,2.18-6.3,2.18-4.89-.72-6.29-2.18-2.09-3.64-2.09-6.57Z"
    fill="#2f2e41"/>
  <path
    d="M371.41,515.39v9.71H368.9V503.46h4.53a18.28,18.28,0,0,1,3.51.25,5.72,5.72,0,0,1,1.95.83,4.85,4.85,0,0,1,1.61,2,7.2,7.2,0,0,1,0,5.71,4.79,4.79,0,0,1-1.61,2,5.31,5.31,0,0,1-1.95.85,18.14,18.14,0,0,1-3.51.25h-2Zm0-2.34h1.3c2.21,0,3.69-.26,4.45-.77a3,3,0,0,0,1.15-2.72,3.34,3.34,0,0,0-1.18-2.91c-.78-.56-2.25-.84-4.42-.84h-1.3Z"
    fill="#2f2e41"/>
  <path d="M384.27,525.1V503.46h2.57v19.18H395v2.46Z" fill="#2f2e41"/>
  <path
    d="M419.13,514.32a11.29,11.29,0,0,1-.86,4.38,11,11,0,0,1-2.5,3.65,11.65,11.65,0,0,1-3.78,2.46,11.8,11.8,0,0,1-4.43.86,11.69,11.69,0,0,1-8.17-3.32,11,11,0,0,1-3.39-8,11.36,11.36,0,0,1,.86-4.38,11,11,0,0,1,2.53-3.67,11.46,11.46,0,0,1,3.75-2.44,11.75,11.75,0,0,1,4.42-.86,11.9,11.9,0,0,1,4.45.86,11.5,11.5,0,0,1,3.76,2.44,10.85,10.85,0,0,1,2.5,3.66A11.34,11.34,0,0,1,419.13,514.32Zm-11.57,8.93a8.6,8.6,0,0,0,3.38-.67,8.87,8.87,0,0,0,2.89-2,8.77,8.77,0,0,0,2.61-6.3,9,9,0,0,0-.68-3.45,8.57,8.57,0,0,0-1.93-2.9,8.66,8.66,0,0,0-6.27-2.6,8.89,8.89,0,0,0-3.41.66,8.53,8.53,0,0,0-2.86,1.94,8.84,8.84,0,0,0-2.6,6.35,8.77,8.77,0,0,0,.66,3.4,9.12,9.12,0,0,0,4.83,4.86A8.57,8.57,0,0,0,407.56,523.25Z"
    fill="#2f2e41"/>
  <path d="M429.63,502.6l9.85,22.5h-2.8l-2.81-6.64h-8.45l-2.81,6.64h-2.79Zm0,5.69-3.44,8h6.89Z" fill="#2f2e41"/>
  <path
    d="M441.71,525.1V503.46h4.15a30.75,30.75,0,0,1,6.49.47,8.81,8.81,0,0,1,3.31,1.56,9,9,0,0,1,2.82,3.66,14.26,14.26,0,0,1,0,10.26,8.93,8.93,0,0,1-2.83,3.66,8.82,8.82,0,0,1-3.23,1.56,24.73,24.73,0,0,1-5.64.47h-5.08Zm2.6-2.43H446a29.63,29.63,0,0,0,5.53-.34,6.15,6.15,0,0,0,2.55-1.19,7.11,7.11,0,0,0,2-2.86,10.93,10.93,0,0,0,.69-4,10.65,10.65,0,0,0-.69-4,6.93,6.93,0,0,0-2-2.85,6.06,6.06,0,0,0-2.59-1.22,28.31,28.31,0,0,0-5.49-.36h-1.64Z"
    fill="#2f2e41"/>
  <circle cx="113.01" cy="351.68" r="23.37" fill="#2f2e41"/>
  <path d="M113,353.24s-12-2.48-13.55,17.48a24.23,24.23,0,0,0,27.1,0S123.87,351.44,113,353.24Z" opacity="0.2"/>
  <circle cx="112.3" cy="343.02" r="7.91" opacity="0.2"/>
  <rect x="156.67" y="335.11" width="87.18" height="7.91" rx="2.74" fill="#2f2e41"/>
  <rect x="260.4" y="335.11" width="57.74" height="7.91" rx="2.74" fill="#2f2e41"/>
  <rect x="156.67" y="359.83" width="65.61" height="8.69" rx="3.01" fill="#2f2e41"/>
  <rect x="241.15" y="359.83" width="39.54" height="8.69" rx="3.01" fill="#2f2e41"/>
  <rect x="22.69" y="613.6" width="554.35" height="18.87" rx="6.55" fill="#2f2e41"/>
  <rect x="22.69" y="613.6" width="554.35" height="18.87" rx="6.55" opacity="0.2"/>
  <polygon
    points="282.39 276.13 281.63 276.13 281.63 248.33 253.99 248.33 253.99 211.95 254.75 211.95 254.75 247.57 282.39 247.57 282.39 276.13"
    fill="#2f2e41"/>
  <rect x="299.52" y="212.72" width="0.75" height="63.41" fill="#2f2e41"/>
  <polygon
    points="319.24 276.13 318.49 276.13 318.49 247.57 346.13 247.57 346.13 212.72 346.88 212.72 346.88 248.33 319.24 248.33 319.24 276.13"
    fill="#2f2e41"/>
  <polygon points="247.99 211.95 254.37 206.1 259.74 211.95 247.99 211.95" fill="#2f2e41"/>
  <polygon points="294.02 211.95 300.4 206.1 305.77 211.95 294.02 211.95" fill="#2f2e41"/>
  <polygon points="340.63 211.95 347.01 206.1 352.38 211.95 340.63 211.95" fill="#2f2e41"/>
  <path
    d="M207,82.29s-56.78,9.46-58.59,63.2c-.83,24.79,17.84,46.25,42.57,48.16.55,0,1.12.08,1.68.11h211s47.78-6.37,43.79-55.74c0,0-.79-66.09-82.81-62.9,0,0-12.21-47.78-66.62-19.11,0,0-13.47-27.72-45.51-25.13a54.13,54.13,0,0,0-34.38,16C210.57,54.62,203.8,66.36,207,82.29Z"
    fill="#ccc"/>
  <path
    d="M265.11,174.88,251.59,125a5.09,5.09,0,0,1,4.92-6.42h92.23a3,3,0,0,1,2.89,3.56l-10.51,50.35a3,3,0,0,1-2.89,2.36Z"
    fill="#2f2e41"/>
  <path
    d="M259.74,118.61V96.38a3,3,0,0,1,3-3h12.91a3,3,0,0,1,2.73,1.79l2.14,4.85h35.56v-.1a2,2,0,0,1,2-2h8.95a2,2,0,0,1,2,2h9.24a5,5,0,0,1,5,5v13.66Z"
    fill="#2f2e41"/>
  <path
    d="M259.74,118.61V96.38a3,3,0,0,1,3-3h12.91a3,3,0,0,1,2.73,1.79l2.14,4.85h35.56v-.1a2,2,0,0,1,2-2h8.95a2,2,0,0,1,2,2h9.24a5,5,0,0,1,5,5v13.66Z"
    opacity="0.2"/>
  <rect x="265.91" y="107.19" width="72.86" height="11.41" fill="#fff"/>
  <rect x="265.91" y="110.4" width="72.86" height="0.75" fill="#2f2e41"/>
  <rect x="265.91" y="114.98" width="72.86" height="0.75" fill="#2f2e41"/>
  <path
    d="M836.49,567.2l.16.08a9,9,0,0,1,1.8,14.59,276.39,276.39,0,0,0-27,27.69c-1.55,1.89-3.7,2.23-6.12,2.55a5.83,5.83,0,0,1-6.23-6.12Z"
    fill="#2f2e41"/>
  <path d="M664.75,621.21s-2.16,7,3.2,11.43a10.66,10.66,0,0,0,6.75,2.29c6.87,0,23.41-2,23.41-22.42Z" fill="#2f2e41"/>
  <polygon points="682.38 573.21 673.84 608.15 693.17 610.63 709.98 574.98 682.38 573.21" fill="#f9b499"/>
  <path d="M791.46,558.35c1.94,1.4,25.55,20.44,25.55,20.44l11.2-17L808,538.64Z" fill="#f9b499"/>
  <path
    d="M676.57,288.75s-3.2,14.75-7.58,19.1L675.84,347s6.55-1.77,10-10.09l11.22-23.49-2.69-20.91S690,287.84,676.57,288.75Z"
    fill="#f9b499"/>
  <path
    d="M704.6,372l-10.87-84.44s-7.39-44.68,20.2-45.07h27.19s9.59.78,17.33,4.45a8.58,8.58,0,0,1,4.92,7.52c.46,17.55,2.72,80.46,11.46,99.87a4.83,4.83,0,0,1-2,6.14C763.84,365.76,739.94,376.89,704.6,372Z"
    fill="#35c0b7"/>
  <path
    d="M674.73,288.86c4.49-.36,14.25-.57,19.63,3.62,0,0-2.78-31.56,4.95-43,0,0-12.48-1.86-26.16,37.33A1.54,1.54,0,0,0,674.73,288.86Z"
    fill="#35c0b7"/>
  <path
    d="M674.73,288.86c4.49-.36,14.25-.57,19.63,3.62,0,0-2.78-31.56,4.95-43,0,0-12.48-1.86-26.16,37.33A1.54,1.54,0,0,0,674.73,288.86Z"
    opacity="0.2"/>
  <path
    d="M749.45,295s4.88,33.12,8.76,36.92l-42.73,23.22L724.42,368l44.72-16.42a16.91,16.91,0,0,0,6.94-4.64,14.94,14.94,0,0,0,3.39-13.05L776.71,295S769.44,283.8,749.45,295Z"
    fill="#f9b499"/>
  <path d="M697.05,377s-7.24,86.52,11.87,109.51L787.1,567.2l26.18-27.6L750,451.51l-4.28-80.79s-10.78,3.45-38,1.67Z"
        fill="#2f2e41"/>
  <path d="M697.05,377s-7.24,86.52,11.87,109.51L787.1,567.2l26.18-27.6L750,451.51l-4.28-80.79s-10.78,3.45-38,1.67Z"
        opacity="0.2"/>
  <path
    d="M676.37,574.63l40,10.26s20.52-93.4,35.38-121c0,0,30.94-73.11,21.13-103.42,0,0-12.37,9.2-44.25,12.32C728.61,372.79,685.57,509.88,676.37,574.63Z"
    fill="#2f2e41"/>
  <path
    d="M675.68,600.63s-11.57,13.61-11,23.14a7,7,0,0,0,5.46,6.3c8.61,2,31,4.15,28.34-30.6C698.43,599.47,684.53,616,675.68,600.63Z"
    fill="#35c0b7"/>
  <path d="M676.86,595.8H690.4a3.3,3.3,0,0,1,3.3,3.3v4.69s-10.94,10.11-18-3.16Z" fill="#2f2e41"/>
  <path d="M807.83,579.25s-7.43-10.85-4-11.09l13.81,10.63S813.92,581.44,807.83,579.25Z" fill="#2f2e41"/>
  <path
    d="M807.83,579.25l-8.54,26.07a3.11,3.11,0,0,0,1.47,3.7,6.91,6.91,0,0,0,9.08-1.54s7.66-10.38,13.06-17a45.36,45.36,0,0,1,6-6c2.32-2,5.83-5.4,7.62-9.51a9.78,9.78,0,0,0-5.86-13.23,3.44,3.44,0,0,0-2.4-.06l-11.2,17S811.73,581.2,807.83,579.25Z"
    fill="#35c0b7"/>
  <path
    d="M715.48,227.35v13.23a8.42,8.42,0,0,0,8.87,8.41l8.33-.45a8.4,8.4,0,0,0,7.83-9.92,149.94,149.94,0,0,1-2.19-29.73Z"
    fill="#f9b499"/>
  <ellipse cx="740.15" cy="202.23" rx="4.56" ry="7.12" fill="#f9b499"/>
  <path d="M701.9,202.9l1.63-6.84a24.87,24.87,0,0,0-4.26,1.63,24.56,24.56,0,0,0-4.9,3.23S696.86,202.9,701.9,202.9Z"
        fill="#2f2e41"/>
  <path d="M701.9,202.9l1.63-6.84a24.87,24.87,0,0,0-4.26,1.63,24.56,24.56,0,0,0-4.9,3.23S696.86,202.9,701.9,202.9Z"
        opacity="0.2"/>
  <path d="M746.56,297.16s-6.35-45.64,15.08-47.63,17.55,47.63,17.55,47.63S765.21,281.89,746.56,297.16Z" fill="#35c0b7"/>
  <path d="M746.56,297.16s-6.35-45.64,15.08-47.63,17.55,47.63,17.55,47.63S765.21,281.89,746.56,297.16Z" opacity="0.2"/>
  <path d="M715.48,232.32v-6.69s17.24-4.15,22.84-16.74l.32,14.35S730.15,235.57,715.48,232.32Z" fill="#f7aa92"/>
  <path d="M701.48,209.35s-.16,26-7.11,40.13l3.47.13s5.34-16.63,5.69-32.47Z" fill="#2f2e41"/>
  <ellipse cx="720.17" cy="206.31" rx="18.86" ry="22.99" fill="#f9b499"/>
  <path
    d="M694.37,200.92s6.07-5.13,11.44-5.13c0,0-1.28-4.29,2.23-6.86a16.16,16.16,0,0,0-.18,6.44s14.42-1.59,20.21,1.06a20.34,20.34,0,0,0,0-6.55s1.72,1.74,1.9,7.68l7.43,1.7.92,9.63c0,4.26.06,8.69.27,13.28.33,7.19.94,14,1.73,20.32a55,55,0,0,1,8.68,1.35c4.66,1,10,2.79,12.64,5.69,0,0,7.85-1.75,12.68,8.22l3.94-2.42s-16.87-13.62-15.69-18.75c0,0,10.38,13.27,18.7,16.45l2.47-1.23s-32.37-26.57-34.49-54.24c0,0,11,32.63,34.49,44.93l.89-1s-22.47-9.2-37.5-54c0,0-4.07-18.75-23.18-20.16,0,0-28.48-3.4-32.37,30.14h2.12s-.17-9.8,4.78-12.28C698.48,185.28,695.92,188.61,694.37,200.92Z"
    fill="#2f2e41"/>
  <path d="M703.53,245.5a111.28,111.28,0,0,0,1-26.35,21.5,21.5,0,0,0,11,9.44l.28,14.16S708.71,242,703.53,245.5Z"
        fill="#2f2e41"/>
  <rect x="563.8" y="304.95" width="127.78" height="102.65" fill="#2f2e41"/>
  <rect id="b637595d-9fbf-4c2d-805f-29f5dff7f477" data-name="SVGID" x="569.24" y="310.4" width="116.91" height="91.75"
        fill="#fff"/>
  <g clip-path="url(#bc47161b-74cb-47b8-88c3-f63844da70f7)">
    <path
      d="M585.08,335.52l-.42-7.19a2.72,2.72,0,0,0-.61-1.58l-4.27-5.33a2.48,2.48,0,0,1,.41-3.51l.62-.48a2.49,2.49,0,0,1,3.57.57l2.09,3.1a.71.71,0,0,0,.92.22h0a.68.68,0,0,0,.34-.47l.66-3a.41.41,0,0,1,.4-.32h0a.41.41,0,0,1,.42.43l-.18,4.13a6.77,6.77,0,0,1-.68,2.69h0a3.37,3.37,0,0,0-.3,1.95l1.18,8.15Z"
      fill="#f9b499"/>
    <path d="M626.33,364.46s-6.72,6.93-9.83,7.44l-6.57-4.54a20.81,20.81,0,0,0,11-8.94Z" fill="#f9b499"/>
    <polygon points="647.62 458.2 647.62 461.02 653.07 463.93 653.07 457.95 647.62 458.2" fill="#f9b499"/>
    <path
      d="M640.89,467.52s.89-3.67,5.57-3.93v-1.86s.17-1.87,1.92-1.37a2.76,2.76,0,0,1,.81.39,14.13,14.13,0,0,1,2.21,1.81s1.28-1.5,2.24,1.34a22.12,22.12,0,0,1,.84,3.62Z"
      fill="#35c0b7"/>
    <rect x="640.89" y="467.52" width="13.59" height="1" fill="#2f2e41"/>
    <polygon points="629.71 458.2 629.71 464.2 634.6 465.37 634.6 458.72 629.71 458.2" fill="#f9b499"/>
    <path
      d="M622.78,467.52s.52-3.29,5.54-4l.16-2.07a1.22,1.22,0,0,1,1.48-1.1l.63.13a2.29,2.29,0,0,1,1.78,1.94l.18,1.36h3.14l.84,3.73Z"
      fill="#35c0b7"/>
    <rect x="622.78" y="467.52" width="13.74" height="1" fill="#2f2e41"/>
    <path
      d="M639.88,391.8s.34,6,3.33,9.47l2.66,39.92.94,17.18s2.22,1.54,6.92.52A105.09,105.09,0,0,0,656,429.14s-.94-10.18-.51-12.83c0,0,1.75-24.69,1.47-28.76C656.91,387.55,646.81,392.07,639.88,391.8Z"
      fill="#2f2e41"/>
    <path
      d="M639.88,391.8s.34,6,3.33,9.47l2.66,39.92.94,17.18s2.22,1.54,6.92.52A105.09,105.09,0,0,0,656,429.14s-.94-10.18-.51-12.83c0,0,1.75-24.69,1.47-28.76C656.91,387.55,646.81,392.07,639.88,391.8Z"
      opacity="0.2"/>
    <path
      d="M640.57,423.72a2.51,2.51,0,0,1,0-.67c.18-1.92,1.12-11.31,3.2-13.07l-.58-8.71s-2.82-3.22-3.33-9.47c0,0-3.61,0-5.45-.85l-2.59,19.81a114.23,114.23,0,0,0-.76,11.79l-2.48,35.74,6.15,2.14s2-1.46,3.93-15.48C638.68,445,641.26,427.19,640.57,423.72Z"
      fill="#2f2e41"/>
    <path
      d="M618.83,357.77s6.86,7.53,7.76,7.66c0,0,1.86-1.73,2.31-.64l2.75,24.55s5.09,6.05,19.85.8l9.13-4s-2.11-23.28-2.24-26.23c0,0,.1-1.05,1.08-1a7.31,7.31,0,0,0,.94,0l2-.1a8.61,8.61,0,0,0,1.35-4.68v-5.84s-9.44-6.61-20.17-5.26c0,0-10.8-.32-16.7,6.35Z"
      fill="#35c0b7"/>
    <path
      d="M636.2,338.82l.2,5.57s-1.93,2.25,2,2.6c0,0,3.46.41,5.53-1.37a1.84,1.84,0,0,0,.55-.84c.14-.45.1-1.06-.82-1.41l-.77-5.42Z"
      fill="#f9b499"/>
    <path d="M628.65,364.47l-.15,0a29.48,29.48,0,0,0-1.66-14.95l.14-.06A29.62,29.62,0,0,1,628.65,364.47Z"
          fill="#2f2e41"/>
    <path d="M659,359c-3.63-6.44-2.71-14.25-2.7-14.33l.15,0c0,.08-.93,7.84,2.68,14.24Z" fill="#2f2e41"/>
    <path d="M652.37,380.27l-.14-.07a47.94,47.94,0,0,0,3.65-13.57l.15,0A48.52,48.52,0,0,1,652.37,380.27Z"
          fill="#2f2e41"/>
    <path
      d="M602.87,347.66s-2.44-1.07.19-4.73c0,0,3.39-5.59,6.34-4.05a1.87,1.87,0,0,1,1.09,1.53s3.14-1.48,5.2.38a3.92,3.92,0,0,1,.81,1.09c.59,1.21,2,4.71.82,8.12a7.15,7.15,0,0,0,.86,6.45,12.07,12.07,0,0,0,2.25,2.41,4.3,4.3,0,0,1,.86,1.12,1.82,1.82,0,0,1-1.7,2.63,5.26,5.26,0,0,1-1.85-.46s2,1.86.51,3.08c0,0-1,1.22-4-1.28,0,0-.25,3.53-3,2.37l-4-3.08h-.64l-5.32-2.56-.9-.83a1.28,1.28,0,0,1,1-1s-2.88.26-3.2-.77c0,0-1.73-2,1.22-4.17a5.25,5.25,0,0,1-2.26-.8,1.15,1.15,0,0,1-.06-1.7,4.93,4.93,0,0,1,2.7-1.12S601.39,350.16,602.87,347.66Z"
      fill="#2f2e41"/>
    <path
      d="M585.25,339.11l1.37,21.21a52.56,52.56,0,0,1-.68,12l-6.24,36.51s2.65,6.75,10.85,11.46a262.77,262.77,0,0,0,6.8-31s0-15.34,2.82-27.63c0,0,1-.16,1.15-.87l-3-2.29-.3.43a11.7,11.7,0,0,1-3.92-7l-2.78-13Z"
      fill="#35c0b7"/>
    <polygon points="583.33 335.61 583.59 339.93 592.01 339.93 590.68 333.69 583.33 335.61" fill="#35c0b7"/>
    <polygon points="583.33 335.61 583.59 339.93 592.01 339.93 590.68 333.69 583.33 335.61" opacity="0.2"/>
    <path
      d="M611.38,367.36s4.39,2.53,6.1,4.58l9.43,8.81s3.74,3.18.66,6.8A46.42,46.42,0,0,1,623,391.8c-1.11.66-3.76-5.07-3.76-5.07l.8-.68-5-3.16,6.32,33.17s-5.77-17.44-10.53-20.69l-.49-16.06Z"
      fill="#35c0b7"/>
    <path
      d="M607.54,383.48l2.39-4.79c-.28-2.16-.6-4.39-1-6.69s-.77-4.3-1.19-6.33a11.09,11.09,0,0,1-2.8,2.79c-1.68,1.24-3.92,2.15-6.19.88-.2,1.54-.38,3.11-.54,4.73-.2,2-.37,4-.5,5.86A73.31,73.31,0,0,0,607.54,383.48Z"
      fill="#2f2e41"/>
    <path d="M605.81,388.27l1.73-4.79a86,86,0,0,1-9.82-3.55l-.45,8Z" fill="#f9b499"/>
    <path
      d="M606.77,458.2s-3.14-18.46-5.57-24.11c0,0-2.63-6.79-2.82-8.72l6.34-1.15A81.9,81.9,0,0,1,610.43,460l-.58,1.15Z"
      fill="#f9b499"/>
    <path d="M601.77,467s.71-5,4.75-5.32c0,0-2.2-3.6.9-3.47a5,5,0,0,1,2.18,2.35s.89-1.9,2,.6c0,0,1.29,3.79,1.09,5.84Z"
          fill="#35c0b7"/>
    <rect x="601.77" y="466.99" width="10.92" height="1.54" fill="#2f2e41"/>
    <path
      d="M616.06,422.76l6.3-3.22s11.13,15.21,14.8,34.12l-1.81,1.72-2.14-1.79s-7.3-17.59-12.05-23a15,15,0,0,1-1.38-1.89Z"
      fill="#f9b499"/>
    <path
      d="M641.08,456.66l-3.12-3.5a1.24,1.24,0,0,0-1.43,1.1s-3.79-2.3-3.57-.2a4.91,4.91,0,0,0,1.19,3.29s-2.48,3.16-2.65,6.24c0,0,3.17,1.28,5-.34C636.53,463.25,640.69,458.5,641.08,456.66Z"
      fill="#35c0b7"/>
    <path
      d="M631.37,464.28l.13-.69s2.78,1.45,5-.34c0,0,3.52-3.72,4.55-6.59a1.35,1.35,0,0,1,.81,1.54s-3.51,5.47-5.6,6.63c0,0-1.18.82-4.21.44A.84.84,0,0,1,631.37,464.28Z"
      fill="#2f2e41"/>
    <path d="M609.93,378.69s.89,8.31.8,11.87l-3.67-.42s-.35,0-.43.19a6.63,6.63,0,0,1-.82-2.06l1.73-4.79Z"
          fill="#35c0b7"/>
    <path d="M609.93,378.69s.89,8.31.8,11.87l-3.67-.42s-.35,0-.43.19a6.63,6.63,0,0,1-.82-2.06l1.73-4.79Z" fill="#fff"
          opacity="0.2"/>
    <path
      d="M663.78,348.37l3,.77s4.94-6.8,6.35-20.33l-2.52-6.72a.58.58,0,0,1,.25-.7h0a.58.58,0,0,1,.77.18l1.88,2.82,2.55-4.29a1.79,1.79,0,0,1,2.91-.22l2.36,2.88a1.77,1.77,0,0,1-.11,2.39l-4.44,4.43S676.34,349,672.3,354c0,0-1.37,2.37-9,2.95a15.71,15.71,0,0,0,.46-4.1Z"
      fill="#f9b499"/>
    <path d="M636.26,340.55l-.06-1.44,6.7-.9.12.8S640.94,341.52,636.26,340.55Z" fill="#f7a389"/>
    <ellipse cx="638.98" cy="331.55" rx="6.37" ry="8.85" fill="#f9b499"/>
    <ellipse cx="632.91" cy="331.85" rx="1.5" ry="1.92" fill="#f9b499"/>
    <path
      d="M632.72,329.94a1.62,1.62,0,0,0-.48.19S628.6,324,633.47,322c0,0,3.93-1.63,6.84-1.37a5,5,0,0,1,0-2.35.55.55,0,0,1,1.06.05,5,5,0,0,1,0,1.87s.71-1.3,1.73-1.47a.47.47,0,0,1,.54.42c0,.42-.13,1.11-1.24,1.82a3.66,3.66,0,0,1,4.1,3.66,35.41,35.41,0,0,1-.39,5.9s-.07,5-1.6,5.5a11.47,11.47,0,0,0,.77-6.13,1,1,0,0,0-.74.1,7.31,7.31,0,0,1-.48-3.39.44.44,0,0,0-.67-.39,2.78,2.78,0,0,0-.57.46s-2.95,2.85-3.21,1.54c0,0-.31-.56.83-2a.15.15,0,0,0,0-.2c-.11-.14-.4-.28-1,.49a4.31,4.31,0,0,1-.39.46c-.78.78-3.18,3-3.8,1.86,0,0-.59-.74.55-2.89a.34.34,0,0,0-.43-.47,2.87,2.87,0,0,0-.92.86,6.79,6.79,0,0,0-1.12,4.62S632.86,331,632.72,329.94Z"
      fill="#2f2e41"/>
    <ellipse cx="644.97" cy="331.85" rx="1.5" ry="1.92" fill="#f9b499"/>
    <path
      d="M595.43,373.91l-2-2.46a24.23,24.23,0,0,1,4.88-13l3,2.29s-.06.57-1.16.87l-1.41,7.76c-.43,3.2-.71,6-.9,8.3s-.29,4.26-.35,5.52c-.1,2.23-.15,4.27-.16,6-.83-1.47-1.64-3-2.48-4.66a50,50,0,0,1-2.91-6.3Z"
      opacity="0.2"/>
    <path d="M610.81,378.7l2.31-1.61-1.92-10.77-4-3.08s3.21,13.66,3.47,25.14l.19,7s1.92-8.4,3.59-11.86Z"
          fill="#35c0b7"/>
    <path d="M610.81,378.7l2.31-1.61-1.92-10.77-4-3.08s3.21,13.66,3.47,25.14l.19,7s1.92-8.4,3.59-11.86Z" opacity="0.2"/>
    <path d="M624,391.8l-4.27,1.77a12.38,12.38,0,0,1-2.65-5l2.69-2.54S621.72,390.7,624,391.8Z" fill="#35c0b7"/>
    <path d="M624,391.8l-4.27,1.77a12.38,12.38,0,0,1-2.65-5l2.69-2.54S621.72,390.7,624,391.8Z" opacity="0.2"/>
    <path
      d="M603.63,357s-2.25,4.6-3.47,4.6c-.26,1.15-.5,2.38-.73,3.62-.26,1.43-.48,2.82-.67,4.16a5.45,5.45,0,0,0,2.62.62c1.61-.07,3.82-.92,6.2-4a2.85,2.85,0,0,1-1.13-2.08c0-.1,0-.2,0-.31a22,22,0,0,1,1.07-5.48Z"
      fill="#f9b499"/>
    <ellipse cx="608.24" cy="351.52" rx="7.8" ry="5.23" transform="translate(-0.3 702.51) rotate(-60)" fill="#f9b499"/>
    <ellipse cx="602.87" cy="350.32" rx="1.57" ry="1.22" transform="translate(55.2 781.88) rotate(-68.4)"
             fill="#f9b499"/>
    <ellipse cx="613.12" cy="353.94" rx="1.57" ry="1.22" transform="translate(58.32 793.7) rotate(-68.4)"
             fill="#f9b499"/>
    <path
      d="M609.32,344.37s-1.77,5.47-3.86,2.24c0,0-1.3,1.6-2.49,1.1a1.56,1.56,0,0,1-.28-.16,2.18,2.18,0,0,1-.48-3.18s3.8-7.35,7.19-5.49a2,2,0,0,1,1.09,1.53s4.31-1.93,6,1.47l-1.9,8.44s-2.18,1.19-2.25-2.59C612.35,347.73,609.88,352.16,609.32,344.37Z"
      fill="#2f2e41"/>
    <path
      d="M598,425.69l-4.92-15s3.38-15.66,4.28-21.47l-.08-1.24,8.54.3s.32,2.67,3.85,5.3a38.31,38.31,0,0,1,7.48,11.55s4.19,9.16,5.22,14.42c0,0-1.93,2.5-6.93,3.33,0,0-5-11-11.34-14.29,0,0-.07,11,1.72,15.32C605.81,423.9,603.38,427,598,425.69Z"
      fill="#2f2e41"/>
    <path
      d="M617.56,389.21,614.15,391l-7.09-.81a.47.47,0,0,0-.52.46h0a.47.47,0,0,0,.33.45l2.21.66-3.11.89a1.33,1.33,0,0,0-.69,2.1l1.58,2.08a1.34,1.34,0,0,0,1.68.38l5.93-3.07,4.81-1.5A11,11,0,0,1,617.56,389.21Z"
      fill="#f9b499"/>
  </g>
  <path
    d="M588.14,315.44l1.42.43a15.53,15.53,0,0,0,17.11-5.95l.91-1.29L602.07,297a1.58,1.58,0,0,0-2.06-.78l-17,7.35a1.59,1.59,0,0,0-.72,2.28Z"
    fill="#f9b499"/>
  <path
    d="M715.88,355l-22.78-2.53a1.52,1.52,0,0,0-1.69,1.51v4.91L686,360.34a7.27,7.27,0,0,0-5,9.32l1.52,4.51a7.26,7.26,0,0,0,9.15,4.58l33.14-10.93Z"
    fill="#f9b499"/>
  <polygon
    points="373.2 473.66 373.2 336.4 341.3 336.4 405.42 251.34 466.96 337.37 436.56 337.37 436.56 473.66 373.2 473.66"
    fill="#d1d3d4"/>
  <rect id="e876ae3d-9aa1-4c54-9d1a-bb675c22f8f8" data-name="SVGID" x="92.89" y="425.97" width="192.03" height="126.3"
        fill="#fff"/>
  <g clip-path="url(#f7f64421-70fe-4370-8077-c2d19085a9e5)">
    <path d="M199.07,449.08a6.88,6.88,0,0,1,6.62-3s10.37.54,9.24,5.25C214.93,451.32,208.85,447.56,199.07,449.08Z"
          fill="#35c0b7"/>
    <path d="M199.07,449.08a6.88,6.88,0,0,1,6.62-3s10.37.54,9.24,5.25C214.93,451.32,208.85,447.56,199.07,449.08Z"
          opacity="0.2"/>
    <path
      d="M201.28,453.62a9.54,9.54,0,0,0-6.5,5.23s-4.26-4.8,1.22-8.42a10.31,10.31,0,0,1,4.5-1.62c3.1-.37,9.41-.58,14.79,2.72,0,0,5.48,3,.81,10.17a14.91,14.91,0,0,0-6.3-6.93A11.62,11.62,0,0,0,201.28,453.62Z"
      fill="#35c0b7"/>
    <path
      d="M192.82,475.07s-1.18-17.12,5.43-20.37a12.19,12.19,0,0,1,13.5,1.18,13.45,13.45,0,0,1,5.1,8.87c.66,5.76,1,10.87,1,10.87l-.64-.06s-.18-4.77-.94-5.8v5.73l-5.22-.42s-2.38-.28-2.89-1.92l-.38-3.87s-3.31,2.43-6.4.86l-.74-.39-.68-.7v4.54a15.87,15.87,0,0,0-2.41,1.45C197.49,475.28,192.82,475.07,192.82,475.07Z"
      fill="#2f2e41"/>
    <path d="M222.17,489.32s7.9,3.05,8.13,10L231.57,523a7.27,7.27,0,0,1-3.15,1s2.34-2.37-.84-7l-3.86-15-1.11-2.29Z"
          fill="#d1d3d4"/>
    <path
      d="M220.86,526.69s1.44.69,8.32-2.75c0,0-6.42-11.79-6.42-12.26s-3-4.34-3-4.34l-2.57,1.05S220.81,522.86,220.86,526.69Z"
      fill="#a7a9ac"/>
    <path
      d="M177.23,468.61s10.58-1,12.15,2.69c0,0,3.92,5,7.05,19.24,0,0-1.79,21.37-3.47,26.3,0,0-10.87,1.6-13.55,1,0,0-6.52-19.66-5.43-35.44Z"
      fill="#2f2e41"/>
    <path d="M152.42,595.39a5.78,5.78,0,0,1-1.87,3.21s3.63,3.38,6.42,2.31a1.65,1.65,0,0,0,1.19-1.86v-3.66Z"
          fill="#f9b499"/>
    <path d="M171.59,595.39v3.21s1.19,2.39,4.7,1.12c0,0,2-.45,1.94-1.12a3.1,3.1,0,0,1-1-3.21Z" fill="#f9b499"/>
    <path
      d="M159,466.4s-9.7,1.26-11,5.83c0,0-5.27,21.88-5.27,25,0,0,5.06-1.48,7.38,2.53l.78,1-1.13,17.65s14.84,3.09,29.68-.56c0,0-6.85-23-5.43-35.44,0,0,2.83.82,3.35,3.06,0,0,8.81,2,10.75,2.91,0,0,1.19-6.56,1.93-8.65,0,0-5.59-9.77-16.41-12.24C173.6,467.45,164,466.08,159,466.4Z"
      fill="#35c0b7"/>
    <path
      d="M159,466.4s-9.7,1.26-11,5.83c0,0-5.27,21.88-5.27,25,0,0,5.06-1.48,7.38,2.53l.78,1-1.13,17.65s14.84,3.09,29.68-.56c0,0-6.85-23-5.43-35.44,0,0,2.83.82,3.35,3.06,0,0,8.81,2,10.75,2.91,0,0,1.19-6.56,1.93-8.65,0,0-5.59-9.77-16.41-12.24C173.6,467.45,164,466.08,159,466.4Z"
      fill="#fff" opacity="0.2"/>
    <path
      d="M149.51,475.51s2.42-5.95,3.93-7.71a35.86,35.86,0,0,1,5.57-1.4l-1.82,3.72s-5.52,7.78-5.82,14.42l-.51,16.18a17.62,17.62,0,0,1-1.35-1.81S145.4,485.88,149.51,475.51Z"
      fill="#2f2e41"/>
    <path
      d="M173.6,467.45l-5.93-.69s-5.28,14.12-1.31,24.86c0,0,5.18,11.35,10.13,14.63,0,0-1.33-6.59-1.59-8.25,0,0-6.44-8.69-3.24-22Z"
      fill="#2f2e41"/>
    <path
      d="M149.73,518.37s-.22,19.13.67,26v51h9.1s1.5-53.41,4.33-63.56h2.53s4.78,56.25,4.63,63.56h7.31s1.79-50,.9-54.31a59.72,59.72,0,0,0,.21-23.27S164.88,521.17,149.73,518.37Z"
      fill="#2f2e41"/>
    <path
      d="M149.73,518.37s-.22,19.13.67,26v51h9.1s1.5-53.41,4.33-63.56h2.53s4.78,56.25,4.63,63.56h7.31s1.79-50,.9-54.31a59.72,59.72,0,0,0,.21-23.27S164.88,521.17,149.73,518.37Z"
      opacity="0.2"/>
    <path
      d="M142.7,497.2S141.13,511.67,142,518c0,0-3.61,7.84,3.46,7.67a5.64,5.64,0,0,0,4.72-3,.52.52,0,0,0-.19-.68c-1.12-.69-4.23-2.9-4.26-5.93,0,0,4.82-10.93,4.66-15.95C150.34,500.07,148.63,495.73,142.7,497.2Z"
      fill="#f9b499"/>
    <path
      d="M170.14,483.56a53.34,53.34,0,0,1,8.11,2.08s8.43,1.86,9.83,2.7c0,0,2.94,6.54,1,9.12,0,0-1.81,1.8-6-.56,0,0-10.44-5.3-14-9.88,0,0-3.28,1-4.58.52a1.54,1.54,0,0,1,0-1.55,1.23,1.23,0,0,1-.72-1.43,1.3,1.3,0,0,1-.05-1.24s-.71-.5-.13-1.67c0,0-1,.84-1.2,0a3,3,0,0,1,.61-1.85,2.71,2.71,0,0,1,2.2-1.06h3.22S170.54,478.58,170.14,483.56Z"
      fill="#f9b499"/>
    <path
      d="M190,497.66l-4-.76s3.37-15.81,4-17.21c0,0,.38-4.7,3.18-4.65h4.36s.57,4.43,3.73,5.44a3.22,3.22,0,0,0,1.77,0c1.38-.35,4.28-1.54,7.63-5.46l6.79.54s1.94-.14,2.53,2.54c0,0,2.84,16.26,4.18,18.5a36.46,36.46,0,0,1-9,2.46l3.06,7.84H194.34c-.33-1.48-.63-3-.91-4.67s-.49-3.28-.66-4.81Z"
      fill="#35c0b7"/>
    <path
      d="M196.06,475.58s1,6.42,6.19,6.05a12.44,12.44,0,0,0,8.8-5.3v-1.26l-.37,0s-3.73,4.87-8.51,5.62c0,0-3.35.33-4.62-5.62h-1.49Z"
      fill="#fff"/>
    <path
      d="M186.74,497s-1.65,14.88-1.05,20.93c0,0-2.94,8.35,4.94,6.86v-4l.32-1.2a4.7,4.7,0,0,1-1.23-3.27l2.87-6.67s-2.65-10.42-2.58-12Z"
      fill="#f9b499"/>
    <path d="M200,469.05v4.54L197.55,475s.75,5.19,4.62,5.62c0,0,4.07-.3,8.51-5.62,0,0-2.05-.67-2.5-1.67l-.45-4.54Z"
          fill="#f9b499"/>
    <path
      d="M141.75,606.21a3.23,3.23,0,0,1,1.64-3.28l7.16-4.33s3.73,3.28,6.42,2.31c0,0,1.19-.44,1.19-1.49,0,0,.75,5-.37,6.79Z"
      fill="#35c0b7"/>
    <path
      d="M171.59,598.6c-1.2-.3-2.09,6.79-2.09,6.79a7.42,7.42,0,0,0,3,.82H183a28.75,28.75,0,0,0-4.77-7.61s0,.79-2.78,1.36C175.45,600,172.78,600.61,171.59,598.6Z"
      fill="#35c0b7"/>
    <path d="M193.71,596a5.81,5.81,0,0,1-1.86,3.21s3.73,3.2,6.41,2.31a2.59,2.59,0,0,0,1.19-1.87V596Z" fill="#f9b499"/>
    <path
      d="M183,606.81a3.24,3.24,0,0,1,1.64-3.29l7.17-4.32s2.55,2.21,5,2.38a2.48,2.48,0,0,0,2.37-1.07,1.73,1.73,0,0,0,.25-.87s.75,5.38-.37,7.17Z"
      fill="#35c0b7"/>
    <path d="M212.88,595.28v3.39s1.2,2.39,4.7,1.12c0,0,2-.44,1.94-1.12a3.48,3.48,0,0,1-1-3.39Z" fill="#f9b499"/>
    <path
      d="M212.88,598.67c-1.19-.29-2.09,6.79-2.09,6.79a7.43,7.43,0,0,0,3,.82H224.3a28.63,28.63,0,0,0-4.78-7.61s-.19.63-2.78,1.37C216.74,600,214.08,600.69,212.88,598.67Z"
      fill="#35c0b7"/>
    <path d="M159,463.44v3l-1.82,3.72s2.88,6.75,8.79,2.54c0,0,1.12-4.15,1.69-5.56l-.32-8.16Z" fill="#f9b499"/>
    <path d="M167.46,461.7S166,466.77,159,465v-1.54l8.34-4.5Z" fill="#f7aa92"/>
    <circle cx="155.52" cy="456.94" r="2" fill="#f9b499"/>
    <ellipse cx="161.55" cy="454.36" rx="6.55" ry="9.85" fill="#f9b499"/>
    <circle cx="168.56" cy="457.46" r="2" fill="#f9b499"/>
    <path
      d="M153.81,452a5.92,5.92,0,0,0,7.25-1.2s3.74-5,5.81-2.16c0,0,.23,5.76,1.18,6.91a1.4,1.4,0,0,1,1,0s4.93-7.59,1.11-10.3a1.72,1.72,0,0,1-.71-1c-.29-1.44-1.44-4.61-6-4.45,0,0-4.28-.68-5.77,3.11a2.67,2.67,0,0,1-1.84,1.62,3.14,3.14,0,0,1-.87.09,2.3,2.3,0,0,0-1.53.44c-.72.55-1.32,1.71-.26,4.16A4,4,0,0,1,153.81,452Z"
      fill="#2f2e41"/>
    <path
      d="M153.81,452a5.92,5.92,0,0,0,7.25-1.2s3.74-5,5.81-2.16c0,0,.23,5.76,1.18,6.91a1.4,1.4,0,0,1,1,0s4.93-7.59,1.11-10.3a1.72,1.72,0,0,1-.71-1c-.29-1.44-1.44-4.61-6-4.45,0,0-4.28-.68-5.77,3.11a2.67,2.67,0,0,1-1.84,1.62,3.14,3.14,0,0,1-.87.09,2.3,2.3,0,0,0-1.53.44c-.72.55-1.32,1.71-.26,4.16A4,4,0,0,1,153.81,452Z"
      fill="#2f2e41" opacity="0.2"/>
    <path d="M193.19,511.8s-4.46-15.44-3.47-19.8l3.47-17h2.87l-3.49,19s-.26,2.2,1.77,12.85Z" fill="#d1d3d4"/>
    <path
      d="M191.1,519l3.24-12h23.87s4.7,20.29,1.87,35.66c0,0-.15,40.73-1.05,52.81h-7.31s-3.43-55-4.77-61.62l-2.69-.15s-4.32,46.11-3.73,62.37h-9.1V550.78s-.86-20.87-.79-28.42A13.46,13.46,0,0,1,191.1,519Z"
      fill="#2f2e41"/>
    <path
      d="M193.46,478.41a3,3,0,1,0,3,3A3,3,0,0,0,193.46,478.41Zm0,4.69a1.72,1.72,0,1,1,1.72-1.72A1.71,1.71,0,0,1,193.46,483.1Z"
      fill="#bcbec0"/>
    <path
      d="M211.05,496.48V475.07l3.73.3-.64,20.23a5.51,5.51,0,0,0,.37,2.12l10.94,27.92a17.44,17.44,0,0,1-1.95.69q-.83.23-1.56.36c-1.72-5.49-3.75-9.63-4.88-12.61-2.53-6.68-5.24-14.13-6-17.18A1.93,1.93,0,0,1,211.05,496.48Z"
      fill="#d1d3d4"/>
    <path
      d="M223.16,497h0a21.3,21.3,0,0,0,.69,5.39l3.73,14.5s3.6,4.45,0,8a2.49,2.49,0,0,1-2.58.49.35.35,0,0,1-.1-.55c.11-.12.13-.34,0-.73,0,0-2.38-3.7-1.11-6.37l-4.55-8.08-4.07-10.65A31.52,31.52,0,0,0,223.16,497Z"
      fill="#f9b499"/>
    <path
      d="M212.76,479.27a3,3,0,1,0,3,3A3,3,0,0,0,212.76,479.27Zm0,4.68a1.72,1.72,0,1,1,1.71-1.71A1.71,1.71,0,0,1,212.76,484Z"
      fill="#bcbec0"/>
    <path d="M200,472.45v-2l6.64.82S203.16,473.53,200,472.45Z" fill="#f4a48e"/>
    <ellipse cx="203.87" cy="463" rx="7.05" ry="9" fill="#f9b499"/>
    <ellipse cx="211.06" cy="465.11" rx="1.65" ry="1.15" transform="translate(-299.1 440.35) rotate(-62.81)"
             fill="#f9b499"/>
    <ellipse cx="196.82" cy="463.23" rx="1.15" ry="1.65" transform="translate(-159.24 107.51) rotate(-22.01)"
             fill="#f9b499"/>
    <path d="M196.82,458.38s6.34.94,10.11-.71a13.15,13.15,0,0,1-9.38,1.56s7,2.18,11.92-2v-1.91l-3.57-1.8L201,454Z"
          fill="#2f2e41"/>
  </g>
</svg>