import React from 'react';
import { Switch } from '@chakra-ui/react'
import colors from "../../utils/commonStyles";

const CustomSwitch = ({value, onChange}) => {
  return (
    <div>
      <Switch
        isChecked={value}
        colorScheme='teal'
        onChange={e => onChange(e.target.checked)}
      />
    </div>
  );
};

export default CustomSwitch;