import { Box, Center } from "@chakra-ui/react";
import React from 'react';
import { HEADER_HEIGHT } from "../..";
import { updateNotifications } from "../../../../firebase/form";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import MoreActionsDropdown from "../../../../shared/MoreActionDropdown";
import SecondaryButton from "../../../../shared/SecondaryButton";
import colors from "../../../../utils/commonStyles";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import GoogleSheetsModal, { useGoogleSheetsModalStore } from "./GoogleSheetsModal";

const Integrations = ({ formData = {} ,fetchForm}) => {
    return (
        <>
            <GoogleSheetsModal fetchForm={fetchForm}/>
            <Box>
                <Box h={`calc(100vh - ${HEADER_HEIGHT})`} overflow={'auto'}>
                    <Center mx={'20px'}>
                        <Center
                            bg={'white'}
                            borderRadius={'12px'}
                            p={'15px 20px'}
                            mt={'20px'}
                            width={'100%'}
                            maxW={'1024px'}
                            mb={'40px'}
                        >
                            <Box>

                                <div class="py-6" >
                                    <div class="text-font_primary font-semibold text-lg text-center">Integrations</div>
                                    <div class="text-font_secondary font-normal text-sm text-center  py-2 px-6 lg:px-24 mx-auto">Connect Formee to your favorite apps</div>
                                </div>

                                <GoogleSheetCard />
                            </Box>

                        </Center>
                    </Center>
                </Box>
            </Box>
        </>
    );
};


const GoogleSheetCard = () => {

    const [onOpen] = useGoogleSheetsModalStore(store => [store.onOpen])

    const [
        formData = {},
        fields,
        setFormData
    ] = useFormConfiguratorStore(state => [
        state.formData,
        state.fields,
        state.setFormData,
    ])

    const googleSheet = formData?.integrations?.googleSheet || {}
    const { isConnected, isActive } = googleSheet

    const saveHandler = async (values) => {
        try {

            const requestData = {
                formId: formData.id,
                integrations: {
                    googleSheet: {
                        ...googleSheet,
                        ...values
                    }
                }
            }

            await updateNotifications(requestData)

            setFormData({
                integrations: {
                    googleSheet: {
                        ...googleSheet,
                        ...values
                    }
                }
            })

        } catch (e) {
            console.log(e)
        } finally {
        }
    }


    return <Box
        bg={colors.bg}
        borderRadius={'8px'}
        className="
        my-4 p-2 flex flex-row items-center justify-between rounded-2xl px-6 py-5 border
        integration-card-shadow fade-in
    ">
        <div class="flex flex-row items-center space-x-4"><img height="60" width="50"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Google_Sheets_logo_%282014-2020%29.svg/1498px-Google_Sheets_logo_%282014-2020%29.svg.png" alt="GoogleSheet Logo" />
            <div class="w-full flex flex-col items-start break-words">
                <p class="text-base font-medium text-font_primary"> Google Sheet </p>
                <p class="text-sm font-normal text-font_secondary mt-1 mr-2"> Google Sheets integration allows
                    you to add form data to your spreadsheets, automate your workflow, and eliminate manual data entry. </p>
            </div>
        </div>

        {!isConnected && <Box maxW={'180px'} ml={'10px'}>
            <SecondaryButton size={'sm'}
                onClick={() => onOpen()}
            >
                Connect 
            </SecondaryButton>
        </Box>}

        {isConnected && <Box display={'flex'} alignItems={'center'}>
            <Box mt={'8px'} mr={'5px'}>

                <CustomSwitch
                    value={!!isActive}
                    onChange={e => saveHandler({ isActive: !!e })}
                />

            </Box>

            <MoreActionsDropdown
                dotsActive={true}
                trigger={'click'}
                items={[
                    {
                        value: 'delete',
                        label: 'Delete',
                        onClick: () => {
                            saveHandler({ isConnected: false })
                        }
                    }
                ]}
            />

        </Box>}


    </Box>
}


export default Integrations;