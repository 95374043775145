import React, { useEffect } from 'react';
import { Box, HStack } from "@chakra-ui/react";
import { PageHeader } from "../Home";
import _ from "lodash";
import useOrganizationLocation from "../Home/hooks/useOrganizationLocation";
import { useSelector } from "react-redux";
import { useOrganizationsQuery } from "../../firebase/form";
import TemplateModal, { useTemplateModalStore } from './ChooseTemplateModal';
import { useParams } from 'react-router-dom';


const ChooseTemplate = ({ }) => {

    const {id} = useParams()

    const [onOpen] = useTemplateModalStore(store => [store.onOpen])

  

    useEffect(() => {
        onOpen({id})
    }, [])

    useOrganizationLocation(false)

    return (
        <>
            <TemplateModal canBeClosed={false} />
        </>
    );
};

export default ChooseTemplate;