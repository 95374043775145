import React from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import {Input, Box, Flex} from '@chakra-ui/react'
import styled from "styled-components";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {Player} from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";


export const FakeInput = styled(Box)`
  border-bottom: 1px solid ${({color}) => color || '#dddddd'};
  color: ${({color}) => color || '#dddddd'};
  padding-bottom: 5px;
  font-size: ${({fontSize}) => fontSize || '28px'};
`;

export const FakeButton = ({backgroundColor, color, children, theme = {}}) => {

  const {buttonRadius} = theme

  const borderRadius = buttonRadius === 'sharp' ? '10px' : '32px'

  return <Box
    backgroundColor={backgroundColor}
    color={color}
    p={'10px 45px'}
    borderRadius={borderRadius}
    fontWeight={700}
    fontSize={'16px'}

  >
    {children}
  </Box>

}

const TextField = ({fieldData, onChange, theme = {}, mentionOptions, messages = {}, showQuestionNumber}) => {

  if (!fieldData) return null


  const {questionColor, fontSize, align, answerColor, buttonBackground, buttonColor} = theme
  const {generalSettings = {}} = fieldData
  const {other, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box>
        <FakeInput
          fontSize={fontSize === 'sm' && '20px'}

          color={answerColor}
        >
          {messages['textFieldPlaceholder']}
        </FakeInput>
      </Box>
      <Flex
        mt={'15px'}
        justifyContent={align === 'center' ? 'center' : 'flex-start'}

      >
        <FakeButton
          backgroundColor={buttonBackground}
          color={buttonColor}
          theme={theme}
        >
          {messages['ok']}
        </FakeButton>
      </Flex>

    </FieldWrapper>
  );
};

export default TextField;