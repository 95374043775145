export const defaultTranslations = {
  en: {
    'ok': 'OK',
    'pressEnter': 'Press Enter ↵',
    'lineBreak': 'Shift ⇧ + Enter ↵ to make a line break',
    'choiceUnlimited': "Choose as many as you want",
    'choiceOtherPlaceholder': "Type your answer",
    'textFieldPlaceholder': "Type your answer here...",
    'selectPlaceholder': "Type or select an option",
    'yesNo.yes': 'Yes',
    'yesNo.no': 'No',
    'yesNo.Y': 'Y',
    'yesNo.N': 'N',
    'legalField.accept': 'Accept',
    'legalField.noAccept': "Don't Accept",
    'legalField.Y': 'Y',
    'legalField.N': 'N',
    'submit': 'Submit',
    'requiredErrorMessage': "This field is required.",
    "numberMinErrorMessage": "This value is too low",
    "numberMaxErrorMessage": "This value is too high",
    'emailErrorMessage': "Hmm… that email doesn't look right",
    "websiteErrorMessage": "Hmm… that web address doesn't look right. Check for any typos or errors.",
    "phoneErrorMessage": "Hmm… that phone number doesn't look right",
    'requiredMoreErrorMessage': "Please select more choices",
    'choiceRange': "Make choice between",
    'choice': "Choose",
    'continue': 'Continue',
    'start': "Start",
    'other': "Other",
    'createTypeform': "Create a form",
    'allDone': "All done! Thanks for your time.",
    'unavailable': "The form, is currently unavailable. Please try again in a few moments.",
    'dragAndDrop': "Choose file or drag here.",
    'fileTooBig': "Your file is too big",
    'sizeLimit': "Size limit:",
    'clear': 'Clear',
    'save': 'Save',
    'remove': 'Remove',
    'closeFormInfo': 'Sorry... but this form is not available',
    'endFormInfo': 'Thanks for completing this form',
    'createOwnForm': 'Create own form',
  },
  pl: {
    'ok': 'OK',
    'pressEnter': 'Kliknij Enter ↵',
    'lineBreak': 'Shift ⇧ + Enter ↵ aby, zacząć nowy wiersz',
    'choiceUnlimited': "Wybierz, ile chcesz",
    'choiceOtherPlaceholder': "Tu wpisz odpowiedź",
    'textFieldPlaceholder': "Tu wpisz odpowiedź...",
    'selectPlaceholder': "Wybierz odpowiedź",
    'yesNo.yes': 'Tak',
    'yesNo.no': 'Nie',
    'yesNo.Y': 'T',
    'yesNo.N': 'N',
    'legalField.accept': 'Akceptuj',
    'legalField.noAccept': "Odrzuć",
    'legalField.Y': 'Y',
    'legalField.N': 'N',
    'submit': 'Wyślij',
    'requiredErrorMessage': "To pole jest wymagane.",
    "numberMinErrorMessage": "Wartość jest zbyt niska",
    "numberMaxErrorMessage": "Wartość jest zbyt wysoka",
    'emailErrorMessage': "Hmm... ten adres e-mail nie wygląda na prawidłowy",
    "websiteErrorMessage": "Hmm... ten adres witryny nie wygląda prawidłowo.",
    "phoneErrorMessage": "Hmm... Ten numer telefonu jest nieprawidłowy",
    'requiredMoreErrorMessage': "Wybierz więcej opcji",
    'choiceRange': "Wybierz pomiędzy",
    'choice': "Wybierz",
    'continue': 'Kontynuuj',
    'start': "Start",
    'other': "Inne",
    'createTypeform': "Stwórz własny formularz",
    'allDone': "Gotowe! Dziękujemy za poświęcony czas.",
    'unavailable': "Ten formularz jest obecnie niedostępny. Spróbuj ponownie za kilka chwil.",
    'dragAndDrop': "Wybierz plik lub przeciągnij tutaj.",
    'fileTooBig': "Twój plik jest zbyt duży",
    'sizeLimit': "Limit wielkości:",
    'clear': 'Wyczyść',
    'save': 'Zapisz',
    'remove': 'Usuń',
    'closeFormInfo': 'Hej :) Ten formularz jest niedostępny.',
    'endFormInfo': 'Dziękujemy za wypełnienie naszego formularza.',
    'createOwnForm': 'Stwórz własny formularz',
  }
}