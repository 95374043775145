import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import { Space } from "antd";
import Logo, { LogoWord } from "../../../shared/Logo";
import { BlockOutlined, FormOutlined, LogoutOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import colors from "../../../utils/commonStyles";
import { useHistory, useParams } from "react-router-dom";
import OrganizationSelect from "./OrganizationSelect";
import { useUserState } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import { logout } from "../../../store/actions/AuthActions";
import ConfirmLogoutModal, { useConfirmLogoutModalStore } from '../../FormConfigurator/components/ConfirmLogoutModal';

const MenuItem = ({ title, activeItem, icon, to, onClick, showArrow }) => {

  const history = useHistory()

  const active = activeItem === title

  return <Box
    mb={'6px'}
    onClick={() => {
      if (active) return
      onClick ? onClick() : history.push(to)
    }}
    p={'10px 16px'}
    borderRadius={'12px'}
    // background={'rgb(71,193,252)'}
    background={active && 'linear-gradient(18deg, rgba(71,95,252,1) 0%, rgba(71,95,252,1) 45%, rgba(71,193,252,1) 86%)'}
    color={active && 'white'}
    fontSize={'17px'}
    cursor={'pointer'}
    // onClick={onClick}
    _hover={{
      bg: !active && colors.bg,
    }}
  >


    <Space>
      <Center>
        {icon}
      </Center>
      <Box
        flex={1}
        ml={'12px'}
        fontSize={'14px'}

      // letterSpacing={'5px'}
      // color={active && 'white'}
      >
        {title}
      </Box>
      {showArrow && <Box>
        <RightOutlined style={{ fontSize: '12px' }} />
      </Box>}

    </Space>
  </Box>
}

const SideBar = ({ activeItem = 'Forms' }) => {

  const [onOpen, onClose, data = {}] = useConfirmLogoutModalStore(store => [store.onOpen, store.onClose, store.data])

  const { organizationId, workspaceId } = useParams()

  const dispatch = useDispatch()

  const [
    organizations = [],
  ] = useUserState(store => ([
    store.organizations,
  ]))
  console.log(organizations, 'organizations')
  const { user } = useSelector(state => state.persistentState)

  // if (_.isEmpty(organizations)) return null

  // console.log(organizations, 'organizations')
  const currentOrganization = organizations?.find(o => o.id === organizationId)

  return <Box
    h={`100vh`}
    w={'300px'}
    d={'flex'}
    bg={'white'}
    flexDirection={'column'}
    // borderRight={'1px solid'}
    borderRightColor={'gray.300'}
    position={'relative'}
    overflow={'hidden'}
    boxShadow={'rgba(149, 157, 165, 0.2) 0px 8px 24px'}
  >
    <ConfirmLogoutModal />
    <Box p={'40px 30px 30px 30px'}>
      <Space
        align={"center"}
      >
        <Logo width={'50px'} />
        <LogoWord fontSize={'26px'} />
      </Space>
    </Box>

    <Box
      px={'30px'}
      flex={1}
    >
      <MenuItem title={'Forms'}
        activeItem={activeItem}
        to={`/organization/${organizationId}/workspace`}
        icon={<FormOutlined />}
      />

      <MenuItem title={'Templates'}
        activeItem={activeItem}
        to={`/templates`}

        icon={<BlockOutlined />}
      />

      <MenuItem title={'Account'}
        // to={`/organization/${organizationId}/workspace/${workspaceId}/settings`}
        to={`/account`}

        activeItem={activeItem}

        icon={<UserOutlined />}
      />



      <MenuItem
        onClick={() => onOpen()}
        title={'Logout'}
        icon={<LogoutOutlined />}
      />
    </Box>
    <Box
      px={'30px'}
      mb={'20px'}
    >

      {!_.isEmpty(organizations) && <OrganizationSelect
        currentOrganization={currentOrganization || organizations?.find(o => o.owner?.id === user?.id)}
        organizations={organizations}
        user={user}
      />}

    </Box>

  </Box>

};

export default SideBar;