import React from 'react';
import {Button, Dropdown, Empty, Popconfirm, Popover, Space} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import styled from "@emotion/styled";
import _ from 'lodash';
import {DropDownMenu} from "../pages/Home/components/CustomDropdown";

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content{
    padding: 0 !important;
  }
`;

const Menu = styled.div`
  
`;

const MenuItem = styled.div`
  padding: 8px 20px;
  cursor: pointer;
  transition: background 0.3s;
  
  &:hover{
    background: #ecf0f9;
  }
`;

const Content = ({items = []}) => {


  if (_.isEmpty(items)) {
    return <Empty/>
  }

  return <Menu>
    {items.map(i => {

      if (i.confirm) {

        return <Popconfirm
          title={i.confirmTitle}
          placement="left"
          onConfirm={i.onClick}
        >
          <MenuItem
            key={i?.id}
          >
            {i?.name}
          </MenuItem>
        </Popconfirm>
      }

      return <MenuItem
        key={i?.id}
        onClick={(e) => {
          e.stopPropagation();
          i?.onClick && i?.onClick();
        }}
      >
        {i?.name}
      </MenuItem>
    })}
  </Menu>
}

const MoreActionsDropdown = ({
                               children,
                               items,
                               loading,
                               trigger,
                               buttonSize = 'small'
                             }) => {
  const filteredItems = items?.filter(i => i.visible === undefined || i.visible)

  const disabled = _.isEmpty(filteredItems)

  const moreOptionsMenu = <DropDownMenu
    options={items}
  />

  return (
    <Dropdown
      overlay={moreOptionsMenu}
      trigger={trigger}
      placement="bottomLeft"
    >
      <Button
        disabled={disabled}
        size={buttonSize}
        type={"text"}
        ghost
        icon={<MoreOutlined/>}
        loading={loading}
      >
        {children}
      </Button>
    </Dropdown>
  );
};

export default MoreActionsDropdown;