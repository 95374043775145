import React from 'react';
import axios from '../utils/api'

const signUp = (data) => axios.post('/auth/signup', data)
const signIn = (data) => axios.post('/auth/signin', data)
const googleSignIn = (data) => axios.post('/auth/google/signin', data)

const getBilling = (data) => axios.get('/billing')
const getCheckoutSession = (data) => axios.post('/billing/checkout', data)
const updateBilling = (data) => axios.post('/billing/update', data)

const fetchMyOrganizations = (data) => axios.get('/organizations')
const getUsage = (data) => axios.get('/organizations/usage')
const addMember = (data) => axios.post('/organizations/addMember', data)
const updatePermission = (data) => axios.put('/organizations/updatePermission', data)
const removeMember = (data) => axios.delete(`/organizations/deletePermission/${data.userId}`)
const getTokenData = (token) => axios.get(`/public/getTokenData/${token}`)
const acceptInvite = (token) => axios.get(`/public/acceptInvite/${token}`)

const getTemplates = () => axios.get(`/public/forms/formTemplates`)

const createWorkspace = (data) => axios.post(`/workspaces`, data)
const updateWorkspace = (data) => axios.put(`/workspaces/${data.id}`, data)
const deleteWorkspace = (data) => axios.delete(`/workspaces/${data.id}`, {data})

const fetchPixabayImages = (params) => axios.get('https://pixabay.com/api/', {params})

const fetchForms = (params) => axios.get('/forms', {params})
const fetchForm = (id) => axios.get(`/forms/${id}`)
const createForm = (data) => axios.post('/forms', data)
const updateForm = (data) => axios.put(`/forms/${data.id}`, data)
const duplicateForm = (data) => axios.post(`/forms/${data.id}/duplicate`, data)
const moveToForm = (data) => axios.post(`/forms/${data.id}/moveTo`, data)
const deleteForm = (data) => axios.delete(`/forms/${data.id}`)
const fetchFormResponses = (id, params) => axios.get(`/forms/${id}/analytics/responses`, {params})
const fetchFormResponsesCSV = (id, params) => axios.post(`/responses/${id}/csv`, {params})
const fetchFormAnalytics = (id, params) => axios.post(`/responses/${id}/analytics`, {params})
const publishForm = (data) => axios.post(`/forms/${data.id}/publish`, data)

const createField = (data) => axios.post('/fields', data)
const updateField = (data) => axios.put(`/fields/${data._id}`, data)
const deleteField = (data) => axios.delete(`/fields/${data.id}`, {data})
const duplicateField = (data) => axios.post(`/fields/duplicate`, data)

const createTheme = (data) => axios.post(`/themes`, data)
const updateTheme = (data) => axios.put(`/themes/${data.id}`, data)
const deleteTheme = (data) => axios.delete(`/themes/${data.id}`, {data})

const updateAccountSettings = (data) => axios.put(`/organizations`, data)

const bulkDeleteResponse = (data) => axios.delete(`/responses`, {data})

const uploadImage = (data) => axios.post(`/asserts/upload`, data)
const addImage = (data) => axios.post(`/asserts/addImage`, data)
const fetchMyImages = (params) => axios.get(`/asserts/myImages`, {params})

const getTemplateCategory = () => axios.get(`/admin/templateCategory`)
const addTemplateCategory = (data) => axios.post(`/admin/templateCategory`, data)
const saveAsTemplate = (data) => axios.post(`/admin/${data.id}/saveAsTemplate`, data)


const getGoogleSheetOAuth = () => axios.get(`/integrations/googleSheet/oauth`)
const createIntegrationGoogleSheet = (data) => axios.post(`/integrations/googleSheet`, data)


const AuthService = {
  getGoogleSheetOAuth,
  createIntegrationGoogleSheet,
  updateBilling,
  getCheckoutSession,
  getBilling,
  getTemplates,
  getTemplateCategory,
  addTemplateCategory,
  signUp,
  fetchMyOrganizations,
  fetchForms,
  fetchForm,
  fetchFormResponses,
  fetchFormResponsesCSV,
  fetchFormAnalytics,
  createField,
  updateField,
  deleteField,
  createTheme,
  updateTheme,
  deleteTheme,
  createForm,
  updateForm,
  publishForm,
  duplicateForm,
  duplicateField,
  moveToForm,
  saveAsTemplate,
  deleteForm,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
  signIn,
  googleSignIn,
  bulkDeleteResponse,
  fetchPixabayImages,
  uploadImage,
  fetchMyImages,
  updateAccountSettings,
  addMember,
  getTokenData,
  acceptInvite,
  updatePermission,
  removeMember,
  addImage, 
  getUsage
}

export default AuthService;