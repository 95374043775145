import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {addMember, updatePermission} from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Checkbox, Col, Form, Row} from "antd";
import {StyledInput} from "../../../shared/form/InlineTextField";
import {permissionsOptions, useAddMemberModalStore} from "./AddMemberModla";
import {modalStore} from "../../../utils/common";
import openNotification from "../../../shared/openNotification";


export const useUpdatePermissionModalStore = modalStore()


const UpdatePermissionModal = ({fetchOrganizations}) => {
  const {organizationId, workspaceId} = useParams()

  const history = useHistory()

  const [permissions, setPermissions] = useState([])

  const [error, setError] = useState()

  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useUpdatePermissionModalStore(store => [store.isOpen, store.onClose, store.data])


  const onCloseHandler = () => {
    setError(null)
    setPermissions([])
    onClose()
  }

  useEffect(() => {
    if (data.permissions) {
      setPermissions(data.permissions)
    }
  }, [data])

  const updatePermissionHandler = useCallback(
    async (values) => {


      setLoading(true)

      try {

        const requestData = {
          ...values,
        }

        await updatePermission(requestData)
        fetchOrganizations()
        onCloseHandler()
        openNotification({description: 'Permission has been updated'})

      } catch (e) {
        console.log(e)
        openNotification({
          type: 'error',
          description: 'Something went wrong ...'
        })
      } finally {
        setLoading(false)
      }
    },
    [data]
  )


  return <CustomModal
    title={'Update permission'}
    zIndex={12}
    onOk={() => updatePermissionHandler({userId: data?.userId, permissions})}
    okButtonProps={{
      title: 'Update',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    size={'2xl'}
  >
    <Box
      p={'30px 0 10px'}
    >


      <Form
        layout={'vertical'}
      >

        <Form.Item
          label={'Permissions'}
        >
          <Checkbox.Group style={{width: '100%'}}
                          value={permissions}
                          onChange={val => setPermissions(val)}>
            <Row gutter={[5, 5]}>
              {permissionsOptions.map(o => <Col key={o.value} span={8}>
                <Checkbox value={o.value}>{o.label}</Checkbox>
              </Col>)}


            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>


    </Box>


  </CustomModal>
};

export default UpdatePermissionModal;