import React, {useEffect, useState} from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import {Divider, Box, HStack, Center} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import {Space} from "antd";
import {HeartOutlined, LikeOutlined, StarOutlined} from "@ant-design/icons";


const formatOptions = [
  {
    value: 'dd-mm-yyyy',
    label: 'DDMMYYYY'
  }, {
    value: 'mm-dd-yyyy',
    label: 'MMDDYYYY'
  }, {
    value: 'yyyy-mm-dd',
    label: 'YYYYMMDD'
  },
]

const splitOptions = [
  {
    value: 'line',
    label: '-',
  }, {
    value: 'slash',
    label: '/',
  }, {
    value: 'dot',
    label: '·',
  },
]

const Configurator = ({data = {}, onChange}) => {

  const {required, format, split} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>


    <Box
      py={'10px'}
      // label={'Required'}
    >
      <StyledSelect
        value={format}
        onChange={e => onChangeHandler('format', e)}
        // style={{width: '80px'}}
        options={formatOptions}
      />

    </Box>


    <Box
      py={'10px'}

      // label={'Required'}
    >
      <StyledSelect
        value={split}
        onChange={e => onChangeHandler('split', e)}
        // style={{width: '150px'}}
        options={splitOptions}
      />

    </Box>

  </Box>
}

const DateFieldSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({...data})
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onGeneralSettingsChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};


export default DateFieldSettings;