import React, { useCallback, useEffect, useState } from 'react';
import { Box, Divider, HStack, NumberInput } from '@chakra-ui/react'
import ColorPicker from "../../../shared/ColorPicker";
import { useDefaultFormik } from "../../../shared/form/useFormBuilder";
import * as Yup from "yup";
import InlineTextField from "../../../shared/form/InlineTextField";
import { ChevronLeftIcon } from '@chakra-ui/icons'
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import CustomSelect from "../../../shared/form/CustomSelect";
import colors, { defaultButtonProps } from "../../../utils/commonStyles";
import { AlignCenterIcon, AlignLeftIcon, ImageConfigIcon, ImageIcon, RemoveIcon } from "../fieldTypes/icons";
import styled from "styled-components";
import { createTheme, updateTheme } from "../../../firebase/form";
import useDebouncedEffect from "../../../hooks/useDebounceEffect";
import PrimaryButton from "../../../shared/PrimaryButton";
import { Space } from "antd";
import SecondaryButton from "../../../shared/SecondaryButton";
import { updateById } from "../../../utils/common";
import { GOOGLE_FONTS_API_KEY } from "../../../constants/config";
import axios from "axios";
import WebFont from 'webfontloader'
import { saveTheme } from "./Design";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
import CustomNumberInput from '../../../shared/form/CustomNumberInput';

const fontSizeOptions = [
  {
    label: 'Lg',
    value: 'lg'
  }, {
    label: 'Md',
    value: 'md'
  }, {
    label: 'Sm',
    value: 'sm'
  }
]


const fetchGoogleFonts = async () => {
  // const url = `https://www.googleapis.com/webfonts/v1/webfonts?sort=alpha&key=${process.env.GOOGLE_FONTS_API_KEY}`
  const url = `https://www.googleapis.com/webfonts/v1/webfonts?sort=alpha&key=${GOOGLE_FONTS_API_KEY}`
  const { data } = await axios.get(url)

  return data
}

const validationSchema = Yup.object().shape({});

export const FieldInlineWrapper = ({ label, children }) => <HStack
  p={['10px 0px']}
>
  <Box flex={1}>
    {label}
  </Box>
  <Box>
    {children}
  </Box>
</HStack>


const ThemeEditor = () => {

  const [
    formData,
    setMyThemes,
    setFormData,
    activeField,
    setActiveField,
    galleryThemes,
    myThemes,
    activeTheme,
    setActiveTheme,
    setImageModalOpen,
    setImageSettingsModalOpen,
    setImageSettingsModalClose,
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.setMyThemes,
    state.setFormData,
    state.activeField,
    state.setActiveField,
    state.galleryThemes,
    state.myThemes,
    state.activeTheme,
    state.setActiveTheme,
    state.setImageModalOpen,
    state.setImageSettingsModalOpen,
    state.setImageSettingsModalClose,
  ])

  const { isAuthenticated, user } = useSelector(state => state.persistentState)
  const isSuperUser = user?.isSuperUser


  const organizationId = formData?.organization

  const formik = useDefaultFormik({
    initialValues: activeTheme || {},
    validationSchema,
    onSubmit(values) {
      createThemeHandler({ ...values })
    }
  });

  useDebouncedEffect(() => setActiveTheme({ ...formik.values }), [formik.values], 100)


  const [creatingTheme, setCreatingTheme] = useState(false)

  const [fonts, setFonts] = useState([])

  const [loadingFont, setLoadingFont] = useState()


  const createThemeHandler = useCallback(
    async (values) => {

      const requestData = {
        ...values,
        organizationId
      }

      setCreatingTheme(true)

      try {
        let data = {}

        if (values.id) {
          data = await updateTheme(requestData)
          setMyThemes(updateById(myThemes, requestData))

        } else {
          data = await createTheme(requestData)
          setMyThemes([...myThemes, data])

        }
        setFormData({ theme: data })
        setActiveTheme(null)
      } catch (e) {
        console.log(e)
      } finally {
        setCreatingTheme(false)
      }
    },
    []
  )

  const onChangeImage = (data) => {
    formik.setFieldValue('imageUrl', data.imageUrl)
  }

  const onChangeImageConfig = (data) => {
    formik.setFieldValue('imageConfig', data)
  }

  const getFonts = async () => {
    try {
      const data = await fetchGoogleFonts()
      const toOptions = data?.items.map(i => ({
        value: i.family,
        label: i.family,
        // files: i.files
      }))

      setFonts(toOptions)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchFont = async (family) => {
    setLoadingFont(true)
    try {
      await WebFont.load({
        google: {
          families: [family]
        }
      });
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingFont(false)
    }
  }

  useEffect(() => {
    getFonts()
    fetchFont(formik.values.fontFamily)
  }, [])


  return (
    <Box
      // h={'calc(100vh - 176px)'}
      h={'100%'}
      // position='relative'
      display={'flex'}
      flexDirection={'column'}
    >
      
      <Footer
        bg={'white'}
        zIndex={9999}
      >
        <HStack>
          <SecondaryButton
            size={'sm'}
            onClick={() => setActiveTheme(null)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size={'sm'}
            onClick={() => formik.handleSubmit()}
            isLoading={creatingTheme}
          >

            {formik.values.id ? 'Update' : 'Save'}
          </PrimaryButton>

        </HStack>

      </Footer>
      <Box
        overflow={'auto'}
        flex={1}

      >

        <HStack
        mb={'10px'}
        >
          <Box
            cursor={'pointer'}
            onClick={() => setActiveTheme(null)}
          >
            <ChevronLeftIcon
              boxSize={'2em'}
              color={'gray.500'}
            />
          </Box>
          <Box>
            <InlineTextField
              name={'name'}
              formik={formik}
              placeholder={'Design name'}
            />
          </Box>
        </HStack>

        <Box
          mb={'10px'}
        >
          <CustomSelect
            showSearch={true}
            loading={loadingFont}
            value={formik.values.fontFamily}
            // name={'fontFamily'}
            onChange={e => {
              formik.setFieldValue('fontFamily', e)
              fetchFont(e)
            }}
            options={fonts}
          />
        </Box>
        <Box>
          <FieldInlineWrapper
            label={'Questions'}
          >
            <ColorPicker
              formik={formik}
              name={'questionColor'}
            />
          </FieldInlineWrapper>
          <FieldInlineWrapper
            label={'Answers'}
          >
            <ColorPicker
              formik={formik}
              name={'answerColor'}
            />
          </FieldInlineWrapper>
          <FieldInlineWrapper
            label={'Buttons'}
          >
            <ColorPicker
              formik={formik}
              name={'buttonBackground'}
            />
          </FieldInlineWrapper>
          <FieldInlineWrapper
            label={'Button text'}
          >
            <ColorPicker
              formik={formik}
              name={'buttonColor'}
            />
          </FieldInlineWrapper>
          <FieldInlineWrapper
            label={'Background'}
          >
            <ColorPicker
              formik={formik}
              name={'backgroundColor'}
            />
          </FieldInlineWrapper>

          <FieldInlineWrapper
            label={'Navigation buttons'}
          >
            <ColorPicker
              formik={formik}
              name={'navButtonsColor'}
            />
          </FieldInlineWrapper>

          {/* <FieldInlineWrapper
            label={'Progress bar'}
          >
            <ColorPicker
              formik={formik}
              name={'progressBarColor'}
            />
          </FieldInlineWrapper> */}

        </Box>
        <Divider
          my={'5px'}
          borderColor={'#cccccc'}
        />
        <FieldInlineWrapper
          label={'Background image'}
        >
          {formik.values.imageUrl ? (
            <HStack>
              <IconButton
                onClick={() => setImageModalOpen({
                  onImageModalChange: onChangeImage
                })}
              >
                <ImageIcon />
              </IconButton>

              {/*<IconButton*/}
              {/*  onClick={() => setImageSettingsModalOpen({*/}
              {/*    onImageSettingModalChange: onChangeImageConfig*/}
              {/*  })}*/}
              {/*>*/}
              {/*  <ImageConfigIcon/>*/}
              {/*</IconButton>*/}
              <IconButton
                onClick={() => {
                  setImageSettingsModalClose()
                  formik.setFieldValue('imageUrl', null)
                }}
              >
                <RemoveIcon />
              </IconButton>

            </HStack>
          ) : (
            // <Box
            //   {...defaultButtonProps}
            //   fontSize={'12px'}
            //   onClick={() => setImageModalOpen({
            //     onImageModalChange: onChangeImage
            //   })}
            // >
            //   Add
            // </Box>

            <SecondaryButton
              {...defaultButtonProps}
              fontSize={'14px'}
              // p={'3px'}
              size={'sm'}
              onClick={() => setImageModalOpen({
                onImageModalChange: onChangeImage
              })}
            >
              + Add
            </SecondaryButton>
          )}
        </FieldInlineWrapper>
        <Divider
          my={'5px'}
          borderColor={'#cccccc'}

        />
        <FieldInlineWrapper
          label={'Font size'}
        >
          <HStack>
            {fontSizeOptions.map(o => (
              <Box
                {...defaultButtonProps}
                border={formik.values.fontSize === o.value && '1px solid'}
                borderColor={formik.values.fontSize === o.value && 'gray.500'}
                backgroundColor={colors.bg}
                onClick={() => formik.setFieldValue('fontSize', o.value)}
              >
                {o.label}
              </Box>
            ))}
          </HStack>

        </FieldInlineWrapper>

        <FieldInlineWrapper
          label={'Align'}
        >
          <HStack>
            <Box
              {...defaultButtonProps}
              borderWidth={'1px'}
              borderColor={formik.values.align === 'left' ? 'gray.500' : 'transparent'}
              backgroundColor={colors.bg}
              onClick={() => formik.setFieldValue('align', 'left')}
            >
              <AlignLeftIcon />
            </Box>
            <Box
              {...defaultButtonProps}
              borderWidth={'1px'}

              borderColor={formik.values.align === 'center' ? 'gray.500' : 'transparent'}

              backgroundColor={colors.bg}
              onClick={() => formik.setFieldValue('align', 'center')}
            >
              <AlignCenterIcon />
            </Box>
          </HStack>
        </FieldInlineWrapper>


        <FieldInlineWrapper
          label={'Slides direction'}
        >

          <HStack>
            <Box
              {...defaultButtonProps}
              borderWidth={'1px'}
              borderColor={!formik.values.slidesDirection || formik.values.slidesDirection === 'upToDown' ? 'gray.500' : 'transparent'}
              backgroundColor={colors.bg}
              onClick={() => formik.setFieldValue('slidesDirection', 'upToDown')}
            >
              <Box transform={'rotate(-45deg)'}>
                <ArrowsAltOutlined />
              </Box>
            </Box>
            <Box
              {...defaultButtonProps}
              borderWidth={'1px'}
              borderColor={formik.values.slidesDirection === 'rightToLeft' ? 'gray.500' : 'transparent'}
              backgroundColor={colors.bg}
              onClick={() => formik.setFieldValue('slidesDirection', 'rightToLeft')}
            >
              <Box transform={'rotate(45deg)'}>
                <ArrowsAltOutlined />
              </Box>
            </Box>
          </HStack>
        </FieldInlineWrapper>


        <FieldInlineWrapper
          label={'Buttons radius'}
        >

          <HStack>
            <Box
              {...defaultButtonProps}
              borderWidth={'1px'}
              borderColor={formik.values?.buttonRadius === 'sharp' ? 'gray.500' : 'transparent'}
              backgroundColor={colors.bg}
              onClick={() => formik.setFieldValue('buttonRadius', 'sharp')}
            >
              <Box>
                Sharp
              </Box>
            </Box>
            <Box
              {...defaultButtonProps}
              borderWidth={'1px'}
              borderColor={formik.values.buttonRadius !== 'sharp' ? 'gray.500' : 'transparent'}
              backgroundColor={colors.bg}
              onClick={() => formik.setFieldValue('buttonRadius', 'round')}
            >
              <Box>
                Round
              </Box>
            </Box>
          </HStack>

        </FieldInlineWrapper>


        {isSuperUser && <Box>
          <FieldInlineWrapper>

            <HStack>
              <Box
                {...defaultButtonProps}
                borderWidth={'1px'}
                borderColor={formik.values?.isGalleryTheme ? 'gray.500' : 'transparent'}
                backgroundColor={colors.bg}
                onClick={() => formik.setFieldValue('isGalleryTheme', true)}
              >
                <Box>
                  yes
                </Box>
              </Box>
              <Box
                {...defaultButtonProps}
                borderWidth={'1px'}
                borderColor={!formik.values.isGalleryTheme ? 'gray.500' : 'transparent'}
                backgroundColor={colors.bg}
                onClick={() => formik.setFieldValue('isGalleryTheme', false)}
              >
                <Box>
                  no
                </Box>
              </Box>

            </HStack>

          </FieldInlineWrapper>
          <FieldInlineWrapper>
            <CustomNumberInput
              // formik={formik}

              value={formik.values.order}
              onChange={(e) => formik.setFieldValue('order', e)}
            />
          </FieldInlineWrapper>
        </Box>
        }
      </Box>



    </Box>
  );
};

export const Footer = styled(Box)` 
  // position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #dddddd;
`;

export const IconButton = styled(Box)` 
  cursor: pointer;
  padding: 4px;
`;

export default ThemeEditor;