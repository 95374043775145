import { Box, Center, Flex, Icon } from "@chakra-ui/react";
import { Input } from "antd";
import React, { useState } from 'react';
import styled from "styled-components";
import CenterImage from "../../../../shared/CenterImage";
import CustomModal from "../../../../shared/CustomModal";
import FieldWrapper from "../../../../shared/FieldWrapper";
import UnderlineTextButton from "../../../../shared/UnderlineTextButton";
import { modalStore, randomId } from "../../../../utils/common";
import { Player } from "../../components/Content";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { dropdownIcon } from "../icons";
import { FakeInput } from '../TextField/TextField';

const FIELD_RIGHT_MARGIN = '12px'

export const OptionContainer = styled(Box)`
  padding: 5px;
  position: relative;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: ${FIELD_RIGHT_MARGIN};
  display: flex;
  flex-direction: column;
`;

export const OptionsToolsBarWrapper = styled(Center)`
    position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  z-index: 9;
  padding-bottom: 3px;
`;

export const OptionsToolsBar = styled(Center)`
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;  
  padding: 3px 8px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end; 
`;


const toString = (options = []) => {
    return options.map(s => s.name).join('\n')
}



const toOptions = (prevOptions = [], values = '') => {

    const splitVal = values.split('\n')

    const results = splitVal.map((v, index) => {
        if(prevOptions[index]){
            return {
                name: v,
                id: prevOptions[index].id
            }
        }
        return {
            name: v,
            id: randomId()
        }
    })

    return results
}

const useOptionSettingsModalStore = modalStore()

const OptionSettingsModal = () => {

    const [isOpen, onClose, data = {}] = useOptionSettingsModalStore(store => ([
        store.isOpen,
        store.onClose,
        store.data,
    ]))

console.log(data)
    const { onSave } = data


    const [optionData, setOptionData] = useState(toString(data?.options))

    const { type, label, width, required, options = [], format, split } = optionData

    const onChangeHandler = (name, value) => {
        setOptionData({ ...optionData, [name]: value })
    }

    if (!isOpen) return null

    return <CustomModal
        title={'Add choices'}
        isOpen={isOpen}
        onClose={onClose}
        onOk={() => {
            onSave(toOptions(data?.options, optionData))
            setOptionData(undefined)
            onClose()
        }}
        size={'xl'}
    >
        <Box mb={'15px'}>
            <Box
                mb={'5px'}
            >
                Write or paste your choices below. Each choice must be on a separate line.
            </Box>
            <Input.TextArea
                value={optionData}
                onChange={e => setOptionData(e.target.value)}
                style={{
                    background: 'white',
                    borderRadius: '6px'
                }}
                autoSize={true}
                
            />
        </Box>
    </CustomModal>
}



const Configurator = ({ options = [], onChange, color, backgroundColor, generalSettings, theme, messages }) => {

    const [isOpen, onOpen] = useOptionSettingsModalStore(store => ([
        store.isOpen,
        store.onOpen,
    ]))

    const { questionColor, fontSize, align, answerColor, buttonBackground, buttonColor } = theme


    const onSaveOptionSettings = (options) => {
        onChange(options)
    }

    const onSettingsModalOpen = () => {
        onOpen({ options, onSave: onSaveOptionSettings })
    }

    return (
        <>
            {isOpen && <OptionSettingsModal />}
            <Box
                w={'100%'}
            >

                <Box>
                    <FakeInput
                        fontSize={fontSize === 'sm' && '20px'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        color={answerColor}
                        display="flex"
                    >

                        {messages['selectPlaceholder']}


                        <Box w={'20px'}
                        pt={'10px'}
                            color={answerColor}

                        >
                            <Icon viewBox='0 0 14 14' width={'100%'} color={answerColor}>
                                <path fill={answerColor} fill-rule="evenodd" clip-rule="evenodd" d="M6.00008 3.58581L3.12139 0.707124C2.34035 -0.0739251 1.07402 -0.0739234 0.292969 0.707125L6.00008 6.41423L11.7072 0.707125C10.9261 -0.0739234 9.6598 -0.0739234 8.87876 0.707125L6.00008 3.58581Z"></path>
                            </Icon>
                        </Box>


                    </FakeInput>
                </Box>

                <Flex
                    mt={'15px'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <UnderlineTextButton
                        color={color}
                        onClick={onSettingsModalOpen}
                    >
                        Add choices...
                    </UnderlineTextButton>

                    <Box
                    
                    color={color}
                    >
                        {options.length} options
                    </Box>
                </Flex>
            </Box>
        </>
    )
}



const SelectField = ({ fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber, messages }) => {

    const { questionColor, fontSize, align, answerColor, backgroundColor } = theme

    const { options = [], generalSettings = {} } = fieldData
    const { required } = generalSettings
    const imageUrl = fieldData?.layoutConfig?.imageUrl
    const videoUrl = fieldData?.layoutConfig?.videoUrl
    const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

    const onChangeHandler = (options = []) => {
        onChange({ options })
    }

    if (!fieldData) return null


    return (
        <FieldWrapper>

            <TitleAndDescriptionInputs
                mentionOptions={mentionOptions}
                afterContent={required && '*'}
                beforeContent={showQuestionNumber && `${fieldData?.order}.`}
                data={fieldData}
                onChange={onChange}
                color={questionColor}
                fontSize={fontSize}
                textAlign={align}
                id={fieldData.id}
            />

            {layoutId === 1 && <Box
                width={'100%'}
                mb={['30px']}
            >
                {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
                    justifyContent={align === 'center' ? 'center' : 'flex-start'}
                    src={imageUrl}
                    height={'100%'}
                    width={'100%'}
          maxHeight='380px'

                />}
            </Box>}

            <Box
                display={'flex'}
                justifyContent={align}
            >
                <Configurator
                    options={options}
                    onChange={onChangeHandler}
                    generalSettings={generalSettings}
                    color={answerColor}
                    backgroundColor={backgroundColor}
                    theme={theme}
                    messages={messages}
                />
            </Box>

        </FieldWrapper>
    );
};

export default SelectField;

