import {
  CopyOutlined, FacebookOutlined,
  LinkedinOutlined, MailOutlined, QrcodeOutlined, TwitterOutlined
} from "@ant-design/icons";
import { Box, Center, HStack, VStack } from "@chakra-ui/react";
import { Space, Tabs, Tooltip } from "antd";
import React, { useState } from 'react';
import { HEADER_HEIGHT } from '../..';
import { StyledInput } from "../../../../shared/form/InlineTextField";
import Anhor from "../../../../shared/Anhor";
import { TableButton } from "../analytics/ResponsesTable";
import EmbedSection from './EmbedSection';
import GetCodeModal from './GetCodeModal';
import MetaData from './MetaData';
import QRModal, { useQRModalStore } from "./QRModal";
import { StyledTabs } from "../ConfiguratorHeader";

const {TabPane} = Tabs;

const ShareSection = ({ formData = {} }) => {

  const [onOpen] = useQRModalStore(store => [store.onOpen])

  const { id } = formData

  const linkUrl = `https://form.formee.io/${id}`

  const fbLink = `https://www.facebook.com/sharer/sharer.php?u=${linkUrl}`
  const twitterLink = `https://twitter.com/share?url=${linkUrl}`
  const linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?url=${linkUrl}`
  const gmailLink = `https://mail.google.com/mail/u/0/?fs=1&tf=cm&to&su=My+form&body=%27${linkUrl}%27&ui=2`




  return <>
    <VStack>

      <div class="py-6" >
        <div class="text-font_primary font-semibold text-lg text-center">Get the link or share on social</div>
        <div class="text-font_secondary font-normal text-sm text-center  py-2 px-6 lg:px-24 mx-auto"> This way of publishing your form is perfect if you want to handle a small amount of code
          or if you want to directly invite somebody to view your form.</div>

      </div>



      <HStack pt={'20px'}>
        <StyledInput
          w={'500px'}
          value={linkUrl}
        />
        <Box>

          <Tooltip title={'Copy to clipboard'}>
            <TableButton onClick={() => {
              navigator.clipboard.writeText(linkUrl)
            }}>
              <HStack>
                <Box>
                  <CopyOutlined />
                </Box>
              </HStack>
            </TableButton>
          </Tooltip>
        </Box>
      </HStack>

      <Box pb={'40px'}>
        <Space style={{ fontSize: '26px', color: '#808080' }}>
          <Anhor href={twitterLink}>
            <Box mr={'10px'} cursor={'pointer'}>
              <TwitterOutlined />
            </Box>
          </Anhor>

          <Anhor href={fbLink}>
            <Box mr={'10px'} cursor={'pointer'}>
              <FacebookOutlined />
            </Box>
          </Anhor>

          <Anhor href={linkedInLink}>
            <Box mr={'10px'} cursor={'pointer'}>
              <LinkedinOutlined />
            </Box>
          </Anhor>

          <Anhor href={gmailLink}>
            <Box mr={'10px'} cursor={'pointer'}>
              <MailOutlined />
            </Box>
          </Anhor>

          <Box mr={'10px'} cursor={'pointer'} onClick={() => onOpen({ linkUrl })}>
            <QrcodeOutlined />
          </Box>

        </Space>
      </Box>


    </VStack>


  </>
}

const Share = ({ formData = {} }) => {

  const [currentTab, setCurrentTab] = useState('1')


  return (
    <>
      <Box>
        <QRModal />
        <GetCodeModal />

        <Box
          borderBottom={'1px solid #dddddd'}
          bg={'white'}
        >
          <Container>
            <StyledTabs
              defaultActiveKey={'1'}
              activeKey={currentTab}
              onChange={(e) => setCurrentTab(e)}
              style={{ padding: '10px' }}
              pb={'0px'}
            >
              <TabPane tab={<Box
                fontSize={'14px'}>Share link</Box>} key={'1'}
              >
              </TabPane>
              <TabPane tab={<Box fontSize={'14px'}>Embed</Box>} key={'2'}>
              </TabPane>
              <TabPane tab={<Box fontSize={'14px'}>Meta data</Box>} key={'3'}>
              </TabPane>
            </StyledTabs>
          </Container>
        </Box>


        <Box h={`calc(100vh - ${HEADER_HEIGHT} - 47px)`} overflow={'auto'}>

          {currentTab === '1' && <Container>
            <Box>
            <ShareSection formData={formData} />

            </Box>
          </Container>}

          {currentTab === '2' && <EmbedSection />}
          {currentTab === '3' && <MetaData formData={formData} />}

        </Box>



        
      </Box>

    </>
  );
};



export const Container = ({ children, ...rest }) => {
  return <Box
    maxW={'1024px'}
    p={'0 15px'}
    w={'100%'}
    margin={'0 auto'}
    {...rest}
  >
    {children}
  </Box>
};

export default Share;