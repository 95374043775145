import React from 'react';
import {Select} from "antd";
import {DEFAULT_INPUT_HEIGHT, defaultOnChange} from "./useFormBuilder";
import styled from "styled-components";
import colors from "../../utils/commonStyles";

const CustomSearchableSelect = ({value, onChange, name, options, children, showSearch = true, permittedValues}) => {

  if (permittedValues && (!permittedValues?.includes(value))) {
    value = undefined
  }

  return (
    <>
      <StyledSelect
        options={options}
        value={value}
        showSearch={showSearch}
        onChange={onChange}
        dropdownMatchSelectWidth={false}
        // style={{height: DEFAULT_INPUT_HEIGHT, width: '100%'}}
      >
        {children}
      </StyledSelect>
    </>
  );
};

export const StyledSelect = styled(Select)`
  height: ${DEFAULT_INPUT_HEIGHT};
  width: 100%;
 
  
  .ant-select-selector{
   border-color: ${colors.border} !important;
    background: ${props => props.bg || colors.lightGrey} !important;
  }
`;

export default CustomSearchableSelect;