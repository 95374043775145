import {LOGOUT, LOGIN, UPDATE_PROFILE} from "../constants";

const initialState = {
  accessToken: null,
  isAuthenticated: false,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {...initialState};
    case LOGIN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        isAuthenticated: true,
        user: action.payload
      };
    default:
      return state;
  }
};
