import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useUserState} from "../../../App";
import _ from "lodash";
import {useSelector} from "react-redux";

const useOrganizationLocation = (redirect = true) => {

  const history = useHistory()

  const {organizationId, workspaceId} = useParams()

  const {user} = useSelector(state => state.persistentState)

  const [
    organizations,
    setCurrentOrganization,
  ] = useUserState(store => ([
    store.organizations,
    store.setCurrentOrganization,
  ]))

  useEffect(() => {

    if (!_.isEmpty(organizations)) {

      let organization = organizations?.find(o => o.id === organizationId)

      if (!organization) {
        const findMyOrganization = organizations.find(o => o.owner?.id === user.id)
        organization = findMyOrganization
      }

      if (!organization) return

      let workspaces = organization.workspaces

      let workspace = workspaces?.find(o => o.id === workspaceId)

      if (!workspace) {
        workspace = workspaces[0]
      }

      redirect &&  history.push(`/organization/${organization.id}/workspace/${workspace.id}`)

    }
  }, [organizations, organizationId, user])


  useEffect(() => {
    if (!_.isEmpty(organizations)) {
      const currentOrganization = organizations.find(o => o.id === organizationId)

      if (currentOrganization) {
        let permissions = []
console.log(currentOrganization, 'currentOrganization')
        const isOwner = currentOrganization.owner.id === user?.id

        if (!isOwner) {
          permissions = currentOrganization.permissions[user?.id]
        }

        setCurrentOrganization({
          isOwner,
          currentOrganization,
          permissions
        })
      }

    }
  }, [organizations, organizationId, user])
}

export default useOrganizationLocation;