import React, { useEffect, useState } from 'react'
import { Divider, Dropdown, Empty, Space, Spin } from 'antd'
import { getForms } from "../../firebase/form";
import { Box, Button, Center, HStack } from '@chakra-ui/react'
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import PrimaryButton from "../../shared/PrimaryButton";
import { AddIcon } from "@chakra-ui/icons";
import colors from "../../utils/commonStyles";
import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import CustomDropdown, { DropDownMenu } from "./components/CustomDropdown";
import AddWorkspaceModal, { useAddWorkspaceModalStore } from "./components/AddWorkspaceModal";
import { useUserState } from "../../App";
import AddFormModal, { useAddFormModalStore } from "./components/AddFormModal";
import useOrganizationLocation from "./hooks/useOrganizationLocation";
import useUserPermissions from "./hooks/useUserPermissions";
import FormListCard from "./components/FormListCard";
import RenameFormModal from "./components/RenameFormModal";
import FormMoveToModal from "./components/FormMoveToModal";
import ConfirmFormDeleteModal from "./components/ConfirmFormDeleteModal";
import SearchInput from "./components/SearchInput";
import { emptyIllustration } from "../../utils/illustrations";
import _ from 'lodash'
import ChartsDashboard from "./components/ChartsDashboard";
import { useQueryClient } from 'react-query';

export const INPUT_BORDER_RADIUS = '10px'

export const PageHeader = ({ title }) => {

  return <Box
    color={colors.black}
    fontSize={'31px'}
    fontWeight={600}
  >
    {title}
  </Box>
}

const workspacesToOptions = (data) => data.map(d => ({
  value: d.id,
  label: d.name,
}))

const Dashboard = () => {
  const history = useHistory()

  const { organizationId, workspaceId } = useParams()

  const queryClient = useQueryClient()

  const [searchValue, setSearchValue] = useState()

  const [onOpenAddWorkspaceModal] = useAddWorkspaceModalStore(store => [store.onOpen])
  const [onOpenAddFormModal] = useAddFormModalStore(store => [store.onOpen])

  const [
    currentOrganization
  ] = useUserState(store => ([
    store.currentOrganization
  ]))

  let workspaces = currentOrganization?.workspaces || []

  let forms = workspaces.find(w => w.id === workspaceId)?.forms || []

  if(searchValue){
    forms = forms.filter(f => f.name.toLocaleLowerCase().includes(searchValue))
  }
  const fetchForms = async (workspaceId) => {
    queryClient.invalidateQueries('organizations')
  }



  const workspaceOptions = workspacesToOptions(workspaces)
  const currentWorkspaceLabel = workspaces?.find(w => w.id === workspaceId)?.name
  const isCurrentWorkspaceDefault = workspaces?.find(w => w.id === workspaceId)?.isDefault

  const onChangeWorkspace = (id) => {
    history.push(`/organization/${organizationId}/workspace/${id}`)
  }

  const allowAddWorkspace = useUserPermissions(['workspace_create'])
  const allowRenameWorkspace = useUserPermissions(['workspace_update'])
  const allowDeleteWorkspace = useUserPermissions(['workspace_delete'])
  const allowAddForm = useUserPermissions(['form_create'])

  const onRename = () => {
    onOpenAddWorkspaceModal({
      name: currentWorkspaceLabel,
      update: true
    })
  }

  const onAddWorkspace = () => {
    onOpenAddWorkspaceModal()
  }

  const onDelete = () => {
    onOpenAddWorkspaceModal({
      delete: true
    })
  }

  const moreOptions = [
    {
      label: 'Add workspace',
      value: 'add',
      onClick: onAddWorkspace,
      visible: allowAddWorkspace
    },
    {
      label: 'Rename',
      value: 'rename',
      onClick: onRename,
      visible: allowRenameWorkspace
    }, {
      label: 'Delete',
      value: 'delete',
      onClick: onDelete,
      visible: !isCurrentWorkspaceDefault && allowDeleteWorkspace
    },
  ]

  const moreOptionsMenu = <DropDownMenu
    options={moreOptions}
  />

  const CreateFormButton = allowAddForm ? <PrimaryButton
    onClick={() => {
      onOpenAddFormModal()
    }}
    size={'sm'}
    leftIcon={<AddIcon />}
  >
    Create form
  </PrimaryButton> : null

  const CreateFirstFormButton = allowAddForm ? <PrimaryButton
    onClick={() => {
      onOpenAddFormModal()
    }}
    size={'sm'}
    leftIcon={<AddIcon />}
  >
    Create your first form
  </PrimaryButton> : null

  const emptyContent = _.isEmpty(forms) && <Center
    bg={'white'}
    borderRadius={'14px'}
    p={'60px 0px'}
  >
    <Box
      d={'flex'}
      flexDirection={'column'}
    >
      <Box w={'200px'}>
        {emptyIllustration}
      </Box>
      <Center mt={'25px'}>
        <Space>
          {CreateFirstFormButton}
        </Space>
      </Center>
    </Box>
  </Center>

  const formsContent = !_.isEmpty(forms) && <>
   

    {forms.map(f => <FormListCard
      key={f.id}
      form={f}
      fetchForms={() => fetchForms(workspaceId)}
    />)}
  </>

  return (
    <>
      <RenameFormModal fetchForms={fetchForms} />
      <FormMoveToModal workspaces={workspaces || []} fetchForms={() => fetchForms(workspaceId)} />
      <ConfirmFormDeleteModal fetchForms={fetchForms} />
      <Box
        p={'20px 30px'}
      >
        <HStack
          mb={'40px'}
          justifyContent={'space-between'}
        >
          <Space size={12}>
            <Box
              mr={'20px'}
            >
              <PageHeader
                title={'Forms'}
              />
            </Box>
            {CreateFormButton}
          </Space>
          <Box>
            <Space>
              <Dropdown
                overlay={moreOptionsMenu}
                trigger={'click'}
                placement="bottomRight"
              >
                <Box
                  _hover={{ bg: '#f2f2f2' }}
                >
                  <Button
                    type={'link'}
                    style={{ background: 'transparent' }}
                  >
                    <MoreOutlined style={{ fontSize: '24px' }} />
                  </Button>
                </Box>
              </Dropdown>
              <CustomDropdown
                value={workspaceId}
                onChange={e => onChangeWorkspace(e)}
                options={workspaceOptions}
              />
            </Space>
          </Box>
        </HStack>

        <Box>

          {/*<ChartsDashboard/>*/}
          <Box
            bg={'white'}
            borderRadius={'14px'}
          >
             <Box p={'16px'} pb={'0'}>
      <Box
        d={'flex'}
        justifyContent={'space-between'}
      >
        <Box>
          
        </Box>
        {/* <Box
          // bg={colors.bg}
          bg={colors.bgDark}
          borderRadius={INPUT_BORDER_RADIUS}
          w={'230px'}
          p={'10px 18px'}
        >
          <HStack
            justifyContent={'space-between'}
          >
            <Box
            >
              Sort by answer
            </Box>
            <DownOutlined />
          </HStack>
        </Box> */}

        <SearchInput
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
      </Box>
      <Divider style={{ marginBottom: '0px' }} />
    </Box>
            {emptyContent}
            {formsContent}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const Home = ({ }) => {

  const history = useHistory()
  const { organizationId, workspaceId } = useParams()


  const [
    workspaces,
  ] = useUserState(store => ([
    store.workspaces,
  ]))

  useOrganizationLocation()

  return <>
    <AddWorkspaceModal workspaces={workspaces || []} />
    <AddFormModal />

    <Switch>
      <Route path={''} component={Dashboard} />
      {/*<Route path={'/settings'} component={Settings}/>*/}
      {/*  <Route path={'/templates'} component={Templates}/>*/}
    </Switch>
  </>
}


export default Home
