import React from 'react';
import {useFormik} from "formik";

export const DEFAULT_INPUT_HEIGHT = '32px'

export function useDefaultFormik(props) {
  return useFormik({
    validateOnChange: false,
    onSubmit: () => {
    },
    ...props
  })
}

export const defaultOnChange = (formik) => async (name, value) => {
  return new Promise(resolve => {
    formik.setFieldValue(name, value).then(() => {
      if (formik.submitCount > 0) {
        formik.validateForm().then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  });
};
