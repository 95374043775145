import React from 'react';
import {Box, HStack, Button, Center} from '@chakra-ui/react'

const SectionHeader = (props) => {
  return (
    <Box
      {...props}
      fontWeight={600}
    >
      {props.children}
    </Box>
  );
};

export default SectionHeader;