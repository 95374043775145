import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Box, Center, Flex} from "@chakra-ui/react";
import {IndexCharContainer, indexChars} from "../MultiChoice/MultiChoice";
import {modalStore, randomId, removeById, shade} from "../../../../utils/common";
import CustomWysiwyg from "../../../../shared/CustomWysiwyg";
import {CloseCircleOutlined, DeleteOutlined, SettingOutlined} from "@ant-design/icons";
import {initialDraftContent} from "../config";
import {ReactSortable} from "react-sortablejs";
import UnderlineTextButton from "../../../../shared/UnderlineTextButton";
import FieldWrapper from "../../../../shared/FieldWrapper";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {Player} from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import {setColorOpacity} from "../MultiChoice/MultiChoice";
import {ImageIcon} from "../icons";
import colors from "../../../../utils/commonStyles";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {Input, Space, Radio} from "antd";
import CustomModal from "../../../../shared/CustomModal";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import FormDraggableSelect from '../../../../shared/form/CustomDraggableSelect'

const FIELD_RIGHT_MARGIN = '12px'

export const OptionContainer = styled(Box)`
  padding: 5px;
  position: relative;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: ${FIELD_RIGHT_MARGIN};
  display: flex;
  flex-direction: column;
`;

export const OptionsToolsBarWrapper = styled(Center)`
    position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  z-index: 9;
  padding-bottom: 3px;
`;

export const OptionsToolsBar = styled(Center)`
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;  
  padding: 3px 8px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end; 
`;

const fieldTypeOptions = [
  {
    value: 'textfield',
    label: 'Text'
  }, {
    value: 'email',
    label: 'E-mail'
  }, {
    value: 'number',
    label: 'Number'
  }, {
    value: 'date',
    label: 'Date'
  }, {
    value: 'select',
    label: 'Dropdown'
  },
]


const formatOptions = [
  {
    value: 'dd-mm-yyyy',
    label: 'DDMMYYYY'
  }, {
    value: 'mm-dd-yyyy',
    label: 'MMDDYYYY'
  }, {
    value: 'yyyy-mm-dd',
    label: 'YYYYMMDD'
  },
]

const splitOptions = [
  {
    value: 'line',
    label: '-',
  }, {
    value: 'slash',
    label: '/',
  }, {
    value: 'dot',
    label: '.',
  },
]

const useOptionSettingsModalStore = modalStore()
// yyyy/MM/dd
const OptionSettingsModal = () => {

  const [isOpen, onClose, data = {}] = useOptionSettingsModalStore(store => ([
    store.isOpen,
    store.onClose,
    store.data,
  ]))

  const {onSave} = data

  const [optionData, setOptionData] = useState(data?.optionData)

  const {type, label, width, required, options = [], format, split} = optionData

  const onChangeHandler = (name, value) => {
    setOptionData({...optionData, [name]: value})
  }

  return <CustomModal
    title={'Field settings'}
    isOpen={isOpen}
    onClose={onClose}
    onOk={() => {
      onSave(optionData)
      onClose()
    }}
    size={'xl'}
  >
    <Box mb={'15px'}>
      <Box
        mb={'5px'}
      >
        Field type
      </Box>
      <StyledSelect
        value={type}
        onChange={e => onChangeHandler('type', e)}
        options={fieldTypeOptions}
      />
    </Box>

    <Box mb={'15px'}>
      <Box
        mb={'5px'}
      >
        Label
      </Box>
      <InlineTextField
        bg={'white'}
        value={label}
        onChange={e => onChangeHandler('label', e)}
      />
    </Box>


    {type === 'select' && <Box mb={'15px'}>
      <Box
        mb={'5px'}
      >
        Options
        {/*<span style={{fontStyle: "italic", fontSize: '12px'}}>(type and press enter)</span>*/}
      </Box>
      <FormDraggableSelect
        sortableList={true}
        value={options}
        onChange={e => onChangeHandler('options', e)}
      />
    </Box>
    }

    {type === 'date' && <Box d={'flex'} mb={'10px'}>
      <Box
        flex={1}
        py={'10px'}
        mr={'10px'}
        // label={'Required'}
      >
        <Box mb={'5px'}>
          Format
        </Box>
        <StyledSelect
          value={format}
          onChange={e => onChangeHandler('format', e)}
          // style={{width: '80px'}}
          options={formatOptions}
        />

      </Box>


      <Box
        flex={1}
        py={'10px'}
      >
        <Box mb={'5px'}>
          Split
        </Box>
        <StyledSelect
          value={split}
          onChange={e => onChangeHandler('split', e)}
          // style={{width: '150px'}}
          options={splitOptions}
        />

      </Box>
    </Box>}

    <Box mb={'15px'}>
      <Box
        mb={'5px'}
      >
        Width
      </Box>
      <Radio.Group onChange={e => onChangeHandler('width', e.target.value)} value={width}>
        <Radio value={100}>100%</Radio>
        <Radio value={50}>50%</Radio>
      </Radio.Group>
    </Box>

    <Box mb={'15px'}>
      <Box
        mb={'5px'}
      >
        Required
      </Box>
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </Box>

  </CustomModal>
}

export const OptionItem = ({
                             data = {},
                             onRemove,
                             allowRemove = true,
                             color,
                             backgroundColor,
                             onSettingsModalOpen,
                           }) => {

  const [highlight, setHighlight] = useState()

  const {type, label, width, required} = data

  return <OptionContainer
    w={`calc(${width}% - ${FIELD_RIGHT_MARGIN})`}
    fontSize={'16px'}
    backgroundColor={highlight && setColorOpacity(color, 0.05)}
    color={color}
    borderBottom={'1px solid'}
    borderBottomColor={color}
    onMouseEnter={() => setHighlight(true)}
    onMouseLeave={() => setHighlight(false)}
  >

    {
      allowRemove && highlight && <OptionsToolsBarWrapper>
        <OptionsToolsBar
          backgroundColor={'white'}
          color={colors.primary}
          border={`1px solid ${setColorOpacity(colors.primary, 1)}`}
        >
          <Space size={14}>
            <DeleteOutlined
              style={{cursor: 'pointer', fontSize: '16px'}}
              onClick={onRemove}
            />

            <SettingOutlined
              style={{
                fontSize: '16px',
              }}
              onClick={onSettingsModalOpen}
            />
          </Space>
        </OptionsToolsBar>
      </OptionsToolsBarWrapper>
    }


    <Box>
      {label} {required && "*"}
    </Box>

  </OptionContainer>
}


const Configurator = ({options = [], onChange, color, backgroundColor, generalSettings}) => {

  const [isOpen, onOpen] = useOptionSettingsModalStore(store => ([
    store.isOpen,
    store.onOpen,
  ]))

  const onAddHandler = () => {

    const newOption = {
      id: randomId(),
      label: '',
      type: 'textfield',
      format: 'dd-mm-yyyy',
      split: 'line',
      width: 100
    }

    const updatedOptions = [...options, newOption]
    onChange(updatedOptions)
  }

  const onRemoveHandler = (optionId) => {
    onChange(removeById(options, optionId))
  }

  const onSaveOptionSettings = (data) => {
    const copyOptions = options.map((option, i) => {
      if (data.id === option.id) {
        return {
          ...data
        }
      } else {
        return {...option}
      }
    })
    onChange(copyOptions)
  }

  const onSettingsModalOpen = (optionData) => {
    onOpen({optionData, onSave: onSaveOptionSettings})
  }

  return (
    <>
      {isOpen && <OptionSettingsModal/>}
      <Box
        w={'100%'}
      >
        <ReactSortable
          list={options}
          setList={(data) => {
            onChange(data)
          }}
          style={{display: "flex", flexWrap: "wrap"}}
          id={'sortable'}
        >
          {options.map((o, index) => (
            <OptionItem
              key={o.id}
              data={o}
              onRemove={() => onRemoveHandler(o.id)}
              color={color}
              backgroundColor={backgroundColor}
              onSettingsModalOpen={() => onSettingsModalOpen(o)}
            />
          ))}
        </ReactSortable>


        <Flex
          mt={'15px'}
        >
          <UnderlineTextButton
            color={color}
            onClick={onAddHandler}
          >
            Add field...
          </UnderlineTextButton>
        </Flex>
      </Box>
    </>
  )
}


const ContactForm = ({fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber}) => {

  const {questionColor, fontSize, align, answerColor, backgroundColor} = theme

  const {options = [], generalSettings = {}} = fieldData
  const {required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  const onChangeHandler = (options = []) => {
    onChange({options})
  }

  if (!fieldData) return null


  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}
        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        display={'flex'}
        justifyContent={align}
      >
        <Configurator
          options={options}
          onChange={onChangeHandler}
          generalSettings={generalSettings}
          color={answerColor}
          backgroundColor={backgroundColor}
        />
      </Box>

    </FieldWrapper>
  );
};

export default ContactForm;

