import React, {useEffect, useState} from 'react';

const useConfigurationContentDimensions = (rootRef, leftMenuOpen, rightMenuOpen) => {
  const [contentDimensions, setContentDimensions] = useState({})


  const calculateDimensions = () => {
    const rootRefWidth = rootRef.current.offsetWidth
    const dimensions = {
      width: window.innerWidth - 256 - 256 - 30,
      height: rootRefWidth * 0.55,
      scale: 1
    }

    setContentDimensions(dimensions)
  }


  useEffect(() => {
    window.addEventListener("resize", calculateDimensions);

    calculateDimensions();

    return () => {
      window.removeEventListener("resize", calculateDimensions);
    }

  }, [leftMenuOpen, rightMenuOpen]);

  return contentDimensions;
};

export default useConfigurationContentDimensions;