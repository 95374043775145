import React, {useEffect, useState} from 'react';
import {useGoogleLogin} from '@react-oauth/google';
import {Box, Button} from "@chakra-ui/react";
import {Space} from "antd";
import GoogleLogo from "../assets/googleLogo.svg";
import AuthService from "../services/AuthService";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {login, logout} from "../store/actions/AuthActions";

const ButtonComponent = (props) => {
  return <Button
    background={'white'}
    border={'1px solid'}
    borderColor={'blue.700'}
    _hover={{
      background: 'white',
      opacity: 0.9
    }}
    color={'blue.700'}
    w={['100%']}
    fontWeigth={200}
    {...props}
  >
    <Space>
      <Box>
        <img
          src={GoogleLogo}
        />
      </Box>
      <Box>
        Log in via Google
      </Box>
    </Space>

  </Button>
}

const GoogleLoginButton = ({signIn}) => {

  const dispatch = useDispatch()

  const history = useHistory()

  const [loading, setLoading] = useState()


  const googleSuccess = async (res) => {

    const token = res.access_token

    try {

      const {data} = await AuthService.googleSignIn({token})
      console.log(data)
      signIn(data)
      history.push('/')
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  const googleError = (err) => {
    console.log('Google Sign In was unsuccessful. Try again later', err);
  }

  const login = useGoogleLogin({
    onSuccess: googleSuccess,
    onError: googleError,
  });

  const onClickHandler = async () => {

    dispatch(logout({
      redirectToLogin: false
    }))

    setLoading(true)


    login()

  }

  return (
    <>
      <ButtonComponent
        loading={loading}
        onClick={onClickHandler}
      />
    </>
  );
};

export default GoogleLoginButton;