import React, {useCallback, useState} from 'react';
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {Box, Button} from '@chakra-ui/react'
import CustomModal from "../../../../shared/CustomModal";
import * as Yup from "yup";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import {Select} from "antd";
import _ from 'lodash';
import FieldBlock from './FieldBlock';
import {updateJumpingLogicSettings, updateVariables} from "../../../../firebase/form";
import colors from "../../../../utils/commonStyles";
import moment from 'moment'
import {defaultDateFormat} from "../../../../shared/form/CustomDatePicker";
import MenuItem from "../MenuItem";

const validationSchema = Yup.object().shape({});


export const logicalOperatorOptions = {
  string: [
    {
      label: 'is equal to',
      value: 'IS_EQUAL_TO'
    }, {
      label: 'is not equal to',
      value: 'IS_NOT_EQUAL_TO'
    }, {
      label: 'begins with',
      value: 'BEGINS_WITH'
    }, {
      label: 'ends with',
      value: 'ENDS_WITH'
    }, {
      label: 'contains',
      value: 'CONTAINS'
    }, {
      label: 'does not contain',
      value: 'DOES_NOT_CONTAIN'
    }
  ],
  number: [
    {
      label: 'is equal to',
      value: 'IS_EQUAL_TO'
    }, {
      label: 'is not equal to',
      value: 'IS_NOT_EQUAL_TO'
    }, {
      label: 'is lower than',
      value: 'IS_LOWER_THAN'
    }, {
      label: 'is greater than',
      value: 'IS_GREATER_THAN'
    }, {
      label: 'is lower or equal than',
      value: 'IS_LOWER_OR_EQUAL_THAN'
    }, {
      label: 'is greater or equal than',
      value: 'IS_GREATER_OR_EQUAL_THAN'
    }
  ],
  choice: [
    {
      label: 'is',
      value: 'IS'
    }, {
      label: 'is not',
      value: 'IS_NOT'
    }
  ],
  date: [
    {
      label: 'is on',
      value: 'IS_ON'
    }, {
      label: 'is not on',
      value: 'IS_NOT_ON'
    }, {
      label: 'is before',
      value: 'IS_BEFORE'
    }, {
      label: 'is after',
      value: 'IS_AFTER'
    }, {
      label: 'is before or on',
      value: 'IS_BEFORE_OR_ON'
    }, {
      label: 'is after or on',
      value: 'IS_AFTER_OR_ON'
    }
  ],
  bool: [
    {
      label: 'Yes',
      value: 'YES'
    }, {
      label: 'No',
      value: 'NO'
    }
  ]
}
//xayooo
export const CONDITION_FIELDS = [
  'textfield',
  'multiChoice',
  'select',
  'singleChoice',
  'pictureChoice',
  'yesNo',
  'opinionScale',
  'rating',
  'textarea',
  'email',
  'website',
  'numberfield',
  'fileUpload',
  'datefield',
  'legal',
  'signature'
]

export const getLogicalOperatorsByFieldType = (fieldType, isNumberVariable, isStringVariable, isHiddenVariable) => {
  //xayoo
  if (isStringVariable) {
    return 'string'
  } else if (isHiddenVariable) {
    return 'string'
  } else if (isNumberVariable) {
    return 'number'
  } else if (['textfield', 'textarea', 'email', 'website'].includes(fieldType)) {
    return 'string'
  } else if (['numberfield', 'opinionScale', 'rating'].includes(fieldType)) {
    return 'number'
  } else if (['multiChoice', 'singleChoice', 'pictureChoice', 'yesNo', 'legal', 'select'].includes(fieldType)) {
    return 'choice'
  } else if (['datefield'].includes(fieldType)) {
    return 'date'
  }
}

export const GhostButton = (props) => {
  const {color = colors.primary} = props

  return <Box
    py={'4px'}
    px={'4px'}
    color={color}
    borderBottom={`1px solid ${color}`}
    cursor={'pointer'}
    {...props}
  >{props.children}</Box>
}


export const Card = (props) => <Box
  p={['20px']}
  borderRadius={'6px'}
  bg={'white'}
  boxShadow={'rgba(0, 0, 0, 0.08) 0px 2px 4px 0px'}

  {...props}
>{props.children}</Box>


export const Label = (props) => <Box {...props}
                                     mt={'5px'}
                                     fontWeight={600}
>{props.children}</Box>


export const getFieldValueAndOptions = (field = {}) => {

  let value = null
  let valueOptions = []
  //xayoo
  if (['multiChoice', 'singleChoice', 'pictureChoice', 'select'].includes(field?.type)) {
    value = field?.options[0]?.id
    valueOptions = field?.options?.map(o => ({value: o.id, label: o?.name}))
  }
  if (['yesNo'].includes(field?.type)) {
    value = 'yes'
  }
  if (['legal'].includes(field?.type)) {
    value = 'accept'
  }
  if (['numberfield', 'opinionScale', 'rating'].includes(field?.type)) {
    value = 0
  }
  if (['textfield', 'textarea', 'email', 'website'].includes(field?.type)) {
    value = 0
  }
  if (['datefield'].includes(field?.type)) {
    value = moment().format(defaultDateFormat)
  }

  return {
    value,
    valueOptions: valueOptions || []
  }
}


const JumpingLogicSettingsModal = ({isOpen, onClose}) => {

  const [
    formData = {},
    fields,
    endingFields = [],
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.endingFields,
    state.setFormData,
  ])

  console.log(fields, 'fields')
  const [jumpingLogicSettings, setJumpingLogicSettings] = useState(formData.jumpingLogicSettings || {})

  const [saving, setSaving] = useState()

  const saveHandler = useCallback(
    async () => {
      setSaving(true)
      try {

        const requestData = {
          jumpingLogicSettings,
          formId: formData.id
        }

        await updateJumpingLogicSettings(requestData)

        setFormData({...formData, jumpingLogicSettings})
        onClose()

      } catch (e) {
        console.log(e)
      } finally {
        setSaving(false)
      }
    },
    [jumpingLogicSettings]
  )

  return <CustomModal
  isCentered={false}
    zIndex={12}
    title={'Logic and Calculations'}
    isOpen={isOpen}
    onClose={onClose}
    onOk={saveHandler}
    okButtonProps={{
      isLoading: saving
    }}
    size={'5xl'}
  >
    {fields.map((field, index) => <FieldBlock
      fields={fields}
      order={index + 1}
      fieldData={field}
      endingFields={endingFields}
      onChange={(data) => setJumpingLogicSettings(prev => ({
        ...prev,
        [field.id]: data
      }))}
      data={jumpingLogicSettings[field.id]}
    />)}
  </CustomModal>
}


const JumpingLogicSettings = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <MenuItem
        onClick={() => setOpen(true)}
      >
        Branching and Calculations >
      </MenuItem>
      {isOpen && <JumpingLogicSettingsModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />}
    </div>
  );
};

export default JumpingLogicSettings;