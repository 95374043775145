import React, {useEffect, useState} from 'react';
import useOrganizationLocation from "../Home/hooks/useOrganizationLocation";
import {Box, HStack, Center, Grid} from "@chakra-ui/react";
import SideBar from "../Home/components/SideBar";
import {PageHeader} from "../Home";
import SearchInput from "../Home/components/SearchInput";
import {TextEllipsis} from "../FormConfigurator/components/FieldsList";
import SecondaryButton from "../../shared/SecondaryButton";
import {getTemplates} from "../../firebase/form";
import {Spin} from "antd";
import PrimaryButton from "../../shared/PrimaryButton";
import colors from "../../utils/commonStyles";
import useDuplicateQuery from "../Home/hooks/useDuplicateQuery";
import {useHistory} from "react-router-dom";
import TemplateModal, { useTemplateModalStore } from '../ChooseTamplate/ChooseTemplateModal';
import Anhor from '../../shared/Anhor';

const Card = ({title, imageUrl, id, form,onUse}) => {
console.log(form, 'form')
  const history = useHistory()
  const [hover, setHover] = useState()


  const {duplicateFormHandler, loading} = useDuplicateQuery(({formId}) => {
    history.push(`/form/${formId}/create`, {
      showNameModal: true
    })
  })


  const ActionButtons = hover && <Center
    bg={'rgba(0,0,0,0.2)'}
    // opacity={'0.2'}
    position={'absolute'} w={'100%'} h={'100%'}
  >
    <Box>
      <PrimaryButton
        size={'sm'}
        // onClick={() => duplicateFormHandler({formId: id})}
        onClick={() => onUse()}
        loading={loading}
      >
        Use template
      </PrimaryButton>
      <Box mt={'10px'}>
        <Anhor href={`https://form.formee.io/${id}?isPreviewMode=true`} target={'_blank'}>
        <SecondaryButton
          bg={'rgba(255,255,255,0.7)'}
          size={'sm'}

        >
          Preview
        </SecondaryButton>
        </Anhor>
      </Box>
    </Box>
  </Center>

  return <Box
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
    p={'10px'}
    pb={'10px'}
    // boxShadow={'rgba(0, 0, 0, 0.16) 0px 1px 4px'}

    // border={'1px solid #dddddd'}
    borderRadius={'8px'}
    bg={colors.bg}
    // fontWeight={300}
    // fontSize={'18px'}

  >
    <Box
      h={['150px']}
      borderRadius={'8px'}
      position={'relative'}
      overflow={'hidden'}
      mb={'7px'}
    >

      {ActionButtons}
      <img src={imageUrl}
           style={{
             objectFit: 'cover',
             height: '100%',
             width: '100%',
           }}/>
    </Box>
    <Box p={'2px 3px 0 3px'}>
      <TextEllipsis linesLength={1}>
        {title}
      </TextEllipsis>
    </Box>
  </Box>
}

const CategorySectionTitle = ({children, index, ...rest}) => {
  return <Box
    py={'13px'}
    mb={'20px'}
    borderBottom={'1px solid #dddddd'}
    // letterSpacing={'3px'}
    // textTransform={'uppercase'}
    fontWeight={600}
    fontSize={'18px'}

  >
    {children}
  </Box>
}

const CategoryItem = ({children, index, ...rest}) => {
  return <Box
    py={'12px'}
    borderTop={index !== 0 && '1px solid #f2f2f2'}
    // letterSpacing={'3px'}
    // textTransform={'uppercase'}
    fontWeight={600}
    fontSize={'16px'}

  >
    {children}
  </Box>
}

const Templates = ({}) => {

  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])

  const [onOpen] = useTemplateModalStore(store => [store.onOpen])

  const fetchTemplates = async () => {
    setLoading(true)

    try {
      const data = await getTemplates()
      setCategories(data)

    } catch (err) {

    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    fetchTemplates()
  }, [])


  return (
    <Box
      h={'100vh'}
    >
      <TemplateModal/>
      {/*Header/>*/}
      <Box
        display={'flex'}
      >
        <SideBar
          activeItem={'Templates'}
        />

        <Box
          flex={1}
          // p={'20px'}
          bg={'#F2F3F7'}
          // bg={'#FAFAFA'}
        >
          <Box
            h={'100vh'}
            d={'flex'}

            flexDirection={'column'}
            p={'40px 50px'}
            overflow={'auto'}


          >
            <HStack
              mb={'20px'}
              justifyContent={'space-between'}
            >
              <PageHeader title={'Templates'}/>
            </HStack>


            {/*<Box*/}
            {/*  borderRadius={'14px'}*/}
            {/*  bg={'white'}*/}
            {/*  p={'5px 20px'}*/}
            {/*  maxW={'300px'}*/}
            {/*  flex={1}*/}
            {/*  mr={'10px'}*/}
            {/*>*/}

            {/*  {categories.map((c, index) => <CategoryItem*/}
            {/*    index={index}*/}
            {/*  >*/}
            {/*    {c.name}*/}
            {/*  </CategoryItem>)}*/}

            {/*</Box>*/}

            <Box
              borderRadius={'14px'}
              bg={'white'}
              p={'20px'}
              flex={1}
              mb={'20px'}
            >

              {loading && <Center h={'100%'}><Spin/></Center>}

              {!loading && <>
                <SearchInput maxW={'400px'}/>
                <Box overflow={'auto'}>


                  {categories.map(c => <Box mt={'20px'}>
                    <CategorySectionTitle>
                      {c.name}
                    </CategorySectionTitle>

                    <Grid
                      templateColumns={[
                        // 'repeat(4, 1fr)',
                        'repeat(auto-fill, minmax(250px,298px))',

                      ]}
                      gap={6}
                    >
                      {c?.forms?.map(f => <Card key={f.id} id={f.id} title={f.name}
                      form={f}
                      imageUrl={f.templateImageUrl}
                      onUse={() => {
                        console.log('eeee')
                        onOpen({id: f.id})
                      }}
                      />)}
                    </Grid>

                  </Box>)}

                </Box>
              </>}

            </Box>


          </Box>
        </Box>
      </Box>
    </Box>
  );
};


export default Templates;