import React from 'react';
import {Box, HStack, Button, Center, Divider} from '@chakra-ui/react'
import {DeleteOutlined} from "@ant-design/icons";

const DeleteButton = (props) => {
  return (
    <Center
      w={'30px'}
      h={'30px'}
      borderRadius={'6px'}
      cursor={'pointer'}
      bg={'#dddddd'}
      {...props}
    >
      <DeleteOutlined/>
    </Center>
  );
};

export default DeleteButton;