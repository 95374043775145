import FieldWrapper from "../../../../shared/FieldWrapper";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {Box, Flex} from "@chakra-ui/react";
import {Player} from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import React from "react";
import {FakeButton, FakeInput} from "../TextField/TextField";

const WebsiteField = ({fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber, messages}) => {

  if (!fieldData) return null


  const {questionColor, fontSize, align, answerColor, buttonBackground, buttonColor} = theme
  const {options = [], generalSettings = {}} = fieldData
  const {other, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}
        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          maxHeight='380px'

          width={'100%'}
        />}
      </Box>}

      <Box>
        <FakeInput
          color={answerColor}
          fontSize={fontSize === 'sm' && '20px'}

        >
          https://
        </FakeInput>
      </Box>
      <Flex
        justifyContent={align === 'center' ? 'center' : 'flex-start'}

        mt={'15px'}
      >
        <FakeButton
          backgroundColor={buttonBackground}
          color={buttonColor}
          theme={theme}
        >
          {messages['ok']}
        </FakeButton>
      </Flex>

    </FieldWrapper>
  );
};

export default WebsiteField;