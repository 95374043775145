import React from 'react';
import {DatePicker} from "antd";
import styled from "styled-components";
import moment from "moment";

export const defaultDateFormat = 'YYYY-MM-DD'

const CustomDatePicker = ({...rest}) => {
  const value = rest?.value && moment(rest?.value)
  const onChange = (e) => rest.onChange && rest.onChange(e.format(defaultDateFormat))

  return (
    <Wrapper>
      <DatePicker value={value} onChange={onChange}/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-picker{
    border-radius: 6px;
  }
`;
export default CustomDatePicker;