import axios from "axios";
import appConfig from "./appConfig";

const instance = axios.create({
  baseURL: appConfig.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;