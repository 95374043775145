import {notification} from 'antd'

const openNotification = (config = {}) => {
  const {
    type = 'success',
    message = type === 'success'
      ? 'Success'
      : type === 'error'
        ? 'Something went wrong...'
        : type === 'warning'
          ? 'Warning'
          : '',
    description,
    duration = 4.5,
  } = config

  notification[type]({
    message,
    description,
    duration,
  })
}

export default openNotification