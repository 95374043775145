import React, { useState } from 'react';
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import SectionHeader from "../../../shared/SectionHeader";
import { Box, HStack, Button, Center } from '@chakra-ui/react'
import FieldTypeSelectDropdown from "./FieldTypeSelectDropdown";
import { fieldTypes } from "../fieldTypes/config";
import { Divider } from "antd";
import { useHistory, useParams } from "react-router-dom";
import styled from 'styled-components'
import { ReactSortable } from "react-sortablejs";
import { deleteField, duplicateField, updateFieldsOrder, updateForm } from "../../../firebase/form";
import _ from 'lodash';
import { mapToIdsArray, rawToHtml } from "../../../utils/common";
import MoreActionsDropdown from "../../../shared/MoreActionDropdown";
import colors from "../../../utils/commonStyles";
import { setColorOpacity } from "../fieldTypes/MultiChoice/MultiChoice";
import { DropDownMenu } from "../../Home/components/CustomDropdown";
import { RemoveIcon } from '../fieldTypes/icons';
import { CopyOutlined } from '@ant-design/icons';


const NoTitle = () => <span
  style={{ opacity: 0.5, fontStyle: "italic" }}
>
  No title
</span>

const FieldItem = ({ field = {}, formData = {}, label, name, active, type, onClick, onDelete, allowCopy, showMoreOptions = true, onDuplicateCallback }) => {

  const [showOptions, setShowOptions] = useState()
  const [duplication, setDuplicationg] = useState()

  const onDuplicateHandler = async () => {

    const { type, id } = field

    setDuplicationg(true)

    try {
      const data = await duplicateField({ fieldId: field?.id, formId: formData.id })
      onDuplicateCallback(data)
    } catch (err) {

    } finally {
      setDuplicationg(false)

    }

  }

  const moreOptions = [
    {
      value: 'delete',
      label: 'Delete',
      onClick: onDelete
    }
  ]


  return <Box
    onClick={onClick}
    p={['12px 11px']}
    // backgroundColor={active ? '#f2f2f2' : ''}
    cursor={'pointer'}
    border={'1px solid'}
    borderColor={active ? colors.primary : colors.border}
    borderRadius={'8px'}
    bg={active ? colors.bg : colors.lightGrey}
    m={'6px 10px'}
    boxShadow={active ? 'rgba(0, 0, 0, 0.26) 1px 2px 5px' : ''}
    // boxShadow={active ? 'rgba(51, 102, 255, 0.6) 0px 2px 8px 0px' : ''}
    overflow={'hidden'}
    transition={'all 0.3s'}
    position={'relative'}
    onMouseEnter={() => setShowOptions(true)}
    onMouseLeave={() => setShowOptions(false)}
    _hover={{
      boxShadow: active ? '' : 'rgba(0, 0, 0, 0.26) 1px 2px 5px'
    }}
  >
    {active && <Box
      zIndex={2}
      w={50}
      h={50}
      bg={colors.primaryLinear}
      // bg={'red'}
      position={'absolute'}
      // right={100}
      top={-30}
      right={-30}
      transform={'rotate(225deg)'}
    // opacity={0.6}
    >
    </Box>}


    {showMoreOptions && showOptions && <Center
      onClick={onDelete}
      zIndex={2}
      w={25}
      h={25}
      borderRadius={'4px'}
      bg={'#f2f2f2'}
      // bg={'red'}
      position={'absolute'}

      border={'1px solid #dddddd'}
      opacity={0.9}
      // right={100}
      top={'10px'}
      right={'5px'}
    // opacity={0.6}
    >
      <RemoveIcon />
    </Center>}

    {allowCopy && showOptions && <Center
      onClick={!duplication && onDuplicateHandler}
      zIndex={2}
      cursor={duplication && 'not-allowed'}
      w={25}
      h={25}
      borderRadius={'4px'}
      bg={'#f2f2f2'}
      // bg={'red'}
      position={'absolute'}
      border={'1px solid #dddddd'}
      opacity={0.9}
      // right={100}
      top={'10px'}
      right={'35px'}
    // opacity={0.6}
    >
      <CopyOutlined />
    </Center>}

    <HStack
      alignItems={'center'}
    >
      <Box cursor={'move'}>
        {label}
      </Box>
      <Box
        flex={1}
      >
        <TextEllipsis
          linesLength={1}
        >
          {name || <NoTitle />}
        </TextEllipsis>
      </Box>

      {showMoreOptions && <Box
        position={'absolute'}
        // right={100}
        w={50}
        h={50}
        bg={'red'}
        top={20}
        zIndex={4}

        // transform={'rotate(225deg)'}

        right={10}
      >

        <RemoveIcon />
      </Box>}

      {/* <MoreActionsDropdown
          dotsActive={active}
          items={moreOptions}
        /> */}
    </HStack>
  </Box>
}

const FieldsList = () => {

  const history = useHistory()
  const { formId, fieldId } = useParams()

  const [
    fields,
    endingFields,
    activeField,
    formData = {},
    setActiveField,
    setFormData,
    setFields,
    setEndingFields,
    setActiveTheme,
    setActiveTab,
  ] = useFormConfiguratorStore(state => [
    state.fields,
    state.endingFields,
    state.activeField,
    state.formData,
    state.setActiveField,
    state.setFormData,
    state.setFields,
    state.setEndingFields,
    state.setActiveTheme,
    state.setActiveTab,
  ])

  const { welcomeScreen } = formData

  const onChange = (field) => {

    const urlId = field.type === 'welcomeScreen' ? 'welcome' : field.id

    history.push(`/form/${formId}?block=${urlId}`)
    setActiveTheme(null)
    setActiveTab()
  }

  const onUpdateOrderHandler = (type = 'fields', updatedFields = []) => {
    if (type === 'fields') {

      if (!_.isEqual(mapToIdsArray(fields), mapToIdsArray(updatedFields))) {
        setFields(updatedFields.map((f, index) => ({ ...f, order: index + 1 })))
        // updateFieldsOrder(formData.id, type, updatedFields)
        updateForm({ id: formData.id, fields: updatedFields })
      }

    } else if (type === 'endingFields') {

      if (!_.isEqual(mapToIdsArray(endingFields), mapToIdsArray(updatedFields))) {
        setEndingFields(updatedFields)
        updateForm({ id: formData.id, endingFields: updatedFields })
      }

    }
  }

  const onDeleteHandler = (field = {}) => {

    const { type, id } = field

    if (type === 'welcomeScreen') {
      setFormData({ welcomeScreen: null })
    } else if (type === 'endingField') {
      const updatedEndingFields = endingFields.filter(f => f.id !== id)
      setEndingFields(updatedEndingFields)
    } else {
      const updatedFields = fields.filter(f => f.id !== id)
      setFields(updatedFields)
    }

    deleteField({ id: field?._id, formId })
  }



  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      h={'calc(100vh - 48px)'}
    >
      <Box
        p={'0 11px'}
        d={'flex'}
        alignItems={'center'}
      // w={'150px'}
      >
        <Box
          flex={1}
        >

          <Label>
            Questions
          </Label>
        </Box>

        <FieldTypeSelectDropdown />
      </Box>

      <Box
        overflowY={'auto'}
        flex={1}
        pb={'30px'}
      >
        {welcomeScreen && (
          <>
            {/*<Label>*/}
            {/*  WELCOME SCREEN*/}
            {/*</Label>*/}

            <Box
              mb={'25px'}
            >
              <FieldItem
                onClick={() => onChange(welcomeScreen)}
                active={activeField.type === 'welcomeScreen'}
                name={rawToHtml(welcomeScreen.nameDraftContent)}
                onDelete={() => onDeleteHandler(welcomeScreen)}
                label={(() => {
                  const IconComponent = fieldTypes[welcomeScreen.type]?.icon
                  return <IconComponent />
                })()}
              />
            </Box>
            {/*<Divider/>*/}
          </>
        )}


        <Box mb={'25px'}>


          <ReactSortable
            list={fields}
            setList={(data) => onUpdateOrderHandler('fields', data)}
            id={'sortable'}
          >
            {fields?.map((f, index) => {
              const IconComponent = fieldTypes[f.type]?.icon
              return (
                <FieldItem
                  field={f}
                  onClick={() => onChange(f)}
                  active={activeField.id === f.id}
                  name={rawToHtml(f.nameDraftContent)}
                  onDelete={() => onDeleteHandler(f)}
                  label={<IconComponent label={index + 1} />}
                  type={f.type}
                  showMoreOptions={fields.length > 1}
                  allowCopy={true}
                  formData={formData}
                  onDuplicateCallback={newField => {
                    setFields([...fields, {...newField, order: fields.length + 1}])
                  }}
                />
              )
            })}
          </ReactSortable>
        </Box>
        {/*{(endingFields.length > 0) && <Label>*/}
        {/*  ENDINGS*/}
        {/*</Label>}*/}


        <ReactSortable
          list={endingFields}
          setList={(data) => onUpdateOrderHandler('endingFields', data)}
          id={'sortable'}
        >
          {endingFields?.map((f, index) => {
            const IconComponent = fieldTypes[f.type]?.icon

            return (
              <FieldItem
                onClick={() => onChange(f)}
                active={activeField.id === f.id}
                name={rawToHtml(f.nameDraftContent)}
                onDelete={() => onDeleteHandler(f)}
                label={<IconComponent label={index + 1} />}
              />
            )
          })}
        </ReactSortable>
      </Box>
    </Box>
  );
}
  ;

export const TextEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-line-clamp: ${({ linesLength }) => linesLength || '5'};
  -webkit-box-orient: vertical;
`
const Label = styled.div`
  font-size: 14px;
  // font-style: italic;
  // font-style: italic;
  padding: 13px 0 10px 0;
  text-align: center;
`


export default FieldsList;