import React, {useCallback, useEffect, useState} from 'react';
import {modalStore} from "../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import {moveForm} from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Form} from "antd";
import _ from 'lodash'
import {StyledSelect} from "../../../shared/form/CustomSearchableSelect";

export const useMoveFormModalStore = modalStore()

const FormMoveToModal = ({fetchForms, workspaces}) => {

  const {organizationId, workspaceId: currentWorkspaceId} = useParams()

  const history = useHistory()

  const [name, setName] = useState()
  const [workspaceId, setWorkspaceId] = useState()

  const [error, setError] = useState()

  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useMoveFormModalStore(store => [store.isOpen, store.onClose, store.data])

  const onCloseHandler = () => {
    setWorkspaceId()
    onClose()
  }
  const withoutCurrent = workspaces.filter(w => w.id !== currentWorkspaceId)

  useEffect(() => {
    if (!_.isEmpty(withoutCurrent)) {
      const withoutCurrent = workspaces.filter(w => w.id !== currentWorkspaceId)
      setWorkspaceId(withoutCurrent?.[0]?.id)
    }
  }, [workspaces])

  const moveToHandler = useCallback(
    async (values) => {


      setLoading(true)

      try {

        const requestData = {
          ...values
        }

        await moveForm(requestData)
        fetchForms()

        onCloseHandler()

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [data]
  )


  return <CustomModal
    title={'Move form'}
    zIndex={12}
    onOk={() => moveToHandler({id: data?.id, workspaceId: workspaceId})}
    okButtonProps={{
      title: 'Move',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    // size={'xl'}
  >
    <Box
      p={'30px 0 10px'}
    >

      <Box mb={'30px'}>
        This action will move form to another workspace, You can't access this form from current workspace
      </Box>
      <Form
        layout={'vertical'}
      >
        <Form.Item
          label={'Choose the workspace'}
          validateStatus={error && 'error'}
          help={error}
        >
          <StyledSelect

            value={workspaceId}
            onChange={e => setWorkspaceId(e)}
            options={withoutCurrent.map(w => ({value: w.id, label: w.name}))}
            bg={'white'}
          />
        </Form.Item>
      </Form>

    </Box>


  </CustomModal>
};


export default FormMoveToModal;