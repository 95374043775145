import React, {useCallback, useState} from 'react';
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {Button, Box, Input, HStack, Center} from "@chakra-ui/react";
import JumpingLogicSettingsModal, {GhostButton} from "../JumpingLogicSettings/JumpingLogicSettingsModal";
import CustomModal from "../../../../shared/CustomModal";
import {useDefaultFormik} from "../../../../shared/form/useFormBuilder";
import * as Yup from "yup";
import {createForm, updateVariables} from "../../../../firebase/form";
import {fieldCardProps} from "../QuizzVariablesSettings/QuizzVariablesSettingsModal";
import {randomId, removeById, updateById, updateByIndex} from "../../../../utils/common";
import {DeleteOutlined} from "@ant-design/icons";
import {Input as AntdInput, InputNumber} from "antd";
import colors from "../../../../utils/commonStyles";
import {setColorOpacity} from "../../fieldTypes/MultiChoice/MultiChoice";
import styled from "styled-components";
import MenuItem from "../MenuItem";

const validationSchema = Yup.object().shape({});
const nameRegex = /^\w+$/;

const sectionTitleProps = {
  fontSize: '18px'
}

const StyledInput = styled.input`
  background: transparent;
  border-width: 0 !important;
  outline: none;
  &:focus{
    border: 0;
  }
  &:focus-visible{
    border: 0 !important;
  }
`;
const VariableNameInput = ({value, onChange}) => {
  return <HStack
    p={'5px'}
    alignItems={'center'}
    borderRadius={'6px'}
    border={'1px solid'}
    borderColor={colors.primary}
    bg={setColorOpacity(colors.primary, 0.1)}
  >
    <span>@</span>
    <StyledInput
      value={value}
      onChange={(e => onChange(e.target.value))}

    />
  </HStack>
}

const mapNumberVariablesWithValidationErrors = (numberVariables = []) => {
  let numberVariablesWithValidationErrors = []
  let isValid = true

  const usedVariableNames = []

  numberVariables.forEach(v => {
    let errorMessage = null

    if (!!!v.name) {
      errorMessage = 'This field is required'
      isValid = false
    } else if (usedVariableNames.includes(v.name)) {
      errorMessage = 'This field is duplicate'
      isValid = false
    } else if (!nameRegex.test(v.name)) {
      errorMessage = 'This field is not valid'
      isValid = false
    }

    usedVariableNames.push(v.name)
    numberVariablesWithValidationErrors.push({...v, errorMessage})
  })

  return {
    numberVariablesWithValidationErrors,
    isValid
  }
}

const mapStringVariablesWithValidationErrors = (stringVariables = []) => {
  let stringVariablesWithValidationErrors = []
  let isValid = true

  const usedVariableNames = []

  stringVariables.forEach(v => {
    let errorMessage = null

    if (!!!v.name) {
      errorMessage = 'This field is required'
      isValid = false
    } else if (usedVariableNames.includes(v.name)) {
      errorMessage = 'This field is duplicate'
      isValid = false
    } else if (!nameRegex.test(v.name)) {
      errorMessage = 'This field is not valid'
      isValid = false
    }

    usedVariableNames.push(v.name)
    stringVariablesWithValidationErrors.push({...v, errorMessage})
  })

  return {
    stringVariablesWithValidationErrors,
    isValid
  }
}

const NumberVariables = ({numberVariables = [], onChange, onAdd, onRemove}) => {
  return <Box>
    {numberVariables.map(v => {
      return <Box
        mb={'10px'}
      >

        {v.errorMessage && <Box
          bg={'#ffcccc'}
          borderRadius={'8px'}
          p={'6px 15px'}
          mb={'10px'}
        >
          {v.errorMessage}
        </Box>}

        <HStack
          alignItems={'center'}
        >
          <VariableNameInput
            value={v.name}
            onChange={(val => onChange({...v, name: val}))}
          />
          <Center>
            =
          </Center>
          <InputNumber
            value={v.value}
            onChange={(e => onChange({...v, value: e}))}
          />
          <Button
            variant='ghost'
            size={'sm'}
            ml={'5px'}
            onClick={() => onRemove(v.id)}
          >
            <DeleteOutlined/>
          </Button>
        </HStack>

      </Box>
    })}
    <HStack
      mt={'25px'}
    >
      <GhostButton
        onClick={onAdd}
      >
        + Add variable
      </GhostButton>
    </HStack>
  </Box>
}

const StringVariables = ({stringVariables = [], onChange, onAdd, onRemove}) => {
  return <Box>
    {stringVariables.map(v => {
      return <Box
        mb={'10px'}
      >

        {v.errorMessage && <Box
          bg={'#ffcccc'}
          borderRadius={'8px'}
          p={'6px 15px'}
          mb={'10px'}
        >
          {v.errorMessage}
        </Box>}

        <HStack
          alignItems={'center'}
        >
          <VariableNameInput
            value={v.name}
            onChange={(val => onChange({...v, name: val}))}
          />
          <Center>
            =
          </Center>
          <AntdInput
            style={{
              maxWidth: '300px',
              borderRadius: '6px'
            }}
            value={v.value}
            onChange={(e => onChange({...v, value: e.target.value}))}
          />
          <Button
            variant='ghost'
            size={'sm'}
            ml={'5px'}
            onClick={() => onRemove(v.id)}
          >
            <DeleteOutlined/>
          </Button>
        </HStack>

      </Box>
    })}
    <HStack
      mt={'25px'}
    >
      <GhostButton
        onClick={onAdd}
      >
        + Add variable
      </GhostButton>
    </HStack>
  </Box>
}

const VariablesSettingsModal = ({isOpen, onClose, jumpingLogicData = {}}) => {

  const [saving, setSaving] = useState()

  const [
    formData = {},
    fields,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.setFormData,
  ])

  const formik = useDefaultFormik({
    initialValues: formData,
    validationSchema,
    onSubmit(values) {
      saveHandler(values)
    }
  });

  const saveHandler = useCallback(
    async (values) => {
      setSaving(true)
      try {

        const requestData = {
          formId: formData.id,
          hiddenVariables: values.hiddenVariables,
          stringVariables: values.stringVariables,
          numberVariables: values.numberVariables,
        }

        await updateVariables(requestData)

        setFormData({...formData, ...values})
        onClose()

      } catch (e) {
        console.log(e)
      } finally {
        setSaving(false)
      }
    },
    [formik]
  )

  const {numberVariables = [], stringVariables = [], hiddenVariables = []} = formik.values

  const {
    numberVariablesWithValidationErrors = [],
    isValid: isNumberVariablesValid
  } = mapNumberVariablesWithValidationErrors(numberVariables)

  const {
    stringVariablesWithValidationErrors = [],
    isValid: isStringVariablesValid
  } = mapStringVariablesWithValidationErrors(stringVariables)

  const {
    stringVariablesWithValidationErrors: hiddenVariablesWithValidationErrors,
    isValid: isHiddenVariablesValid
  } = mapStringVariablesWithValidationErrors(hiddenVariables)

  return <CustomModal
    title={'Variables'}
    zIndex={12}
    onOk={() => formik.handleSubmit()}
    okButtonProps={{
      isLoading: saving,
      isDisabled: !isNumberVariablesValid || !isStringVariablesValid || !isHiddenVariablesValid
    }}
    isOpen={isOpen}
    onClose={onClose}
    size={'5xl'}
  >
    <Box
      mt={'20px'}
      {...sectionTitleProps}
    >
      Number variables
    </Box>

    <Box
      {...fieldCardProps}
    >
      <NumberVariables
        numberVariables={numberVariablesWithValidationErrors}
        onChange={(variable) => {
          formik.setFieldValue('numberVariables', updateById(numberVariables, variable))
        }}

        onAdd={() => {
          formik.setFieldValue('numberVariables', [...numberVariables, {
            id: randomId(),
            name: `variable_${numberVariables.length + 1}`,
            value: 0
          }])
        }}
        onRemove={(variableId) => {
          formik.setFieldValue('numberVariables', removeById(numberVariables, variableId))
        }}
      />
    </Box>

    <Box
      mt={'20px'}
      {...sectionTitleProps}
    >
      String variables
    </Box>

    <Box
      {...fieldCardProps}
    >
      <StringVariables
        stringVariables={stringVariablesWithValidationErrors}
        onChange={(variable) => {
          formik.setFieldValue('stringVariables', updateById(stringVariables, variable))
        }}

        onAdd={() => {
          formik.setFieldValue('stringVariables', [...stringVariables, {
            id: randomId(),
            name: `variable_${stringVariables.length + 1}`,
            value: ''
          }])
        }}
        onRemove={(variableId) => {
          formik.setFieldValue('stringVariables', removeById(stringVariables, variableId))
        }}
      />
    </Box>

    <Box
      mt={'20px'}
      {...sectionTitleProps}
    >
      Hidden variables
    </Box>

    <Box
      {...fieldCardProps}
    >
      <StringVariables
        stringVariables={hiddenVariablesWithValidationErrors}
        onChange={(variable) => {
          formik.setFieldValue('hiddenVariables', updateById(hiddenVariables, variable))
        }}

        onAdd={() => {
          formik.setFieldValue('hiddenVariables', [...hiddenVariables, {
            id: randomId(),
            name: `variable_${hiddenVariables.length + 1}`,
            value: ''
          }])
        }}
        onRemove={(variableId) => {
          formik.setFieldValue('hiddenVariables', removeById(hiddenVariables, variableId))
        }}
      />
    </Box>
  </CustomModal>
}


const VariablesSettings = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <MenuItem
        onClick={() => setOpen(true)}
      >
        Variables >
      </MenuItem>
      {isOpen && <VariablesSettingsModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />}
    </div>
  );
};

export default VariablesSettings;