import React, {useEffect, useState} from 'react';
import {Box, HStack, Input, Center} from '@chakra-ui/react'
import styled from "styled-components";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Space, Tabs, Tag} from "antd";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  RightCircleOutlined,
  ShareAltOutlined,
  BarChartOutlined,
  FormOutlined,
  SisternodeOutlined
} from "@ant-design/icons";
import PrimaryButton from "../../../shared/PrimaryButton";
import SecondaryButton from "../../../shared/SecondaryButton";
import FormPreview from '../../FormPreview'
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import useDebounceEffect from "../../../hooks/useDebounceEffect";
import {publishForm, updateForm} from "../../../firebase/form";
import SuperAdminTools from "./SuperAdminTools";
import CustomModal from "../../../shared/CustomModal";

import {StyledInput} from "../../../shared/form/InlineTextField";
import { useSelector } from 'react-redux';
import openNotification from '../../../shared/openNotification';

const FORM_URL = 'https://form.formee.io'

const FormUrlPopover = ({isOpen, onClose, formSlug}) => {

  let url = `${FORM_URL}/${formSlug}`
  
  return <CustomModal
    title={'You published a form'}
    isOpen={isOpen}
    onClose={onClose}
    onOk={() => {
      navigator.clipboard.writeText(url);
      openNotification({description: 'Copied !'})
    }}
    okButtonProps={{
      title: 'Copy'
    }}
    size={'xl'}
  >
    <Box p={'20px 0'}>

      <Box mb={'10px'}>
        Use this url to collect responses from your user.
      </Box>
      <StyledInput
        bg={'white'}
        value={url}
      />
    </Box>


  </CustomModal>
}

const {TabPane} = Tabs;


const Breadcrumbs = ({formData}) => {

  const history = useHistory()


  const workspaceId = formData?.workspace?.id
  const organizationId = formData?.organization

  const workspaceLabel = formData?.workspace?.name

  const [currentName, setCurrentName] = useState(formData.name)

  const updateFormNameHandler = async () => {
    try {
      await updateForm({name: currentName, id: formData?.id})
    } catch (e) {
      console.log(e)
    }
  }

  const onBack = () => {
    history.push(`/organization/${organizationId}/workspace/${workspaceId}`)
  }

  const autoSave = () => {
    updateFormNameHandler()
  }

  useDebounceEffect(autoSave, [currentName], 500, 200)

  useEffect(() => {
    if (formData?.name) {
      setCurrentName(formData?.name)
    }
  }, [formData])

  return <Box
    d={'flex'}
    alignItems={'center'}
  >
    <Box
      d={'flex'}
      alignItems={'center'}
      onClick={onBack}
      cursor={'pointer'}

    >
      <Center mr={'15px'}>
        <ArrowLeftOutlined/>
      </Center>
      <Box mr={'7px'}>
        {workspaceLabel}
      </Box>
    </Box>

    <Box mr={'4px'}>
      /
    </Box>
    <Box>
      <Input
        _hover={{
          border: '1px solid #dddddd'
        }}
        _focus={{
          border: '1px solid #dddddd'
        }}
        border={0}
        fontSize={'14px'}
        p={'4px'}
        h={'28px'}
        onBlur={updateFormNameHandler}
        value={currentName}
        onChange={e => setCurrentName(e.target.value)}
      />
    </Box>
  </Box>
}

const ConfiguratorHeader = () => {

  const {formId} = useParams()

  const history = useHistory()
  const location = useLocation()

  const locationSegments = location.pathname.split('/')

  const locationKey = locationSegments[3]

  const [isPublishing, setIsPublishing] = useState()

  const [showFormUrlModal, setShowFormUrlModal] = useState()

  const {isAuthenticated, user} = useSelector(state => state.persistentState)
  

  const [
    formData,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.setFormData,
  ])

  const publishHandler = async () => {

    setIsPublishing(true)

    try {
      await publishForm({id: formId})
      setShowFormUrlModal(true)
      setFormData({isPublished: true})
    } catch (err) {
      console.log(err)
    } finally {
      setIsPublishing(false)
    }

  }

  const isSuperUser = user?.isSuperUser

  const [isOpen, setOpen] = useState()

  return (
    <>

      <FormUrlPopover
        formSlug={formData.id}
        isOpen={showFormUrlModal}
        onClose={() => setShowFormUrlModal(false)}
      />

      <HStack
        px={'10px'}
        backgroundColor={'white'}
        h={'48px'}
        borderBottom={'1px solid #dddddd'}
        justifyContent={'space-between'}
      >
        <FormPreview
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          formData={formData}
        />

        <Box flex={1}>
          <Breadcrumbs formData={formData}/>
        </Box>

        <StyledTabs
          defaultActiveKey="1"
          activeKey={locationKey || ''}
          onChange={(e) => {
            if(!formData.isPublished){
              return
            }
            if (!e) {
              return history.push(`/form/${formId}`)
            }
            history.push(`/form/${formId}/${e}`)
          }}
          style={{padding: '10px'}}
        >
          <TabPane tab={<Center
            fontSize={'16px'}><FormOutlined/> Build</Center>} key={''}
          >
            
          </TabPane>
          
           
          <TabPane tab={<Center
            color={!formData.isPublished && '#cccccc'}
            cursor={!formData.isPublished && 'no-drop'}
            fontSize={'16px'}><SisternodeOutlined />Integrate</Center>} key="integrate">

          </TabPane>
          
          <TabPane tab={<Center
            color={!formData.isPublished && '#cccccc'}
            cursor={!formData.isPublished && 'no-drop'}
            fontSize={'16px'}><ShareAltOutlined/>Share</Center>} key="share">

          </TabPane>
          <TabPane tab={<Center
            cursor={!formData.isPublished && 'no-drop'}
            color={!formData.isPublished && '#cccccc'}
            fontSize={'16px'}>
            <BarChartOutlined/>
            Results</Center>} key="results">

          </TabPane>

        </StyledTabs>
        <Box
          // w={'200px'}
          flex={1}
          d={'flex'}
        >
          <Box flex={1}></Box>


          {formData?.isTemplate && <Box>
            <Tag color={'blue'}>TEMPLATE</Tag>
          </Box>}

          <Space>
            {isSuperUser && <SuperAdminTools formData={formData}/>}

            <SecondaryButton
              size={'sm'}
              px={'4px'}
              onClick={() => {
                setOpen(true)
              }}
            >
              <EyeOutlined/>
            </SecondaryButton>


            <PrimaryButton
              size={'sm'}
              loading={isPublishing}
              onClick={publishHandler}
            >
              Publish
            </PrimaryButton>


          </Space>
        </Box>
      </HStack>
    </>
  );
};

export const StyledTabs = styled(Tabs)`
    padding: 0 !important;
    padding-bottom: ${({pb = '2px'}) => pb} !important;
    font-weight: 300;

  .ant-tabs-nav{
    margin-bottom: 0 !important;
  }
  .ant-tabs-tab{
  padding-left: 10px;
  padding-right: 10px;
    span{
      margin-right: 5px !important;
    }
  }
 
`;
export default ConfiguratorHeader;