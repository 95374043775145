import React, { useEffect } from 'react';
import { randomId, removeById } from "../../../../utils/common";
import { initialDraftContent } from "../config";
import { Box, Flex } from "@chakra-ui/react";
import { ReactSortable } from "react-sortablejs";
import { IndexCharContainer, indexChars } from "../MultiChoice/MultiChoice";
import UnderlineTextButton from "../../../../shared/UnderlineTextButton";
import FieldWrapper from "../../../../shared/FieldWrapper";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { Player } from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import { OptionItem, otherNameBlock, otherOption } from "../MultiChoice/MultiChoice";
import { renderNameBlock } from "../LegalField/LegalField";



const Configurator = ({ options = [], otherOption, onChange, color, backgroundColor, theme = {}, messages, fontSize }) => {

  const onAddHandler = () => {

    const newOption = {
      id: randomId(),
      name: 'Choice 223',
      nameBlocks: initialDraftContent
    }
    const updatedOptions = [...options, newOption]
    onChange(updatedOptions)
  }

  const onRemoveHandler = (optionId) => {
    onChange(removeById(options, optionId))
  }

  const { buttonRadius } = theme

  const borderRadius = buttonRadius === 'sharp' ? '8px' : '44px'

  return (
    <Box>
      <ReactSortable
        list={options}
        setList={(data) => {
          onChange(data)
        }}
        id={'sortable'}
      >
        {options.map((o, index) => (<OptionItem
          key={o.id}
          char={indexChars[index]}
          id={o.id}
          borderRadius={borderRadius}
          name={o.name}
          onRemove={() => onRemoveHandler(o.id)}
          color={color}
          fontSize={fontSize === 'sm' ? '16px' : '20px'}
          backgroundColor={backgroundColor}
          nameBlocks={o.nameBlocks}
          onChange={(data) => {
            const copyOptions = options.map((option, i) => {
              if (option.id === o.id) {
                return {
                  ...option,
                  nameBlocks: data
                }
              } else {
                return { ...option }
              }
            })
            onChange(copyOptions)
          }}
        />
        ))}
      </ReactSortable>

      {otherOption && <OptionItem
        id={'other'}
        fontSize={fontSize === 'sm' ? '16px' : '20px'}
        char={indexChars[options.length]}
        name={'Other'}
        allowRemove={false}
        color={color}
        borderRadius={borderRadius}

        backgroundColor={backgroundColor}
        readOnly={true}
        nameBlocks={renderNameBlock(messages.choiceOtherPlaceholder || '')}

      />}
      <Flex>
        <UnderlineTextButton
          color={color}
          onClick={onAddHandler}
        >
          Add choice...
        </UnderlineTextButton>
      </Flex>

    </Box>
  )
}


const SingleChoice = ({ fieldData, onChange, theme = {}, mentionOptions, messages = {}, showQuestionNumber }) => {

  const { questionColor, fontSize, align, answerColor, backgroundColor } = theme

  const { options = [], generalSettings = {} } = fieldData
  const { other, required } = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  const onChangeHandler = (options = []) => {
    if (other) {
      onChange({ options: [...options, otherOption] })
    } else {
      onChange({ options })
    }
  }

  useEffect(() => {
    let optionsWithoutOther = options.filter(o => o.id !== 'other')
    if (other) {
      return onChange({ options: [...optionsWithoutOther, otherOption] })
    }
    return onChange({ options: optionsWithoutOther })
  }, [other])


  if (!fieldData) return null


  const isOtherOption = options.some(o => o.id === 'other')
  let optionsWithoutOther = isOtherOption ? options.filter(o => o.id !== 'other') : options



  // console.log(fieldData, 'fieldData')
  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        display={'flex'}
        justifyContent={align}
      >
        <Configurator
          messages={messages}
          align={align}
          fontSize={fontSize}
          theme={theme}
          options={optionsWithoutOther}
          onChange={onChangeHandler}
          color={answerColor}
          backgroundColor={backgroundColor}
          otherOption={isOtherOption}
        />
      </Box>

    </FieldWrapper>
  );
};

export default SingleChoice;