import React from 'react';
import create from 'zustand'
import { mapWithOrder } from "../../../utils/common";
import { updateFieldMentions } from "../hooks/msic";
import WebFont from "webfontloader";

export const fetchFont = async (family) => {
  let values = family

  if (!Array.isArray(values)) {
    values = [values]
  }

  values = values.filter(e => !!e)

  if(values.length === 0) return

  try {
    await WebFont.load({
      google: {
        families: values
      }
    });
  } catch (err) {
    console.log(err)
  }
}

const useFormConfiguratorStore = create(set => ({
  formData: {},
  fields: [],
  endingFields: [],
  activeField: {},
  galleryThemes: [],
  myThemes: [],
  activeTheme: null,
  activeTab: {},
  imageModalOpen: false,
  onImageModalChange: null,
  imageSettingsModalOpen: false,
  onImageSettingModalChange: null,
  setFormData(data) {
    console.log(data, 'data')
    set(store => ({
      formData: { ...store.formData, ...data },
    }));
  },
  setFields(data) {
    set({
      fields: data,
    });
  },
  setEndingFields(data) {
    set({
      endingFields: data,
    });
  },
  addField(data) {
    set((store) => {
      const updatedData = {}
      if (data.type === 'endingField') {
        updatedData.endingFields = mapWithOrder([...store.endingFields, data])
      } else {
        updatedData.fields = mapWithOrder([...store.fields, data])
      }
      return updatedData
    })
  },
  setActiveField: (data) => {
    set(store => {

      const updatedData = updateFieldMentions(data, store.fields, store.formData)
      // const updatedData = data

      return {
        activeField: { ...updatedData },
      }
    });
  },
  updateActiveField(data) {
    set(store => ({
      activeField: { ...store.activeField, ...data },
    }));
  },
  updateLayoutConfig(data) {
    set(store => {

      let results = {
        ...store.activeField?.layoutConfig,
        ...data
      }

      let layoutId = store.activeField?.layoutConfig?.layoutId

      if ((data.videoUrl || data.imageUrl) && !layoutId) {
        layoutId = 1
      }

      if (data.videoUrl) {
        layoutId = [4, 5, 6].includes(layoutId) ? 1 : layoutId
        results['imageUrl'] = null
        results['videoUrl'] = data?.videoUrl
      } else if (data.imageUrl) {
        results['imageUrl'] = data?.imageUrl
        results['videoUrl'] = null
      }

      return {
        activeField: {
          ...store.activeField,
          layoutConfig: {
            ...results,
            layoutId
          }
        }
      }
    })
  },
  setGalleryThemes(data) {
    set(store => ({
      galleryThemes: data,
    }));
  },
  setMyThemes(data) {
    set(store => ({
      myThemes: data,
    }));
  },
  setActiveTheme(data) {
    set(store => {
      fetchFont(data?.fontFamily)
      return {
        activeTheme: data,
      }
    });
  },
  setActiveTab(data) {
    set(store => ({
      activeTab: {},
    }));
  },
  setImageModalOpen(data) {
    set({
      imageModalOpen: true,
      imageSettingsModalOpen: false,
      onImageModalChange: data?.onImageModalChange,
    });
  },
  setImageModalClose() {
    set({
      imageModalOpen: false,
    });
  },
  setImageSettingsModalOpen(data) {
    set({
      imageSettingsModalOpen: true,
      imageModalOpen: false,
      onImageSettingModalChange: data?.onImageSettingModalChange
    });
  },
  setImageSettingsModalClose(data) {
    set({
      imageSettingsModalOpen: false,
    });
  },
  resetStore() {
    set({
      formData: {},
      fields: [],
      endingFields: [],
      activeField: {},
      galleryThemes: [],
      myThemes: [],
      activeTheme: null,
      activeTab: {},
      imageModalOpen: false,
      onImageModalChange: null,
      imageSettingsModalOpen: false,
      onImageSettingModalChange: null,
    });
  },
  
}))


export default useFormConfiguratorStore;