import React from 'react';
import {Box, HStack, Button} from '@chakra-ui/react'
import {DEFAULT_INPUT_HEIGHT, defaultOnChange} from "./useFormBuilder";
import {InputNumber} from "antd";
import colors from "../../utils/commonStyles";


const CustomNumberInput = ({formik, name, value, onChange, integerOnly, ...rest}) => {

  const _value = value || formik?.values?.[name]

  const onHandleChangeNumeric = e => {
    onChange ? onChange(e) : defaultOnChange(formik)(name, e)

  };

  return (
    <Box>
      <InputNumber
        h={DEFAULT_INPUT_HEIGHT}
        value={_value}
        onChange={onHandleChangeNumeric}
        style={{
          background: colors.inputBg,
          border: colors.inputBorder,
          width: '100%'
        }}
        {...rest}
      />
    </Box>
  );
};

export default CustomNumberInput;