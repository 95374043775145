import React from 'react';
import {Input, Select} from "@chakra-ui/react";
import {DEFAULT_INPUT_HEIGHT, defaultOnChange} from "./useFormBuilder";
import {StyledSelect} from "./CustomSearchableSelect";

const CustomSelect = ({onChange, value, options = [], showSearch}) => {


  return (
    <>
      <StyledSelect
      showSearch={showSearch}
        h={DEFAULT_INPUT_HEIGHT}
        value={value}
        onChange={onChange}
        colorScheme={'gray.500'}
        focusBorderColor={'gray.500'}
      >
        {options.map(o => <option value={o.value}>
            {o.label}
          </option>
        )}
      </StyledSelect>
    </>
  );
};

export default CustomSelect;