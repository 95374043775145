import React, {useEffect, useState} from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import {Divider, Box, HStack, Center} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import {Space} from "antd";
import {HeartOutlined, LikeOutlined, StarOutlined} from "@ant-design/icons";


const toScaleOptions = [
  {
    value: 1,
    label: 1
  }, {
    value: 2,
    label: 2
  }, {
    value: 3,
    label: 3
  }, {
    value: 4,
    label: 4
  }, {
    value: 5,
    label: 5
  }, {
    value: 6,
    label: 6
  }, {
    value: 7,
    label: 7
  }, {
    value: 8,
    label: 8
  }, {
    value: 9,
    label: 9
  }, {
    value: 10,
    label: 10
  }
]

const iconOptions = [
  {
    value: 'star',
    label: 'Star',
    icon: <StarOutlined/>
  },
  // {
  //   value: 'heart',
  //   label: 'Heart',
  //   icon: <HeartOutlined/>
  // },{
  //   value: 'like',
  //   label: 'Like',
  //   icon: <LikeOutlined/>
  // },
]

const Configurator = ({data = {}, onChange}) => {

  const {required, iconType, toScale} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>

    <HStack
      p={['10px 0px']}
    >
      <Space>
        <StyledSelect
          value={toScale}
          onChange={e => onChangeHandler('toScale', e)}
          style={{width: '80px'}}
          options={toScaleOptions}
        />

        <StyledSelect
          value={iconType}
          onChange={e => onChangeHandler('iconType', e)}
          style={{width: '150px'}}
          // options={toScaleOptions}
        >
          {iconOptions.map(o => <StyledSelect.Option
            value={o.value}
          >
            <Space>
              {o.icon}
              {o.label}
            </Space>
          </StyledSelect.Option>)}
        </StyledSelect>
      </Space>
    </HStack>
  </Box>
}

const RatingSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({...data})
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onGeneralSettingsChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};


export default RatingSettings;