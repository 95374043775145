import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react'
import useFormConfiguratorStore, { fetchFont } from "../stores/useFormConfiguratorStore";
import ThemeEditor from "./ThemeEditor";
import { defaultTheme } from "./Content";
import PrimaryButton from "../../../shared/PrimaryButton";
import colors from "../../../utils/commonStyles";
import { TextEllipsis } from "./FieldsList";
import { EditOutlined } from "@ant-design/icons";
import { updateForm } from "../../../firebase/form";
import _ from 'lodash'

export const saveTheme = async (themeId, formId) => {
  try {
    const requestData = {
      theme: themeId,
      id: formId
    }
    await updateForm(requestData)
  } catch (e) {
    console.log(e)
  }
}

export const useInitFontFamily = () => {

  const [
    formData = {},
    myThemes,
    galleryThemes,
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.myThemes,
    state.galleryThemes,
  ])

  const { fields = [], endingFields = [], welcomeScreen } = formData

  const [initFontFamily, setInitFontFamily] = useState()

  useEffect(() => {
    if ((!_.isEmpty(myThemes) || !_.isEmpty(galleryThemes) && !initFontFamily)) {

      setInitFontFamily(true)
      fetchFont([formData.theme?.fontFamily])
    }
  }, [myThemes, galleryThemes])
}

const ThemeCard = ({ theme, active, onClick, onEdit }) => {

  const {
    name,
    fontFamily,
    questionColor,
    answerColor,
    buttonBackground,
    backgroundColor,
    imageUrl
  } = theme

  const contentProps = {
    backgroundPosition: 'center',
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundImage: imageUrl && `url('${imageUrl}')`,
  }

  return (
    <Box
      onClick={onClick}
      border={active ? `3px solid ${colors.primary}` : '1px solid #dddddd'}
      borderRadius={'12px'}
      overflow={'hidden'}
      mb={'10px'}
      boxShadow={'rgb(0 0 0 / 8%) 0px 2px 4px'}
      cursor={'pointer'}

    >
      <Box
        p={'20px'}
        {...contentProps}
        backgroundColor={!imageUrl && backgroundColor}
      >

        <Box
          // borderBottom={}
          fontSize={'16px'}
          // fontFamily={fontFamily}
          color={questionColor}
        >
          Question
        </Box>
        <Box
          fontSize={'14px'}
          // fontFamily={fontFamily}
          color={answerColor}
          mb={'4px'}
        >
          Answer
        </Box>
        <Box
          w={'45px'}
          h={'20px'}
          borderRadius={'6px'}
          backgroundColor={buttonBackground}
        />
      </Box>


      <Box
        overflow={'hidden'}
        bg={colors.bg}
        p={'10px 10px'}
        d={'flex'}
        alignItems={'center'}
      >
        <Box
          flex={1}
        >
          <TextEllipsis
            linesLength={1}
          >
            {name}
          </TextEllipsis>
        </Box>
        <Box px={'10px'}
          cursor={'pointer'}
          onClick={(e) => {
            e.stopPropagation()
            onEdit()
          }}

        >
          <EditOutlined />
        </Box>
      </Box>

    </Box>
  )
}

const Gallery = ({
  saveTheme,
  setFormData,
  formData,
  galleryThemes,
  setActiveTheme,
  activeField,
  setActiveField
}) => {

  console.log(galleryThemes)
  return (
    <>
      <Box>
        <Box h={'calc(100vh - 170px)'} overflowX={'auto'}>
          {_.orderBy(galleryThemes, ['order']).map(t => <ThemeCard
            theme={t}
            active={t.id === formData?.theme?.id}
            onClick={() => {
              fetchFont(t.fontFamily)
              saveTheme(t.id, formData.id)
              setFormData({ theme: t })
            }}
            onEdit={() => setActiveTheme({ ...t, id: undefined })}

          />)}
        </Box>
      </Box>
    </>
  )
}

const MyThemes = ({
  saveTheme,
  setFormData,
  formData,
  myThemes,
  setActiveTheme,
  activeField,
  setActiveField
}) => {

  return (
    <Box >
      <PrimaryButton
        flex={1}
        size={'sm'}
        onClick={() => setActiveTheme({ ...defaultTheme })}
      >
        Add theme
      </PrimaryButton>
      <Box mt={'15px'} >

        {myThemes.map(t => <ThemeCard
          theme={t}
          active={t.id === formData?.theme?.id}
          onClick={() => {
            fetchFont(t.fontFamily)
            // setActiveField({ ...activeField, theme: t.id })
            saveTheme(t.id, formData.id)
            setFormData({ theme: t })
          }}
          onEdit={() => setActiveTheme({ ...t })}

        />)}
      </Box>
    </Box>
  )
}

const Design = () => {

  const [
    activeTheme,
    setFormData,
    formData,
    myThemes,
    galleryThemes,
    setActiveTheme,
    activeField,
    setActiveField,
  ] = useFormConfiguratorStore(state => [
    state.activeTheme,
    state.setFormData,
    state.formData,
    state.myThemes,
    state.galleryThemes,
    state.setActiveTheme,
    state.activeField,
    state.setActiveField,
  ])

  const [tab, setTab] = useState(1)

  if (activeTheme) return <ThemeEditor />

  return (
    <>
      <Box w={'100%'} mb={'10px'}>
        <ButtonGroup
          w={'100%'}
          borderRadius={'6px'}
          overflow={'hidden'}
          border={`1px solid ${colors.black}`}
          fontWeight={700}
          isAttached
          variant={'outline'}
        >
          <Button
            bg={tab !== 1 && colors.bg}
            onClick={(set) => setTab(2)}
            // w={'120px'}
            flex={1}
          >Gallery</Button>

          <Button
            flex={1}
            bg={tab === 1 && colors.bg}
            onClick={(set) => setTab(1)}
          >My themes</Button>

        </ButtonGroup>

      </Box>


      <Box >
        {tab === 1 ?
          <MyThemes
            saveTheme={saveTheme}
            setFormData={setFormData}
            formData={formData}
            myThemes={myThemes}
            setActiveTheme={setActiveTheme}
            activeField={activeField}
            setActiveField={setActiveField}
          /> : <Gallery
            saveTheme={saveTheme}
            galleryThemes={galleryThemes}
            setFormData={setFormData}
            formData={formData}
            setActiveTheme={setActiveTheme}
            activeField={activeField}
            setActiveField={setActiveField}
          />}
      </Box>
    </>
  );
}
  ;

export default Design;