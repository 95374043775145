import React from 'react';
import {VStack, Center, Box, Text, Input, Button} from '@chakra-ui/react';

const Page404 = () => {
  return (
    <Center h={'100vh'}>
      <h1>
        404
      </h1>
    </Center>
  );
};

export default Page404;