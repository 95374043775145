import React from 'react';
import {Box, HStack, Button, Center} from '@chakra-ui/react'

const LayoutItem = ({layoutId, onClick, _color, disabled}) => {

  const color = disabled ? '#f2f2f2' : _color

  let content = null

  const twoLines = <Box
    borderRadus={'6px'}
  >
    <Box
      w={'24px'}
      h={'3px'}
      bg={color}
      mb={'3px'}
      borderRadius={'3px'}
    />

    <Box
      w={'24px'}
      h={'3px'}
      bg={color}
      borderRadius={'3px'}
    />
  </Box>


  if (layoutId === 1) {
    content = <Center
      flex={1}
      p={'4px'}
    >
      <Box>
        <Box
          w={'30px'}
          h={'3px'}
          bg={color}
          mb={'3px'}
          borderRadius={'3px'}
        />
        <Box
          w={'36px'}
          h={'17px'}
          mb={'3px'}

          bg={color}
          borderRadius={'3px'}
        />
        <Box
          w={'25px'}
          h={'3px'}
          bg={color}
          borderRadius={'3px'}
        />
      </Box>
    </Center>
  }

  if (layoutId === 7) {
    content = <Center
      flex={1}
      p={'4px'}
    >
      <Box>

        <Box
          w={'36px'}
          h={'17px'}
          mb={'3px'}

          bg={color}
          borderRadius={'3px'}
        />
        <Box
          w={'30px'}
          h={'3px'}
          bg={color}
          mb={'3px'}
          borderRadius={'3px'}
        />
        <Box
          w={'25px'}
          h={'3px'}
          bg={color}
          borderRadius={'3px'}
        />
      </Box>
    </Center>
  }

  if (layoutId === 2) {
    content = <HStack
      flex={1}
      p={'6px'}
    >
      {twoLines}
      <Box
        w={'24px'}
        h={'20px'}
        bg={color}
        borderRadius={'3px'}
      />
    </HStack>
  }

  if (layoutId === 3) {
    content = <HStack
      flex={1}
      p={'6px'}
    >
      <Box
        w={'24px'}
        h={'20px'}
        bg={color}
        borderRadius={'3px'}
      />
      {twoLines}
    </HStack>
  }

  if (layoutId === 4) {
    content = <HStack
      flex={1}
      pl={'6px'}
    >
      {twoLines}

      <Box
        w={'35px'}
        ml={'5px'}
        h={'50px'}
        bg={color}
        borderRadius={'3px'}
      />
    </HStack>
  }

  if (layoutId === 5) {
    content = <HStack
      flex={1}
      pr={'6px'}
    >
      <Box
        w={'32px'}
        // mr={'5px'}
        h={'50px'}
        bg={color}
        borderRadius={'3px'}
      />
      {twoLines}
    </HStack>
  }

  if (layoutId === 6) {
    content = <Center
      flex={1}
      bg={color}
      h={'50px'}
    >
      <Center
        flexDirection={'column'}
      >
        <Box
          w={'36px'}
          h={'3px'}
          bg={'white'}
          mb={'4px'}
          borderRadius={'3px'}
        />

        <Box
          w={'28px'}
          h={'3px'}
          bg={'white'}
          borderRadius={'3px'}
        />
      </Center>

    </Center>
  }

  return (
    <HStack
      cursor={disabled ? 'no-drop' : 'pointer'}
      onClick={!disabled && onClick}
      border={`2px solid ${color}`}
      borderRadius={'8px'}
      // w={'100%'}
      h={'50px'}
      overflow={'hidden'}
    >
      {content}
    </HStack>
  );
};

export default LayoutItem;