import React, { useEffect, useState } from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import { Divider, Box, HStack, Center } from "@chakra-ui/react";
import { FieldInlineWrapper } from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";


const Configurator = ({ data = {}, onChange }) => {

  const { isRedirect, redirectLink, btnText, isButton, isButtonLink, buttonLink } = data

  const onChangeHandler = (keyName, value) => {
    onChange({ ...data, [keyName]: value })
  }

  return <Box>
    {/* <FieldInlineWrapper
      label={'Social share icons'}
    >
      <CustomSwitch
        value={socialShares}
        onChange={val => onChangeHandler('socialShares', val)}
      />
    </FieldInlineWrapper> */}

    <FieldInlineWrapper
      label={'Redirect on completion'}
    >
      <CustomSwitch
        value={isRedirect}
        onChange={val => onChangeHandler('isRedirect', val)}
      />
    </FieldInlineWrapper>

    {isRedirect && <>

      <Box mb={'10px'}>
        <InlineTextField
        placeholder={'https://'}
          value={redirectLink}
          onChange={val => onChangeHandler('redirectLink', val)}

        />
      </Box>
    </>}

    <FieldInlineWrapper
      label={'Button'}
    >
      <CustomSwitch
        value={isButton}
        onChange={val => onChangeHandler('isButton', val)}
      />
    </FieldInlineWrapper>

    {isButton && <>

      <Box mb={'10px'}>
        <InlineTextField
          value={btnText}
          onChange={val => onChangeHandler('btnText', val)}

        />
      </Box>
    </>}


    <FieldInlineWrapper
      label={'Button link'}
    >
      <CustomSwitch
        value={isButtonLink}
        onChange={val => onChangeHandler('isButtonLink', val)}
      />
    </FieldInlineWrapper>

    {isButtonLink && <>
      <Box mb={'10px'}>
        <InlineTextField
        placeholder={'https://'}

          value={buttonLink}
          onChange={val => onChangeHandler('buttonLink', val)}

        />
      </Box>
    </>}


  </Box>
}

const EndingFieldSettings = ({ activeField = {}, onChange }) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({ ...data })
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onGeneralSettingsChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings />

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      {<>
        <RightToolsBarLayoutSettings />
      </>}

    </div>
  );
};

export default EndingFieldSettings;