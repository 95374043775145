import Home from "../pages/Home";
import {Route, Switch} from "react-router-dom";
import FormPreview from "../pages/FormPreview";
import FormConfigurator from "../pages/FormConfigurator";
import React from "react";
import Login from "../pages/Login";
import Register from "../pages/Register";
import LandingPage from "../pages/LandingPage/LandingPage";
import Settings from "../pages/Settings";
import InvitationAccept from "../pages/InvitationAccept";
import Page404 from "../pages/Page404";
import Templates from "../pages/Templates";
import AuthLayout from "../shared/AuthLayout";

export const GOOGLE_FONTS_API_KEY = 'AIzaSyC109GOL9CsEQtV1pyeLoFJPEjiQvhTpYQ'
export const SECRET_KEY = 'dsd323d23d2d2323d23'

export const routes = [
  {
    path: '/organization/:organizationId?/workspace/:workspaceId?',
    component: Home,
    authenticated: true,
    layout: AuthLayout
  },
  {
    path: '/preview/:id',
    component: FormPreview,
    authenticated: true
  }, {
    path: '/login',
    component: Login
  }, {
    path: '/register',
    component: Register
  }, {
    path: '/acceptInvitation/:token?',
    component: InvitationAccept
  },{
    path: '/404',
    component: Page404
  },{
    path: '/home',
    component: LandingPage
  },
]

