import { Box, Center } from '@chakra-ui/react';
import React from 'react';
import styled from "styled-components";
import CenterImage from "../../../../shared/CenterImage";
import FieldWrapper from "../../../../shared/FieldWrapper";
import { Player } from "../../components/Content";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { setColorOpacity } from "../MultiChoice/MultiChoice";


export const OptionContainer = styled(Center)`
  margin-right: 5px;
  border-radius: 6px;
  
  &:last-child{
    margin-right: 0px;    
  } 
`;

const renderScaleOptions = (fromScale = 0, toScale = 10) => {
  const results = []

  for (let step = fromScale; step <= toScale; step++) {
    results.push(step)
  }
  return results
}

const OpinionScale = ({fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber}) => {

  const {questionColor, fontSize, align, answerColor, backgroundColor} = theme

  const {generalSettings = {}} = fieldData
  const {fromScale, toScale, leftText, centerText, rightText, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null


  if (!fieldData) return null

  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        display={'flex'}
        justifyContent={align}
      >
        {renderScaleOptions(fromScale, toScale).map(e => <OptionContainer
          flex={1}
          mr={'5px'}
          fontSize={'20px'}
          h={'57px'}
          backgroundColor={setColorOpacity(answerColor, 0.1)}
          color={answerColor}
          boxShadow={`${setColorOpacity(answerColor, 1)} 0px 0px 0px 1px inset`}
        >
          {e}

        </OptionContainer>)}
      </Box>
      <Box
        color={answerColor}
        mt={'10px'}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box
          w={'150px'}
        >
          {leftText}
        </Box>
        <Box
          w={'150px'}
          textAlign={'center'}
        >
          {centerText}
        </Box>
        <Box
          w={'150px'}
          display={'flex'}
          justifyContent={'end'}
        >
          {rightText}
        </Box>
      </Box>

    </FieldWrapper>
  );
};


export default OpinionScale;