import {Box, Center} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps";

const geoUrl = "/geoChart.json";

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#e6ecff", "#3366ff"]);

const ChartsDashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`/vulnerability.csv`).then((data) => {
      setData(data);
    });
  }, []);

  return (
    <Box
    bg={'white'}
    borderRadius={'10px'}
    px={'20px'}c
    py={'-20px'}
    >

    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 147
      }}
    >
      {/*<Sphere stroke="#E4E5E6" strokeWidth={0.5} />*/}
      {/*<Graticule stroke="#E4E5E6" strokeWidth={0.5} />*/}
      {data.length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const d = data.find((s) => s.ISO3 === geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d["1995"]) : "#F5F4F6"}
                />
              );
            })
          }
        </Geographies>
      )}
    </ComposableMap>
    </Box>

  );
};

export default ChartsDashboard;