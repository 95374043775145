import React from 'react';
import {Box, HStack} from "@chakra-ui/react";
import colors from "../../../utils/commonStyles";

const MenuItem = ({onClick, children}) => {
  return (
    <Box
      border={'1px solid'}
      borderColor={colors.border}
      borderRadius={'8px'}
      p={'15px 10px'}
      mb={'10px'}
      fontWeight={600}
      fontSize={'14px'}
      cursor={'pointer'}
      bg={'rgb(250, 250, 250)'}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default MenuItem;