import React, { useCallback, useState } from 'react';
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import { Box, Button, Center, HStack } from "@chakra-ui/react";
import CustomModal from "../../../../shared/CustomModal";
import * as Yup from "yup";
import { updateQuizzSettings } from "../../../../firebase/form";
import { setColorOpacity } from "../../fieldTypes/MultiChoice/MultiChoice";
import _ from 'lodash';
import { InputNumber } from "antd";
import SecondaryButton from "../../../../shared/SecondaryButton";
import { useTranslation } from "../../../../hooks/useTranslation";
import { isPresent, rawToStyledHtml } from "../../../../utils/common";
import MenuItem from "../MenuItem";
import { fieldTypes } from "../../fieldTypes/config";

const validationSchema = Yup.object().shape({});

export const indexChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ']

export const fieldCardProps = {
  bg: 'white',
  p: '20px',
  my: '20px',
  borderRadius: '6px',
  boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 4px 0px'
}

export const permittedQuizzFieldTypes = [
  'multiChoice',
  'singleChoice',
  'yesNo',
  'legal',
  'opinionScale',
  'pictureChoice',
  'rating',
  'select'
]

const CharIcon = ({ char, color }) => <Center
  w={'25px'}
  h={'25px'}
  borderRadius={'4px'}
  boxShadow={`${'#3366FF'} 0px 0px 0px 1px inset`}
  bg={setColorOpacity('#3366FF', 0.1)}
  fontFamily={'sans-serif'}
>
  {char}
</Center>

const renderFieldsOptions = (field = {}, translations) => {

  const { type, generalSettings } = field

  let results = []

  if (['multiChoice', 'singleChoice', 'pictureChoice'].includes(type)) {
    results = field.options
  }
  if (['select'].includes(type)) {
    results = field.options.map(o => ({ ...o, label: o.name }))
  }
  if (['yesNo'].includes(type)) {
    results = [
      {
        label: translations['yesNo.yes'],
        id: 'yes'
      }, {
        label: translations['yesNo.no'],
        id: 'no'
      }
    ]
  }
  if (['legal'].includes(type)) {
    results = [
      {
        label: translations['legalField.accept'],
        id: 'accept'
      }, {
        label: translations['legalField.noAccept'],
        id: 'noAccept'
      }
    ]
  }
  if (type === 'opinionScale') {
    const { fromScale, toScale } = generalSettings
    for (let step = fromScale; step <= toScale; step++) {
      results.push({
        label: step,
        id: step
      })
    }
  }
  if (type === 'rating') {
    const { toScale } = generalSettings
    for (let step = 1; step <= toScale; step++) {
      results.push({
        label: step,
        id: step
      })
    }
  }
  return results
}

const OptionsItem = ({ o, fieldQuizzValues, field, onChange, index }) => {
  const [highlight, setHighlight] = useState()

  return <HStack
    bg={highlight ? '#f2f2f2' : 'transparent'}
    mt={'5px'}
    onMouseEnter={() => setHighlight(true)}
    onMouseLeave={() => setHighlight(false)}
    p={'3px'}
    borderRadius={'4px'}
    transition={'all 0.3s'}
    alignItems={'center'}
  >
    <HStack flex={1}>
      <CharIcon

        // color={'#e23f54'}
        char={indexChars[index]} />
      <Box>
        {isPresent(o?.label) ? o?.label : rawToStyledHtml(o?.nameBlocks)}
      </Box>
    </HStack>

    <InputNumber
      // min={0}
      max={999999}
      placeholder={'Score'}
      value={fieldQuizzValues[o.id]}
      onChange={(val) => onChange(field.id, o.id, val)}
    />
  </HStack>
}

const FieldOptions = ({
  field = {},
  fieldQuizzValues = {},
  onChange
}) => {


  const [translations] = useTranslation(store => ([store.translations]))

  const fieldOptions = renderFieldsOptions(field, translations)

  return <Box>
    {fieldOptions.map((o, index) => <OptionsItem
      o={o}
      field={field}
      fieldQuizzValues={fieldQuizzValues}
      onChange={onChange}
      index={index}
    />)}
  </Box>
}

const VariablesSettingsModal = ({ isOpen, onClose }) => {

  const [saving, setSaving] = useState()

  const [
    formData = {},
    fields,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.setFormData,
  ])

  const permittedFields = fields.filter(f => permittedQuizzFieldTypes.includes(f.type))

  const [quizzVariablesSettings, setQuizzVariablesSettings] = useState(formData?.quizzVariablesSettings || {})

  const saveHandler = useCallback(
    async () => {
      setSaving(true)
      try {

        const requestData = {
          quizzVariablesSettings,
          formId: formData.id
        }

        await updateQuizzSettings(requestData)

        setFormData({ ...formData, quizzVariablesSettings })
        onClose()

      } catch (e) {
        console.log(e)
      } finally {
        setSaving(false)
      }
    },
    [quizzVariablesSettings]
  )

  const onChangeHandle = (fieldId, optionId, val) => {

    const copySettings = { ...quizzVariablesSettings }
    if (!copySettings[fieldId]) {
      copySettings[fieldId] = {
        [optionId]: val
      }
    } else {
      copySettings[fieldId][optionId] = val
    }
    setQuizzVariablesSettings(copySettings)
  }

  const onClearAll = () => {
    setQuizzVariablesSettings({})
  }

  const footerExtraContent = <SecondaryButton
    size={'sm'}
    onClick={onClearAll}
  >
    Clear all
  </SecondaryButton>

  return <CustomModal
    isCentered={false}

    zIndex={12}
    onOk={() => saveHandler()}
    okButtonProps={{
      isLoading: saving
    }}
    title={'Quizz points'}
    isOpen={isOpen}
    onClose={onClose}
    size={'5xl'}
    footerExtraContent={footerExtraContent}
  >
    {permittedFields.map((f, index) => {
      const FieldIcon = fieldTypes[f.type]?.icon


      return <Box
        {...fieldCardProps}
      >
        <HStack
          fontWeight={600}
          fontSize={'16px'}
          display={'flex'}
          mb={'15px'}
        >
          <Box mr={'4px'}>{index + 1}. </Box>
          <Box><FieldIcon /></Box>
          <Box>{rawToStyledHtml(f.nameDraftContent)}</Box>
        </HStack>
        <FieldOptions
          field={f}
          fieldQuizzValues={quizzVariablesSettings[f.id]}
          onChange={onChangeHandle}
        />
      </Box>
    })}
  </CustomModal>
}


const QuizzVariablesSettings = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <MenuItem
        onClick={() => setOpen(true)}
      >
        Quiz scroes >
      </MenuItem>
      {isOpen && <VariablesSettingsModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />}
    </div>
  );
};


export default QuizzVariablesSettings;