import { Box } from "@chakra-ui/react";
import { DatePicker, Form, InputNumber } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useUserState } from "../../../../App";
import { updateAccessAndScheduling } from "../../../../firebase/form";
import CustomModal from "../../../../shared/CustomModal";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import { useDefaultFormik } from "../../../../shared/form/useFormBuilder";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import MenuItem from "../MenuItem";



const validationSchema = Yup.object().shape({});

const AccessAndSchedulingModal = ({ isOpen, onClose }) => {

  const [saving, setSaving] = useState()

  const [
    formData = {},
    fields,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.setFormData,
  ])

  const [
    user
  ] = useUserState(state => [
    state.user,
  ])

  const formik = useDefaultFormik({
    initialValues: {
      isActive: true,
      limitCount: 100,
    },
    validationSchema,
    onSubmit(values) {
      saveHandler(values)
    }
  });


  useEffect(() => {
    if (!_.isEmpty(formData?.accessAndScheduling)) {
      formik.setValues({
        ...formData?.accessAndScheduling,
        startDate: formData?.accessAndScheduling?.startDate ? moment(formData?.accessAndScheduling?.startDate) : null,
        endDate: formData?.accessAndScheduling?.endDate ? moment(formData?.accessAndScheduling?.endDate) : null,
      })
    }
  }, [formData])

  const saveHandler = async (values) => {
    setSaving(true)

    try {

      const requestData = {
        formId: formData.id,
        accessAndScheduling: values
      }

      await updateAccessAndScheduling(requestData)

      setFormData({ accessAndScheduling: values })
      onClose()

    } catch (e) {
      console.log(e)
    } finally {
      setSaving(false)
    }
  }
  console.log(formik.values?.startDate)

  return <CustomModal
    title={'Access and Scheduling'}
    zIndex={12}
    onOk={() => formik.handleSubmit()}
    okButtonProps={{
      isLoading: saving
    }}
    isOpen={isOpen}
    onClose={onClose}
    size={'3xl'}
  >
    <Box>

      <Form>

        <Form.Item
          label={'Is form active'}
        >
          <Box ml={'5px'}>
            <CustomSwitch
              value={formik.values?.isActive}
              onChange={e => formik.setFieldValue('isActive', e)}
            />
          </Box>

        </Form.Item>

        <Form.Item
          label={'Schedule a date'}
        >
          <Box ml={'5px'}>
            <CustomSwitch
              value={formik.values?.scheduleDate}
              onChange={e => formik.setFieldValue('scheduleDate', e)}
            />
          </Box>

        </Form.Item>


        {formik.values.scheduleDate && <Box>
          <Form.Item
            label={'Start date'}
          >
            <Box ml={'5px'}>
              <DatePicker
                value={formik.values?.startDate}
                onChange={e => formik.setFieldValue('startDate', e)}
                format="YYYY-MM-DD HH:mm"
                showTime={true}
              />

            </Box>
          </Form.Item>

          <Form.Item
            label={'End date'}
          >
            <Box ml={'5px'}>

              <DatePicker
                value={formik.values?.endDate}
                onChange={e => formik.setFieldValue('endDate', e)}
                format="YYYY-MM-DD HH:mm"
                showTime={true}
              />

            </Box>
          </Form.Item>

        </Box>}


        <Form.Item
          label={'Set a response limit'}
        >
          <Box ml={'5px'}>
            <CustomSwitch
              value={formik.values?.responseLimit}
              onChange={e => formik.setFieldValue('responseLimit', e)}
            />
          </Box>

        </Form.Item>

        {formik.values.responseLimit && <Form.Item
          label={'Responses limit'}
        >
          <Box ml={'5px'}>
            <InputNumber
              min={0}
              value={formik.values?.limitCount}
              onChange={e => formik.setFieldValue('limitCount', e)}
            />
          </Box>

        </Form.Item>}



      </Form>
    </Box>
  </CustomModal>
}

const AccessAndScheduling = () => {

  const [isOpen, setOpen] = useState(false)

  return (
    <div>
      <MenuItem
        onClick={() => setOpen(true)}
      >
        Access and Scheduling >
      </MenuItem>
      {isOpen && <AccessAndSchedulingModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />}
    </div>
  );
};


export default AccessAndScheduling;