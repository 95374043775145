import React, {useEffect, useState} from 'react';
import {Container} from "./Results";
import CustomDropdown from "../../../Home/components/CustomDropdown";
import {Space, Tooltip} from "antd";
import {Box, HStack} from "@chakra-ui/react";
import colors from "../../../../utils/commonStyles";
import {DownOutlined, ExpandAltOutlined, DownloadOutlined, EyeOutlined, DeleteOutlined} from "@ant-design/icons";
import {INPUT_BORDER_RADIUS} from "../../../Home";
import Divider from "../Divider";
import Table from "../../../../shared/StyledTable";
import {deleteResponses, getForms, getResponses, getResponsesCSV} from "../../../../firebase/form";
import {useParams} from "react-router-dom";
import {renderColumns} from "./msic";
import ResponseDrawer, {useResponseDrawerStore} from "./ResponseDrawer";
import SecondaryButton from "../../../../shared/SecondaryButton";
import DeleteResponseModal, {useDeleteResponseModalStore} from "./DeleteResponseModal";

const sortOptions = [
  {
    value: 'asc',
    label: 'Date: ascending'
  }, {
    value: 'desc',
    label: 'Date: descending'
  }
]

export const TableButton = ({children, ...rest}) => {
  return <SecondaryButton
    size={'sm'}
    // px={'4px'}
    w={'20px'}
    border={'0'}
    color={'black'}
    bg={colors.bgDark}
    {...rest}
  >
    {children}
  </SecondaryButton>
}

export const ToolsCell = ({record}) => {

  const onOpen = useResponseDrawerStore(s => s.onOpen)

  return <Box>
    <Tooltip title={'Open'}>
      <TableButton
        onClick={() => {
          onOpen(record)
        }}
      >
        <EyeOutlined/>
      </TableButton>
    </Tooltip>
  </Box>
}


const ExportButton = (props) => {

  const {formId} = useParams()

  const [loading, setLoading] = useState()

  const onClickHandler = async () => {
    setLoading(true)
    try {
      await getResponsesCSV(formId)
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }

  return <Tooltip title={'Download all responses'}>
    <TableButton {...props} isLoading={loading} onClick={onClickHandler}>
      <HStack>
        <Box>
          <DownloadOutlined/>
        </Box>
      </HStack>
    </TableButton>
  </Tooltip>
}

const ExpandButton = (props) => {
  return <Tooltip title={'Expand rows'}>
    <TableButton {...props}>
      <HStack>
        <Box>
          <ExpandAltOutlined/>
        </Box>
      </HStack>
    </TableButton>
  </Tooltip>
}

const DeleteButton = (props) => {

  const [onOpen] = useDeleteResponseModalStore(store => [store.onOpen])

  const onClickHandler = () => {
    onOpen()
  }

  return <TableButton
    w={'auto'}
    {...props}
    onClick={onClickHandler}
  >
    <HStack>
      <Box>
        <DeleteOutlined/>
      </Box>
      <Box>
        Delete
      </Box>
    </HStack>
  </TableButton>
}


const ResponsesTable = () => {

  const onCloseDrawer = useResponseDrawerStore(s => s.onClose)

  const {formId} = useParams()

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [fetching, setFetching] = useState();

  const [expanded, setExpanded] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);
  const [sort, setSort] = useState('desc');


  const fetchResponses = async () => {

    const params = {
      page,
      perPage,
      sort
    }

    setFetching(true)

    try {

      const {docs, totalDocs} = await getResponses(formId, params)

      const {data, columns} = docs

      setTotalDocs(totalDocs)
      setData(data)
      setColumns(columns)
    } catch (err) {

    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    fetchResponses()
  }, [page, perPage, sort])

  const paginationConfig = {
    total: totalDocs,
    pageSize: perPage,
    onChange: (page, perPage) => {
      setPage(page)
      setPerPage(perPage)
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    fixed: true,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const refs = {
    responseIds: selectedRowKeys,
  }

  const actionCallbacks = {
    onDelete: () => {
      fetchResponses()
      setSelectedRowKeys([])
      onCloseDrawer()
    }
  }

  return (
    <>
      <ResponseDrawer columns={columns}/>

      <DeleteResponseModal refs={refs} actionCallbacks={actionCallbacks}/>

      <Container mt={'20px'}>
        <HStack justifyContent={'space-between'}>
          <Box>
            <CustomDropdown
              value={sort}
              onChange={e => setSort(e)}
              options={sortOptions}
              containerProps={{
                bg: colors.bgDark
              }}
            />
          </Box>

          <Box>
            <Space>
              {hasSelected && <DeleteButton/>}
              <ExpandButton onClick={() => setExpanded(!expanded)}/>
              <ExportButton/>
            </Space>
          </Box>

        </HStack>
        <Divider my={'20px'}/>
      </Container>

      <Box
        mx={'20px'}
        p={'20px'}
        borderRadius={'8px'}
        overflow={'hidden'}
        // bg={'white'}
      >
        <Table
          dataSource={data}
          columns={renderColumns(columns, {expanded})}
          bordered
          pagination={paginationConfig}
          loading={fetching}
          scroll={{x: 800, y: 550}}
          rowSelection={rowSelection}
        />
      </Box>
    </>
  );
};

export default ResponsesTable;