import React from 'react';
import {Box, Grid, HStack} from "@chakra-ui/react";
import {XAxis, YAxis} from "./Axis";

const TextTypeContent = ({data = []}) => <HStack>
  <Box flex={1} mr={'20px'}>
    <XAxis
      label={data[0].label}
      responsesCount={data[0].responsesCount}
      percent={data[0].percent}
    />
  </Box>

  <Box flex={1} ml={'20px'}>
    <XAxis
      label={data[1].label}
      responsesCount={data[1].responsesCount}
      percent={data[1].percent}
    />
  </Box>

</HStack>

const OpinionTypeContent = ({data = []}) => <Box>
  <Grid
    templateColumns={[
      'repeat(auto-fit, minmax(20px, 1fr))',
    ]}
    gap={3}
  >
    {data.map(i => <Box mr={'10px'}>
      <YAxis
        label={i.label}
        responsesCount={i.responsesCount}
        percent={i.percent}
      />
    </Box>)}
  </Grid>
</Box>

const ChoiceTypeContent = ({data = []}) => <Box>
  {data.map((i) => <Box mr={'10px'}>
    <XAxis
      label={i.label}
      responsesCount={i.responsesCount}
      // responsesCount={i === 1 ? 56 : i === 0 ? 14 : i === 2 ? 17 : 13}
      // percent={i === 1 ? 56 : i === 0 ? 14 : i === 2 ? 17 : 13}
      percent={i.percent}
    />
  </Box>)}
</Box>

export const FieldAnalyticsContent = ({data = {}}) => {

  const {type, options = []} = data

  if (['multiChoice', 'singleChoice', 'pictureChoice', 'select'].includes(type)) {
    return <ChoiceTypeContent data={options}/>
  }
  if (['opinionScale', 'rating'].includes(type)) {
    return <OpinionTypeContent data={options}/>
  }

  return <TextTypeContent data={options}/>
}