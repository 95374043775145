import React from 'react';
import {Center, Box} from '@chakra-ui/react';
import {Spin, Space} from "antd";

const AppLoading = ({
                      title = 'Please wait we are loading your data...'
                    }) => {
  return (
    <Center
      h={'100vh'}
    >
      <Space>
        <Spin size={33}/>
        <Box>
          {title}
        </Box>
      </Space>
    </Center>
  );
};

export default AppLoading;