import React from 'react';
import {Box, HStack, Button, Center} from '@chakra-ui/react'

const FieldWrapper = ({children}) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default FieldWrapper;