import React, {useEffect, useState} from 'react';
import {Box, Divider} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import SectionHeader from "../../../../shared/SectionHeader";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";

const Configurator = ({data = {}, onChange}) => {

  const {btnText} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }


  return <Box
    mb={'10px'}
  >
    <FieldInlineWrapper
      label={'Button text'}
    >
    </FieldInlineWrapper>

    <Box>
      <InlineTextField
        value={btnText}
        onChange={val => onChangeHandler('btnText', val)}
      />
    </Box>
  </Box>
}



const InfoFieldSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({...data})
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      {/*<SectionHeader*/}
      {/*  my={'10px'}*/}
      {/*>*/}
      {/*  Settings*/}
      {/*</SectionHeader>*/}
      {/*<Configurator*/}
      {/*  data={generalSettings}*/}
      {/*  onChange={onGeneralSettingsChange}*/}
      {/*/>*/}
      {/*<Divider*/}
      {/*  my={'5px'}*/}
      {/*  borderColor={'#cccccc'}*/}
      {/*/>*/}

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};

export default InfoFieldSettings;