import {modalStore} from "../../../../utils/common";
import React, {useEffect, useState} from "react";
import CustomModal from "../../../../shared/CustomModal";
import {Box, Center, VStack} from "@chakra-ui/react";
import QRCode from 'qrcode'
import PrimaryButton from "../../../../shared/PrimaryButton";

export const useQRModalStore = modalStore()

const QRModal = () => {

  const [isOpen, onClose, data = {}] = useQRModalStore(store => [store.isOpen, store.onClose, store.data])

  const [qrUrl, setQrUrl] = useState()

  const linkUrl = data?.linkUrl

  const generateQR = async linkUrl => {
    try {
      console.log(linkUrl)
      const url = await QRCode.toDataURL(linkUrl)
      setQrUrl(url)

      return url
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    generateQR(linkUrl)
  }, [data])

  const onDownloadHandler = () => {
    var a = document.createElement("a"); //Create <a>
    a.href = qrUrl; //Image Base64 Goes here
    a.download = "QR.png"; //File name Here
    a.click(); //Downloaded file
  }

  return <CustomModal
    title={'QR code'}
    zIndex={12}
    isOpen={isOpen}
    onClose={onClose}
    size={'xl'}
    showFooter={false}
  >
    <Center>
      <VStack>

        <Box textAlign={'center'}>
          Open this qr code to access your form. You can share this qr code or download them.
        </Box>

        {qrUrl && <Box mt={'20px'}>
          <img src={qrUrl}
            style={{width: '300px'}}
          />
        </Box>}


        <Box mt={'20px'}>
          <PrimaryButton onClick={onDownloadHandler}>
            Download
          </PrimaryButton>
        </Box>
      </VStack>




    </Center>


  </CustomModal>
};


export default QRModal;