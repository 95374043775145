import React, {useState} from 'react';
import AuthLayout from "../../shared/AuthLayout";
import Logo, {LogoWord} from "../../shared/Logo";
import {VStack, Center, Box, Text, Input, Button} from '@chakra-ui/react';
import {Space, Divider} from "antd";
import GoogleLogo from '../../assets/googleLogo.svg'
import {useHistory} from "react-router-dom";
import colors from "../../utils/commonStyles";
import PrimaryButton from "../../shared/PrimaryButton";
import * as Yup from "yup";
import {useDefaultFormik} from "../../shared/form/useFormBuilder";
import openNotification from "../../shared/openNotification";
import AuthService from "../../services/AuthService";
import {login} from "../../store/actions/AuthActions";
import {useDispatch} from "react-redux";
import GoogleLoginButton from "../../shared/GoogleLoginButton";

export const BigFlerr = () => {
  return (
    <Box
      fontFamily={'Rubik Mono One'}
      fontSize={'590px'}
      color={'#dddddd'}
      opacity={0.2}
      // overflow={"hidden"}
    >
      FORMEE
    </Box>
  );
};


const validationSchema = Yup.object().shape({
  email: Yup.string().email('Field must be a valid email').required('Email is required'),
  password: Yup.string().required('Password is required').min(8, 'Minimum 8 characters').max(30, 'Maximum 30 characters'),
})

export const ErrorMessage = ({children}) => {
  return <Box
    color={'red'}
  >
    {children}
  </Box>
}

const Login = () => {

  const history = useHistory()

  const dispatch = useDispatch()

  const [signUpLoading, setSignUpLoading] = useState(false)

  const formik = useDefaultFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit(values) {
      initiateSignUp(values)
    },
  })

  const initiateSignUp = async (values) => {
    setSignUpLoading(true)

    try {
      const {data} = await AuthService.signIn(values)

      dispatch(login(data))

      history.push('/')
    } catch (e) {
      console.log(e)
      openNotification({type: 'error', description: 'E-mail or password is invalid'})
    } finally {
      setSignUpLoading(false)

    }
  }

  const {email, password} = formik.values


  const handleChange = (fieldName, e) => {
    formik.setFieldValue(fieldName, e.target.value)
  }

  return <Center
    h={'100vh'}
    bg={colors.bg}
  >
    <Box
      position={'absolute'}
      bottom={['-20%']}
      left={'20%'}
      zIndex={1}
    >
      <BigFlerr/>
    </Box>


    <Box
      zIndex={9999}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      p={'70px 50px'}
      bg={'white'}
      borderRadius={'12px'}
      boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
      maxW={['500px']}
      w={'100%'}
    >
      <VStack
        mb={['40px']}
      >
        <Space
          align={"center"}
          size={2}
          style={{marginRight: '15px'}}

        >
          <Logo width={'60px'}/>
          <LogoWord fontSize={'30px'}/>
        </Space>
        <Box
          fontSize={['20px']}
          fontWeight={300}
          w={'100%'}
        >
          Welcome back! We missed you.
        </Box>
      </VStack>




      <Box
        mb={['20px']}
      >
        {/*<Text mb='8px'>Email:</Text>*/}
        <Input
          borderColor={'#abbdd4'}
          bg={'white'}
          w={['270px']}
          onChange={e => handleChange('email', e)}
          placeholder='Email'
          value={email}
          isInvalid={formik.errors.email}
          onKeyPress={(e) => {
            if(e.charCode === 13){
              formik.handleSubmit()
            }
          } }
          // size='sm'
        />
        <ErrorMessage>
          {formik.errors.email}
        </ErrorMessage>
      </Box>

      <Box
        mb={['20px']}

      >
        {/*<Text mb='8px'>Email:</Text>*/}
        <Input
          bg={'white'}

          borderColor={'#abbdd4'}
          w={['270px']}
          value={password}
          isInvalid={formik.errors.password}
          type={'password'}
          onChange={e => handleChange('password', e)}
          placeholder='Password'
          onKeyPress={(e) => {
            if(e.charCode === 13){
              formik.handleSubmit()
            }
          } }
          // size='sm'
        />
        <Box
          w={'100%'}
        >
          <u>Forgot password ?</u>
        </Box>
        <ErrorMessage>
          {formik.errors.password}
        </ErrorMessage>
      </Box>



      <Box
        w={['270px']}
      >

        <PrimaryButton
          w={['100%']}
          fontWeigth={200}
          loading={signUpLoading}
          onClick={() => formik.handleSubmit()}
        >
          Log in to FORMEE
        </PrimaryButton>

        <Divider>
          <Box
            color={'#7895ba'}
          >
            OR
          </Box>
        </Divider>


        <GoogleLoginButton
          signIn={(data) => dispatch(login(data))}
        />



        <Box
          w={'100%'}
          mt={['10px']}
          textAlign={'center'}
        >
          Don't have an account yet? <u
          style={{cursor: 'pointer'}}
          onClick={() => history.push('/register')}>Sign up.</u>
        </Box>

      </Box>


    </Box>

  </Center>
};

export default Login;