import React, {useEffect} from 'react';
import _ from "lodash";
import {updateField} from "../../../firebase/form";
import useDebouncedEffect from '../../../hooks/useDebounceEffect';

const useFieldAutoSave = (fields, endingFields, activeField, setFields, setEndingFields, setFormData) => {
 
const effect = () => {
  if (activeField.type === 'endingField') {
    const oldField = endingFields.find(f => f.id === activeField.id)
    if (!_.isEqual(oldField, activeField)) {
      setEndingFields(endingFields.map(f => f.id === activeField.id ? {...activeField} : {...f}))
      updateField(activeField)
    }
  } else if (activeField.type === 'welcomeScreen') {
    console.log('UPDATE')
    setFormData({welcomeScreen: activeField})
    updateField(activeField)
  } else {
    const oldField = fields.find(f => f.id === activeField.id)
    if (!_.isEqual(oldField, activeField)) {
      setFields(fields.map(f => f.id === activeField.id ? {...activeField} : {...f}))
      updateField(activeField)
    }
  }
}

  useDebouncedEffect(effect, [activeField], 0, 500)
 
  

};

export default useFieldAutoSave;