import React, {useState} from 'react';
import {Box, HStack, Input, Button} from "@chakra-ui/react";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import {StyledInput} from "../../../shared/form/InlineTextField";
import PrimaryButton from "../../../shared/PrimaryButton";

const VideoGallery = () => {

  const [
    activeField,
    onImageModalChange,
  ] = useFormConfiguratorStore(state => [
    state.activeField,
    state.onImageModalChange,
  ])

  const [videoUrl, setVideoUrl] = useState(activeField?.layoutConfig?.videoUrl)

  return (
    <Box
      px={'10px'}
      w={'100%'}
      h={'375px'}
      overflowY={'scroll'}
      overflowX={'hidden'}
    >
      <Box
        flex={1}
      >
        <StyledInput
          placeholder={'Paste or type a link from YouTube.'}
          value={videoUrl}
          onChange={e => setVideoUrl(e.target.value)}
        />
      </Box>
      <Box mt={'15px'}>
        <PrimaryButton
          size={'sm'}
          onClick={() => onImageModalChange({videoUrl})}
        >
          Add video
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default VideoGallery;