import React from 'react';
import { Box, Modal, ModalContent } from "@chakra-ui/react";
import PrimaryButton from '../../shared/PrimaryButton';

const PreviewModal = ({ isOpen, onClose, formData }) => {

  if (!isOpen) return null

  let formUrl = `https://form.formee.io/${formData.id}?isPreviewMode=true`
  // let formUrl = `http://localhost:3005/form-view-front/${formData.id}?isPreviewMode=true`
  return <Modal isOpen={isOpen}
    onClose={onClose}
    size='full'
  >

    <ModalContent>
      <PrimaryButton
        position={'absolute'}
        top='20px'
        right='20px'
        zIndex={999999999999999}
        w='70px'
        onClick={onClose}
      >
        Close
      </PrimaryButton>

      <Box h={'100vh'}>
        <iframe
          width={'100%'}
          height={'100%'}
          src={formUrl}
        />
      </Box>


    </ModalContent>
  </Modal>


  // <Box
  //   position={'absolute'}
  //   top={0}
  //   left={0}
  //   w={'100vw'}
  //   h={'100vh'}

  // >


  // </Box>
}

export default PreviewModal;