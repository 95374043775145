import React from 'react'
import { Box, Center, HStack, Button } from '@chakra-ui/react'

const Selecton = ({ }) => {
    const bg = '#F1F1F1'
    return (
        <Box
            overflow={'hidden'}
            bg={'white'}
            boxShadow={'rgba(0, 0, 0, 0.1) 0px 4px 12px'}
            borderRadius={'8px'}
            py={'15px'}
            px={'30px'}
            w={'100%'}
            position={'relative'}
        >




            <Box display={'flex'}>
                <Box w={'18px'} h={'18px'} bg={bg} borderRadius={'50%'} mr={['20px']}>

                </Box>
                <Box w={'18px'} h={'18px'} bg={bg} borderRadius={'50%'} mr={['20px']}>

                </Box>
                <Box w={'18px'} h={'18px'} bg={bg} borderRadius={'50%'} mr={['20px']}>

                </Box>
            </Box>

            <Box display={'flex'} pt={'30px'} pb={'20px'}>

                <Box flex={1} h={'700px'}>

                    <Box w={'100%'} h={'100%'} bg={bg} borderRadius={'4px'} overflow={'hidden'} border={'1px solid #dddddd'}>

                        <iframe src={'/htm.html'}
                            id={'formee-preview-iframe'}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />

                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default Selecton