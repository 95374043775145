import React from 'react';
import {Box, Center, HStack} from '@chakra-ui/react'
import Logo from "../../shared/Logo";
import SideNavigation from "./components/SideNavigation";
import SecondaryButton from "../../shared/SecondaryButton";
import PrimaryButton from "../../shared/PrimaryButton";
import colors from "../../utils/commonStyles";
import {DownOutlined} from "@ant-design/icons";
import {BigFlerr} from "../Login";

const SIDE_MENU_WIDTH = '110px'

const Menu = () => {
  return <Box>

  </Box>
}

const H1 = ({children, ...rest}) => <Box
  as={'h1'}
  fontSize={'160px'}
  lineHeight={['160px']}
  {...rest}
>
  {children}
</Box>

const LandingPage = () => {
  return (
    <Box
      position={'relative'}
    >
      <Box
        position={'absolute'}
        top={'-460px'}
        right={'560px'}
        color={'#dddddd'}
      >

        {/*<DownOutlined style={{fontSize: '40px'}}/>*/}
        <img
          style={{
            width: '130px',
            filter: 'grayscale(100%)'
          }}
        src={'/arrows.png'}
        />
      </Box>


      {/*<Box*/}
      {/*  position={'absolute'}*/}
      {/*  left={150}*/}

      {/*  top={'20px'}*/}
      {/*  color={'white'}*/}
      {/*  fontSize={'40px'}*/}
      {/*>*/}
      {/*  Preview*/}
      {/*</Box>*/}


      <Box
        position={'absolute'}
        left={150}

        w={'900px'}
        h={'1px'}
        bg={'#dddddd'}
        top={'100px'}
      />

      <Box
        position={'absolute'}
        left={150}

        w={'600px'}
        h={'1px'}
        bg={'#dddddd'}
        top={'50px'}
      />

      <Box
        position={'absolute'}
        left={150}

        w={'400px'}
        h={'1px'}
        bg={'#dddddd'}
        top={'150px'}
      />


      <Box
        position={'fixed'}
        top={0}
        left={0}
        h={'100vh'}
        w={SIDE_MENU_WIDTH}
      >

        <Box
          position={'relative'}
          h={'100vh'}
          // w={SIDE_MENU_WIDTH}
          d={'flex'}
          flexDirection={'column'}
          p={'20px 0'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >

          <Box
            position={'absolute'}
            right={0}
            w={'1px'}
            h={'100%'}
            bg={'#dddddd'}
            top={0}
          />


          <Logo width={'50px'}/>
          <SideNavigation/>
        </Box>


      </Box>


      <Box
        ml={SIDE_MENU_WIDTH}
      >


        <Box
          flex={1}
        >


          <Box
            top={0}
            position={'fixed'}
            zIndex={-1}
            w={`calc(100% - ${SIDE_MENU_WIDTH})`}

          >

            <BigFlerr/>


            <Box
              w={'100%'}
              p={'20px'}
              d={'flex'}
              justifyContent={'space-between'}
            >
              <Box></Box>
              <Box>
                <PrimaryButton
                  p={'6px 20px'}
                  size={'small'}
                >
                  Log in
                </PrimaryButton>
              </Box>
            </Box>

            <Box
              p={['20px 90px 70px 90px']}

            >
              <H1
              >
                There's a better
              </H1>
              <H1
                ml={'40px'}
                mb={'90px'}
              >
                way to ask
              </H1>

              <Box
                as={'h2'}
                fontSize={'28px'}
                ml={'40px'}
                maxW={'1000px'}
                // pr={'530px'}
              >
                <span style={{color: colors.primary}}>flerr</span> is a best tool for forms. And your audience won't answer one.
                Create a typeform instead—and
                make everyone happy.
              </Box>
            </Box>

          </Box>


          <Box
            zIndex={1}

          >
            <Box
              bg={'#27272E'}
              opacity={1}
              h={'900px'}
              mt={'80vh'}
            >

              {/*<Center*/}
              {/*  w={'100%'}*/}
              {/*>*/}
              {/*  <Box*/}
              {/*    pt={'80px'}*/}
              {/*    // w={'400px'}*/}
              {/*    color={'white'}*/}
              {/*    fontSize={'40px'}*/}
              {/*    w={'750px'}*/}
              {/*    // d={'flex'}*/}
              {/*    textAlign={'center'}*/}
              {/*  >*/}
              {/*    Create <U>forms</U>, <U>surveys</U>, and <U>quizzes</U> {'\n'}*/}
              {/*    that people enjoy answering*/}
              {/*  </Box>*/}
              {/*</Center>*/}


              {/*<Center*/}
              {/*  mt={'180px'}*/}
              {/*>*/}
              {/*  <Box*/}
              {/*    border={'2px solid white'}*/}
              {/*    borderRadius={'8px'}*/}
              {/*    w={'900px'}*/}
              {/*  >*/}
              {/*    <div*/}
              {/*      data-tf-widget={"o3EYLBnE"}*/}
              {/*      data-tf-iframe-props="title=My typeform"*/}
              {/*      data-tf-medium="snippet"*/}
              {/*      style={{*/}
              {/*        width: '100%',*/}
              {/*        height: '400px'*/}
              {/*      }}>*/}

              {/*    </div>*/}

              {/*    <script src={"//embed.typeform.com/next/embed.js"}></script>*/}
              {/*  </Box>*/}
              {/*</Center>*/}

            </Box>

          </Box>

        </Box>


      </Box>
    </Box>

  );
};

const U = ({children}) => {
  return <span
    style={{
      textDecoration: 'underline 3px',
      textUnderlineOffset: '3px'
    }}
  >
    {children}
  </span>
}

export default LandingPage;