import React, { useEffect, useState } from 'react';
import { Form, Progress, Tag } from "antd";
import InlineTextField, { StyledInput } from "../../shared/form/InlineTextField";
import { Box, HStack } from "@chakra-ui/react";
import PrimaryButton from "../../shared/PrimaryButton";
import { useUserState } from "../../App";
import { useSelector } from "react-redux";
import { getUsage, updateAccountSettings } from "../../firebase/form";
import { updateById } from "../../utils/common";
import Divider from "../FormConfigurator/components/Divider";
import _ from 'lodash';
import colors from '../../utils/commonStyles';
import moment from 'moment';

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const Usage = () => {

    const [
        organizations,
        setOrganizations,
    ] = useUserState(store => ([
        store.organizations,
        store.setOrganizations,
    ]))

    const { user } = useSelector(state => state.persistentState)

    const [saving, setSaving] = useState()

    const [usage, setUsage] = useState({})

    const { storageTotalSize = 0, workspacesCount, membersCount, responsesCount, nextResetDate } = usage

    const [myOrganization, setMyOrganization] = useState(organizations?.find(o => o.owner?.id === user?.id))

    const { storageSize = 0, responsesPerMounth = 0, teamMembersLimit = 0, workspaceLimit = 0 } = myOrganization.limits || {}

    const fetchUsage = async () => {
        const data = await getUsage()
        setUsage(data)
    }

    useEffect(() => {
        fetchUsage()
    }, [])



    return (
        <Box
        // p={'0 20px 20px 20px'}
        >

            {/*<Divider m={'20px 0 40px 0'}/>*/}
            <Box d='flex' w={'100%'}>

                <Box bg={colors.bg} borderRadius={'8px'} p={'15px'} pr={'25px'} mr={'10px'} w={'50%'}>
                    <Box
                        fontSize={'18px'}
                    >

                        Responses limit  <Tag color={'blue'}>


                            <Box
                                as={'span'}
                                flex={1}
                                fontSize={'12px'}
                            >
                                {responsesCount} / {responsesPerMounth}
                            </Box>
                        </Tag>
                        <Tag color={'blue'}>


                            Resets on {moment(nextResetDate).format('DD MMM YYYY')}
                        </Tag>

                    </Box>
                    <Progress percent={_.round(responsesCount / responsesPerMounth * 100, 2)} />
                </Box>



                <Box bg={colors.bg} borderRadius={'8px'} p={'15px'} pr={'25px'} mr={'10px'} w={'50%'} >
                    <Box
                        fontSize={'18px'}
                    >

                        Storage size   <Tag color={'blue'}>


                            <Box
                                as={'span'}
                                flex={1}
                                fontSize={'12px'}
                            >
                                {formatBytes(storageTotalSize)} / {formatBytes(storageSize)}
                            </Box>
                        </Tag>
                    </Box>
                    <Progress percent={_.round(storageTotalSize / storageSize * 100, 2)} />
                </Box>


            </Box>

            <Box display={'flex'} mt='10px'>
                <Box bg={colors.bg} borderRadius={'8px'} p={'15px'} pr={'25px'} mr={'10px'} w={'50%'}>
                    <Box
                        fontSize={'18px'}
                    >Members <Tag color={'blue'}>


                            <Box
                                as={'span'}
                                flex={1}
                                fontSize={'12px'}
                            >
                                {membersCount} / {teamMembersLimit}
                            </Box>
                        </Tag>

                    </Box>
                    <Progress percent={_.round(membersCount / teamMembersLimit * 100, 2)}
                        showInfo={false}

                        status={_.round(membersCount / teamMembersLimit * 100, 2) === 100 && "exception"}

                    />
                </Box>

                <Box bg={colors.bg} borderRadius={'8px'} p={'15px'} pr={'25px'} mr={'10px'} w={'50%'}>
                    <Box
                        fontSize={'18px'}
                    >

                        Workspaces   <Tag color={'blue'}>


                            <Box
                                as={'span'}
                                flex={1}
                                fontSize={'12px'}
                            >
                                {workspacesCount} / {workspaceLimit}
                            </Box>
                        </Tag>
                    </Box>
                    <Progress percent={_.round(workspacesCount / workspaceLimit * 100, 2)}
                        showInfo={false}
                        status={_.round(workspacesCount / workspaceLimit * 100, 2) === 100 && "exception"}
                    />
                </Box>

            </Box>




        </Box>
    );
};

export default Usage;