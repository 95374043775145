import { Box, HStack, Button } from '@chakra-ui/react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { LogoWord } from '../../../shared/Logo';
import { setColorOpacity } from '../fieldTypes/MultiChoice/MultiChoice';
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";


const NavigationButtons = ({formData = {}, theme = {} }) => {


  const { buttonBackground, buttonColor } = theme

  const styleProps = {}

  const { buttonRadius, navButtonsColor } = theme

  const borderRadius = buttonRadius === 'sharp' ? '10px' : '50%'

  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)'
  })

  const logoUrl = formData.settings.logoUrl

  return (
    <>

      {logoUrl && <Box
        // w={'100%'}
        position={'absolute'}
        top={0}
        left={0}
        zIndex={99}
        m={'15px'}
        maxW={'40px'}
        maxH={'100px'}
      // d={'flex'}
      // flexDirection={'row'}
      >
        <img
          src={logoUrl}
        />
      </Box>
      }

      <HStack
        // w={'100%'}
        position={'absolute'}
        bottom={0}
        right={0}
        zIndex={999}
        p={'8px 30px 13px 20px'}
        // d={'flex'}
        // flexDirection={'row'}
        alignItems={'center'}
        justifyContent={isTablet ? 'space-between' : 'flex-end'}
      // bg={isTablet && 'rgba(255,255,255, 0.6)'}
      >

        {/* {showBranding && <Box><Anhor
      href={LANDING_PAGE_URL}
    >
      <HStack
        mt={'4px'}
        mr={'20px'}
        alignItems={'center'}
        color={navButtonsColor}
      >
        <Box color={navButtonsColor} fontSize={'12px'}>
          Made with
        </Box>
        <LogoWord fontSize={'16px'}/>
      </HStack>
    </Anhor></Box>
    } */}

        <HStack
        // display={'flex'}
        >
          {formData?.settings?.showBranding && <HStack
            mt={'4px'}
            mr={'20px'}
            alignItems={'center'}
            color={navButtonsColor}
          >
            <Box
              transition={'all 0.3s'}

              color={navButtonsColor} fontSize={'12px'}>
              Made with
            </Box>
            <LogoWord fontSize={'16px'} color={navButtonsColor} />
          </HStack>}


          <NavButton
            border={`1px solid ${navButtonsColor}`}
            bg={setColorOpacity(navButtonsColor, 0.06)}
            borderRadius={borderRadius}
          >
            <Box>
              {arrowIcon(navButtonsColor)}
            </Box>
          </NavButton>

          <NavButton
            borderRadius={borderRadius}
            border={`1px solid ${navButtonsColor}`}
            bg={setColorOpacity(navButtonsColor, 0.06)}
          >
            <Box
              transform={'rotate(180deg)'}
            >
              {arrowIcon(navButtonsColor)}
            </Box>
          </NavButton>
        </HStack>
      </HStack>
    </>
  );
};

const NavButton = ({ children, ...rest }) => {
  return <Button
    h={'40px'}
    w={'40px'}
    cursor={'default'}
    backdropFilter={'blur(4px)'}
    _hover={{
      bg: rest.bg

      // bg: 'transparent'
    }}
    _disabled={{
      // bg: 'transparent',
      bg: rest.bg,

      opacity: 0.4
    }}
    _active={{
      bg: rest.bg
    }}
    size={'md'}
    {...rest}
  >
    {children}

  </Button>
}

const arrowIcon = (color = '#888589') => <svg width="14" height="9" viewBox="0 0 14 9">
  <path fill="none" stroke={color} className="svgColor" stroke-width="2"
    d="M13.333 7.667L7.037 1.37.741 7.667"></path>
</svg>

export default NavigationButtons;