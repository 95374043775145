import React, { useCallback, useEffect, useState } from 'react';
import { modalStore, updateByIndex } from "../../../utils/common";
import { useHistory, useParams } from "react-router-dom";
import { addTemplateCategory, deleteForm, getTemplateCategory, saveFormAsTemplate  } from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import { Box } from "@chakra-ui/react";
import { Input, InputNumber, message, Space, Form, Select, Checkbox } from 'antd'
import SecondaryButton from "../../../shared/SecondaryButton";
import _ from 'lodash'
import useDebounceEffect from "../../../hooks/useDebounceEffect";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";

export const useTemplateCategoriesModalStore = modalStore()


const Item = ({ name, order, onChange: _onChange, id, fetchCategories }) => {

  const [flag, setFlag] = useState(null)

  const onChange = (data) => {
    _onChange(data)
    setFlag({})
  }

  useDebounceEffect(() => addTemplateCategory({ id, name, order }), [flag], 500, 500)

  return <Box mb={'10px'}>
    <Space>
      <Box w={'400px'}>

        <Input
          value={name}
          onChange={e => onChange({ name: e.target.value, order })}
        />
      </Box>
      <Box w={'200px'}>

        <InputNumber
          value={order}
          onChange={e => onChange({ name, order: e })}
        />
      </Box>
      <Box ml={'30px'}>
        <SecondaryButton
          onClick={async () => {
            await addTemplateCategory({ id, _delete: true })
            fetchCategories()
          }
          }>
          Delete
        </SecondaryButton>
      </Box>
    </Space>
  </Box>
}

const TemplateCategoriesModal = () => {
  const { organizationId, workspaceId } = useParams()

  const history = useHistory()

  const [
    setImageModalOpen,
  ] = useFormConfiguratorStore(state => [
    state.setImageModalOpen,
  ])

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState()

  const [state, setState] = useState({
    templateOrder: 1,
    categoryId: null,
    templateImageUrl: null,
    isTemplate: false
  })

  const { templateOrder, categoryId, templateImageUrl, isTemplate } = state

  const [isOpen, onClose, data = {}] = useTemplateCategoriesModalStore(store => [store.isOpen, store.onClose, store.data])

  const { isSaveAsTemplate } = data

  const onCloseHandler = () => {
    onClose()
  }

  const add = async () => {
    setLoading(true)
    try {
      await addTemplateCategory({ name: '', order: categories.length + 1 })
      fetchCategories()
    } catch (err) {
      message.error('Error....')
    } finally {
      setLoading(false)
    }
  }

  const saveTemplate = async () => {

    setLoading(true)
    try {
      await saveFormAsTemplate({...state, id: data?.id})
      message.success('Success')
    } catch (err) {

    } finally {
      setLoading(false)
    }
  }

  const onChangeImage = (data) => {
    setState({ ...state, templateImageUrl: data?.imageUrl })
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setState({ ...state, ...data?.templateData, isTemplate: data?.isTemplate })
    }
  }, [data])

  const fetchCategories = async () => {
    setLoading(true)

    try {
      const data = await getTemplateCategory()
      setCategories(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)

    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])


  const categoriesToOptions = categories.map(c => ({ value: c.id, label: c.name }))

  return <CustomModal
    title={isSaveAsTemplate ? 'Save as template' : 'Edit categories'}
    zIndex={12}
    onOk={isSaveAsTemplate && saveTemplate}
    okButtonProps={{
      title: 'Save',
      isLoading: loading,
      visible: false
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    size={'3xl'}
  >

    {isSaveAsTemplate && <Box>
      <Form>
        <Form.Item
          label={'Order'}
        >
          <InputNumber
            value={templateOrder}
            onChange={e => setState({ ...state, templateOrder: e })}
          />
        </Form.Item>
        <Form.Item
          label={'Category'}
        >
          <Select
            value={categoryId}
            onChange={e => setState({ ...state, categoryId: e })}
            options={categoriesToOptions}
          />
        </Form.Item>

        <Form.Item
          label={'is categor'}
          name={'isTemplate'}
        >
          <Checkbox
            checked={isTemplate}
            onChange={e => setState({ ...state, isTemplate: e.target.checked })}
          />
        </Form.Item>
      </Form>

      {templateImageUrl && <Box m={'20px'} w={'200px'}>
        <img src={templateImageUrl}

        />
      </Box>
      }

      <SecondaryButton
        onClick={() => setImageModalOpen({
          onImageModalChange: onChangeImage
        })}
      >
        Choose image
      </SecondaryButton>
    </Box>}

    {!isSaveAsTemplate && <Box
      p={'30px 0 10px'}
    >
      {categories.map((c, index) => <Item
        name={c.name}
        order={c.order}
        fetchCategories={fetchCategories}
        onChange={(item) => {
          setCategories(updateByIndex(categories, { ...c, ...item }, index))
        }}
        id={c.id}
      />)}

      <SecondaryButton
        onClick={add}
      >
        Add new category
      </SecondaryButton>

    </Box>}


  </CustomModal>
};


export default TemplateCategoriesModal;