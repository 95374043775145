import create from "zustand";
import qs from 'qs';
import draftToHtml from "draftjs-to-html";

import styled from "styled-components";


export const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const randomId = () => {
  let id = '';
  const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  for (let i = 1; i <= 8; i++) {
    let char = Math.floor(Math.random() * str.length + 1);
    id += str.charAt(char)
  }
  return id;
}

export const parseSearchQuery = (query) => qs.parse(query, {ignoreQueryPrefix: true})

// array
export const updateOrCreateWithRandomId = (array, item) => {
  if (!!item?.id) {
    return array.map(a => a.id === item.id ? {...item} : a)
  } else {
    return [...array, {id: randomId(), ...item}]
  }
}

export const updateById = (array, item, idKey = 'id') => array.map(a => a.id === item[idKey] ? {...item} : a)

export const updateByIndex = (array, item, index) => array.map((a, i) => (i === index) ? item : a)
export const saveByIndex = (array, item, index) => !!index ? array.map((a, i) => (i + 1 === index) ? item : a) : [...array, item]

export const removeById = (array, id) => {
  return array.filter(e => e.id !== id)
}

export const removeByIndex = (array, index) => {
  return array.filter((e, i) => i !== index)
}

export const swapArrayElements = (array = [], indexA, indexB) => {
  const copyArray = [...array]
  let temp = copyArray[indexA];

  copyArray[indexA] = copyArray[indexB];
  copyArray[indexB] = temp;
  return copyArray
}

export const mapResourceToOptions = (array) => array.map(a => ({value: a.id, label: a.name}))
export const mapToOptions = (array) => array.map(a => ({value: a, label: a}))

export const mapWithOrder = (array) => array.map((a, index) => ({...a, order: index + 1}))

export const mapToIdsArray = (array) => array.map(a => a?.id)

function hexToRgb(hex) {
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return [r, g, b]
}

function RGBAtoRGB(r, g, b, a, r2, g2, b2) {
  var r3 = Math.round(((1 - a) * r2) + (a * r))
  var g3 = Math.round(((1 - a) * g2) + (a * g))
  var b3 = Math.round(((1 - a) * b2) + (a * b))
  return "rgb(" + r3 + "," + g3 + "," + b3 + ")";
}

export const shade = (hexColor, alpha, backgroundColor = '#ffffff') => {
  const rgb = hexToRgb(hexColor.replace('#', ''))
  const backgroundRgb = hexToRgb(backgroundColor.replace('#', ''))

  return RGBAtoRGB(rgb[0], rgb[1], rgb[2], alpha, backgroundRgb[0], backgroundRgb[1], backgroundRgb[2])
}

export const isPresent = (val) => {
    return Boolean(val) || (val === 0)
}

export const modalStore = () => create(set => ({
  data: {},
  isOpen: false,
  onOpen(data) {
    set(store => ({
      data: !!data ? {...store.data, ...data} : store.data,
      isOpen: true
    }));
  },
  onClose() {
    set({
      isOpen: false,
      data: {},
    });
  },
}))



export const rawToHtml = (raw) => {
  const htmlContent = draftToHtml(
    raw
  );
  return <div dangerouslySetInnerHTML={{__html: htmlContent}}></div>
}

const StyledHTMWrapper = styled.div`
  a {
    color: blue !important;
    text-decoration: underline !important;
  }
`;

export const rawToStyledHtml = (raw) => {
  const htmlContent = draftToHtml(
    raw
  );

  return <StyledHTMWrapper>
    <div dangerouslySetInnerHTML={{__html: htmlContent}}></div>
  </StyledHTMWrapper>
}