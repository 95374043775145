import React, {useState} from 'react';
import {Box, HStack} from "@chakra-ui/react";
import {RightCircleOutlined, ShareAltOutlined} from "@ant-design/icons";
import {StyledTabs} from "../ConfiguratorHeader";
import {Tabs} from "antd";
import Insights from "./Insights";
import Divider from "../Divider";
import colors from "../../../../utils/commonStyles";
import {HEADER_HEIGHT} from "../../index";
import ResponsesTable from "./ResponsesTable";

const {TabPane} = Tabs;

const Results = () => {

  const [currentTab, setCurrentTab] = useState('1')


  return (
    <>
      <Box
        borderBottom={'1px solid #dddddd'}
        bg={'white'}
      >
        <Container>
          <StyledTabs
            defaultActiveKey={'1'}
            activeKey={currentTab}
            onChange={(e) => setCurrentTab(e)}
            style={{padding: '10px'}}
            pb={'0px'}
          >
            <TabPane tab={<Box
              fontSize={'14px'}>Summary </Box>} key={'1'}
            >
            </TabPane>
            <TabPane tab={<Box fontSize={'14px'}>Responses</Box>} key={'2'}>
            </TabPane>
          </StyledTabs>
        </Container>
      </Box>

      <Box h={`calc(100vh - ${HEADER_HEIGHT} - 47px)`} overflow={'auto'}>

        {currentTab === '1' && <Container>
          <Box>
            <Insights/>
          </Box>
        </Container>}

        {currentTab === '2' && <ResponsesTable/>}

      </Box>

    </>
  );
};

export const Container = ({children, ...rest}) => {
  return <Box
    maxW={'1024px'}
    p={'0 15px'}
    w={'100%'}
    margin={'0 auto'}
    {...rest}
  >
    {children}
  </Box>
};

export default Results;