import {LOGOUT, LOGIN, UPDATE_PROFILE} from "../constants";
import history from '../../history'

export const logout = (config = {}) => {

  const {redirectToLogin = true} = config

  if (redirectToLogin) {
    history.push({pathname: '/login', state: {allowRouteChange: true}})
  }

  return {
    type: LOGOUT
  };
};

export const login = (data) => {
  history.push('/')

  return {
    type: LOGIN,
    payload: data
  };
};

export const updateProfile = (data) => {

  return {
    type: UPDATE_PROFILE,
    data,
  };
};

export const updateToken = (data) => {

  return {
    type: 'UPDATE_TOKEN',
    data,
  };
};