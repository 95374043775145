import React from 'react';
import FieldWrapper from "../../../../shared/FieldWrapper";
import {Input, Box, Flex} from '@chakra-ui/react'
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import {Player} from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import {useTranslation} from "../../../../hooks/useTranslation";
import {FakeButton} from "../TextField/TextField";



const WelcomeScreen = ({fieldData, onChange, theme = {}, mentionOptions, messages}) => {


  if (!fieldData) return null

  const {questionColor, fontSize, answerColor, buttonBackground, buttonColor} = theme
  const {options = [], generalSettings = {}} = fieldData
  const {btnText, required} = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  const align = 'center'

  return (
    <FieldWrapper>



      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          maxHeight='380px'

          width={'100%'}
        />}
      </Box>}

      <Flex
        mt={'15px'}
        w={'100%'}
        justifyContent={'center'}
      >
        <FakeButton
          backgroundColor={buttonBackground}
          color={buttonColor}
          theme={theme}

        >
          {messages['start']}
        </FakeButton>
      </Flex>

    </FieldWrapper>
  );
};
export default WelcomeScreen;