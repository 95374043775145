import { Box } from "@chakra-ui/react";
import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomModal from "../../../shared/CustomModal";
import { logout } from '../../../store/actions/AuthActions';
import { modalStore } from "../../../utils/common";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";


export const useConfirmLogoutModalStore = modalStore()


const ConfirmLogoutModal = ({ }) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const [
        setActiveField,
      ] = useFormConfiguratorStore(state => [
        state.setActiveField,
      ])

    const [isOpen, onClose, data = {}] = useConfirmLogoutModalStore(store => [store.isOpen, store.onClose, store.data])


    return <CustomModal
        title={'Logout'}
        zIndex={12}
        onOk={() => {
            onClose()
            dispatch(logout())
            history.push('/login')
            setActiveField({})
        }}
        okButtonProps={{
            title: 'Yes',
        }}
        isOpen={isOpen}
        onClose={onClose}
    // size={'xl'}
    >
        <Box
            p={'30px 0 10px'}
        >
            Are you sure you want to logout ?
        </Box>


    </CustomModal>
};

export default ConfirmLogoutModal;