import React, {useCallback, useEffect, useState} from 'react';
import {modalStore} from "../../../utils/common";
import {useHistory, useParams} from "react-router-dom";
import {deleteForm} from "../../../firebase/form";
import CustomModal from "../../../shared/CustomModal";
import {Box} from "@chakra-ui/react";
import {Form} from "antd";
import {StyledInput} from "../../../shared/form/InlineTextField";


export const useDeleteFormModalStore = modalStore()


const ConfirmFormDeleteModal = ({fetchForms}) => {
  const {organizationId, workspaceId} = useParams()

  const history = useHistory()



  const [loading, setLoading] = useState()

  const [isOpen, onClose, data = {}] = useDeleteFormModalStore(store => [store.isOpen, store.onClose, store.data])

  const onCloseHandler = () => {
    onClose()
  }


  const deleteFormHandler = useCallback(
    async (values) => {



      setLoading(true)

      try {

        const requestData = {
          ...values
        }

        await deleteForm(requestData)
        fetchForms(workspaceId)

        onCloseHandler()

      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    [data]
  )


  return <CustomModal
    title={'Delete form and responses?'}
    zIndex={12}
    onOk={() => deleteFormHandler({id: data?.id})}
    okButtonProps={{
      title: 'Delete',
      isLoading: loading,
    }}
    isOpen={isOpen}
    onClose={onCloseHandler}
    // size={'xl'}
  >
    <Box
      p={'30px 0 10px'}
    >

      You're about to delete. It'll be gone forever and we won't be able to recover it.


    </Box>


  </CustomModal>
};

export default ConfirmFormDeleteModal;