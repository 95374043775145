import React from 'react';
import {Avatar, Dropdown} from "antd";
import {Box, HStack} from "@chakra-ui/react";
import colors from "../../../utils/commonStyles";
import {INPUT_BORDER_RADIUS} from "../index";
import {TextEllipsis} from "../../FormConfigurator/components/FieldsList";
import {useHistory} from "react-router-dom";

export const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];


export const User = ({name = '', description, color}) => {
  return <HStack
  >
    <Box>
      <Avatar style={{backgroundColor: color, verticalAlign: 'middle'}} size="small">
        {name[0]?.toUpperCase()}
      </Avatar>
    </Box>
    <Box
      flex={1}
      fontSize={'14px'}
      fontWeight={300}
    >
      <TextEllipsis linesLength={1}>
        {name}
      </TextEllipsis>
      <Box
        // py={'5px'}
        fontSize={'12px'}
        fontWeight={800}
        letterSpacing={'3px'}
      >
        {description}
      </Box>
    </Box>
  </HStack>
}

const UserWrapper = (props) => <Box
  pl={'15px'}
  borderRadius={'6px'}
  _hover={{bg: colors.bg}}
  cursor={'pointer'}
  my={'7px'}
  {...props}
>
  {props.children}
</Box>

const OrganizationSelect = ({currentOrganization = {}, organizations = [], user = {}}) => {

  const userId = user?.id
  const history = useHistory()
  const {name} = currentOrganization

  const myOrganization = organizations?.find(o => o.owner?.id === userId)
  const role = currentOrganization?.owner?.id === userId ? 'OWNER' : 'MEMBER'


  const organizationsOverlay = <Box
    overflow={'hidden'}
    borderRadius={INPUT_BORDER_RADIUS}
    bg={'white'}
    boxShadow={colors.shadowColor}
    p={'15px'}
    border={'1px solid #f2f2f2'}
  >
    <Box
      mb={'6px'}
    >
      YOUR ORGANIZATIONS
    </Box>

    {organizations?.map(o => {
      const active = o.id === currentOrganization?.id
      return <UserWrapper
        bg={active && colors.bg}
        onClick={() => {
          if (active) return
          history.push(`/organization/${o.id}/workspace`)
        }}
      >
        <User
          color={ColorList[3]}
          name={o?.name}
          description={o?.owner?.id === userId ? 'OWNER' : 'MEMBER'}
        />
      </UserWrapper>

    })}


  </Box>


  return (
    <div>
      <Dropdown
        overlay={organizationsOverlay}
        trigger={'click'}
        placement="topRight"
      >
        <Box
          bg={colors.bg}
          borderRadius={INPUT_BORDER_RADIUS}
          p={'10px 18px'}
          cursor={'pointer'}
        >
          <User
            color={ColorList[3]}
            name={user?.email}
            description={role}
          />
        </Box>

      </Dropdown>

    </div>
  );
};

export default OrganizationSelect;