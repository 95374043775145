import React, { useCallback, useState } from 'react';
import CustomDropdownMenu from "../../../shared/CustomDropdownMenu";
import { createField, createForm } from "../../../firebase/form";
import { useHistory, useParams } from "react-router-dom";
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";

import { Box, HStack, Button, Center } from '@chakra-ui/react'
import { fieldTypes } from "../fieldTypes/config";
import styled from "styled-components";
import { Dropdown, Spin } from "antd";
import PrimaryButton from "../../../shared/PrimaryButton";
import { PlusOutlined } from "@ant-design/icons";
import colors from "../../../utils/commonStyles";

const getFields = (types = []) => types.map(t => fieldTypes[t])

const contactFieldTypes = getFields(['contactForm', 'email', 'website', 'phone'])
const formStructureFieldTypes = getFields(['infofield', 'welcomeScreen', 'endingField'])
const textFieldTypes = getFields(['textfield', 'textarea'])
const numberFieldTypes = getFields(['numberfield'])
const choiceFieldTypes = getFields(['select', 'multiChoice', 'singleChoice', 'pictureChoice', 'yesNo', 'legal'])
const ratingFieldTypes = getFields(['opinionScale', 'rating'])
const otherFieldTypes = getFields(['signature', 'datefield', 'fileUpload'])

const FieldBlock = ({ title, items, disabled, createFieldHandler }) => <FieldsBox
  title={title}
>
  {items.map(t => (
    <FieldCard
      name={t.label}
      type={t.type}
      onClick={() => {
        if (!disabled) {
          createFieldHandler(t.type)
        }
      }}
    />

  ))}
</FieldsBox>

const Overlay = ({ createFieldHandler, creatingField }) => {
  return <>
    <Box
      p={'10px 20px'}
      bg={'white'}
      border={`1px solid #bbbbbb`}
      borderRadius={'8px'}
      boxShadow={colors.shadowColor}
    >
      <Box
        fontWeight={600}
        fontSize={'18px'}
      >
        All field types
      </Box>

      <Box
        d={'flex'}
        p={'20px 0'}
      >
        <Box>
          <FieldBlock
            createFieldHandler={createFieldHandler}
            disabled={creatingField}
            title={'Contact info'}
            items={contactFieldTypes}
          />
          <FieldBlock
            disabled={creatingField}
            createFieldHandler={createFieldHandler}

            title={'Form structure'}
            items={formStructureFieldTypes}
          />


        </Box>

        <Box>
          <FieldBlock
            title={'Text'}
            createFieldHandler={createFieldHandler}

            items={textFieldTypes}
            disabled={creatingField}

          />
          <FieldBlock
            title={'Number'}
            items={numberFieldTypes}
            createFieldHandler={createFieldHandler}

            disabled={creatingField}
          />
        </Box>

        <Box>


          <FieldBlock
            title={'Choices'}
            items={choiceFieldTypes}
            createFieldHandler={createFieldHandler}

            disabled={creatingField}

          />

        </Box>


        <Box>
          <FieldBlock
            title={'Rating'}
            items={ratingFieldTypes}
            createFieldHandler={createFieldHandler}

            disabled={creatingField}

          /> <FieldBlock
            title={'Other'}
            items={otherFieldTypes}
            createFieldHandler={createFieldHandler}

            disabled={creatingField}

          />
        </Box>


      </Box>
    </Box>
  </>
}

const FieldTypeSelectDropdown = () => {


  const history = useHistory()
  const { formId } = useParams()

  const [
    addField,
    fields,
    activeField,
    formData,
    setFormData
  ] = useFormConfiguratorStore(state => [
    state.addField,
    state.fields,
    state.activeField,
    state.formData,
    state.setFormData
  ])

  const [visible, setVisible] = useState(false)
  const [creatingField, setCreatingField] = useState(false)

  const createFieldHandler = async (fieldType) => {
    setCreatingField(true)
    try {

      const requestData = {
        formId,
        type: fieldType,
        themeId: activeField.theme
      }

      const data = await createField(requestData)
      const type = data.type
      setVisible(false)

      if (type === 'welcomeScreen') {
        history.push(`/form/${formId}?block=welcome`)
        setFormData({ ...formData, welcomeScreen: data })
      } else {
        addField(data)
        history.push(`/form/${formId}?block=${data?.id}`)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setCreatingField(false)
    }
  }

  const overlay = <Overlay
    creatingField={creatingField}
    createFieldHandler={createFieldHandler}
  />

  return (
    <div>
      {/*<CustomDropdownMenu*/}
      {/*  loading={creatingField}*/}
      {/*>*/}
      <Dropdown
        overlay={overlay}
        trigger={'click'}
        visible={visible}
        onVisibleChange={e => setVisible(e)}
      >
        <PrimaryButton
          size={'sm'}
          loading={creatingField}
          onClick={() => setVisible(true)}
        >
          <PlusOutlined />
          {/*{creatingField ? <Spin/> : }*/}
        </PrimaryButton>

      </Dropdown>

      {/*</CustomDropdownMenu>*/}
    </div>
  );
};

const FieldsBox = ({ children, title }) => {
  return (
    <Box
      mt={'15px'}
      mr={'55px'}
      mb={'30px'}
    >
      <Box

      >
        <b>{title}</b>
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  )
}

const FieldCard = ({ icon, type, name, onClick }) => {

  const IconComponent = fieldTypes[type]?.icon

  return (
    <HStack
      cursor={'pointer'}
      m={'12px 0'}
      onClick={onClick}
    >
      <Box
        color={'black'}
      // w={'26px'}
      // h={'26px'}
      // borderRadius={'6px'}
      // backgroundColor={colors.primary}
      >
        <IconComponent />
      </Box>
      <Box
        ml={'10px'}
      >
        {name}
      </Box>
    </HStack>
  )
}

export const SliderBox = styled(Box)`
   &::-webkit-scrollbar {
    display: none;    
  }
 
  overflow-y: auto;
  overflow-x: hidden;
`;


export default FieldTypeSelectDropdown;