import React from 'react';
import { Box } from "@chakra-ui/react";
import FieldWrapper from "../../../../shared/FieldWrapper";
import TitleAndDescriptionInputs from "../../components/TitleAndDescriptionInputs";
import { Player } from "../../components/Content";
import CenterImage from "../../../../shared/CenterImage";
import { OptionItem } from "../MultiChoice/MultiChoice";
import { useTranslation } from "../../../../hooks/useTranslation";

export const renderNameBlock = (name) => ({
  "blocks": [
    {
      "key": "drc9v",
      "text": name,
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  "entityMap": {}
}
)

const LegalField = ({ fieldData, onChange, theme = {}, mentionOptions, showQuestionNumber, messages }) => {

  const translations = messages

  const { questionColor, fontSize, align, answerColor, backgroundColor } = theme

  const { generalSettings = {} } = fieldData
  const { required } = generalSettings
  const imageUrl = fieldData?.layoutConfig?.imageUrl
  const videoUrl = fieldData?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? fieldData?.layoutConfig?.layoutId : null

  if (!fieldData) return null

  const { buttonRadius } = theme

  const borderRadius = buttonRadius === 'sharp' ? '8px' : '44px'


  return (
    <FieldWrapper>

      <TitleAndDescriptionInputs
        mentionOptions={mentionOptions}

        afterContent={required && '*'}
        beforeContent={showQuestionNumber && `${fieldData?.order}.`}
        data={fieldData}
        onChange={onChange}
        color={questionColor}
        fontSize={fontSize}
        textAlign={align}
        id={fieldData.id}
      />

      {layoutId === 1 && <Box
        width={'100%'}
        mb={['30px']}
      >
        {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
          justifyContent={align === 'center' ? 'center' : 'flex-start'}
          src={imageUrl}
          height={'100%'}
          width={'100%'}
          maxHeight='380px'

        />}
      </Box>}

      <Box
        d={'flex'}
        justifyContent={align === 'center' ? 'center' : 'flex-start'}
      >


        <Box
          maxW={'240px'}
        >
          <OptionItem
            id={'yes'}
            char={'A'}
            name={'Yes'}
            allowRemove={false}
            fontSize={fontSize === 'sm' ? '16px' : '20px'}
            borderRadius={borderRadius}

            color={answerColor}
            backgroundColor={backgroundColor}
            readOnly={true}
            nameBlocks={renderNameBlock(translations["legalField.accept"])}
          />
          <OptionItem
            id={'no'}
            char={'B'}
            name={'No'}
            allowRemove={false}
            fontSize={fontSize === 'sm' ? '16px' : '20px'}
            borderRadius={borderRadius}

            color={answerColor}
            backgroundColor={backgroundColor}
            readOnly={true}
            nameBlocks={renderNameBlock(translations["legalField.noAccept"])}
          />

        </Box>
      </Box>

    </FieldWrapper>
  );
};

export default LegalField;