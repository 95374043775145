import axiosInstance from "./api";
import {logout} from "../store/actions/AuthActions";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {

      const token = store.getState().persistentState.accessToken

      if (token) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          dispatch(logout())
        }
      }

      return Promise.reject(err.response);
    }
  );
};

export default setup;