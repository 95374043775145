import React, { useEffect, useMemo } from 'react';
import { Box, Center } from '@chakra-ui/react'
import useFormConfiguratorStore from "../stores/useFormConfiguratorStore";
import _ from 'lodash'
import styled, { css } from "styled-components";
import { fieldTypes } from "../fieldTypes/config";
import useFieldAutoSave from "../hooks/useFieldAutoSave";
import { useParams } from "react-router-dom";
import { animated, useTransition } from 'react-spring';
import CenterImage from "../../../shared/CenterImage";
import CustomDatePicker from "../../../shared/form/CustomDatePicker";
import { renderMentionOptions } from "../../../shared/CustomWysiwyg";
import { updateFieldMentions } from "../hooks/msic";
import { message } from "antd";
import NavigationButtons from './NavigationButtons';

export const defaultTheme = {
  isGalleryTheme: false,
  fontFamily: 'Inter',
  fontSize: 'md',
  align: 'left',
  questionColor: '#000000',
  answerColor: '#0445AF',
  buttonBackground: '#0445AF',
  buttonColor: '#ffffff',
  backgroundColor: '#ffffff',
  imageUrl: null,
  imageConfig: null,
  navButtonsColor: '#888589',
  progressBarColor: '#0445AF'
}

const ALIGN_CENTER_TYPES = ['welcomeScreen', 'endingField']

export const getFieldTheme = (themeId, galleryThemes, myThemes) => {
  return galleryThemes.concat(myThemes).find(t => t.id === themeId)
}

export const Player = ({ videoUrl }) => {

  const youtubeRegrex1 = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const youtubeRegrex2 = /^[0-9a-zA-Z\-_]+$/;

  const match = videoUrl.match(youtubeRegrex1);
  const match2 = videoUrl.match(youtubeRegrex2);

  const videoId =
    match && match[7].length === 11
      ? match[7]
      : match2 && videoUrl.length === 11
        ? videoUrl
        : false;

  return (
    <Box>
      <VideoContainer>
        <Video
          // src={videoUrl}
          src={`//www.youtube.com/embed/${videoId}`}
          // src={`https://player.vimeo.com/external/289258217.hd.mp4?s=5cf87d7670d96bbd2c110f4dc97fd5116f4468ad&profile_id=175&oauth2_token_id=57447761`}
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
          // width="460"
          height="500"
        />
      </VideoContainer>
    </Box>

  )
}

const containerStyles = {
  backgroundPosition: 'center',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'row',
  w: '100%',
  h: '100%',
  alignItems: 'stretch'
}

export const VideoContainer = styled(Box)`
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
`;

export const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const Content = () => {

  const [
    formData = {},
    fields,
    activeField,
    setActiveField,
    galleryThemes,
    myThemes,
    activeTheme,
  ] = useFormConfiguratorStore(state => [
    state.formData,
    state.fields,
    state.activeField,
    state.setActiveField,
    state.galleryThemes,
    state.myThemes,
    state.activeTheme,
  ])

  const messages = formData.messages

  const transitions = useTransition(activeField.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  })

  // const backgroundImageUrl = null

  const showQuestionNumber = formData?.settings?.showQuestionNumber

  const imageUrl = activeField?.layoutConfig?.imageUrl
  const videoUrl = activeField?.layoutConfig?.videoUrl
  const layoutId = (imageUrl || videoUrl) ? activeField?.layoutConfig?.layoutId : null

  const fieldTheme = getFieldTheme(formData.theme?.id, galleryThemes, myThemes)

  if (_.isEmpty(activeField)) return null

  const theme = activeTheme || fieldTheme || defaultTheme

  const { fontFamily, questionColor, backgroundColor, imageUrl: backgroundImageUrl, fontSize, align } = theme

  const FieldComponent = fieldTypes?.[activeField.type]?.component

  if (!FieldComponent) return null

  const mentionOptions = renderMentionOptions(fields, activeField, formData)

  const Field = <FieldComponent
    config={activeField?.config}
    fieldData={activeField}
    messages={messages}
    onChange={(data) => {
      setActiveField({ ...activeField, ...data })
    }}
    mentionOptions={mentionOptions}
    theme={theme}
    showQuestionNumber={showQuestionNumber}
  />

  const defaultContainerStyles = {
    ...containerStyles,
    // backgroundColor,
    fontFamily
  }

  let content = null

  switch (layoutId) {
    case 1: {
      content = (<Container
        {...defaultContainerStyles}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              m={['80px 0 80px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>
      </Container>)
    }
      break
    case 7: {
      content = (<Container
        {...defaultContainerStyles}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              m={['80px 0 80px 0']}
            >
              <Box
                width={'100%'}
                mb={['30px']}
              >
                <Center>
                  {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
                    justifyContent={ALIGN_CENTER_TYPES.includes(activeField.type) ? 'center' : align === 'center' ? 'center' : 'flex-start'}
                    src={imageUrl}
                    height={'100%'}
                    width={'100%'}
                    maxHeight='380px'
                  />}
                </Center>

              </Box>

              {Field}
            </Box>
          </Center>
        </SliderBox>
      </Container>)
    }
      break
    case 2: {
      content = (<Container
        {...defaultContainerStyles}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              p={['0 80px 0 80px']}
              m={['80px 0 80px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>

        <Center flex={1} w={'100%'} h={'100%'}>
          <Box w={'100%'} p={'0 80px 0 52px'} maxW={['720px']}>
            {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
              src={imageUrl}
              height={'100%'}
              width={'100%'}

            />}
          </Box>
        </Center>
      </Container>)
    }
      break
    case 3: {
      content = (<Container
        {...defaultContainerStyles}
        flexDirection={'row-reverse'}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              p={['0 80px 0 80px']}
              m={['80px 0 80px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>

        <Center flex={1} w={'100%'} h={'100%'}>
          <Box w={'100%'} p={'0 52px 0 80px'} maxW={['720px']}>
            {videoUrl ? <Player videoUrl={videoUrl} /> : <CenterImage
              src={imageUrl}
              height={'100%'}
              width={'100%'}
            />}
          </Box>
        </Center>

        {/*<Center flex={1}>*/}
        {/*  <Box w={'100%'} h={'100%'} p={'0 80px 0 52px'}>*/}
        {/*    {videoUrl ? <Player videoUrl={videoUrl}/> : <CenterImage*/}
        {/*      src={imageUrl}*/}
        {/*      height={'100%'}*/}
        {/*      width={'100%'}*/}
        {/*    />}*/}
        {/*  </Box>*/}
        {/*</Center>*/}
      </Container>)
    }
      break
    case 4: {
      content = (<Container
        {...defaultContainerStyles}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              p={['0 80px 0 80px']}
              m={['80px 0 80px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>
        <Center flex={1}>
          <Box w={'100%'} h={'100%'}>
            <img
              src={imageUrl}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Center>
      </Container>)
    }
      break
    case 5: {
      content = (<Container
        {...defaultContainerStyles}
        flexDirection={'row-reverse'}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              p={['0 80px 0 80px']}
              m={['80px 0 80px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>
        <Center flex={1}>
          <Box w={'100%'} h={'100%'}>
            <img
              src={imageUrl}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Center>
      </Container>)
    }
      break
    case 6: {
      content = (<Container
        {...defaultContainerStyles}
        backgroundImage={`url('${imageUrl}')`}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              p={['0 80px 0 52px']}
              m={['80px 0 80px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>
      </Container>)
    }
      break
    default: {
      content = <Container
        {...defaultContainerStyles}
      >
        <SliderBox>
          <Center
            w={'100%'}
            minH={'100%'}
          >
            <Box
              w={'100%'}
              maxW={['720px']}
              m={['80px 0 144px 0']}
            >
              {Field}
            </Box>
          </Center>
        </SliderBox>
      </Container>
    }
  }

  return (
    <Box
      style={{
        height: '100%',
        backgroundColor,
        position: 'relative'
      }}
    >

      <NavigationButtons formData={formData} theme={theme} />

      {transitions(
        (styles, item) => (item === activeField.id) && <animated.div
          style={{
            ...styles,
            height: '100%',
            backgroundPosition: 'center',
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundImage: backgroundImageUrl && `url('${backgroundImageUrl}')`

          }}
        >
          {content}
        </animated.div>
      )}
    </Box>
  );
};

export const SliderBox = styled.div`
   &::-webkit-scrollbar {
    display: none;    
  }
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Container = styled(Box)`
  font-family: Roboto;
  transition: all 0.4s;
  ${({ fontFamily }) => !!fontFamily && css`
    font-family: ${fontFamily};
  `}
  
`;

export default Content;