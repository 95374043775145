import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";
import { Tabs as AntdTabs } from 'antd';
import qs from 'qs';
import {parseSearchQuery} from "../utils/common";

const StyledTab = styled.div`
  padding: 0 30px;

  @media (max-width: 1350px) {
    padding: 0 10px;
  }
`
export default function Tabs({ children }) {
  const history = useHistory();
  const location = useLocation();
  const queryObject = parseSearchQuery(location.search);

  const handleChange = key => {
    queryObject.activeKey = key;

    history.push({
      search: qs.stringify(queryObject),
    });
  };

  const filteredChildren = children.filter(tab => !tab.hidden);

  return (
    <AntdTabs
      onChange={handleChange}
      defaultActiveKey={children[0]?.key}
      activeKey={queryObject.activeKey}
      style={{wordBreak: 'normal'}}
      moreIcon="More"
    >
      {filteredChildren.map(tab => (
        <AntdTabs.TabPane
          tab={<StyledTab>{tab.name}</StyledTab>}
          key={tab.key}
          disabled={tab.disabled}
          style={{ paddingTop: '10px' }}
        >
          {tab.content}
        </AntdTabs.TabPane>
      ))}
    </AntdTabs>
  );
}
