import React, { useState } from 'react';
import { Form, Spin } from "antd";
import InlineTextField, { StyledInput } from "../../shared/form/InlineTextField";
import { Box, HStack } from "@chakra-ui/react";
import PrimaryButton from "../../shared/PrimaryButton";
import { useUserState } from "../../App";
import { useSelector } from "react-redux";
import { updateAccountSettings, useBillingQuery } from "../../firebase/form";
import { updateById } from "../../utils/common";
import Divider from "../FormConfigurator/components/Divider";
import SecondaryButton from '../../shared/SecondaryButton';
import { useHistory } from 'react-router-dom';
import moment from 'moment'

const NextInvoice = ({ data = {} }) => {
    let { next_payment_attempt, lines, subtotal = 0, total = 0, discount = 0 } = data

    total = `${total / 100}.00`
    subtotal = `${subtotal / 100}.00`
    discount = `${discount / 100}.00`

    const nextPayment = moment.unix(next_payment_attempt).format('MMM DD, YYYY')

    return <div class="">
        <div class="border-b pl-5 ">
            <p class="text-lg font-medium">Upcoming invoice</p>
            <p class="text-xs font-normal text-font_secondary mt-1">{nextPayment}</p>
        </div>
        <div class="py-3 px-5 border-b">
            <p class="text-xs py-2"> Billed items </p>

            {lines?.data.map(d => <div class="my-2 px-4">
                <p class="text-sm font-normal">{d.description}</p>
            </div>)}

        </div>
        <div class="py-3 px-7 my-4">
            <div class="flex justify-between">
                <p class="text-sm font-normal">Sub total</p>
                <p class="text-sm font-medium">${subtotal}</p>
            </div>
            <div class="flex justify-between my-3">
                <p class="text-sm font-normal">Discount</p>
                <p class="text-sm font-medium">${discount}</p>
            </div>
            <div class="flex justify-between border-t pt-3">
                <p class="text-sm font-normal">Total</p>
                <p class="text-sm font-medium">${total}</p>
            </div>
        </div>
    </div>
}

const Table = ({ invoices = [] }) => {

    return <Box class="mt-4 " >
        <p class="my-4 font-medium">Payment history</p>
        <table class="w-full bg-gray-100 rounded-lg ">
            <thead>
                <tr class="flex flex-row items-start bg-gray-200 rounded-t-lg text-center">
                    <th class="w-1/3 px-5 py-2 font-normal rounded-l-lg">Order Id</th>
                    <th class="w-1/3 px-5 py-2 font-normal">Date</th>
                    <th class="w-1/3 px-5 py-2 font-normal rounded-r-lg">Invoice</th>
                </tr>
            </thead>
            <tbody class="text-center ">
                <Box maxH={'300px'} overflowY={'auto'}>

                    {invoices.map(i => <tr class="flex items-center justify-center py-3 hover:bg-white">
                        <p class="w-1/3 px-5  text-font_primary font-normal text-[12px]">{i.number}</p>
                        <p class="w-1/3 px-5 text-font_primary font-normal text-sm">{moment.unix(i?.status_transitions?.finalized_at).format('YYYY/MM/DD')}</p><a
                            class="w-[30%] px-5 text-primary font-normal text-sm flex items-center space-x-2 group cursor-pointer"
                            href={i.invoice_pdf}
                            target="blank"><span>View and download</span><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-4">
                                <path fill-rule="evenodd"
                                    d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                                    clip-rule="evenodd"></path>
                            </svg></a>
                    </tr>)}
                </Box>


            </tbody>
        </table>
    </Box>
}

const BlurBg = () => {
    return <Box
        position='absolute'
        top={0}
        left={0}
        w='100%'
        h='100%'
        // bg='red'
        zIndex={923}
        className='blur-2xl'
    >

    </Box>
}
const InfoCard = ({ data = {} }) => {
    const { price = '0.00', label = 'Free' } = data

    const history = useHistory()
    return <div class="relative w-[45%] px-6 py-5 rounded-xl bg-primary space-y-3 shadow-lg bg-blue-400">
        <div class="absolute top-7 right-7"><svg width="50" height="50" viewBox="0 0 50 50" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.2" cx="25" cy="25" r="25" fill="white"></circle>
            <g clip-path="url(#clip0_3088_17938)">
                <path
                    d="M36.9374 22.2069C36.7803 21.721 36.3493 21.3758 35.8393 21.3299L28.9123 20.7009L26.1731 14.2896C25.9712 13.8198 25.5112 13.5156 25.0001 13.5156C24.4891 13.5156 24.0291 13.8198 23.8271 14.2907L21.088 20.7009L14.1598 21.3299C13.6508 21.3769 13.2208 21.721 13.0628 22.2069C12.9048 22.6929 13.0507 23.226 13.4358 23.562L18.6718 28.154L17.1278 34.9553C17.0149 35.4553 17.209 35.9723 17.6239 36.2722C17.8469 36.4333 18.1079 36.5154 18.371 36.5154C18.5979 36.5154 18.8229 36.4542 19.0249 36.3333L25.0001 32.7621L30.9732 36.3333C31.4103 36.5963 31.9612 36.5723 32.3752 36.2722C32.7904 35.9713 32.9843 35.4542 32.8713 34.9553L31.3273 28.154L36.5633 23.5629C36.9484 23.226 37.0955 22.6938 36.9374 22.2069Z"
                    fill="white"></path>
            </g>
            <defs>
                <clipPath id="clip0_3088_17938">
                    <rect width="24" height="24" fill="white" transform="translate(13 13)"></rect>
                </clipPath>
            </defs>
        </svg></div>
        <div class="text-white text-sm font-medium">Your current plan.</div>
        <h1 class="text-white text-4xl font-bold"> {label}</h1>
        <div class="text-white font-medium  tracking-wide">$ {price} / month</div>
        <SecondaryButton size={'sm'} onClick={() => history.push('/account?activeKey=plans')}>
            Change plan
        </SecondaryButton>
    </div>
}

const DebitCard = ({ data = { card: {}, billing_details: {} } }) => {

    const { last4, exp_month, exp_year } = data.card
    const { name } = data.billing_details

    return <>
        <div class=" h-56  bg-red-100 rounded-xl relative text-white shadow-2xl ">

            <img class="relative object-cover w-full h-full rounded-xl" src="https://i.imgur.com/kGkSg1v.png" />

            <div class="w-full px-8 absolute top-8">
                <div class="flex justify-between">
                    <div class="">
                        <h1 class="font-light">
                            Name
                        </h1>
                        <p class="font-medium tracking-widest">
                            {name}
                        </p>
                    </div>
                    <img class="w-14 h-14" src="https://i.imgur.com/bbPHJVe.png" />
                </div>
                <div class="pt-1">
                    <h1 class="font-light">
                        Card Number
                    </h1>
                    <p class="font-medium tracking-more-wider">
                        •••• •••• •••• {last4}
                    </p>
                </div>
                <div class="pt-6 pr-6">
                    <div class="flex justify-between">

                        <div class="">
                            <h1 class="font-light text-xs text-xs">
                                Expiry
                            </h1>
                            <p class="font-medium tracking-wider text-sm">
                                {exp_month}/{exp_year}
                            </p>
                        </div>

                        <div class="">
                            <h1 class="font-light text-xs">
                                CVV
                            </h1>
                            <p class="font-bold tracking-more-wider text-sm">
                                ···
                            </p>
                        </div>
                        <div></div>
                    </div>
                </div>

            </div>
        </div>
    </>
}
const Billing = () => {


    const [
        organizations,
        setOrganizations,
    ] = useUserState(store => ([
        store.organizations,
        store.setOrganizations,
    ]))

    const { user } = useSelector(state => state.persistentState)

    const [saving, setSaving] = useState()

    const [myOrganization = {}, setMyOrganization] = useState(organizations?.find(o => o.owner?.id === user?.id))

    const planId = myOrganization?.billing?.plan || 'free'
    const hasPlan = planId !== 'free'
    const { data, isLoading } = useBillingQuery(planId !== 'free')

    const { email, username } = myOrganization?.owner

    const saveHandler = async () => {
        setSaving(true)

        try {
            await updateAccountSettings({ username, organizationName: myOrganization.name })
            setOrganizations(updateById(organizations, myOrganization))
        } catch (err) {
        } finally {
            setSaving(false)
        }
    }

    if (isLoading) return <Box>
        <Spin />
    </Box>

    return (

        <div class="">
            <div class="flex py-5 ">
                <Box 
                className="pr-4" 
                flex={4}
                
                >

                    <div className={'flex justify-evenly space-x-4'}>
                        <InfoCard data={data?.plan} />

                        <Box position='relative' className={!hasPlan && 'blur-sm'}>
                            <DebitCard data={data?.card?.data[0]} />
                        </Box>
                    </div>


                    <Box position='relative' className={!hasPlan && 'blur-sm'}>
                        <Table invoices={data.invoices} />
                    </Box>


                </Box>


                <Box position='relative'
                flex={2}
                    className={`rounded-xl py-2 border shadow-lg ${!hasPlan && 'blur-sm'}`}
                    >
                    <NextInvoice hasPlan={hasPlan} data={data.upcomingInvoice} />
                </Box>


            </div>
        </div>
    );
};

export default Billing;