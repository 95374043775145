import React, {useEffect, useState} from 'react';
import SectionHeader from "../../../../shared/SectionHeader";
import {Divider, Box, HStack, Center} from "@chakra-ui/react";
import {FieldInlineWrapper} from "../../components/ThemeEditor";
import CustomSwitch from "../../../../shared/form/CustomSwitch";
import RightToolsBarImageSettings from "../../components/RightToolsBarImageSettings";
import RightToolsBarLayoutSettings from "../../components/RightToolsBarLayoutSettings";
import useFormConfiguratorStore from "../../stores/useFormConfiguratorStore";
import {StyledSelect} from "../../../../shared/form/CustomSearchableSelect";
import InlineTextField from "../../../../shared/form/InlineTextField";
import useDebouncedEffect from "../../../../hooks/useDebounceEffect";
import {Space} from "antd";
import {HeartOutlined, LikeOutlined, StarOutlined} from "@ant-design/icons";
import CustomNumberInput from "../../../../shared/form/CustomNumberInput";


const Configurator = ({data = {}, onChange}) => {

  const {required, isMin, isMax, max, min} = data

  const onChangeHandler = (keyName, value) => {
    onChange({...data, [keyName]: value})
  }

  return <Box>
    <FieldInlineWrapper
      label={'Required'}
    >
      <CustomSwitch
        value={required}
        onChange={val => onChangeHandler('required', val)}
      />
    </FieldInlineWrapper>

    <FieldInlineWrapper
      label={'Min number'}
    >
      <CustomSwitch
        value={isMin}
        onChange={val => onChangeHandler('isMin', val)}
      />
    </FieldInlineWrapper>

    {isMin && <Box>
      <CustomNumberInput
        max={max}
        min={0}
        value={min}
        onChange={val => onChangeHandler('min', val)}
      />
    </Box>}

    <FieldInlineWrapper
      label={'Max number'}
    >
      <CustomSwitch
        value={isMax}
        onChange={val => onChangeHandler('isMax', val)}
      />
    </FieldInlineWrapper>

    {isMax && <Box>
      <CustomNumberInput
        max={999999999999}
        min={min}
        value={max}
        onChange={val => onChangeHandler('max', val)}
      />
    </Box>}

  </Box>
}

const NumberFieldSettings = ({activeField = {}, onChange}) => {

  const imageUrl = activeField?.layoutConfig?.imageUrl

  const [activeFieldId, setActiveFieldId] = useState(activeField?.id)
  const [generalSettings, setGeneralSettings] = useState(activeField?.generalSettings)

  const onGeneralSettingsChange = (data) => {
    setGeneralSettings({...data})
  }

  useDebouncedEffect(() => onChange(generalSettings), [generalSettings], 100)

  useEffect(() => {
    if (activeField.id !== activeFieldId) {
      setActiveFieldId(activeField?.id)
      setGeneralSettings(activeField?.generalSettings)
    }
  }, [activeField])

  return (
    <div>
      <SectionHeader
        my={'10px'}
      >
        Settings
      </SectionHeader>
      <Configurator
        data={generalSettings}
        onChange={onGeneralSettingsChange}
      />
      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      <RightToolsBarImageSettings/>

      <Divider
        my={'5px'}
        borderColor={'#cccccc'}
      />

      { <>
        <RightToolsBarLayoutSettings/>
      </>}

    </div>
  );
};

export default NumberFieldSettings;